import {PipeTransform} from '@angular/core';
import {LocalDate} from '../../models/local-date.model';
import {SortDirectionEnum} from '../../enums/common/sort-direction.enum';

export function compare(v1, v2) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

export function sort(list: any[], column: string, direction: string): any[] {
  if (direction === '') {
    return list;
  } else {
    const columnElems = column.split('.');
    return [...list].sort((a, b) => {
      let aVal = a;
      let bVal = b;
      columnElems.map(x => {
        aVal = aVal[x];
        bVal = bVal[x];
        if (x === 'season') {
          aVal = (aVal.year * 100 + aVal.month);
          bVal = (bVal.year * 100 + bVal.month);
        }
      });
      const res = compare(aVal, bVal);
      return direction === 'asc' ? res : -res;
    });
  }
}

export function matches(ana: any, term: string, pipe: PipeTransform) {
  return ana;
}

export interface SearchResult {
  list: any[];
  filteredList?: any[];
  total: number;
}

export interface State {
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  searchID?: string;
  searchType?: number;
  searchName?: string;
  searchStatus?: any;
  searchSeason?: number;
  searchCompany?: number;
  sortColumn?: string;
  searchYearVal?: boolean;
  dirSelected?: number;
  searchEmpty?: number;
  searchPeriod?: string;
  searchDefault?: any;
  searchInfoSet?: number;
  searchInfoSetTemplate?: number;
  searchProgress?: boolean;
  searchDateFrom?: LocalDate;
  searchDateTo?: LocalDate;
  searchReminderDateFrom?: LocalDate;
  searchReminderDateTo?: LocalDate;
  searchRole?: any;
  searchTraceProcess?: string;
  searchTraceActivity?: string;
  sortDirection?: SortDirectionEnum;
}
