import { EventEmitter, Injectable } from '@angular/core';
import { DmsDownloadElement } from '../models/dms-download-element';
import { DmsFolderRepositoryService } from './dms-folder-repository.service';
import { first, flatMap, switchMap, takeWhile, tap } from 'rxjs/operators';
import { timer } from 'rxjs';
import { DownloadedItem } from '../models/common/downloaded-item';
import { AuthenticationService } from '@pwc/security';
import { ExportHistory } from '../models/api/export-history/export-history';

@Injectable({
  providedIn: 'root'
})
export class DownloaderService {

  queue: DmsDownloadElement[] = [];
  onDownloadCompleted: EventEmitter<DownloadedItem> = new EventEmitter<DownloadedItem>();

  constructor(private dmsRepositoryService: DmsFolderRepositoryService,
    private authenticationService: AuthenticationService) {
    this.authenticationService.logout$.subscribe(() => {
      this.queue = [];
    });
  }

  /**
   * add element to queue (visible on frontend in the lower right corner) and triggers download
   * @param dmsDownloadElement 
   * @param extension 
   */
  public download(dmsDownloadElement: DmsDownloadElement, extension?: string) {
    this.queue.push(dmsDownloadElement);
    this.startDownloading(extension);
  }

  /**
   * for every element in the queue, calls backend api if the element is ready-status
   * @param extension 
   */
  startDownloading(extension?: string) {
    for (const item of this.queue.filter(val => !val.isDownloading)) {
      if (!item.isError && !item.isSuccess && extension !== ".csv") {
        item.isDownloading = true;
        const call = timer(0, 2000).pipe(
          flatMap(value => this.dmsRepositoryService.getDownloadStatus(item.id).pipe(
            takeWhile(val => val.status === 'READY' || val.status === 'ERROR'),
            tap(val => {
              item.fileName = val.fileName;
              item.isError = val.status === 'ERROR';
              item.isSuccess = val.status === 'READY';
            })
          )),
          first(),
          switchMap(value => this.dmsRepositoryService.getDownloadByToken(item.token, extension)),
        ).subscribe((res: { blob: Blob, url: string, fileName: string }) => {
          item.isDownloading = false;
          if (item.isSuccess) {
            item.url = res.url;
            const downloadItem = new DownloadedItem();
            downloadItem.fileName = item.fileName;
            downloadItem.url = res.url;
            downloadItem.blob = res.blob;
            call.unsubscribe();
            this.onDownloadCompleted.emit(downloadItem);
          }
        }, error => {
          item.isSuccess = false;
          item.isDownloading = false;
          item.isError = true;
        }
        );
      }
    }
  }

  /**
   * Downlolad export inside export history page
   * @param item 
   * @returns 
   */
  downloadExportHistory(item: ExportHistory) {
    return this.dmsRepositoryService.getDownloadByToken(item.token, ".csv")
      .subscribe((res: { blob: Blob, url: string, fileName: string }) => {
        const downloadItem = new DownloadedItem();
        downloadItem.fileName = item.fileName;
        downloadItem.url = res.url;
        downloadItem.blob = res.blob;
        this.onDownloadCompleted.emit(downloadItem);
      }, error => {
        console.log(error);
      }
      );
  }

  get isDownloading(): boolean {
    return this.queue.filter(val => val.isDownloading).length > 0;
  }

  get isError(): boolean {
    return this.hasItems && !this.isDownloading && this.queue.filter((item: DmsDownloadElement) => item.isError).length > 0;
  }

  get isSuccess(): boolean {
    return this.hasItems && !this.isDownloading && !this.isError;
  }

  public removeAll(): void {
    this.queue = [];
  }

  get totalItemsCount(): number {
    return this.queue.length;
  }

  get hasItems(): boolean {
    return this.totalItemsCount > 0;
  }
}
