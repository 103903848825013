import { AuthenticationOptions } from '@pwc/security';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthenticationServiceConfig implements AuthenticationOptions {
  cookieDomain = environment.cookieDomain;
  cookiePath = environment.cookiePath;
  frontendUrl = environment.communicoUrl;
  loginUrl = environment.communicoUrl;
  // loginUrl = environment.loginUrl;
  url = environment.authUrl;
  secureFlag = environment.secureFlag;
}
