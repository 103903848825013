import {ApiResource} from '../../common/base/api-resource';
import {ShareState} from '../../../enums/share/share-state.enum';
import {Share2UserResource} from './share2-user-resource';
import {Share2ElementsResource} from './share2-elements-resource';
import {DmsSharingEntityResource} from './dms-sharing-entity-resource';
import {SortDirectionEnum} from '../../../enums/common/sort-direction.enum';

export class DmsSharingResource extends ApiResource {
  sphereId: number;
  elementIds: number[];
  ownerUserId: number;
  name: string;
  permissionRead: boolean;
  permissionDownload: boolean;
  permissionUpload: boolean;
  permissionReSharing: boolean;
  permissionDelete: boolean;
  permissionLawConservationAllowed: boolean;
  permissionLawConservationDownloadAllowed: boolean;
  validityStartDate?: number;
  validityEndDate?: number;
  shareState: ShareState;
  share2Users: Share2UserResource[];
  share2Elements: Share2ElementsResource[];
  userEmails: string[];
  fiscalYears: number[];
  sharingDate: number;
  userIsolation = false;

  sharingEntryList: DmsSharingEntityResource[] = [];

  // Search parameters
  elementId?: number;
  fiscalYearId?: number;
  userId?: number;

  page?: number;
  sort?: string;
}
