import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '@pwc/security';
import { DmsUploadElementResource } from '../models/api/documents/dms-upload-element-resource';
import { isNullOrUndefined } from 'util';
import { FileItem } from '../modules/file-upload/file-item.class';
import { FileUploader, FileUploaderOptions, ParsedResponseHeaders } from '../modules/file-upload/file-uploader.class';
import { UploadFile } from '../models/upload-file';
import { DmsElementResource } from '../models/api/documents/dms-element-resource';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UploaderService {
  uploader: FileUploader;
  onUploadCompleted: EventEmitter<void> = new EventEmitter<void>();
  onItemCompleted: EventEmitter<DmsElementResource> = new EventEmitter<DmsElementResource>();
  private uploaderOptions: FileUploaderOptions;
  private data: DmsUploadElementResource;

  constructor(private authenticationService: AuthenticationService) {
    const cookie = this.authenticationService.cookieValue;

    this.uploaderOptions = {
      url: `${environment.apiUrl}/folder/upload`,
      authTokenHeader: 'Authorization',
      authToken: `Bearer ${cookie.access_token}`
    };

    this.uploader = new FileUploader(this.uploaderOptions);
  }

  public setData(data: DmsUploadElementResource): this {
    this.data = data;
    return this;
  }

  public upload() {
    if (!this.uploader.queue.length) {
      console.warn('UploaderService: uploader queue is empty.');
      return;
    }

    // Refresh uploader options to ensure access_token is correctly set.
    this.refreshOptions();

    //  Set custom items data.
    this.setItemsData();

    // Prevent authorization errors.
    this.uploader.onBeforeUploadItem = (item: FileItem) => {
      item.withCredentials = false;
    };

    this.uploader.onCompleteItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
      // console.debug({item, response, status, headers});
      if (status === 200) {
        const res = JSON.parse(response) as DmsElementResource;
        // console.debug(res);
        this.onItemCompleted.emit(res);
      }
      return { item, response, status, headers };
    };

    this.uploader.onCompleteAll = () => {
      this.onUploadCompleted.emit();
    };

    // console.debug('Upload all items');
    //  Upload all queued items.
    this.uploader.uploadAll();
  }

  public removePending(): void {
    if (!this.uploader.queue.length) {
      return;
    }

    const toRemove = this.uploader.queue.filter((item: FileItem) => !item.isSuccess && !item.isError);

    for (const item of toRemove) {
      this.uploader.removeFromQueue(item);
    }
  }

  public removeAll(): void {
    this.uploader.queue = [];
  }

  get pendingItemsCount(): number {
    return this.uploader.getNotUploadedItems().length;
  }

  get totalItemsCount(): number {
    return this.uploader.queue.length;
  }

  get completedItemsCount(): number {
    return this.totalItemsCount - this.pendingItemsCount;
  }

  get isUploading(): boolean {
    return this.uploader.isUploading;
  }

  get isPending(): boolean {
    return !this.isUploading && this.pendingItemsCount > 0;
  }

  get progress(): number {
    return this.uploader.progress;
  }

  get hasItems(): boolean {
    return this.totalItemsCount > 0;
  }

  get isError(): boolean {
    return this.hasItems && !this.isUploading && this.uploader.queue.filter((item: FileItem) => item.isError).length > 0;
  }

  get isSuccess(): boolean {
    return this.hasItems && !this.isPending && !this.isUploading && !this.isError;
  }

  get isCorrupted(): boolean {
    return this.hasItems && !this.isUploading && this.uploader.queue.filter((item: FileItem) => item.isCorrupted).length > 0;
  }

  private refreshOptions() {
    const cookie = this.authenticationService.cookieValue;

    // Ensure the correct auth token is set in the request.
    this.uploaderOptions.authToken = `Bearer ${cookie.access_token}`;

    this.uploader.setOptions(this.uploaderOptions);
  }

  // Add custom data to upload request body.
  private setItemsData() {
    this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
      if (!isNullOrUndefined(fileItem.formData) && (!Array.isArray(fileItem.formData) || fileItem.formData.length !== 0)) {
        const data: UploadFile = fileItem.formData;
        form.append('parentId', data.parentId || 0);
        form.append('fiscalYear', data.fiscalYear);

        if (!isNullOrUndefined(data.fileName)) {
          form.append('name', data.fileName);
        } else {
          form.append('name', fileItem.file.name);
        }

        if (!isNullOrUndefined(data.meta)) {
          for (const meta of data.meta) {
            if (!isNullOrUndefined(meta.value)) {
              form.append(`metadata[${meta.elementMetadataReg.code}]`, meta.value);
            }
          }
        }

        if (!isNullOrUndefined(data.documentDate)) {
          form.append('documentDate', moment(data.documentDate).format('YYYY-MM-DD'));
        }

        if (!isNullOrUndefined(data.fiscalStartDate)) {
          form.append('fiscalStartDate', moment(data.fiscalStartDate).format('YYYY-MM-DD'));
        }

        if (!isNullOrUndefined(data.fiscalEndDate)) {
          form.append('fiscalEndDate', moment(data.fiscalEndDate).format('YYYY-MM-DD'));
        }
      } else {
        form.append('parentId', this.data.parentId || 0);
        form.append('fiscalYear', this.data.fiscalYear);
        form.append('name', fileItem.file.name);
      }

      form.append('sphereId', this.data.sphereId);
      form.append('excludeFromLawConservation', this.data.excludeFromLawConservation);
      form.append('lawConservation', '' + (this.data.lawConservation || false));

      if (!isNullOrUndefined(this.data.forceOverrideById)) {
        form.append('forceOverrideById', this.data.forceOverrideById);
      }

      if (!isNullOrUndefined(this.data.connection)) {
        form.append('connection', JSON.stringify(this.data.connection));
      }

      return { fileItem, form };
    };
  }
}
