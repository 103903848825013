import {ApiResource} from '../../common/base/api-resource';
import {LawConservationConfigurationFiscalYear} from '../../law-conservation/law-conservation-configuration-fiscal-year';

export class LawConservationListConfigurationResource extends ApiResource {

  sphereId: number;
  userId: number;
  id: number;
  name: string;
  bucket: string;
  year: LawConservationConfigurationFiscalYear[];
  alias: string;
  date: Date;

  page?: number;
  sort?: string;
  sortType?: string;

  searchFiscalYear: number;
}
