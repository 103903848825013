import {Injectable} from '@angular/core';
import {DmsElementResource} from '../models/api/documents/dms-element-resource';
import {DmsElement} from '../models/dms-element';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SphereService} from './sphere.service';
import {PageableService} from './common/pageable.service';
import {DmsMetadataService} from './dms-metadata.service';
import {map} from 'rxjs/operators';
import {isNullOrUndefined} from 'util';
import {CheckCrossYearResource} from '../models/api/check-cross-year-resource';
import {DmsRepositoryResponse} from '../models/api/documents/dms-repository-response';
import {PageableResponse} from '../models/api/pageable-response';
import {DmsFolderRepositoryService} from './dms-folder-repository.service';
import {DmsDocClassTypeResource} from '../models/api/documents/dms-doc-class-type-resource';
import {DmsDocClassType} from '../models/doc_class_type/dms-doc-class-type';

@Injectable({
  providedIn: 'root'
})
export class DmsDocClassTypeRepositoryService extends PageableService<DmsDocClassTypeResource, DmsDocClassType> {
  protected endpoint = 'doc_class_type';

  constructor(http: HttpClient) {
    super(http);
  }

  public search(search?: Partial<DmsDocClassTypeResource>): Observable<DmsDocClassType[]> {
    return this.get<DmsDocClassTypeResource[]>('').pipe(
      map((list: DmsDocClassTypeResource[]): DmsDocClassType[] => this.convertListToModel(list))
    );
  }

  public getBySphere(sphereId: number): Observable<DmsDocClassType[]> {
    let params = new HttpParams();
    params = params.set('sphere', sphereId.toString());

    return this.get<DmsDocClassTypeResource[]>('by-sphere', {params}).pipe(
      map((list: DmsDocClassTypeResource[]): DmsDocClassType[] => this.convertListToModel(list))
    );
  }

  convertModelToResource(model: DmsDocClassType): DmsDocClassTypeResource {
    const resource = new DmsDocClassTypeResource();
    Object.assign(resource, model);
    return resource;
  }

  convertResourceToModel(resource: DmsDocClassTypeResource): DmsDocClassType {
    const model = new DmsDocClassType();
    Object.assign(model, resource);
    return model;
  }
}
