import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {DmsElement} from '../models/dms-element';
import {AuthenticationService} from '@pwc/security';

@Injectable({
  providedIn: 'root'
})
export class DmsCartMoveService {

  private listSubject: BehaviorSubject<DmsElement[]> = new BehaviorSubject<DmsElement[]>([]);
  list$: Observable<DmsElement[]> = this.listSubject.asObservable();

  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.logout$.subscribe(() => {
      this.clear();
    });
  }

  public push(element: DmsElement): void {
    this.pushAll([element]);
  }

  public pushAll(elements: DmsElement[]): void {
    const list = [...this.listSubject.getValue()];
    elements.forEach(value => {
      if (this.listSubject.getValue().filter(value1 => value === value1).length === 0) {
        list.push(value);
      }
    });
    this.listSubject.next(list);
  }

  public remove(element: DmsElement): void {
    const list = this.listSubject.getValue();
    const newList: DmsElement[] = [];

    for (const item of list) {
      if (item.id !== element.id) {
        newList.push(item);
      }
    }

    this.listSubject.next(newList);
  }

  public removeById(id: number): void {
    const list = this.listSubject.getValue();
    const newList: DmsElement[] = list.filter((item: DmsElement) => item.id !== id);

    this.listSubject.next(newList);
  }

  public clear(): void {
    this.listSubject.next([]);
  }

  public isEmpty(): boolean {
    return this.listSubject.getValue().length === 0;
  }

  public isNotEmpty(): boolean {
    return !this.isEmpty();
  }

  public count(): number {
    return this.listSubject.getValue().length;
  }

  getSelected(): DmsElement[] {
    return this.listSubject.getValue();
  }
}
