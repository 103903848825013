<pwc-modal #addExportCSVModal id="add-export-csv-modal">
  <div modal-title>
    <pwc-icon name="export"></pwc-icon>
    {{'export.modal.title'|translate}}
  </div>

  <div modal-body>
    <p>{{'export.modal.select'|translate}}</p>

    <div>
      <button type="button" class="btn btn-default" (click)="typeList()">
        {{'export.modal.export-list'|translate}}
      </button>
    </div>
    <!-- <div>
      <button type="button" class="btn btn-default" (click)="typeMetadata()" *ngIf="!hasFolder">
        {{'export.modal.export-metadata'|translate}}
      </button>
    </div>
    <div>
      <button type="button" class="btn btn-default" (click)="typeTag()" *ngIf="!hasFolder">
        {{'export.modal.export-tag'|translate}}
      </button>
    </div> -->
    <div>
      <button type="button" class="btn btn-default" (click)="onExportCSVRich()" *ngIf="canDownloadExportCsvRich">
        {{'export.modal.rich-export'|translate}}
      </button>
    </div>
  </div>
  <div modal-footer class="text-right">
    <button type="button" class="btn btn-undo" (click)="close()">
      {{'generic.action.close'|translate}}
    </button>
  </div>
</pwc-modal>
