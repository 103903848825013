import {Component, Input, OnInit} from '@angular/core';
import {ContentLoaderComponent} from '@ngneat/content-loader';

@Component({
  selector: 'app-table-content-loader',
  templateUrl: './table-content-loader.component.html',
  styleUrls: ['./table-content-loader.component.scss']
})
export class TableContentLoaderComponent {
}
