<div class="card toolbar-card mb-3" style="width: 100%;">
  <div class="card-body card-table">
    <div class="d-flex p-2">
      <div class="m-2 pr-2 toolbar-box-border-right">
        <ng-content select="[toolbar-actions]"></ng-content>
      </div>
      <div class="m-2 align-self-center">
        <ng-content select="[toolbar-text]"></ng-content>
      </div>
    </div>
  </div>
</div>
