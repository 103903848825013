import {AfterViewChecked, ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Tag} from '../../../models/tag/tag';
import {PageableResponse} from '../../../models/api/pageable-response';
import {TagResource} from '../../../models/api/tag/tag-resource';
import {ColumnDef} from '../../../models/common/data-table/column-def';
import {SortDirectionEnum} from '../../../enums/common/sort-direction.enum';
import {Observable, of, Subject} from 'rxjs';
import {DataTableComponent} from '../../common/data-table/data-table.component';
import {NgbdSortableHeaderDirective, SortEvent} from '../../../directives/common/sortable.directive';
import {CdkColumnDef} from '@angular/cdk/table';
import {Router} from '@angular/router';
import {TagService} from '../../../services/tag.service';
import {isNullOrUndefined} from 'util';
import {debounceTime, switchMap} from 'rxjs/operators';
import {SphereService} from '../../../services/sphere.service';
import {Sphere} from '../../../models/sphere';
import {TagElementResource} from '../../../models/api/tag/tag-element-resource';
import {isSystemTag, tagType} from '../../../helpers/common/tag-utils';
import {ModalComponent} from '../../common/modals/modal/modal.component';
import {piDelete, piEdit, piExport, PwcIconsLibrary, piPen, piAdd} from "@pwc/icons";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-tags-manager',
  templateUrl: './tags-manager.component.html',
  styleUrls: ['./tags-manager.component.scss'],
  providers: [TagsManagerComponent]
})
export class TagsManagerComponent implements OnInit, AfterViewChecked {

  data: Tag[] = [];
  pagination: PageableResponse<TagResource[]>;
  columns: ColumnDef<Tag>[] = [
    {
      field: 'description',
      header: this.translateSrv.instant('generic.field.name'),
      sortable: true,
      cell: (row: Tag) => `${(row.description ? row.description : '')}`,
    }
  ];
  customColumnsActive: string[] = [];
  customMetaColumnsActive: string[] = [];

  sort: Map<string, SortDirectionEnum> = new Map();
  viewChecked = false;

  searchTerm = '';
  searchSubject: Subject<string> = new Subject<string>();
  search$: Observable<string> = this.searchSubject.asObservable();

  @ViewChild('dataTable') dataTable: DataTableComponent<Tag>;
  @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;
  @ViewChildren(CdkColumnDef) customColumns: QueryList<CdkColumnDef>;
  @ViewChild('deleteTagModal') deleteTagModal: ModalComponent;
  @ViewChild('editTagModal') editTagModal: ModalComponent;
  @ViewChild('createTag') createTag: ModalComponent;

  selectedTag: Tag = new Tag();
  currentSphereId: number;
  newTagName: string;
  privateTag: boolean;

  constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef, private tagService: TagService,
              private sphereService: SphereService, private iconsLibrary: PwcIconsLibrary,
              private translateSrv: TranslateService) {
    iconsLibrary.registerIcons([
      piDelete,
      piEdit,
      piPen,
      piAdd
    ]);
  }

  ngOnInit(): void {
    this.tagService.pagination$.subscribe((pagination: PageableResponse<TagResource[]>) => {
      this.pagination = pagination;
    });

    this.searchTerm = '';
    this.refreshList();

    this.search$.pipe(
      debounceTime(200),
      switchMap(() => this.sphereService.currentSphere),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        this.currentSphereId = sphere.id;
        return this.tagService.search(this.getSearchBody(sphere.id));
      }),
    ).subscribe((res: Tag[]) => this.updateElements(res));
  }

  private refreshList() {
    this.sphereService.currentSphere.pipe(
      switchMap((sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        this.currentSphereId = sphere.id;
        return this.tagService.search(this.getSearchBody(sphere.id));
      })
    ).subscribe((res: Tag[]) => this.updateElements(res));
  }

  private updateElements(elements: Tag[]) {
    this.data = [...elements] || [];
    this.refreshColumns();
  }

  private refreshColumns() {
    let columns = [];
    columns = columns.concat(this.customMetaColumnsActive);
    columns.push('type');
    columns.push('details');
    this.customColumnsActive = columns;
  }

  ngAfterViewChecked(): void {
    this.viewChecked = true;
    this.changeDetectorRef.detectChanges();
  }

  onSearch() {
    if (!isNullOrUndefined(this.searchTerm) && this.searchTerm.length >= 3) {
      this.searchSubject.next(this.searchTerm);
    } else {
      this.searchSubject.next(null);
    }
  }

  onPageChange(page: number): void {
    const data = this.getSearchBody(this.currentSphereId);
    data.page = page - 1;
    this.tagService.search(data).pipe()
      .subscribe((res: Tag[]) => this.updateElements(res));
  }

  onSort($event: SortEvent) {
    if ($event.direction === SortDirectionEnum.NONE) {
      this.sort.delete($event.column);
    } else {
      this.sort.set($event.column, $event.direction);
    }
    this.refreshList();
  }

  getTagType(tag: Tag): string {
    return tagType(tag);
  }

  enableChanges(tag: Tag): boolean {
    return !isSystemTag(tag);
  }

  openDeleteTag(tag: Tag) {
    this.copyTag(tag);
    this.deleteTagModal.open();
  }

  openEditTag(tag: Tag) {
    this.copyTag(tag);
    this.editTagModal.open();
  }

  openCrateTag() {
    this.newTagName = '';
    this.privateTag = false;
    this.createTag.open();
  }

  removeTag() {
    this.deleteTagModal.close();
    this.tagService.delete(this.selectedTag.id).pipe(switchMap(value => {
      const body: Partial<TagElementResource> = {
        sphereId: this.currentSphereId
      };
      return this.tagService.search(body);
    })).subscribe((res: Tag[]) => {
      this.selectedTag = new Tag();
      this.updateElements(res);
    });
  }

  editTag() {
    this.editTagModal.close();
    const tagResource = new TagResource();
    tagResource.id = this.selectedTag.id;
    tagResource.code = this.selectedTag.description;
    tagResource.description = this.selectedTag.description;
    tagResource.sphereId = this.currentSphereId;
    tagResource.type = this.selectedTag.type;
    tagResource.flagValid = true;
    this.tagService.update(tagResource).pipe(switchMap(value => {
      const body: Partial<TagElementResource> = {
        sphereId: this.currentSphereId
      };
      return this.tagService.search(body);
    })).subscribe((res: Tag[]) => {
      this.selectedTag = new Tag();
      this.updateElements(res);
    });
  }

  addTag() {
    this.createTag.close();
    const tagResource = new TagResource();
    tagResource.description = this.newTagName;
    tagResource.code = this.newTagName;
    tagResource.sphereId = this.currentSphereId;
    tagResource.onlyForMe = this.privateTag;
    this.tagService.insert(tagResource).pipe(switchMap(value => {
      const body: Partial<TagElementResource> = {
        sphereId: this.currentSphereId
      };
      return this.tagService.search(body);
    })).subscribe((res: Tag[]) => {
      this.newTagName = '';
      this.updateElements(res);
    });
  }

  private copyTag(tag: Tag) {
    this.selectedTag.id = tag.id;
    this.selectedTag.code = tag.code;
    this.selectedTag.description = tag.description;
    this.selectedTag.user = tag.user;
    this.selectedTag.sphereId = tag.sphereId;
    this.selectedTag.type = tag.type;
  }

  private getSearchBody(idSphere: number): Partial<TagElementResource> {
    const body: Partial<TagElementResource> = {sphereId: idSphere};

    if (!isNullOrUndefined(this.searchTerm) && this.searchTerm !== '' && this.searchTerm.length >= 3) {
      body.description = this.searchTerm;
    }

    if (!isNullOrUndefined(this.sort)) {
      if (isNullOrUndefined(body.sort)) {
        body.sort = '';
      }
      this.sort.forEach((value, key) => {
        body.sort += key + ',' + value;
      });
    } else {
      body.sort = null;
    }

    return body;
  }
}
