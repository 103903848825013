import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  selector: 'pwc-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() id: string;
  @Input() bodyPadding: boolean = true;
  @Input() showFooter: boolean = true;
  @Input() closable: boolean = true;
  @Input() dismissable: boolean = true;
  @Input() escapable; boolean = true;

  // Emits this modal's id on open.
  @Output() opened: EventEmitter<string> = new EventEmitter<string>();

  // Emits this modal's id on close.
  @Output() closed: EventEmitter<string> = new EventEmitter<string>();

  constructor(private ngxSmartModalService: NgxSmartModalService) {
  }

  get bodyClass() {
    let cssClass = '';
    if (this.bodyPadding) {
      cssClass += 'modal-body';
    }
    return cssClass;
  }

  open() {
    this.ngxSmartModalService.getModal(this.id).open();
    this.opened.emit(this.id);
  }

  close() {
    this.ngxSmartModalService.getModal(this.id).close();
    // this.onClose();
  }

  onClose() {
    this.closed.emit(this.id);
  }
}
