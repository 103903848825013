import {Component, Input, OnInit} from '@angular/core';
import {DmsSharing} from '../../../models/share/dms-sharing';

@Component({
  selector: 'app-share-type',
  templateUrl: './share-type.component.html',
  styleUrls: ['./share-type.component.scss']
})
export class ShareTypeComponent {
  @Input() sharing: DmsSharing = new DmsSharing();
  @Input() readonly: boolean = false;

  get canEdit(): boolean {
    return !this.readonly && this.sharing.flagValid;
  }

  get isPermissionReSharingEnabled(): boolean {
    return this.anyBasicPermissionEnabled();
  }

  get isPermissionDeleteEnabled(): boolean {
    return this.allBasicPermissionsEnabled();
  }

  get isPermissionLawConservationEnabled(): boolean {
    return this.allBasicPermissionsEnabled();
  }

  get isPermissionLawConservationDownloaddEnabled(): boolean {
    return this.allBasicPermissionsEnabled();
  }

  private anyBasicPermissionEnabled(): boolean {
    return this.sharing.permissionRead || this.sharing.permissionDownload || this.sharing.permissionUpload;
  }

  private allBasicPermissionsEnabled(): boolean {
    return this.sharing.permissionRead && this.sharing.permissionDownload && this.sharing.permissionUpload;
  }

  onPermissionReadChange(): void {
    if (!this.sharing.permissionRead) {
      this.sharing.permissionDownload = false;
      this.sharing.permissionReSharing = false;
      this.onPermissionDownloadChange();
    }
  }

  onPermissionDownloadChange() {
    if (this.sharing.permissionDownload) {
      this.sharing.permissionRead = true;
      this.onPermissionReadChange();
    } else {
      this.sharing.permissionUpload = false;
      this.onPermissionUploadChange();
    }
  }

  onPermissionUploadChange() {
    if (this.sharing.permissionUpload) {
      this.sharing.permissionDownload = true;
      this.onPermissionDownloadChange();
    } else {
      this.sharing.permissionLawConservationAllowed = false;
    }
  }
}
