<pwc-modal #lawConservationSendNowModal [id]="documentConfirmLawConservationSendNowModalId" (closed)="onClose($event)">
  <div modal-title>
    {{title}}
  </div>

  <div modal-body>
    <div class="row">
      <div class="col-md-12">
        {{message}}
      </div>
    </div>
  </div>

  <div modal-footer class="text-right">
    <button *ngIf="negativeButtonText !== ''" type="button" class="btn btn-danger" data-dismiss="modal"
            (click)="onNegativeClick()">{{negativeButtonText}}</button>
    <button *ngIf="positiveButtonText !== ''" type="button" class="btn btn-success ml-2"
            (click)="onPositiveClick()">{{positiveButtonText}}</button>
  </div>
</pwc-modal>
