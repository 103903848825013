import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ColumnDef} from '../../../models/common/data-table/column-def';
import {SelectableModel} from '../../../models/common/base/selectable-model';
import {isNullOrUndefined} from 'util';
import {SortEvent} from '../../../directives/common/sortable.directive';
import {CdkColumnDef, CdkTable} from '@angular/cdk/table';
import {SortDirectionEnum} from '../../../enums/common/sort-direction.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
/**
 * Usage:
 * In template: <data-table #dataTable></data-table>
 * In component: @ViewChild('dataTable') dataTable: DataTableComponent<BaseModel>;
 */
export class DataTableComponent<M extends SelectableModel> implements AfterViewChecked, OnDestroy {
  @Input() dataSource: M[] = [];
  @Input() columns: ColumnDef<M>[] = [];
  @Input() customColumns: string[] = [];
  @Input() customColumnDefs: QueryList<CdkColumnDef>;
  @Input() detailRowTpl: TemplateRef<any>;
  @Input() hoverable = true;

  @Input() enableSelect = false;
  @Input() allSelected = false;
  @Output() select: EventEmitter<number> = new EventEmitter<number>();
  @Output() selectAll: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sort: EventEmitter<SortEvent> = new EventEmitter<SortEvent>();

  private internalCustomColumns: CdkColumnDef[] = [];

  private changesSubscription: Subscription;

  @ViewChild(CdkTable) table: CdkTable<M>;

  sortType = '';
  sortReverse = 0;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }
  
  ngOnDestroy(): void {
    this.changesSubscription?.unsubscribe();
  }

  ngAfterViewChecked(): void {
    this.changesSubscription = this.customColumnDefs.changes.subscribe(() => {
      this.customColumnDefs.forEach((col: CdkColumnDef) => {
        if (!this.internalCustomColumns.filter((item: CdkColumnDef) => item.name === col.name).length) {
          this.table.addColumnDef(col);
          this.internalCustomColumns.push(col);
        }
      });
      this.changeDetectorRef.detectChanges();
    });
  }

  get displayedColumns(): string[] {
    let displayedColumns = [];

    if (this.enableSelect) {
      displayedColumns.push('selected');
    }

    const sortable = this.columns.filter(value => value.sortable);
    if (!isNullOrUndefined(sortable) && sortable.length > 0) {
      this.sortType = sortable[0].field;
    }

    displayedColumns = displayedColumns.concat(this.columns.map((col: ColumnDef<M>) => col.field))
      .concat(this.customColumns.filter((colName: string) => this.internalCustomColumns.filter(
        (item: CdkColumnDef) => item.name === colName).length > 0));

    return displayedColumns;
  }

  selectVisibleRows() {
    this.selectAll.emit(this.allSelected);
  }

  selectRows(id: number) {
    this.select.emit(id);
  }

  getColumnStyle(column: ColumnDef<M>): { [p: string]: string } {
    let style = {};

    if (!isNullOrUndefined(column.width)) {
      style = {...style, width: column.width};
    }

    return style;
  }

  handleDoubleClick(column: ColumnDef<M>, row: M) {
    if (isNullOrUndefined(column.onDblClick)) {
      return;
    }

    column.onDblClick(row);
  }

  hasDblClickHandler(column: ColumnDef<M>): boolean {
    return !isNullOrUndefined(column.onDblClick);
  }

  onSort($event: SortEvent) {
    this.sortType = $event.column;
    if ($event.direction === SortDirectionEnum.NONE) {
      this.sortReverse = 0;
    } else if ($event.direction === SortDirectionEnum.ASC) {
      this.sortReverse = 1;
    } else if ($event.direction === SortDirectionEnum.DESC) {
      this.sortReverse = 2;
    }
    this.sort.emit($event);
  }
}
