import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TagService } from '../../../services/tag.service';
import { TagResource } from '../../../models/api/tag/tag-resource';
import { Tag } from '../../../models/tag/tag';
import { TagElementResource } from '../../../models/api/tag/tag-element-resource';
import { DmsElement } from '../../../models/dms-element';
import { FiscalYearResource } from '../../../models/api/fiscal-year-resource';
import { TagTypeEnum } from '../../../enums/tag-type.enum';

@Component({
  selector: 'app-add-tag-modal',
  templateUrl: './add-tag-modal.component.html',
  styleUrls: ['./add-tag-modal.component.scss']
})
export class AddTagModalComponent implements OnInit {

  @Input() sphereId: number;
  @Input() fiscalYear: FiscalYearResource;
  @Input() elements: DmsElement[] = [];
  @Output() added: EventEmitter<void> = new EventEmitter<void>();

  selected: number[] = [];
  tags: Tag[] = [];
  newTag = false;
  createTag = false;
  privateTag = false;
  addTagCallback: any;
  isLoading: boolean = false;
  isSubmitted: boolean = false;

  constructor(private ngxSmartModalService: NgxSmartModalService, private tagService: TagService) {
  }

  ngOnInit(): void {
    this.addTagCallback = this.addTagPromise.bind(this);
  }

  open() {
    this.ngxSmartModalService.open('addTagModal');
    this.createTag = false;
    this.privateTag = false;
    this.newTag = false;
    this.isLoading = true;
    this.isSubmitted = false;
    this.tags = [];
    this.selected = [];
    const body: Partial<TagResource> = {
      sphereId: this.sphereId
    };
    this.tagService.search(body)
      .subscribe((res: Tag[]) => {
        this.tags = [...res.filter((tag: Tag) => tag.type !== TagTypeEnum.SYSTEM)];
        this.isLoading = false;
      });
  }

  confirm(): void {
    if (this.isSubmitted) {
      return;
    }

    this.isSubmitted = true;
    const body = new TagResource();
    body.sphereId = this.sphereId;
    body.fiscalYearId = this.fiscalYear.id;
    body.idElements = this.elements.map((el: DmsElement) => el.id);
    body.tagElementEntries = this.selected.map((id: number): TagElementResource => {
      const tag = this.tags.find((item: Tag) => item.id === id);
      const resource = new TagElementResource();
      if (tag.id > 0) {
        resource.id = tag.id;
      }
      resource.code = tag.code;
      resource.requiredInsert = this.createTag;
      resource.onlyForMe = this.privateTag;
      // console.debug('tag', tag);
      // console.debug('tagResource', resource);
      return resource;
    });

    this.tagService.addTag(body).subscribe(() => {
      this.isSubmitted = false;
      this.close();
      this.added.emit();
    });
  }

  close(): void {
    this.ngxSmartModalService.close('addTagModal');
  }

  addTagPromise(value) {
    // console.debug('addTagPromise', value);
    const tag = this.addTagNow(value);
    return tag.id;
  }

  addTagNow(name) {
    // console.debug('addTagNow', name);
    this.newTag = true;
    const tag = new Tag();
    tag.id = this.getMinId();
    tag.code = name;
    // console.debug('newTag', tag);
    this.tags.push(tag);
    return tag;
  }

  getMinId(): number {
    let min = -1;
    this.tags.forEach(value => {
      if (value.id < min) {
        min = value.id;
      }
    });
    return Math.min(-1, min - 1);
  }

  removeTag(tagOpt: any): void {
    const tag = this.tags.find(value => value.id === tagOpt.value);
    if (tag != null && (tag.flagValid === undefined || tag.flagValid === false)) {
      const index = this.tags.indexOf(tag, 0);
      if (index > -1) {
        this.tags.splice(index, 1);
      }

      if (this.tags.filter(value => value.flagValid === undefined || value.flagValid === false).length === 0) {
        this.newTag = false;
      }
    }
  }

  disableForNewTag(): boolean {
    return (this.newTag && !this.createTag);
  }
}
