import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Language } from '../../../models/common/language';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  languages: Language[] = [];
  currentLanguage: string;

  constructor(private translate: TranslateService,
    private router: Router) {
  }

  private returnUrl: string = environment.communicoUrl + '/documents'

  ngOnInit() {

    if (!this.router.url || this.router.url === '/') {
      this.router.navigate(['/documents']);
    }

  }

  useLanguage(language: string) {
    this.translate.use(language.toLowerCase());
  }
}
