<div class="mb-4 ml-2 mr-2">
  <textarea [(ngModel)]="text"
            rows="1"
            [placeholder]="'comments.add-note'|translate"
            class="form-control form-input"
            [disabled]="false"></textarea>
</div>

<div class="mb-4 mr-2">
  <div class="text-md-right">
    <button type="button" class="btn btn-secondary" (click)="addNote()">
      {{'comments.save-note-caps'|translate}}
    </button>
  </div>
</div>

<div *ngIf="commentList && commentList.length > 0"
  class="d-flex flex-row mb-4 mr-2 ml-2 align-items end box-filter">
  <div class="card w-100">
    <h5 class="card-header">
      <span>{{'comments.notes-history-caps'|translate}}</span>
      <fa-icon [icon]="faAngleUp" size="2x" class="text-primary"
               style="cursor: pointer;position:absolute;right:1rem;top:0.5rem;"
               (click)="collapsed = true" *ngIf="!collapsed"></fa-icon>
      <fa-icon [icon]="faAngleDown" size="2x" class="text-primary"
               style="cursor: pointer;position:absolute;right:1rem;top:0.5rem;"
               (click)="collapsed = false" *ngIf="collapsed"></fa-icon>
    </h5>
    <div class="card-body" *ngIf="!collapsed">
      <app-comment-list [commentList]="commentList">
      </app-comment-list>
    </div>
  </div>
</div>
