<pwc-modal #connectionTypeModal id="connection-type-modal">
  <div modal-title>
    <img src="/assets/images/icons/documents/tag.svg" [alt]="'connection.modal.link'|translate"
         style="width: 40px;height: 40px;">
    {{'connection.type.choose-title'|translate}}
  </div>

  <div modal-body>
    <p>{{'connection.type.choose'|translate}}</p>

    <div>
      <button type="button" class="btn btn-default" (click)="typeAlias()">
        {{'connection.type.show'|translate}}
      </button>
    </div>
    <div>
      <button type="button" class="btn btn-default" (click)="typeGroup()">
        {{'connection.type.link'|translate}}
      </button>
    </div>
  </div>
</pwc-modal>
