import {Injectable} from '@angular/core';
import {PageableService} from '../common/pageable.service';
import {isNullOrUndefined} from 'util';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PageableResponse} from '../../models/api/pageable-response';
import {LawConservationListConfigurationResource} from '../../models/api/law-conservation/law-conservation-list-configuration-resource';
import {LawConservationConfiguration} from '../../models/law-conservation/law-conservation-configuration';
import {LawConservationListBucketResponse} from '../../models/api/law-conservation/law-conservation-list-bucket-response';
import {LawConservationListBucketResource} from '../../models/api/law-conservation/law-conservation-list-bucket-resource';
import {LawConservationBucket} from '../../models/law-conservation/law-conservation-bucket';
import {LawConservationListConfigurationResponse} from '../../models/api/law-conservation/law-conservation-list-configuration-response';

@Injectable({
  providedIn: 'root'
})
export class LawConservationListConfigurationService extends PageableService<LawConservationListConfigurationResource, LawConservationConfiguration> {
  protected endpoint = 'law-conservation/configuration';

  constructor(http: HttpClient) {
    super(http);
  }

  convertModelToResource(model: LawConservationConfiguration): LawConservationListConfigurationResource {
    return Object.assign(new LawConservationListConfigurationResource(), model);
  }

  convertResourceToModel(resource: LawConservationListConfigurationResource): LawConservationConfiguration {
    const model = new LawConservationConfiguration();
    Object.assign(model, resource);
    return model;
  }

  search(search?: Partial<LawConservationListConfigurationResource>): Observable<LawConservationConfiguration[]> {
    let params = new HttpParams();
    if (!isNullOrUndefined(search)) {
      for (const key of Object.getOwnPropertyNames(search)) {
        params = params.set(key, `${search[key]}`);
      }
    }
    return this.get<LawConservationListConfigurationResponse>(`search`, {params}).pipe(
      map((response: LawConservationListConfigurationResponse) => {
        return response.list;
      }),
      map((response: PageableResponse<LawConservationListConfigurationResource[]>): LawConservationListConfigurationResource[] =>
        this.extractPaginationInfo(response)),
      map((list: LawConservationListConfigurationResource[]): LawConservationConfiguration[] => this.convertListToModel(list))
    );

  }
}
