import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthorizationService } from '@pwc/security';
import { Observable } from 'rxjs';
import { AuthorityCodeEnum } from '../enums/common/authority-code.enum';

@Injectable({
  providedIn: 'root'
})
export class SuperUserGuard implements CanActivate {
  constructor(private authorizationService: AuthorizationService, private router: Router) {

  }

  permission = false;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.authorizationService.checkAuthorities(AuthorityCodeEnum.ROLE_DSA_IMPORT).subscribe(permission => this.permission = permission);
    if(!this.permission) {
      // force redirect if user does not have superuser privileges
      return this.router.parseUrl('/documents');
    } else {
      return true;
    }

  }

}
 