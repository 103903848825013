import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileDocument } from '../../../models/file-document';
import { DmsElement } from '../../../models/dms-element';
import {
  piPen,
  PwcIconsLibrary
} from "@pwc/icons";

@Component({
  selector: 'app-documents-breadcrumbs',
  templateUrl: './documents-breadcrumbs.component.html',
  styleUrls: ['./documents-breadcrumbs.component.scss']
})
export class DocumentsBreadcrumbsComponent implements OnInit {

  @Input() path: DmsElement[] = [];
  @Input() showEdit = true;
  @Input() basePath = 'Documenti';
  @Input() componentName: string = 'documents';
  @Output() navigate: EventEmitter<DmsElement> = new EventEmitter<DmsElement>();

  public pathToBeTranslated: string = '';
  private breadcrumbStatic: string = 'breadcrumb.';

  constructor(private pwcIconsLibrary: PwcIconsLibrary) {
    this.pwcIconsLibrary.registerIcons([
      piPen,
    ]);
  }

  ngOnInit() {
    this.pathToBeTranslated = this.breadcrumbStatic + this.componentName;
  }

  onNavigate(file: DmsElement) {
    this.navigate.emit(file);
  }
}
