import {Injectable} from '@angular/core';
import {ApiResource} from '../../models/common/base/api-resource';
import {BaseModel} from '../../models/common/base/base-model';
import {HttpService} from './http.service';
import {Observable, Subject} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {isNullOrUndefined} from 'util';
import {PageableResponse} from '../../models/api/pageable-response';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export abstract class PageableService<R extends ApiResource, M extends BaseModel> extends HttpService<R, M> {
  paginationSubject: Subject<PageableResponse<R[]>> = new Subject<PageableResponse<R[]>>();
  pagination$: Observable<PageableResponse<R[]>> = this.paginationSubject.asObservable();

  public search(search?: Partial<R>): Observable<M[]> {
    let params = new HttpParams();
    if (!isNullOrUndefined(search)) {
      for (const key of Object.getOwnPropertyNames(search)) {
        params = params.set(key, `${search[key]}`);
      }
    }

    return this.get<PageableResponse<R[]>>('', {params}).pipe(
      map(this.extractPaginationInfo),
      map((list: R[]): M[] => this.convertListToModel(list))
    );
  }

  public extractPaginationInfo(response: PageableResponse<R[]>): R[] {
    if (isNullOrUndefined(this.paginationSubject)) {
      this.paginationSubject = new Subject<PageableResponse<R[]>>();
    }

    this.paginationSubject.next({
      content: ([] as R[]),
      pageable: response.pageable,
      totalElements: response.totalElements,
      size: response.size || 50,
      number: response.number,
      numberOfElements: response.numberOfElements
    });

    return response.content || [];
  }
}
