import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../abstract-value-accessor';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  providers: [MakeProvider(FormInputComponent)]
})
export class FormInputComponent extends AbstractValueAccessor<string> implements OnInit {
  @Input() id: string;
  @Input() name: string;
  @Input() type: string = 'text';
  @Input() cssClass: string | string[] | Set<string> | {
    [klass: string]: any;
  };
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() label: string;
  @Input() isInvalid: boolean = false;
  @Input() invalidFeedback: string;

  ngOnInit(): void {
    if (!isNullOrUndefined(this.id) && isNullOrUndefined(this.name)) {
      this.name = this.id;
    }
    this.getClasses();
  }

  public classes: string | string[] | Set<string> | { [klass: string]: any; } = { 'is-invalid': this.isInvalid };

  getClasses(): void {
    let classes = {
      'is-invalid': this.isInvalid
    };

    if (isNullOrUndefined(this.cssClass)) {
      this.classes = classes;
      return;
    }

    if (typeof this.cssClass === 'string') {
      classes[this.cssClass] = true;
    } else if (typeof this.cssClass === 'object') {
      classes = { ...classes, ...this.cssClass };
    } else {
      // @ts-ignore
      for (const item of this.cssClass) {
        classes[item] = true;
      }
    }

    this.classes = classes;
  }
}
