import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@pwc/common';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { DocumentTypeEnum } from '../../enums/document-type.enum';
import { FiscalYearResource } from '../../models/api/fiscal-year-resource';
import { PageableResponse } from '../../models/api/pageable-response';
import { DmsSharingResource } from '../../models/api/share/dms-sharing-resource';
import { Share2ElementsResource } from '../../models/api/share/share2-elements-resource';
import { Share2UserResource } from '../../models/api/share/share2-user-resource';
import { UserResource } from '../../models/api/user-resource';
import { DmsElement } from '../../models/dms-element';
import { FiscalYear } from '../../models/fiscalYear';
import { DmsSharing } from '../../models/share/dms-sharing';
import { DmsSharingElement } from '../../models/share/dms-sharing-element';
import { DmsSharingEntity } from '../../models/share/dms-sharing-entity';
import { PageableService } from '../common/pageable.service';
import { DmsFileRepositoryService } from '../dms-file-repository.service';
import { DmsFolderRepositoryService } from '../dms-folder-repository.service';

@Injectable({
  providedIn: 'root'
})
export class SharingElementsService extends PageableService<DmsSharingResource, DmsSharing> {
  protected endpoint = 'sharing';

  constructor(http: HttpClient,
    private dmsFolderRepositoryService: DmsFolderRepositoryService,
    private dmsFileRepositoryService: DmsFileRepositoryService) {
    super(http);
  }

  public searchByElement(search: Partial<DmsSharingResource>): Observable<DmsSharing[]> {
    let params = new HttpParams();
    if (!isNullOrUndefined(search)) {
      for (const key of Object.getOwnPropertyNames(search)) {
        params = params.set(key, `${search[key]}`);
      }
    }

    return this.get<DmsSharingResource[]>('by-element', { params })
      .pipe(
        map((res: DmsSharingResource[]): DmsSharing[] => this.convertListToModel(res))
      );
  }

  userExistsByEmail(email: string): Observable<boolean> {
    return this.findUserByEmail(email).pipe(
      first(),
      map((user: User) => {
        return !(isNullOrUndefined(user) || isNullOrUndefined(user.id));
      })
    );
  }

  findUserByEmail(email: string): Observable<UserResource> {
    return this.post<UserResource>('findUserByEmail', email);
  }

  convertModelToResource(model: DmsSharing): DmsSharingResource {
    return Object.assign(new DmsSharingResource(), model);
  }

  convertResourceToModel(resource: DmsSharingResource): DmsSharing {
    const model = new DmsSharing();
    Object.assign(model, resource);

    if (!isNullOrUndefined(resource.share2Users) && resource.share2Users.length > 0) {
      model.users = resource.share2Users.map<User>((item: Share2UserResource) => {
        const user = new User();
        user.id = item.userId;
        user.email = item.email;
        return user;
      });
    }

    if (!isNullOrUndefined(resource.share2Elements) && resource.share2Elements.length > 0) {
      model.elements = resource.share2Elements.map<DmsSharingElement>((item: Share2ElementsResource) => {
        const sharingElement = new DmsSharingElement();
        sharingElement.fiscalStartDate = item.fiscalStartDate;
        sharingElement.fiscalEndDate = item.fiscalEndDate;
        sharingElement.elementId = item.dmsElementId;

        if (!isNullOrUndefined(item.dmsElement)) {
          if (DocumentTypeEnum.FOLDER === item.dmsElement.documentType) {
            sharingElement.element = this.dmsFolderRepositoryService.convertResourceToModel(item.dmsElement);
          } else if (DocumentTypeEnum.FILE === item.dmsElement.documentType) {
            sharingElement.element = this.dmsFileRepositoryService.convertResourceToModel(item.dmsElement);
          }
        }

        return sharingElement;
      });
    }

    return model;
  }

  loadDetails(ids: number[]): Observable<DmsSharingResource[]> {
    return this.post<DmsSharingResource[]>(`sharingDetails`, ids).pipe(
      map((response: DmsSharingResource[]) => {
        return response;
      })
    );
  }

  loadDetail(id: number, onlyElementSelected: boolean): Observable<DmsSharing> {
    let params = new HttpParams();
    if (!isNullOrUndefined(onlyElementSelected)) {
      params = params.set('onlyElementSelected', `${onlyElementSelected ? 'true' : 'false'}`);
    }
    return this.get<DmsSharingResource>(`${id}`, { params }).pipe(
      map((response: DmsSharingResource) => {
        return this.convertResourceToModel(response);
      })
    );
  }

  searchUserSharing(search?: Partial<DmsSharingResource>): Observable<DmsSharing[]> {
    let params = new HttpParams();
    if (!isNullOrUndefined(search)) {
      for (const key of Object.getOwnPropertyNames(search)) {
        params = params.set(key, `${search[key]}`);
      }
    }
    return this.get<PageableResponse<DmsSharingResource[]>>(`userSharing`, { params }).pipe(
      map((response: PageableResponse<DmsSharingResource[]>): DmsSharingResource[] =>
        this.extractPaginationInfo(response)),
      map((list: DmsSharingResource[]): DmsSharing[] => {
        return this.convertListToModel(list);
      })
    );
  }

  public convertElementToSharingEntity(element: DmsElement, year: FiscalYear | FiscalYearResource = new FiscalYear()): DmsSharingEntity {
    const sharingEntity = new DmsSharingEntity();
    sharingEntity.elementId = element.id;
    sharingEntity.element = element;
    sharingEntity.fiscalYearId = year.id;
    sharingEntity.fiscalYear = year.year;

    return sharingEntity;
  }

  public convertElementsListToSharingEntities(elements: DmsElement[], year: FiscalYear | FiscalYearResource = new FiscalYear()): DmsSharingEntity[] {
    return elements.map((element: DmsElement) => this.convertElementToSharingEntity(element, year));
  }

  public convertSharingElementsListToSharingEntities(elements: DmsSharingElement[]): DmsSharingEntity[] {
    return elements.map((element: DmsSharingElement) => {
      const fiscalYear = new FiscalYear();
      fiscalYear.year = this.getYear(element);
      const entity = this.convertElementToSharingEntity(element.element, fiscalYear);
      entity.id = element.id;
      return entity;
    });
  }

  public getYear(element: DmsSharingElement): number {
    return moment(element.fiscalStartDate).year();
  }

  public getUsers(search: Partial<DmsSharingResource>): Observable<Share2UserResource[]> {
    let params = new HttpParams();
    if (!isNullOrUndefined(search)) {
      for (const key of Object.getOwnPropertyNames(search)) {
        params = params.set(key, `${search[key]}`);
      }
    }

    return this.get<Share2UserResource[]>('users', { params });
  }
}
