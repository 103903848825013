import {SelectableModel} from '../base/selectable-model';
import {NotificationRegistry} from './notification-registry';

export class NotificationModel extends SelectableModel {
  id: number;
  cause: string;
  date: any;
  notificationRegistry: NotificationRegistry;
  isRead: boolean;
  name: string;
  description: string;
}
