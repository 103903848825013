export enum LocalTranslateEnum {
  errorGeneric = 'generic.message.error-generic',
  errorSaving = 'generic.message.error-saving',
  errorLoading = 'generic.message.error-loading',
  deleteConfirm = 'generic.message.delete-confirm',
  deleteSelectedConfirm = 'generic.message.delete-selected-confirm',
  deleteSuccess = 'generic.message.delete-success',
  savingSuccess = 'generic.message.saving-success',
  usersOwnerActivity = 'generic.text.users-owner-activity',
  yesAnswer = 'generic.text.yes-answer',
  noAnswer = 'generic.text.no-answer'
}
