import { CdkColumnDef } from '@angular/cdk/table';
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, LOCALE_ID, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { NgbdSortableHeaderDirective, SortEvent } from '../../directives/common/sortable.directive';
import { SortDirectionEnum } from '../../enums/common/sort-direction.enum';
import { sphereType } from '../../helpers/common/sphere-utils';
import { PageableResponse } from '../../models/api/pageable-response';
import { SphereResource } from '../../models/api/sphere-resource';
import { ColumnDef } from '../../models/common/data-table/column-def';
import { DmsElement } from '../../models/dms-element';
import { Sphere } from '../../models/sphere';
import { ConfiguratorImportService } from '../../services/configurator-import.service';
import { SphereService } from '../../services/sphere.service';
import { DataTableComponent } from '../common/data-table/data-table.component';

@Component({
  selector: 'app-legal-entity',
  templateUrl: './legal-entity.component.html',
  styleUrls: ['./legal-entity.component.scss'],
  providers: [ConfiguratorImportService]
})
export class LegalEntityComponent implements OnInit, AfterViewChecked {

  data: Sphere[] = [];
  pagination: PageableResponse<SphereResource[]>;
  columns: ColumnDef<Sphere>[] = [
    {
      field: 'id',
      header: 'ID',
      sortable: true,
      cell: (row: Sphere) => `${(row.id ? row.id : '')}`,
      onDblClick: (row: Sphere) => this.openElement(row)
    },
    {
      field: 'name',
      header: this.translateSrv.instant('legal-entity.sphere-name'),
      sortable: true,
      cell: (row: Sphere) => `${(row.name ? row.name : '')}`,
      onDblClick: (row: Sphere) => this.openElement(row)
    },
    {
      field: 'type',
      header: this.translateSrv.instant('tag.type'),
      sortable: false,
      cell: (row: Sphere) => `${(row.sphereUserRelation ? sphereType(row) : '')}`,
      onDblClick: (row: Sphere) => this.openElement(row)
    },
    {
      field: 'society',
      header: this.translateSrv.instant('legal-entity.society'),
      sortable: true,
      cell: (row: Sphere) => `${(row.societyName ? row.societyName : '')}`,
      onDblClick: (row: Sphere) => this.openElement(row)
    },
    {
      field: 'society_vat',
      header: this.translateSrv.instant('legal-entity.society_vat'),
      sortable: false,
      cell: (row: Sphere) => `${(row.societyVatNumber ? row.societyVatNumber : '')}`,
      onDblClick: (row: Sphere) => this.openElement(row)
    },
    {
      field: 'owner',
      header: this.translateSrv.instant('legal-entity.owner'),
      sortable: false,
      cell: (row: Sphere) => `${(row.owner ? row.owner : '')}`,
      onDblClick: (row: Sphere) => this.openElement(row)
    }
  ];
  customColumnsActive: string[] = [];
  customMetaColumnsActive: string[] = [];

  sort: Map<string, SortDirectionEnum> = new Map();

  path: DmsElement[] = [];

  viewChecked = false;

  searchTerm = '';
  searchSubject: Subject<string> = new Subject<string>();
  search$: Observable<string> = this.searchSubject.asObservable();

  @ViewChild('dataTable') dataTable: DataTableComponent<DmsElement>;
  @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;
  @ViewChildren(CdkColumnDef) customColumns: QueryList<CdkColumnDef>;

  constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef,
    private sphereService: SphereService, @Inject(LOCALE_ID) public locale: string,
    private translateSrv: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.sphereService.pagination$.subscribe((pagination: PageableResponse<SphereResource[]>) => {
      this.pagination = pagination;
    });

    this.refreshList();

    this.search$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(() => {
        const body: Partial<Sphere> = {};
        if (!isNullOrUndefined(this.searchTerm) && this.searchTerm !== '') {
          body.name = this.searchTerm;
        }
        if (!isNullOrUndefined(this.sort)) {
          if (isNullOrUndefined(body.sort)) {
            body.sort = '';
          }
          this.sort.forEach((value, key) => {
            body.sort += key + ',' + value;
          });
        } else {
          body.sort = null;
        }

        return this.sphereService.search(body);
      })
    ).subscribe((data: Sphere[]) => {
      this.updateElements(data);
    });
  }

  private refreshList() {
    const body: Partial<Sphere> = {};
    if (!isNullOrUndefined(this.searchTerm) && this.searchTerm !== '') {
      body.name = this.searchTerm;
      body.societyName = this.searchTerm;
    }
    if (!isNullOrUndefined(this.sort)) {
      if (isNullOrUndefined(body.sort)) {
        body.sort = '';
      }
      this.sort.forEach((value, key) => {
        body.sort += key + ',' + value;
      });
    } else {
      body.sort = null;
    }
    this.sphereService.search(body).pipe()
      .subscribe((res: Sphere[]) => this.resetPathAndUpdateElements(res));
  }

  private resetPathAndUpdateElements(elements: Sphere[]) {
    this.path = [];
    this.searchTerm = '';

    this.updateElements(elements);
  }

  private updateElements(elements: Sphere[]) {
    this.data = [...elements] || [];
    this.refreshColumns();
  }

  private refreshColumns() {
    let columns = [];
    columns = columns.concat(this.customMetaColumnsActive);
    columns.push('details');
    this.customColumnsActive = columns;
  }

  ngAfterViewChecked(): void {
    this.viewChecked = true;
    this.changeDetectorRef.detectChanges();
  }

  onSearch() {
    if (!isNullOrUndefined(this.searchTerm) && this.searchTerm.length >= 3) {
      this.searchSubject.next(this.searchTerm);
    } else {
      this.searchSubject.next(null);
    }
  }

  openElement(elem: Sphere) {
    this.sphereService.setCurrentSphere(elem);
    this.router.navigate(['/documents']);
  }

  onPageChange(page: number): void {
    let sortValues = '';
    if (!isNullOrUndefined(this.sort)) {
      this.sort.forEach((value, key) => {
        sortValues += key + ',' + value;
      });
    } else {
      sortValues = null;
    }

    const data: Partial<Sphere> = {
      sort: sortValues,
      page: page - 1
    };

    if (!isNullOrUndefined(this.searchTerm) && this.searchTerm !== '') {
      data.name = this.searchTerm;
    }

    this.sphereService.search(data).pipe()
      .subscribe((res: Sphere[]) => this.updateElements(res));
  }

  onSort($event: SortEvent) {
    if ($event.direction === SortDirectionEnum.NONE) {
      this.sort.delete($event.column);
    } else {
      this.sort.set($event.column, $event.direction);
    }
    this.refreshList();
  }

}
