import {Component, Input, OnInit} from '@angular/core';
import {ContentLoaderComponent} from '@ngneat/content-loader';

@Component({
  selector: 'app-table-row-content-loader',
  templateUrl: './table-row-content-loader.component.html',
  styleUrls: ['./table-row-content-loader.component.scss']
})
export class TableRowContentLoaderComponent extends ContentLoaderComponent {
  width = 1140;
  height = 49;
  primaryColor: string = '#ccc';
}
