<div class="page-container">

    <div class="row">
        <div class="col">
            <dd class="col-sm-12 metadata-value">
                <h2 class="mb-4">{{ 'admin-export.title.main' | translate }}</h2>
            </dd>
        </div>
    </div>


    <div class="card">
        <div class="card-body row">
            <h5 class="card-title col-12">{{ 'admin-export.title.select-period' | translate }}
            </h5>

            <div class="item form-group-sm form-inline col-6">
                <div class="row align-items-center">
                    <div class="col-4">
                        <label for="configuration-filter-start-date">Da</label>
                    </div>
                    <div class="form-group form-input-group col-8">
                        <input type="text" class="form-control form-input no-shadow" placeholder="DD/MM/YYYY" ngbDatepicker (dateSelect)="setStartDate($event)" #d0="ngbDatepicker">
                        <div (click)="d0.toggle()" class="form-input-icon append pointer mr-2">
                            <img src="/assets/images/icons/calendar-small.svg">
                        </div>
                        <!-- <div *ngIf="!startDate" class="invalid-feedback" style="padding-top: 5px">{{errorStartDate}}</div> -->
                    </div>
                </div>
            </div>
            <div class="item form-group-sm form-inline col-6 row">

                <div class="row">
                    <label for="configuration-filter-end-date col-4">A</label>
                    <div class="form-group form-input-group col-8">
                        <input type="text" class="form-control form-input no-shadow" placeholder="DD/MM/YYYY" ngbDatepicker (dateSelect)="setEndDate($event)" #d1="ngbDatepicker">
                        <div (click)="d1.toggle()" class="form-input-icon append pointer mr-2">
                            <img src="/assets/images/icons/calendar-small.svg">
                        </div>
                        <!-- <div *ngIf="!endDate" class="invalid-feedback" style="padding-top: 5px">{{errorEndDate}}</div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col">
            <div class="text-right col-sm-12">
                <button type="button" class="btn btn-secondary" (click)="generateWeightReport()" [disabled]="!startDate || !endDate">
                    {{ 'admin-export.title.generate-report' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>