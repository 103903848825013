<div class="page-container">

    <app-documents-breadcrumbs componentName="tags" [showEdit]="false" basePath="Gestione Tag">
    </app-documents-breadcrumbs>

    <div class="d-flex flex-row mb-4 align-items-end">
        <div style="max-width: 300px;">
            <search-input [placeholder]="'generic.field.search'|translate" [label]="'documents.component.search-tag'|translate" [(ngModel)]="searchTerm" (ngModelChange)="onSearch()">
            </search-input>
        </div>
        <div class="ml-auto">
            <button class="btn btn-ghost ml-auto align-self-end text-nowrap" style="min-width: 125px" (click)="openCrateTag()">
        <!-- <img src="/assets/images/icons/documents/create_tag.svg" [alt]="'tag.create'|translate" class="btn-ghost-icon"> -->
        <pwc-icon name="add"></pwc-icon>
        <span class="d-inline-block" style="margin-left: 5px;">Tag</span>
      </button>
        </div>
    </div>

    <div class="card" *ngIf="data && viewChecked">
        <div class="card-body card-table">
            <data-table #dataTable [dataSource]="data" [columns]="columns" [customColumnDefs]="customColumns" [customColumns]="customColumnsActive" [enableSelect]="false" (sort)="onSort($event)">

                <ng-container cdkColumnDef="type">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col">{{'tag.type'|translate}}</th>
                    <td cdk-cell *cdkCellDef="let row">{{getTagType(row)}}</td>
                </ng-container>

                <ng-container cdkColumnDef="details">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col" style="width: 120px;"></th>
                    <td cdk-cell *cdkCellDef="let row" class="text-right">
                        <pwc-icon name="pen" [ngbTooltip]="'generic.action.edit-item'|translate" placement="left" *ngIf="enableChanges(row)" class="document-info-icon" (click)="openEditTag(row)"></pwc-icon>
                        <pwc-icon name="delete" [ngbTooltip]="'generic.action.delete.label'|translate" placement="left" *ngIf="enableChanges(row)" class="document-info-icon" (click)="openDeleteTag(row)"></pwc-icon>
                    </td>
                </ng-container>

            </data-table>
        </div>
    </div>

    <div class="my-4">
        <pwc-pagination *ngIf="pagination" [page]="pagination.number + 1" [pageSize]="pagination.size" [collectionSize]="pagination.totalElements" (pageChange)="onPageChange($event)"></pwc-pagination>
    </div>
</div>

<pwc-modal #deleteTagModal id="deleteTagModal">
    <div modal-title>{{'generic.action.delete.label'|translate}}</div>
    <div modal-body>
        {{'documents.metadata.delete-tag'|translate}} {{selectedTag.description}}
    </div>
    <div modal-footer class="text-right">
        <button type="button" class="btn btn-undo" (click)="deleteTagModal.close()">{{'generic.action.cancel'|translate}}</button>
        <button type="button" class="btn btn-success ml-2" (click)="removeTag()">{{'generic.action.confirm'|translate}}</button>
    </div>
</pwc-modal>

<pwc-modal #editTagModal id="editTagModal">
    <div modal-title>{{'generic.action.edit-item'|translate}}</div>
    <div modal-body>
        {{'tag.edit-tag'|translate}}
        <div class="form-group form-input-group">
            <input type="text" class="form-control form-input no-shadow" [(ngModel)]="selectedTag.description">
        </div>
    </div>
    <div modal-footer class="text-right">
        <button type="button" class="btn btn-undo" (click)="editTagModal.close()">{{'generic.action.cancel'|translate}}</button>
        <button type="button" class="btn btn-success ml-2" (click)="editTag()">{{'generic.action.confirm'|translate}}</button>
    </div>
</pwc-modal>

<pwc-modal #createTag id="createTag">
    <div modal-title>{{'tag.create'|translate}}</div>
    <div modal-body>
        {{'tag.digit-tag'|translate}}
        <div class="form-group form-input-group">
            <input type="text" class="form-control form-input no-shadow" [(ngModel)]="newTagName">
            <div class="custom-control custom-switch mr-3 mt-3">
                <input type="checkbox" class="custom-control-input" id="privateTag" [(ngModel)]="privateTag">
                <label for="privateTag" class="custom-control-label">{{'tag.private'|translate}}</label>
            </div>
        </div>
    </div>
    <div modal-footer class="text-right">
        <button type="button" class="btn btn-undo" (click)="createTag.close()">{{'generic.action.cancel'|translate}}</button>
        <button type="button" class="btn btn-success ml-2" (click)="addTag()">{{'generic.action.confirm'|translate}}</button>
    </div>
</pwc-modal>