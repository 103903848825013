import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ConnectionCartService} from '../../../services/connection-cart.service';
import {ConnectionElementType} from '../../../enums/connection-element-type.enum';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-connection-toolbar',
  templateUrl: './connection-toolbar.component.html',
  styleUrls: ['./connection-toolbar.component.scss']
})
export class ConnectionToolbarComponent {

  @Input() connectionType: ConnectionElementType;
  @Input() canCreateFolder: boolean;
  @Output() confirm: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  constructor(public connectionCartService: ConnectionCartService) {
  }

  onConfirm(): void {
    this.confirm.emit();
  }

  onCancel(): void {
    this.cancel.emit();
  }

  get isAlias(): boolean {
    return !isNullOrUndefined(this.connectionType) && this.connectionType === ConnectionElementType.ALIAS;
  }
}
