import {BaseModel} from '../common/base/base-model';
import {ShareState} from '../../enums/share/share-state.enum';
import {User} from '@pwc/common';
import {Share2UserResource} from '../api/share/share2-user-resource';
import {DmsSharingElement} from './dms-sharing-element';

export class DmsSharing extends BaseModel {
  flagValid: boolean = true;
  sphereId: number;
  elementIds: number[];
  ownerUserId: number;
  name: string;
  permissionRead: boolean = false;
  permissionDownload: boolean = false;
  permissionUpload: boolean = false;
  permissionReSharing: boolean = false;
  permissionDelete: boolean = false;
  permissionLawConservationAllowed: boolean = false;
  permissionLawConservationDownloadAllowed: boolean = false;
  validityStartDate?: number;
  validityEndDate?: number;
  shareState: ShareState;
  users: User[] = [];
  elements: DmsSharingElement[] = [];
  alwaysValid = true;
  userIsolation = false;
  sharingDate: number;
  share2Users: Share2UserResource[];
}
