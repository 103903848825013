<pwc-modal #documentShareModal id="shareModal">
    <!-- (closed)="cancel()" -->
    <div modal-title>
        <img src="/assets/images/icons/documents/share-dark.svg" [alt]="'documents.law.new-folder.create'|translate" style="width: 40px;height: 40px;"> {{title}}
    </div>

    <div modal-body>
        <ul ngbNav #nav="ngbNav" (navChange)="onTabChange($event)" orientation="horizontal" class="nav-pills">
            <li ngbNavItem="elements" id="elements" [disabled]="isDisabled(STEP_ELEMENTS)">
                <a ngbNavLink>{{'share.modal.shared-elements'|translate}}</a>
                <ng-template ngbNavContent>
                    <app-share-elements-list [sharing]="sharing" [elements]="elements" [canRemoveAll]="isNew" [readonly]="!isEditEnabled" (removed)="onRemove($event)"></app-share-elements-list>
                </ng-template>
            </li>
            <li ngbNavItem="share" id="share" [disabled]="isDisabled(STEP_SHARE)">
                <a ngbNavLink>{{'share.label'|translate}}</a>
                <ng-template ngbNavContent>
                    <app-share-details [sharing]="sharing" [readonly]="!isEditEnabled"></app-share-details>
                </ng-template>
            </li>
            <li ngbNavItem="share-type" id="share-type" [disabled]="isDisabled(STEP_SHARE_TYPE)">
                <a ngbNavLink>{{'share.modal.share-type'|translate}}</a>
                <ng-template ngbNavContent>
                    <app-share-type [sharing]="sharing" [readonly]="!isEditEnabled"></app-share-type>
                </ng-template>
            </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>



    <div modal-footer class="d-flex justify-content-between" style="width: 100%" *ngIf="isEditEnabled">
        <div>
            <button type="button" class="btn btn-default" (click)="back()" *ngIf="isNew && currentStep !== STEP_ELEMENTS" [disabled]="submitted">
        {{'generic.action.back'|translate}}
      </button>
        </div>
        <div *ngIf="isNew">
            <button type="button" class="btn btn-default" data-dismiss="modal" (click)="cancel()" [disabled]="submitted">
        {{'share.modal.cancel-sharing'|translate}}
      </button>
            <button type="button" class="btn btn-secondary" (click)="next()" *ngIf="currentStep !== STEP_SHARE_TYPE" [disabled]="submitted || !isCurrentStepValid">
        {{'generic.action.forward'|translate}}
      </button>
            <button type="button" class="btn btn-secondary" (click)="submit()" *ngIf="currentStep === STEP_SHARE_TYPE" [disabled]="submitted || !isValid">
        {{'generic.action.confirm'|translate}}
      </button>
        </div>
        <div *ngIf="!isNew">
            <button type="button" class="btn btn-danger mr-2" (click)="startDelete()" [disabled]="submitted">
        {{'share.modal.close-sharing'|translate}}
      </button>
            <pwc-button [variant]="btnSuccess" (click)="submit()" [disabled]="submitted || !isValid">
                {{'generic.action.confirm'|translate}}
            </pwc-button>
        </div>
    </div>
</pwc-modal>

<pwc-confirmation-modal #deleteSharingConfirmation [title]="'documents.component.error-title'|translate" [message]="'share.modal.message'|translate" (positiveButtonClick)="delete()"></pwc-confirmation-modal>