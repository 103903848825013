import {BaseModel} from '../common/base/base-model';
import {SelectableModel} from '../common/base/selectable-model';
import {ServiceTypeEnum} from '../../enums/law-conservation/service-type.enum';

export class LawConservationBucket extends SelectableModel {

  sphereId: number;
  description: string;
  date: Date;
  preservationSystem: ServiceTypeEnum;
  username: string;
  bucket: string;
  password: string;
  policy: string;

  allowDelete: boolean;
  allowModify: boolean;

}
