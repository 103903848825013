import { Component, ContentChild, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  NgbPaginationEllipsis, NgbPaginationFirst,
  NgbPaginationLast,
  NgbPaginationNext,
  NgbPaginationNumber,
  NgbPaginationPrevious
} from '@ng-bootstrap/ng-bootstrap';
import { PaginationConfig } from '../../models/pagination-config';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { getValueInRange, isNumber } from '../../util/util';

@Component({
  selector: 'pwc-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
  pageCount = 0;
  pages: number[] = [];

  tmpPage: number;

  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;

  // @ts-ignore
  @ContentChild(NgbPaginationEllipsis) tplEllipsis: NgbPaginationEllipsis;
  // @ts-ignore
  @ContentChild(NgbPaginationFirst) tplFirst: NgbPaginationFirst;
  // @ts-ignore
  @ContentChild(NgbPaginationLast) tplLast: NgbPaginationLast;
  // @ts-ignore
  @ContentChild(NgbPaginationNext) tplNext: NgbPaginationNext;
  // @ts-ignore
  @ContentChild(NgbPaginationNumber) tplNumber: NgbPaginationNumber;
  // @ts-ignore
  @ContentChild(NgbPaginationPrevious) tplPrevious: NgbPaginationPrevious;

  /**
   * If `true`, pagination links will be disabled.
   */
  @Input() disabled: boolean;

  /**
   * If `true`, the "First" and "Last" page links are shown.
   */
  @Input() boundaryLinks: boolean;

  /**
   * If `true`, the "Next" and "Previous" page links are shown.
   */
  @Input() directionLinks: boolean;

  /**
   * If `true`, the ellipsis symbols and firs/last page numbers will be shown when `maxSize` > number of pages.
   */
  @Input() ellipses: boolean;

  /**
   * Whether to rotate pages when `maxSize` > number of pages.
   *
   * The current page always stays in the middle if `true`.
   */
  @Input() rotate: boolean;

  /**
   * The number of items in your paginated collection.
   *
   * Note that this is not the number of pages. Page numbers are calculated dynamically based on `collectionSize` and `pageSize`.
   * Ex. if you have 100 items in your collection and displaying 20 items per page, you'll end up with 5 pages.
   */
  @Input() collectionSize: number;

  /**
   * The maximum number of pages to display.
   */
  @Input() maxSize: number;

  /**
   * The current page.
   *
   * Page numbers start with `1`.
   */
  @Input() page = 1;

  /**
   * The number of items per page.
   */
  @Input() pageSize: number;

  /**
   * An event fired when the page is changed. Will fire only if collection size is set and all values are valid.
   *
   * Event payload is the number of the newly selected page.
   * Page numbers start with `1`.
   */
  @Output() pageChange = new EventEmitter<number>(true);

  /**
   * The pagination display size.
   *
   * Bootstrap currently supports small and large sizes.
   */
  @Input() size: 'sm' | 'lg';

  constructor(config: PaginationConfig) {
    this.disabled = config.disabled;
    this.boundaryLinks = config.boundaryLinks;
    this.directionLinks = config.directionLinks;
    this.ellipses = config.ellipses;
    this.maxSize = config.maxSize;
    this.pageSize = config.pageSize;
    this.rotate = config.rotate;
    this.size = config.size;
  }

  hasPrevious(): boolean {
    return this.page > 1;
  }

  hasNext(): boolean {
    return this.page < this.pageCount;
  }

  nextDisabled(): boolean {
    return this.disabled || !this.hasNext();
  }

  previousDisabled(): boolean {
    return this.disabled || !this.hasPrevious();
  }

  selectPage(pageNumber: number): void {
    this.updatePages(pageNumber);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tmpPage = this.page;
    this.updatePages(this.page);
  }

  isEllipsis(pageNumber): boolean {
    return pageNumber === -1;
  }

  /**
   * Appends ellipses and first/last page number to the displayed pages.
   */
  private applyEllipses(start: number, end: number) {
    if (!this.ellipses) {
      return;
    }

    if (start > 1) {
      this.pages.unshift(-1);
    }
    if (start > 0) {
      this.pages.unshift(1);
    }
    if (end < (this.pageCount - 1)) {
      this.pages.push(-1);
    }
    if (end < this.pageCount) {
      this.pages.push(this.pageCount);
    }
  }

  /**
   * Rotates page numbers based on maxSize items visible.
   * Currently selected page stays in the middle.
   *
   * Ex. for selected page = 6;
   * [5,*6*,7] for maxSize = 3
   * [4,5,*6*,7] for maxSize = 4
   */
  private applyRotation(): [number, number] {
    let start = 0;
    let end = this.pageCount;
    const leftOffset = Math.floor(this.maxSize / 2);
    const rightOffset = this.maxSize % 2 === 0 ? leftOffset - 1 : leftOffset;

    if (this.page <= leftOffset) {
      // Very beginning, no rotation -> [0..maxSize]
      end = this.maxSize;
    } else if (this.pageCount - this.page < leftOffset) {
      // Very end, no rotation -> [len-maxSize..len]
      start = this.pageCount - this.maxSize;
    } else {
      // Rotate
      start = this.page - leftOffset - 1;
      end = this.page + rightOffset;
    }

    return [start, end];
  }

  /**
   * Paginates page numbers based on maxSize items per page.
   */
  private applyPagination(): [number, number] {
    const page = Math.ceil(this.page / this.maxSize) - 1;
    const start = page * this.maxSize;
    const end = start + this.maxSize;

    return [start, end];
  }

  private setPageInRange(newPageNo) {
    const prevPageNo = this.page;
    this.page = getValueInRange(newPageNo, this.pageCount, 1);
    // console.debug('newPageNo', newPageNo);
    // console.debug('page', this.page);

    if (this.page !== prevPageNo && isNumber(this.collectionSize)) {
      this.pageChange.emit(this.page);
    }
  }

  private updatePages(newPage: number) {
    this.pageCount = Math.ceil(this.collectionSize / this.pageSize);

    if (!isNumber(this.pageCount)) {
      this.pageCount = 0;
    }

    // fill-in model needed to render pages.
    this.pages.length = 0;
    for (let i = 1; i <= this.pageCount; i++) {
      this.pages.push(i);
    }

    // Set page within 1..max range
    this.setPageInRange(newPage);

    // Apply maxSize if necessary.
    if (this.maxSize > 0 && this.pageCount > this.maxSize) {
      let start = 0;
      let end = this.pageCount;

      // Either paginating or rotating page numbers
      if (this.rotate) {
        [start, end] = this.applyRotation();
      } else {
        [start, end] = this.applyPagination();
      }

      this.pages = this.pages.slice(start, end);

      // Adding ellipses
      this.applyEllipses(start, end);

      this.tmpPage = this.page;
    }
  }

  applyPageChange(): void {
    setTimeout(() => {
      this.tmpPage = getValueInRange(this.tmpPage, this.pageCount, 1);
      this.selectPage(this.tmpPage);
    }, 100);
  }

  resetPageChange(): void {
    setTimeout(() => {
      this.tmpPage = this.page;
    }, 100);
  }
}
