import {ApiResource} from '../../common/base/api-resource';
import {UserResource} from '../user-resource';
import {TagElementResource} from './tag-element-resource';
import {TagTypeEnum} from '../../../enums/tag-type.enum';

export class TagResource extends ApiResource {
  code: string;
  description: string;
  sphereId: number;
  user?: UserResource;
  fiscalYearId: number;
  onlyForMe: boolean;
  type: TagTypeEnum;

  idElements: number[] = [];
  tagElementEntries: TagElementResource[] = [];

  page?: number;
  sort?: string;
}
