import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DmsSharing } from '../../../models/share/dms-sharing';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@pwc/common';
import { isNullOrUndefined } from 'util';
import { ValidationUtils } from '../../../helpers/common/validation-utils';
import { LocalDate } from '../../../models/local-date.model';
import { SharingElementsService } from '../../../services/share/sharing-elements.service';
import { first } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { piCalendarSmall, piClear, piDelete, piEdit, piView, PwcIconsLibrary } from "@pwc/icons";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-share-details',
  templateUrl: './share-details.component.html',
  styleUrls: ['./share-details.component.scss']
})
export class ShareDetailsComponent implements OnChanges, OnInit {
  @Input() sharing: DmsSharing = new DmsSharing();
  @Input() readonly: boolean = false;
  emailInputValue: string;

  private sharingAppoggio: DmsSharing = new DmsSharing();

  validityStartDate: LocalDate;
  validityEndDate: LocalDate;

  error: string;

  searchingUserSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchingUser$: Observable<boolean> = this.searchingUserSubject.asObservable();

  constructor(private sharingElementsService: SharingElementsService, private iconsLibrary: PwcIconsLibrary, private translateSrv: TranslateService) {
    iconsLibrary.registerIcons([
      piCalendarSmall
    ]);
  }

  ngOnInit(): void {

    this.sharingAppoggio = JSON.parse(JSON.stringify(this.sharing));

    this.sharingAppoggio?.users?.forEach(
      user => {
        this.userBadgeList.push({ id: user.email, label: user.email, canRemove: !this.readonly });
      });
    this.ngOnChanges();  
  }

  ngOnChanges(): void {
    if (!isNullOrUndefined(this.sharingAppoggio.validityStartDate)) {
      this.validityStartDate = new LocalDate(
        new Date(this.sharingAppoggio.validityStartDate).getFullYear(),
        new Date(this.sharingAppoggio.validityStartDate).getMonth() + 1,
        new Date(this.sharingAppoggio.validityStartDate).getDate()
      );
    }

    if (!isNullOrUndefined(this.sharingAppoggio.validityEndDate)) {
      this.validityEndDate = new LocalDate(
        new Date(this.sharingAppoggio.validityEndDate).getFullYear(),
        new Date(this.sharingAppoggio.validityEndDate).getMonth() + 1,
        new Date(this.sharingAppoggio.validityEndDate).getDate()
      );
    }
  }

  setNgbValidityStartDate(date: NgbDate) {
    this.sharingAppoggio.validityStartDate = new Date(date.year, date.month - 1, date.day).getTime();
    this.sharing.validityStartDate=this.sharingAppoggio.validityStartDate;
  }

  setNgbValidityEndDate(date: NgbDate) {
    this.sharingAppoggio.validityEndDate = new Date(date.year, date.month - 1, date.day).getTime();
    this.sharing.validityEndDate=this.sharingAppoggio.validityEndDate;
  }

  addUser() {
    this.error = undefined;
    if (!ValidationUtils.email(this.emailInputValue)) {
      return;
    }

    this.searchingUserSubject.next(true);

    this.sharingElementsService.userExistsByEmail(this.emailInputValue)
      .pipe(first())
      .subscribe((exists: boolean) => {
        if (exists) {
          const user = new User();
          user.email = this.emailInputValue;

          if (isNullOrUndefined(this.sharingAppoggio.users)) {
            this.sharingAppoggio.users = [];
            this.sharing.users = [];
          }

          if (this.sharingAppoggio.users.filter((item: User) => item.email === user.email).length === 0) {
            this.sharingAppoggio.users.push(user);
            this.sharing.users.push(user);
            this.userBadgeList.push({ id: user.email, label: user.email, canRemove: !this.readonly });
          }

          this.emailInputValue = null;
        } else {
          this.error = this.translateSrv.instant('share.detail.user-error');;
        }

        this.searchingUserSubject.next(false);
      }, (error) => {
        this.error = this.translateSrv.instant('share.detail.general-error');
        this.searchingUserSubject.next(false);
      });
  }

  // List of user tags
  userBadgeList: Array<any> = new Array<any>();

  removeUser(email: any) {

    if (!this.readonly) {
      this.userBadgeList = this.userBadgeList.filter((item: User) => item.id !== email);
      this.sharingAppoggio.users = this.sharingAppoggio.users.filter((item: User) => item.email !== email);
      this.sharing.users = this.sharingAppoggio.users.filter((item: User) => item.email !== email);
    }
  }


  get canEdit(): boolean {
    return !this.readonly && this.sharingAppoggio.flagValid;
  }

  get isNew(): boolean {
    return this.sharingAppoggio == null || this.sharingAppoggio.id == null;
  }
}
