import {SelectableModel} from '../common/base/selectable-model';
import {User} from '@pwc/common';
import {TagTypeEnum} from '../../enums/tag-type.enum';

export class Tag extends SelectableModel {
  code: string;
  description?: string;
  type?: TagTypeEnum;
  sphereId: number;
  user?: User;
}
