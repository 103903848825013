<pwc-modal #connectionModal id="connection-modal">
  <div modal-title>
    <img src="/assets/images/icons/documents/toolbar/link.svg" [alt]="'connection.modal.link'|translate"
         style="width: 40px;height: 40px;">
         {{'connection.modal.link'|translate}}
  </div>

  <div modal-body>
    <app-connection-elements-list [elements]="elements" (removed)="onRemove($event)"></app-connection-elements-list>
  </div>

  <div modal-footer>
    <button type="button" class="btn btn-default" (click)="close()">
      {{'generic.action.close'|translate}}
    </button>
    <button type="button" class="btn btn-danger ml-2" (click)="cancel()">
      {{'connection.modal.cancel-link'|translate}}
    </button>
    <button type="button" class="btn btn-success ml-2" (click)="submit()" [disabled]="!isValid">
      {{'generic.action.confirm'|translate}}
    </button>
  </div>
</pwc-modal>
