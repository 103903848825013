import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@pwc/security';
import { first, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { environment } from '../../../environments/environment';
import { Cookie } from '../../models/common/cookie';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  otpForm: FormGroup;
  loading = false;
  submitted = false;
  otpRequired = false;
  returnUrl: string = '/';
  error = '';

  private otpToken;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private translateSrv: TranslateService
  ) {
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.otpForm = this.formBuilder.group({
      code: ['', Validators.required]
    });

    // reset login status
    // this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || window.location.origin + '/';

    if (isNullOrUndefined(this.returnUrl) || !this.returnUrl.length) {
      this.returnUrl = '/';
    }
    // console.debug('returnUrl', this.returnUrl);

    this.authenticationService.cookie.subscribe((cookie: Cookie) => {
      if (!isNullOrUndefined(cookie)) {
        this.router.navigate([this.returnUrl]);
      }
    });

    this.route.queryParamMap.subscribe((params: ParamMap) => {
      if (params.get('token')) {
        this.authenticationService.setToken(params.get('token'));
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get o() {
    return this.otpForm.controls;
  }

  onSubmit() {
    this.error = '';
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(
        first(),
        tap(() => {
          this.authenticationService.dataUser().subscribe();
        })
      )
      .subscribe(
        data => {
          this.router.navigateByUrl(this.returnUrl);
        },
        err => {
          if (err.status === 403 && err.error != null && err.error.error === 'otp_required') {
            this.otpToken = err.error.otp_token;
            this.submitted = false;
            this.otpRequired = true;
            this.loading = false;
            return;
          } else if (err.status >= 400 && err.status < 500) {
            this.error = this.translateSrv.instant('login.message.wrong-credentials');
          } else {
            this.error = this.translateSrv.instant('login.message.service-not-available');
          }
          this.loading = false;
        });
  }

  onSubmitOtp() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.otpForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.loginOtp(this.otpToken, this.otpForm.get('code').value)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigateByUrl(this.returnUrl);
          // window.location.href = this.returnUrl;
        },
        error => {
          console.error(error);

          this.error = this.translateSrv.instant('login.message.wrong-code');;
          this.loading = false;
        });
  }

  goToForgotPasswordPage() {
    window.location.href = `${environment.communicoUrl}/forgot-password`;
  }
}
