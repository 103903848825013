<footer id="footer" class="d-flex">
    <div class="lign-self-center">{{'footer.right'|translate}}</div>
    <!--    <div class="d-flex ml-auto">-->
    <!--        <div class="ml-3">-->
    <!--            <a routerLink="#">Full Legal</a>-->
    <!--        </div>-->
    <!--        <div class="ml-3">-->
    <!--            <a routerLink="#">Privacy Policy</a>-->
    <!--        </div>-->
    <!--        <div class="ml-3">-->
    <!--            <a  routerLink="#">Terms of Use</a>-->
    <!--        </div>-->
    <!--    </div>-->
</footer>