<ngx-smart-modal [identifier]="id" [id]="id" customClass="modal-content p-0" [force]="false" (onClose)="onClose()"
                 [closable]="closable" [dismissable]="dismissable" [escapable]="escapable">
  <div class="modal-header">
    <h5 class="modal-title text-uppercase">
      <ng-content select="[modal-title]"></ng-content>
    </h5>
  </div>
  <div [ngClass]="bodyClass">
    <ng-content select="[modal-body]"></ng-content>
  </div>
  <div class="modal-footer" *ngIf="showFooter">
    <ng-content select="[modal-footer]"></ng-content>
  </div>
</ngx-smart-modal>
