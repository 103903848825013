<div class="page-container">
    <h3 translate>generic.page-title.notification</h3>

    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/notifications" translate>generic.page-title.notification</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page" translate>generic.page-title.notification-list</li>
        </ol>
    </nav>
    <app-notifications-list></app-notifications-list>
</div>
<div class="apps-versions" [class.apps-versions-show]="showingVersions" (click)="showingVersions = true">{{'BE: ' + BEversion + ' / FE: ' + FEversion}}</div>