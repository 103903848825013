import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {isNullOrUndefined} from 'util';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UserService} from './common/user.service';
import {PageableService} from './common/pageable.service';
import {map} from 'rxjs/operators';
import {PageableResponse} from '../models/api/pageable-response';
import {TagResource} from '../models/api/tag/tag-resource';
import {Tag} from '../models/tag/tag';

@Injectable({
  providedIn: 'root'
})
export class TagService extends PageableService<TagResource, Tag> {
  protected endpoint = 'tag';

  constructor(http: HttpClient, private userService: UserService) {
    super(http);
  }

  public convertModelToResource(model: Tag): TagResource {

    const resource = new TagResource();
    Object.assign(resource, model);

    if (!isNullOrUndefined(model.user)) {
      resource.user = this.userService.convertModelToResource(model.user);
    }

    return resource;
  }

  public convertResourceToModel(resource: TagResource): Tag {
    const model = new Tag();
    Object.assign(model, resource);

    if (!isNullOrUndefined(resource.user)) {
      model.user = this.userService.convertResourceToModel(resource.user);
    }
    return model;
  }

  public search(search?: Partial<TagResource>): Observable<Tag[]> {
    let params = new HttpParams();
    if (!isNullOrUndefined(search)) {
      for (const key of Object.getOwnPropertyNames(search)) {
        params = params.set(key, `${search[key]}`);
      }
    }
    return this.get<PageableResponse<TagResource[]>>('search', {params}).pipe(
      map((response: PageableResponse<TagResource[]>): TagResource[] =>
        this.extractPaginationInfo(response)),
      map(value => this.convertListToModel(value))
    );
  }

  public addTag(tagResource: Partial<TagResource>): Observable<string> {
    return this.post<string>('add', tagResource).pipe();
  }

  public removeTag(tagResource: Partial<TagResource>): Observable<string> {
    return this.post<string>('remove', tagResource).pipe();
  }

  public getTags(elementId: number, fiscalYear: number): Observable<Tag[]> {
    return this.get<TagResource[]>(`getTagByElement/${elementId}?fiscalYear=${fiscalYear}`).pipe(
      map(value => this.convertListToModel(value))
    );
  }
}
