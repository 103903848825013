import {ApiResource} from '../common/base/api-resource';

export class UserResource extends ApiResource {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  email?: string;
  token?: string;
  language: string;
  enabled?: boolean = true;
}
