import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DmsSharing } from '../../models/share/dms-sharing';

@Injectable({
  providedIn: 'root'
})
export class SharingUtils {

  constructor(
    private translateSrv: TranslateService
  ) { }

  sharingPermission(sharing: DmsSharing): string {
    let permission = ' ';
    if (sharing.permissionUpload) {
      permission += this.translateSrv.instant('share.utils.upload') + ", ";
    }
    if (sharing.permissionDownload) {
      permission += this.translateSrv.instant('share.utils.download') + ", ";
    }
    if (sharing.permissionRead) {
      permission += this.translateSrv.instant('share.utils.reading') + ", ";
    }

    if (sharing.permissionReSharing) {
      permission += ' ' + this.translateSrv.instant('share.utils.resharing') + ", ";
    }

    if (sharing.permissionLawConservationAllowed) {
      permission += ' ' + this.translateSrv.instant('share.utils.conservation') + ", ";
    }

    if (sharing.permissionLawConservationDownloadAllowed) {
      permission += ' ' + this.translateSrv.instant('share.utils.download-conservation') + ", ";
    }

    if (sharing.permissionDelete) {
      permission += ' ' + this.translateSrv.instant('share.utils.delete');
    }

    if (permission.trim().endsWith(",")) {
      permission = permission.slice(0, -2);
    }

    return permission;
  }

  sharingUsers(sharing: DmsSharing): string {
    let users = '';
    sharing.share2Users.forEach(value => {
      users += value.email + ', ';
    });
    if (users.length > 0) {
      users = users.substr(0, users.length - 2);
    }
    return users;
  }

}