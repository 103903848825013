<ngx-smart-modal #createModal identifier="createModal" customClass="modal-content p-0" [force]="false">
  <form (ngSubmit)="save()">
    <div class="modal-header">
      <h5 class="modal-title text-uppercase">
        <img src="/assets/images/icons/documents/new_folder-dark.svg" [alt]="'documents.law.new-folder.create'|translate"
             style="width: 40px;height: 40px;">
             {{'documents.law.new-folder.create-new'|translate}}
      </h5>
    </div>
    <div class="modal-body">
      <form-input id="folder-name" name="name" [(ngModel)]="name"
                  [placeholder]="'documents.law.new-folder.create'|translate"
                  [label]="'generic.field.name'|translate"></form-input>
    </div>
    <div class="modal-footer text-right">
      <button type="button" class="btn btn-default" data-dismiss="modal" (click)="cancel()">{{'generic.action.cancel'|translate}}</button>
      <button type="submit" class="btn btn-secondary" [disabled]="submitted">
        {{'documents.law.new-folder.create'|translate}}
      </button>
    </div>
  </form>
</ngx-smart-modal>
