import {Pipe, PipeTransform} from '@angular/core';
import {isNullOrUndefined} from 'util';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(value: string, letters: number = 1): any {
    if (isNullOrUndefined(value)) {
      return '';
    }

    return value.substr(0, letters);
  }

}
