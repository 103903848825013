import {BaseModel} from './common/base/base-model';
import {DmsElementMetadataReg} from './dms-element-metadata-reg';

export class DmsElementMetadata extends BaseModel {
  value: any;
  elementId: number;
  elementMetadataReg?: DmsElementMetadataReg;

  error = false;
}
