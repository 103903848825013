import {Component} from '@angular/core';
import {ContentLoaderComponent} from '@ngneat/content-loader';

@Component({
  selector: 'app-circle-content-loader',
  templateUrl: './circle-content-loader.component.html',
  styleUrls: ['./circle-content-loader.component.scss']
})
export class CircleContentLoaderComponent extends ContentLoaderComponent {
}
