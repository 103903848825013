import {Injectable} from '@angular/core';
import {PageableService} from './common/pageable.service';
import {DmsElementMetadataResource} from '../models/api/documents/dms-element-metadata-resource';
import {DmsElementMetadata} from '../models/dms-element-metadata';
import {HttpClient} from '@angular/common/http';
import {SphereService} from './sphere.service';
import {isNullOrUndefined} from 'util';
import {DmsElementMetadataRegResource} from '../models/api/documents/dms-element-metadata-reg-resource';
import {DmsElementMetadataReg} from '../models/dms-element-metadata-reg';

@Injectable({
  providedIn: 'root'
})
export class DmsMetadataService extends PageableService<DmsElementMetadataResource, DmsElementMetadata> {
  protected endpoint = 'metadata';

  constructor(http: HttpClient) {
    super(http);
  }

  convertModelToResource(model: DmsElementMetadata): DmsElementMetadataResource {
    const resource = new DmsElementMetadataResource();
    Object.assign(resource, model);

    if (!isNullOrUndefined(model.elementMetadataReg)) {
      const reg = new DmsElementMetadataRegResource();
      Object.assign(reg, model.elementMetadataReg);
      resource.elementMetadataReg = reg;
    }

    return resource;
  }

  convertResourceToModel(resource: DmsElementMetadataResource): DmsElementMetadata {
    const model = new DmsElementMetadata();
    Object.assign(model, resource);

    if (!isNullOrUndefined(resource.elementMetadataReg)) {
      const reg = new DmsElementMetadataReg();
      Object.assign(reg, resource.elementMetadataReg);
      model.elementMetadataReg = reg;
    }

    return model;
  }

  convertMetadataRegToModel(resource: DmsElementMetadataRegResource): DmsElementMetadataReg {
    const model = new DmsElementMetadataReg();
    Object.assign(model, resource);
    return model;
  }

  convertMetadataRegToResource(model: DmsElementMetadataReg): DmsElementMetadataRegResource {
    const resource = new DmsElementMetadataRegResource();
    Object.assign(resource, model);
    return model;
  }
}
