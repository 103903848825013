import {Directive, HostListener} from '@angular/core';
import {FileSelectDirective} from '../../modules/file-upload/file-select.directive';

@Directive({
  selector: '[pwcDirectorySelect]'
})
export class PwcDirectorySelectDirective extends FileSelectDirective {

  onChange(): any {
    const files = this.element.nativeElement.files;
    const options = this.getOptions();
    const filters = this.getFilters();

    this.manageSelectEvent(files, options, filters);

    this.onFileSelected.emit(files);
    if (this.isEmptyAfterSelection()) {
      this.element.nativeElement.value = '';
    }
  }

  protected manageSelectEvent(files, options, filters) {
    for (const file of files) {
      const fullPath = file.webkitRelativePath;
      const isDirUpload = true;

      this.uploader.addToQueue([file], {
        ...options,
        additionalParameter: {
          fullPath,
          isDirUpload
        }
      }, filters);
    }
  }
}
