<div class="page-container">

    <h2 class="mb-4">{{'configurator.import'|translate}}</h2>

    <div class="mb-4">
        <div ng2FileDrop [ngClass]="{'nv-file-over': hasDropZoneOver}" (fileOver)="fileOver($event)" [uploader]="uploader" class="well drop-zone d-flex flex-column ml-0 justify-content-center align-items-center" style="min-height: 40vh">
            <div class="drop-text">
                {{'configurator.drag-file-or'|translate}} <a href="javascript:;" (click)="files.click()">{{'configurator.select-file'|translate}}</a>
            </div>
            <input #files type="file" style="display:none;" ng2FileSelect [uploader]="uploader" multiple="true">
        </div>
    </div>

    <div class="d-flex justify-content-between mb-4">
        <div style="line-height: 42px;">
            <span *ngIf="uploader.getNotUploadedItems().length">
        {{uploader.getNotUploadedItems().length}} {{'configurator.selected-files'|translate}}
      </span>
        </div>
        <div class="text-md-right">
            <button type="button" class="btn btn-default" data-dismiss="modal" (click)="cancel()">{{'configurator.cancel'|translate}}</button>
            <button type="button" class="btn btn-secondary" (click)="upload()" [disabled]="!uploader.queue.length">{{'configurator.import'|translate}}
      </button>
        </div>
    </div>

    <div class="alert alert-success" role="alert" *ngIf="success">
        {{'configurator.import-success'|translate}}
    </div>

    <div class="alert alert-danger" role="alert" *ngIf="failed">
        {{'configurator.import-fail'|translate}}
    </div>

    <div class="alert alert-success" role="alert" *ngIf="syncSuccess">
        {{'configurator.sync-completed'|translate}}
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="syncFailed">
        {{'configurator.sync-failed'|translate}}
    </div>

    <div class="row mb-4">
        <div class="col-md-4 col-sm-6">
            <form-input id="sphereId" placeholder="" label="ID Sfera" [(ngModel)]="sphereId" [disabled]="uploader.isUploading || this.isSyncing"></form-input>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <button type="button" class="btn btn-secondary" (click)="syncSphere()" [disabled]="uploader.isUploading || this.isSyncing">{{'configurator.sync-conf'|translate}}
      </button>
        </div>
    </div>
</div>