import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {DmsElement} from '../../../models/dms-element';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {DataTableComponent} from '../../common/data-table/data-table.component';
import {CdkColumnDef} from '@angular/cdk/table';
import {DmsSharing} from '../../../models/share/dms-sharing';
import * as moment from 'moment';
import {DmsSharingEntity} from '../../../models/share/dms-sharing-entity';

@Component({
  selector: 'app-share-elements-list',
  templateUrl: './share-elements-list.component.html',
  styleUrls: ['./share-elements-list.component.scss']
})
export class ShareElementsListComponent implements OnInit, AfterViewChecked {

  @Input() sharing: DmsSharing = new DmsSharing();
  @Input() elements: DmsSharingEntity[] = [];
  @Input() canRemoveAll: boolean = true;
  @Input() readonly: boolean = false;
  @Output() removed: EventEmitter<DmsSharingEntity> = new EventEmitter<DmsSharingEntity>();

  faTimes = faTimes;
  customColumnsActive: string[] = [
    'type',
    'name',
    'fiscalYear',
    'path',
    'remove'
  ];

  @ViewChild('dataTable') dataTable: DataTableComponent<DmsElement>;
  @ViewChildren(CdkColumnDef) customColumns: QueryList<CdkColumnDef>;
  viewChecked: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    this.viewChecked = true;
  }

  onRemove(row: DmsSharingEntity) {
    this.removed.emit(row);
  }

  getFiscalYear(row: DmsElement): string {
    const start = moment(row.fiscalStartDate).year();
    const end = moment(row.fiscalEndDate).year();

    if (start !== end) {
      return `${start} - ${end}`;
    }

    return `${start}`;
  }

  get showRemove(): boolean {
    return !this.readonly && this.sharing.flagValid && (this.canRemoveAll || this.elements.length > 1);
  }
}
