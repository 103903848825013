<ngx-smart-modal #attachmentModal identifier="attachmentModal" customClass="modal-content documents-uploader-modal p-0"
                [force]="false" [closable]="false" [dismissable]="false" [escapable]="false">
  <div class="modal-header">
    <h5 class="modal-title text-uppercase">
      <img src="/assets/images/icons/documents/toolbar/paper-clip.svg" [alt]="'attachment.uploader.title'|translate"
           style="width: 40px;height: 40px;">
      {{'attachment.uploader.title'|translate}}
    </h5>
  </div>
  <div class="modal-body">
    <div pwcDirectoryDrop
         [ngClass]="{'nv-file-over': hasDropZoneOver}"
         (fileOver)="fileOver($event)"
         (onFileDrop)="onFileAdded($event)"
         [uploader]="uploader"
         class="well drop-zone d-flex flex-column ml-0 justify-content-center align-items-center"
         style="min-height: 40vh">
      <div *ngIf="multipleSelection">
        <div class="drop-text">
          {{'attachment.uploader.drag-file'|translate}} <a href="javascript:;" (click)="files.click()">{{'attachment.uploader.select-file'|translate}}</a> {{'generic.simple-word.or'|translate}}
          <a href="javascript:;" (click)="directories.click()">{{'attachment.uploader.select-folder'|translate}}</a>
        </div>
        <input #files type="file" style="display:none;" ng2FileSelect [uploader]="uploader" multiple="true"
               (onFileSelected)="onFileAdded($event)">
        <input #directories type="file" style="display:none;" pwcDirectorySelect [uploader]="uploader" multiple="true"
               directory webkitdirectory mozdirectory (onFileSelected)="onFileAdded($event)">
      </div>
      <div class="drop-text" *ngIf="!multipleSelection">
        {{'attachment.uploader.drag-file-or'|translate}} <a href="javascript:;" (click)="files.click()">{{'attachment.uploader.select-file'|translate}}</a>
        <input #files type="file" style="display:none;" ng2FileSelect [uploader]="uploader"
               (onFileSelected)="onFileAdded($event)">
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-between">
    <div style="line-height: 42px;">
        <span *ngIf="uploader.getNotUploadedItems().length">
          {{uploader.getNotUploadedItems().length}} {{'attachment.uploader.select-file'|translate}}
        </span>
    </div>
    <div class="text-md-right">
      <button type="button" class="btn btn-default" data-dismiss="modal" (click)="cancel()">{{'generic.action.cancel'|translate}}</button>
      <button type="button" class="btn btn-secondary"
              (click)="submit()" [disabled]="!uploader.queue.length">{{'generic.action.confirm'|translate}}
      </button>
    </div>

  </div>
</ngx-smart-modal>
