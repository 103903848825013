import {Component, OnInit, ViewChild} from '@angular/core';
import {SphereService} from '../../services/sphere.service';
import {Sphere} from '../../models/sphere';
import {Observable, of, Subject} from 'rxjs';
import {SphereUserRelationEnum} from '../../enums/sphere-user-relation.enum';
import {AuthenticationService} from '@pwc/security';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {Cookie} from '../../models/common/cookie';
import {isNullOrUndefined} from 'util';
import {getSphereColorBySphereUserRelationEnum} from '../../helpers/common/generic-utils';
import {SearchInputComponent} from '../common/forms/search-input/search-input.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sphere-selector',
  templateUrl: './sphere-selector.component.html',
  styleUrls: ['./sphere-selector.component.scss']
})
export class SphereSelectorComponent implements OnInit {

  @ViewChild('searchInputComponent') searchInput: SearchInputComponent;

  sphereList: Sphere[] = [];
  currentSphere$: Observable<Sphere>;

  noName = this.translateSrv.instant('sphere.no-name');

  searchTerm = '';
  searchSubject: Subject<string> = new Subject<string>();
  search$: Observable<string> = this.searchSubject.asObservable();

  open: boolean = false;

  constructor(private sphereService: SphereService, private authenticationService: AuthenticationService, private translateSrv: TranslateService) {
    this.currentSphere$ = sphereService.currentSphere;
  }

  ngOnInit(): void {
    this.authenticationService.cookie.pipe(
      switchMap((cookie: Cookie) => {
        if (isNullOrUndefined(cookie)) {
          return of([]);
        }

        return this.sphereService.search();
      })
    ).subscribe((data: Sphere[]) => {
      this.sphereList = data;
      if (this.sphereList.length) {
        if (this.sphereList.filter((sphere: Sphere) => sphere.sphereUserRelation === SphereUserRelationEnum.OWNER).length > 0) {
          //  Find first owned sphere.
          for (const sphere of this.sphereList) {
            if (sphere.sphereUserRelation === SphereUserRelationEnum.OWNER) {
              this.sphereService.setCurrentSphere(sphere);
              break;
            }
          }
        } else {
          this.sphereService.setCurrentSphere(this.sphereList[0]);
        }
      }
    });

    this.search$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(() => {
        const body: Partial<Sphere> = {};
        if (!isNullOrUndefined(this.searchTerm) && this.searchTerm !== '') {
          body.name = this.searchTerm;
        }

        return this.sphereService.search(body);
      })
    ).subscribe((data: Sphere[]) => {
      data.forEach(sphere => sphere.sphereColor = getSphereColorBySphereUserRelationEnum(sphere.sphereUserRelation));
      this.sphereList = data;
    });
  }

  changeSphere(sphere: Sphere): void {
    this.sphereService.setCurrentSphere(sphere);
    this.searchTerm = '';
  }

  get ownedSpheres(): Sphere[] {
    return this.sphereList.filter((sphere: Sphere) => sphere.sphereUserRelation === SphereUserRelationEnum.OWNER);
  }

  get sharedSpheres(): Sphere[] {
    return this.sphereList.filter((sphere: Sphere) => sphere.sphereUserRelation === SphereUserRelationEnum.SHARED
      || sphere.sphereUserRelation === SphereUserRelationEnum.MASTER);
  }

  onSearch() {
    this.searchSubject.next(this.searchTerm);
  }

  onClose(open: boolean) {
    this.open = open;
    if (!open) {
      this.searchInput.value = '';
      this.searchTerm = '';
    }
  }
}
