import {Injectable} from '@angular/core';
import {PageableService} from '../common/pageable.service';
import {isNullOrUndefined} from 'util';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PageableResponse} from '../../models/api/pageable-response';
import {LawConservationListBucketResource} from '../../models/api/law-conservation/law-conservation-list-bucket-resource';
import {LawConservationBucket} from '../../models/law-conservation/law-conservation-bucket';
import {LawConservationListBucketResponse} from '../../models/api/law-conservation/law-conservation-list-bucket-response';

@Injectable({
  providedIn: 'root'
})
export class LawConservationListBucketService extends PageableService<LawConservationListBucketResource, LawConservationBucket> {
  protected endpoint = 'law-conservation/bucket';

  constructor(http: HttpClient) {
    super(http);
  }

  convertModelToResource(model: LawConservationBucket): LawConservationListBucketResource {
    return Object.assign(new LawConservationListBucketResource(), model);
  }

  convertResourceToModel(resource: LawConservationListBucketResource): LawConservationBucket {
    const model = new LawConservationBucket();
    Object.assign(model, resource);
    return model;
  }

  search(search?: Partial<LawConservationListBucketResource>): Observable<LawConservationBucket[]> {
    let params = new HttpParams();
    if (!isNullOrUndefined(search)) {
      for (const key of Object.getOwnPropertyNames(search)) {
        params = params.set(key, `${search[key]}`);
      }
    }
    return this.get<LawConservationListBucketResponse>(`search`, {params}).pipe(
      map((response: LawConservationListBucketResponse) => {
        return response.list;
      }),
      map((response: PageableResponse<LawConservationListBucketResource[]>): LawConservationListBucketResource[] =>
        this.extractPaginationInfo(response)),
      map((list: LawConservationListBucketResource[]): LawConservationBucket[] => this.convertListToModel(list))
    );

  }

}
