<nav class="breadcrumb-wrapper" aria-label="breadcrumb">
    <div class="breadcrumb-prefix">
        <a href="javascript:;" (click)="onNavigate(null)">
            <img src="/assets/images/icons/docs-home.svg" alt="Documents Root">
        </a>
    </div>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="javascript:;" (click)="onNavigate(null)">{{ pathToBeTranslated | translate }}</a>
        </li>
        <li class="breadcrumb-item" *ngFor="let dir of path">
            <a href="javascript:;" (click)="onNavigate(dir)">{{ dir.name }}</a>
        </li>
    </ol>
    <div class="breadcrumb-postfix" *ngIf="showEdit">
        <button (click)="onNavigate(null)" class="btn btn-ghost">
      <pwc-icon name="pen"></pwc-icon>
    </button>
    </div>
</nav>