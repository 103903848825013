import {Component, Input} from '@angular/core';
import {AbstractValueAccessor, MakeProvider} from '../abstract-value-accessor';
import {ValidationUtils} from '../../../../helpers/common/validation-utils';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.scss'],
  providers: [MakeProvider(EmailInputComponent)]
})
export class EmailInputComponent extends AbstractValueAccessor<string> {
  @Input() placeholder: string;
  @Input() label: string;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() invalidFeedback: string = this.translateSrv.instant('generic.message.invalid-email');
  @Input() loading: boolean = false;

  faCircleNotch = faCircleNotch;

  constructor(private translateSrv: TranslateService){
    super();
  }
  

  get isValid(): boolean {
    if (!this.required && ValidationUtils.isEmptyValue(this.value)) {
      return true;
    }
    return ValidationUtils.email(this.value);
  }

  onEnter($event): void {
    // Prevent keyup.enter from bubbling if email is invalid.
    if (!this.isValid) {
      $event.stopPropagation();
    }
  }
}
