<content-loader [animate]="animate" [width]="width" [height]="height" [speed]="speed"
                [preserveAspectRatio]="preserveAspectRatio" [primaryColor]="primaryColor"
                [secondaryColor]="secondaryColor" [primaryOpacity]="primaryOpacity" [uniqueKey]="uniqueKey" [style]="{width:'100%',height:'49px'}">
  <svg:rect x="12" y="12" r="3" width="25" height="25"></svg:rect>
  <svg:rect x="61" y="12" r="3" width="25" height="25"></svg:rect>
  <svg:rect x="110" y="12" r="3" width="400" height="25"></svg:rect>
  <svg:rect x="534" y="12" r="3" width="100" height="25"></svg:rect>
  <svg:rect x="658" y="12" r="3" width="100" height="25"></svg:rect>
  <svg:rect x="782" y="12" r="3" width="100" height="25"></svg:rect>
  <svg:rect x="906" y="12" r="3" width="80" height="25"></svg:rect>
  <svg:rect x="1042" y="12" r="3" width="25" height="25"></svg:rect>
  <svg:rect x="1091" y="12" r="3" width="25" height="25"></svg:rect>
</content-loader>
