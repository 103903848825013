import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '@pwc/security';
import { DmsExportWeightReportResource } from 'src/app/models/api/documents/dms-export-weight-report-resource';
import { DmsDownloadElement } from 'src/app/models/dms-download-element';
import { DmsFolderRepositoryService } from 'src/app/services/dms-folder-repository.service';
import { DownloaderService } from 'src/app/services/downloader.service';

@Component({
  selector: 'app-weight-report',
  templateUrl: './weight-report.component.html',
  styleUrls: ['./weight-report.component.scss']
})
export class WeightReportComponent implements OnInit {

  startDate;
  endDate;

  errorStartDate = "Inserisci una data di inizio";
  errorEndDate = "Inserisci una data di fine";

  constructor(private downloaderService: DownloaderService,
    private dmsFolderRepositoryService: DmsFolderRepositoryService
  ) { }

  ngOnInit(): void {
  }

  generateWeightReport() {

    //Todo aggiungere gestione delle date
    let dmsExportWeightReportResource: DmsExportWeightReportResource;
    dmsExportWeightReportResource = {
      startDate: this.startDate,
      endDate: this.endDate
    }

    this.dmsFolderRepositoryService.exportWeightReport(dmsExportWeightReportResource)
      .subscribe((res: DmsDownloadElement) => {
        this.downloaderService.download(res, '.csv');
      });
  }


  setStartDate($event) {
    this.startDate = new Date($event.year, $event.month - 1, $event.day, 0, 0, 0);
  }

  setEndDate($event) {
    this.endDate = new Date($event.year, $event.month - 1, $event.day + 1, 0, 0, -1);
  }

}
