<div class="page-container">

  <app-documents-breadcrumbs [showEdit]="false" componentName="summary" basePath="Riepilogo invio">
  </app-documents-breadcrumbs>

  <div class="d-flex flex-row mb-4 align-items-end box-filter">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">{{'generic.filters.label'|translate}}
          <button class="mb-2 btn btn-primary btn-sm btn-action-icon ml-0 btn-clear-filter"
            [ngbTooltip]="'generic.filters.clear'|translate" type="button" (click)="emptyFilters()">
            <pwc-icon name="clear"></pwc-icon>
          </button>
        </h5>

        <div class="d-lg-flex flex-lg-row flex-sm-column flex-wrap">
          <div class="item form-group-sm form-inline">
            <label for="configuration-filter-name">{{'generic.field.name'|translate}}</label>
            <div class="form-group form-input-group">
              <input class="form-control input-with-border input-auto-height" id="configuration-filter-name"
                name="configuration-filter-name" type="text" [(ngModel)]="searchTerm" (ngModelChange)="onSearch()">
            </div>
          </div>
          <div class="item form-group-sm form-inline">
            <label for="configuration-filter-year">{{'generic.field.years'|translate}}</label>
            <ng-select bindLabel="code" [placeholder]="'generic.field.years'|translate" appendTo="body"
              id="configuration-filter-year" style="min-width: 200px; max-width: 250px;" [clearable]="true"
              [(ngModel)]="searchTermYear" (ngModelChange)="onSearch()">
              <ng-option [value]="year.id" *ngFor="let year of yearsList">
                {{year.year}}
              </ng-option>
            </ng-select>
          </div>
          <div class="item form-group-sm form-inline">
            <label for="configuration-filter-error">{{'generic.field.error'|translate}}</label>
            <ng-select appendTo="body" id="configuration-filter-error" style="min-width: 200px; max-width: 250px;"
              [clearable]="true" [(ngModel)]="searchTermError" (ngModelChange)="onSearch()">
              <ng-option [value]="code" *ngFor="let code of errorCodes">
                {{getErrorDescription(code)}}
              </ng-option>
            </ng-select>
          </div>
          <div class="item form-group-sm form-inline">
            <label for="configuration-filter-error">{{'digitalpreservation.collection.status' | translate }}</label>
            <ng-select appendTo="body" id="configuration-filter-error" style="min-width: 200px; max-width: 250px;"
              [clearable]="true" [(ngModel)]="searchStatus" (ngModelChange)="onSearch()">
              <ng-option [value]="code" *ngFor="let code of statusCodes">
                {{getStatusDescription(code)}}
              </ng-option>
            </ng-select>
          </div>
          <div class="item form-group-sm form-inline">
            <label for="configuration-filter-start-date">{{'generic.simple-word.from'|translate}}</label>
            <div class="form-group form-input-group">
              <input type="text" id="configuration-filter-start-date"
                class="form-control form-input no-shadow input-with-border" placeholder="DD/MM/YYYY"
                (ngModelChange)="setNgbStartDate($event)" [ngModel]="startDate" container="body" ngbDatepicker
                #d1="ngbDatepicker" style="width: 100%;">
              <div (click)="d1.toggle()" class="form-input-icon append pointer w-auto" style="right: 25px;">
                <pwc-icon name="calendar_small"></pwc-icon>
              </div>
            </div>
          </div>
          <div class="item form-group-sm form-inline">
            <label for="configuration-filter-end-date">{{'generic.simple-word.to'|translate}}</label>
            <div class="form-group form-input-group">
              <input type="text" id="configuration-filter-end-date"
                class="form-control form-input no-shadow input-with-border" placeholder="DD/MM/YYYY"
                (ngModelChange)="setNgbEndDate($event)" [ngModel]="endDate" container="body" ngbDatepicker
                #d2="ngbDatepicker" style="width: 100%;">
              <div (click)="d2.toggle()" class="form-input-icon append pointer w-auto" style="right: 25px;">
                <pwc-icon name="calendar_small"></pwc-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="ml-auto text-right">
          <button class="btn btn-ghost ml-auto align-self-end text-nowrap" style="min-width: 175px"
            (click)="downloadCSV()">
            <img src="/assets/images/icons/documents/toolbar/csv.svg" style="margin-right: 10px;"
              [alt]="'generic.action.export-csv' | translate " class="btn-ghost-icon">
            <span class="d-inline-block">{{'generic.action.export-csv' | translate }}</span>
          </button>
        </div>

      </div>



    </div>

  </div>
  <div class="card" *ngIf="data && viewChecked">
    <div class="card-body card-table">
      <data-table #dataTable [dataSource]="data" [columns]="columns" [customColumnDefs]="customColumns"
        [customColumns]="customColumnsActive" [enableSelect]="false" (sort)="onSort($event)">

        <ng-container cdkColumnDef="name">
          <th cdk-header-cell *cdkHeaderCellDef scope="col" sortable="name" (sort)="onSort($event)">
            {{'documents.law.summary.document-name'|translate}}
            <pwc-sorting-arrows [selected]="sortType == 'name'" [direction]="sortReverse"></pwc-sorting-arrows>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <div>{{(row.name ? row.name : '')}}</div>
            <div>{{(row.path ? this.lawConservationPath(row) : '')}}</div>
          </td>
        </ng-container>

        <ng-container cdkColumnDef="element_fiscal_year">
          <th cdk-header-cell *cdkHeaderCellDef scope="col" sortable="year" (sort)="onSort($event)">
            {{'generic.field.fiscal-year'|translate}}
            <pwc-sorting-arrows [selected]="sortType == 'year'" [direction]="sortReverse"></pwc-sorting-arrows>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            {{row.fiscalYear}}
          </td>
        </ng-container>

        <ng-container cdkColumnDef="status">
          <th cdk-header-cell *cdkHeaderCellDef scope="col" sortable="lawConservation" class="text-center"
            (sort)="onSort($event)">
            {{'generic.field.state'|translate}}
            <pwc-sorting-arrows [selected]="sortType == 'lawConservation'" [direction]="sortReverse">
            </pwc-sorting-arrows>
          </th>
          <td cdk-cell *cdkCellDef="let row" class="text-center">
            <div *ngIf="row.lawConservation">
              <img src="/assets/images/icons/positive.svg" class="outcome-true">
            </div>
            <div *ngIf="row.lawConservation != null && row.lawConservation != undefined && !row.lawConservation "
              [ngbPopover]="row.lawConservationError" placement="top" [popoverTitle]="'generic.field.error'|translate"
              triggers="mouseenter:mouseleave">
              <img src="/assets/images/icons/negative.svg" class="outcome-false">
            </div>
            <div *ngIf="lawConservationNotRun(row)"
              [ngbPopover]=" 'digitalpreservation.collection.not-sent' | translate " placement="top" popoverTitle="Info"
              triggers="mouseenter:mouseleave">
              <img src="/assets/images/icons/definizione.svg" class="outcome-false">
            </div>
          </td>
        </ng-container>

        <ng-container cdkColumnDef="element_date_execution">
          <th cdk-header-cell *cdkHeaderCellDef scope="col" sortable="lastLawConservationExecution"
            (sort)="onSort($event)">
            {{'generic.field.execution-date'|translate}}
            <pwc-sorting-arrows [selected]="sortType == 'lastLawConservationExecution'" [direction]="sortReverse">
            </pwc-sorting-arrows>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            {{lawConservationDateExecution(row)|date:'dd/MM/yyyy'}}
          </td>
        </ng-container>

      </data-table>
    </div>

    <div class="my-4">
      <pwc-pagination *ngIf="pagination" [page]="pagination.number + 1" [pageSize]="pagination.size"
        [collectionSize]="pagination.totalElements" (pageChange)="onPageChange($event)"></pwc-pagination>
    </div>

  </div>

  <ap-alert [(show)]="displayCsvInProgressAlert" [alertType]="'success'" [iconName]="'icon-confirmed-fill'">
    <span>{{'export.modal.toast-left-lc'|translate}}</span><a routerLink="/exports">{{'export.modal.toast-hyperlink'|translate}}</a><span>{{'export.modal.toast-right'|translate}}</span>
  </ap-alert>
</div>