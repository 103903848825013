import {ApiResource} from '../../common/base/api-resource';

export class LawConservationElementsConfigurationResource {

  configurationId: number;

  elements: number[] = [];

  spheres: number[] = [];

  year: number;

  sphereId: number;

}
