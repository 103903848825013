<div class="page-container">

    <app-documents-breadcrumbs componentName="legalEntity" [path]="path" [showEdit]="false" basePath="Legal Entity">
    </app-documents-breadcrumbs>

    <div class="d-flex flex-row mb-4 align-items-end">
        <div style="max-width: 300px;">
            <search-input [placeholder]="'generic.field.search'|translate" [label]="'sphere.search'|translate" [(ngModel)]="searchTerm" (ngModelChange)="onSearch()"></search-input>
        </div>
    </div>

    <div class="card" *ngIf="data && viewChecked">
        <div class="card-body card-table">
            <data-table #dataTable [dataSource]="data" [columns]="columns" [customColumnDefs]="customColumns" [customColumns]="customColumnsActive" [enableSelect]="false" (sort)="onSort($event)">
            </data-table>
        </div>
    </div>

    <div class="my-4">
        <pwc-pagination *ngIf="pagination" [page]="pagination.number + 1" [pageSize]="pagination.size" [collectionSize]="pagination.totalElements" (pageChange)="onPageChange($event)"></pwc-pagination>
    </div>
</div>