<ngx-smart-modal #uploadModal identifier="uploadModal" customClass="modal-content documents-uploader-modal p-0"
                [force]="false" [closable]="false" [dismissable]="false" [escapable]="false">
  <div class="modal-header">
    <h5 class="modal-title text-uppercase">
      <img src="/assets/images/icons/documents/new_document-dark.svg" [alt]="'documents.uploader.upload-document'|translate"
           style="width: 40px;height: 40px;">
      {{'documents.uploader.new-document'|translate}}
    </h5>
  </div>
  <div class="modal-body">
    <div pwcDirectoryDrop
         [ngClass]="{'nv-file-over': hasDropZoneOver}"
         (fileOver)="fileOver($event)"
         (onFileDrop)="onFileAdded($event)"
         [uploader]="uploader"
         class="well drop-zone d-flex flex-column ml-0 justify-content-center align-items-center"
         style="min-height: 40vh">
      <div *ngIf="multipleSelection">
        <div class="drop-text">
          {{'configurator.drag-files-here'|translate}} <a href="javascript:;" (click)="files.click()">{{'documents.uploader.select-file'|translate}}</a> {{'generic.simple-word.or'|translate}}
          <a href="javascript:;" (click)="directories.click()">{{'documents.uploader.select-folder'|translate}}</a>
        </div>
        <input #files type="file" style="display:none;" ng2FileSelect [uploader]="uploader" multiple="true"
               (onFileSelected)="onFileAdded($event)">
        <input #directories type="file" style="display:none;" pwcDirectorySelect [uploader]="uploader" multiple="true"
               directory webkitdirectory mozdirectory (onFileSelected)="onFileAdded($event)">
      </div>
      <div class="drop-text" *ngIf="!multipleSelection">
        {{'configurator.drag-file-or'|translate}} <a href="javascript:;" (click)="files.click()">{{'documents.uploader.select-file'|translate}}</a>
        <input #files type="file" style="display:none;" ng2FileSelect [uploader]="uploader"
               (onFileSelected)="onFileAdded($event)">
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-between">
    <div style="line-height: 42px;">
        <span *ngIf="uploader.getNotUploadedItems().length">
          {{uploader.getNotUploadedItems().length}} {{'documents.uploader.selected-file'|translate}}
        </span>
    </div>
    <div class="text-md-right">
      <button type="button" class="btn btn-default" data-dismiss="modal" (click)="cancel()">{{'generic.action.cancel'|translate}}</button>
      <button type="button" class="btn btn-secondary"
              (click)="uploadConfirm()" [disabled]="!uploader.queue.length">{{'documents.uploader.upload-document'|translate}}
      </button>
    </div>

  </div>
</ngx-smart-modal>

<app-document-confirm-law-conservation-rectification-modal
    #lawConservationRectificationModal
    (positiveButtonClick)="confirmLawConservationRectificationModal()"
    (negativeButtonClick)="negateLawConservationRectificationModal()"
    documentConfirmLawConservationRectificationModalId="document-law-conservation-rectification-modal-id"
    message="{{lawConservationRectificationMessage}}"
    [positiveButtonText]="'documents.metadata.rectification'|translate" [negativeButtonText]="'generic.action.not-rectify'|translate"
    [title]="'documents.law.rectify-question'|translate"></app-document-confirm-law-conservation-rectification-modal>
