<form>
    <div class="row">
        <div class="col">
            <div class="card filter-card">
                <div class="card-body">
                    <h5 class="card-title d-flex">
                        <div translate>generic.filters.label</div>
                        <button type="button" class="btn btn-primary btn-sm btn-action-icon ml-0 btn-clear-filter" ngbTooltip="{{ 'generic.filters.clear' | translate }}" (click)="resetFilters()">
                            <pwc-icon name="clear"></pwc-icon>
                        </button>
                    </h5>
                    <div class="card-text">
                        <div class="d-lg-flex flex-lg-row flex-sm-column  flex-wrap">
                            <div class="item form-group-sm form-inline">
                                <label for="filterIDText" translate>generic.field.id</label>
                                <input class="form-control" type="text" name="filterIDText" [(ngModel)]="notificationsListService.searchID" />
                            </div>
                            <div class="item form-group-sm form-inline">
                                <label for="filterNameText" translate>generic.field.notification</label>
                                <input class="form-control" type="text" name="filterNameText" [(ngModel)]="notificationsListService.searchName" />
                            </div>
                            <div class="item form-group-sm form-inline">
                                <label for="filterDefaultSelect" translate>generic.field.is-read</label>
                                <ng-select [(ngModel)]="notificationsListService.searchDefault" class="custom" name="filterDefaultSelect" autofocus="false" [searchable]="false">
                                    <ng-option *ngFor="let elem of [ { value: true, text: ('generic.simple-word.yes'|translate) }, { value: false, text: ('generic.simple-word.no'|translate) } ]" [value]="elem.value">{{ elem.text}}</ng-option>
                                </ng-select>
                            </div>
                            <div class="form-group form-inline" *ngIf="false">
                                {{'documents.filter.fulltext-search'|translate}} <input class="form-control ml-2" type="text" name="searchTerm" [(ngModel)]="notificationsListService.searchTerm" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="d-flex flex-row">
        <div class="card toolbar-card mb-3" *ngIf="selectedList.length>0">
            <div class="card-body card-table">
                <div class="d-flex p-2">
                    <div class="m-2 pr-2 toolbar-box-border-right">
                        <button class="btn btn-primary btn-sm btn-action-icon m-1" type="button" ngbTooltip="{{ 'generic.action.delete.label' | translate }}" (click)="deleteMassive()" *ngIf="deleteMassiveEnable()">
                            <pwc-icon name="delete"></pwc-icon>
                        </button>
                    </div>
                    <div class="m-2 align-self-center">
                        <span *ngIf="selectedCount>1"> {{ 'generic.selected-items-status.plural' | translate:{n:
                            selectedCount} }}
                        </span>
                        <span *ngIf="selectedCount==1"> {{ 'generic.selected-items-status.singolar' | translate
                            }}</span> &nbsp;
                        <a [routerLink]="" *ngIf="selectedCount != total" (click)="selectAllRows()" translate>generic.action.select-all-label-status</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-body card-table">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col" style="width: 15px" *ngIf="false">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="selectAllRowsCheckBox" #checkall name="selectAllRowsCheckBox" (change)="selectVisibleRows(selectAll)" [(ngModel)]="selectAll">
                                <label class="custom-control-label" for="selectAllRowsCheckBox"></label>
                            </div>
                        </th>
                        <th scope="col" sortable="id" (sort)="onSort($event)" style="width: 80px" translate>
                            generic.field.id</th>
                        <th scope="col" sortable="date" (sort)="onSort($event)" style="width: 120px" translate>
                            generic.field.date
                        </th>
                        <th scope="col" sortable="description" (sort)="onSort($event)" translate>
                            generic.field.notification</th>
                        <th scope="col" class="d-none d-sm-table-cell" sortable="idRead" (sort)="onSort($event)" style="width: 90px" translate>generic.field.is-read
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let notification of notificationsList$ | async" (click)="!notification.isRead && readNotification(notification)" [ngClass]="{'pointer': !notification.isRead, 'notRead': !notification.isRead }">
                        <th scope="row" *ngIf="false">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [attr.id]="notification.id" [attr.name]="notification.id" [checked]="notification.selected" [ngModelOptions]="{standalone: true}" [(ngModel)]="notification.selected" (change)="selectRows(notification.id, notification.selected)">
                                <label class="custom-control-label" [attr.for]="notification.id"></label>
                            </div>
                        </th>
                        <td>
                            <ngb-highlight [result]="notification.id | idToStringPipe"></ngb-highlight>
                        </td>
                        <td>
                            <div style="white-space: nowrap;">
                                {{ notification.date | date: 'dd/MM/yyyy' }}
                                <div class="icon-small-table ml-2">
                                    <pwc-icon name="calendar_small"></pwc-icon>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ngb-highlight [ngClass]="{ 'showHtmlText': notification.isRead}" [result]="truncateText(notification.description || notification.notificationRegistry.description || '', notification.isRead)" [term]="notificationsListService.searchTerm"></ngb-highlight>
                        </td>
                        <td class="d-none d-sm-table-cell">
                            <div style="white-space: nowrap;">
                                <div class="outcome-true pointer" *ngIf="notification.isRead">
                                    <img src="/assets/images/icons/read.svg">
                                </div>
                                <div class="outcome-true pointer" *ngIf="!notification.isRead">
                                    <img src="/assets/images/icons/unread.svg">
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex justify-content-between p-2 table-paginator">
                <ngb-pagination (pageChange)="pageChange()" style="float: left" [collectionSize]="total$ | async" [(page)]="notificationsListService.page" [pageSize]="notificationsListService.pageSize" [maxSize]="5">
                </ngb-pagination>

                <ng-select [(ngModel)]="notificationsListService.pageSize" style="width: auto;" name="pageSize" [clearable]="false" autofocus="false" class="custom" [searchable]="false">
                    <ng-option *ngFor="let elem of [ { value: 10 }, { value: 20 }, { value: 50 } ]" [value]="elem.value">{{ elem.value }} {{ 'generic.action.switch-row-per-page' | translate }}
                    </ng-option>
                </ng-select>
            </div>
        </div>
    </div>

    <pwc-confirmation-modal #confirmationModal (positiveButtonClick)="callbackApprovation()"></pwc-confirmation-modal>
</form>