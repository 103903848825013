<ngx-smart-modal #lawConservationModal identifier="lawConservationModal" customClass="modal-content law-conservation-modal p-0 col-md-8" [force]="false" id="law-conservation-modal" [dismissable]="false">
    <div class="modal-header">
        <h5 class="modal-title text-uppercase">
            <img src="/assets/images/icons/documents/toolbar/conservazione_a_norma.svg" [alt]="'documents.metadata.norm-conservation'|translate" style="width: 40px;height: 40px;"> {{'documents.law.modal.select-title'|translate}}
        </h5>
    </div>
    <div class="modal-body" style="overflow: inherit;">
        <div class="row">
            <div class="col-md-12 p-3">
                <div class="row">
                    <div class="col-md-9">
                        <span>{{'documents.law.modal.send-element'|translate}}</span>
                    </div>
                    <div class="col-md-3 text-md-right">
                        <button class="btn btn-secondary" (click)="sendNow()" [disabled]="!isSendNowEnabled">{{'documents.law.modal.send-now'|translate}}</button>
                    </div>
                </div>
            </div>
            <div class="col-md-12 p-3">
                <span class="p-2">{{'documents.law.modal.select-message-1'|translate}} </span><a routerLink="/archive-configuration">{{'documents.law.modal.select-message-2'|translate}}</a>

                <div *ngIf="isParentConfigurationSet()" class="p-2">{{'documents.law.modal.inherit-message'|translate}} <a routerLink="/archive-configuration">{{getParentConfiguration()}}</a></div>

                <div class="ng-autocomplete p-2" style="width: 100%;">
                    <ng-autocomplete #lawConservationAuto [data]="data" name="name" [(ngModel)]="name" [initialValue]="initialValue" [searchKeyword]="keyword" [placeholder]="placeholder" (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)' (inputCleared)='onClearedSearch($event)'
                        [minQueryLength]="3" [isLoading]="isAutocompleterLawConservation()" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.name"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound">{{'documents.law.modal.no-configuration-found'|translate}}</div>
                    </ng-template>
                </div>

            </div>
            <div class="col-md-12 p-3" style="display: none">
                <span>{{'documents.law.modal.repeat-configuration'|translate}}</span>



            </div>
            <div class="col-md-6" style="display: none">
                <span class="p-2">{{'documents.law.modal.sphere-list'|translate}}</span>
            </div>
            <div class="col-md-6" style="display: none">
                <span class="p-2">{{'documents.law.modal.selected-sphere'|translate}}</span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-md-right">
            <button type="button" class="btn btn-default" data-dismiss="modal" (click)="cancel()">{{'generic.action.edit.cancel'|translate}}</button>
            <button type="button" class="btn btn-secondary" (click)="save()" [disabled]="isSaveDisabled() || saveLoading">{{'generic.action.edit.save'|translate}}</button>
        </div>

    </div>
</ngx-smart-modal>