<div class="row mb-4">
    <div class="col">
        <form-input [placeholder]="'generic.placeholder.name'|translate" [label]="'share.detail.share-name'|translate" [(ngModel)]="sharing.name" [disabled]="!canEdit"></form-input>
    </div>
</div>

<div class="row mb-4">
    <div class="col">
        <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="always-valid" name="always-valid" [ngModel]="sharing.alwaysValid" (ngModelChange)="sharing.alwaysValid = true" [disabled]="!canEdit">
            <label class="custom-control-label" for="always-valid">
                {{'share.manager.always-valid'|translate}}
            </label>
        </div>
    </div>
    <div class="col">
        <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="valid-period" name="valid-period" [ngModel]="!sharing.alwaysValid" (ngModelChange)="sharing.alwaysValid = false" [disabled]="!canEdit">
            <label class="custom-control-label" for="valid-period">
                {{'share.detail.valid-period'|translate}}
            </label>
        </div>
    </div>
</div>

<div class="row mb-4" *ngIf="!sharing.alwaysValid">
    <div class="col">
        <div class="form-group form-input-group">
            <label class="form-input-label">{{'share.detail.start-validation-date'|translate}}</label>
            <input type="text" class="form-control form-input mandatory no-shadow mr-2" placeholder="YYYY-MM-DD" name="editDeadlineDate" [ngModel]="validityStartDate" (ngModelChange)="setNgbValidityStartDate($event)" placement="bottom" ngbDatepicker #d1="ngbDatepicker"
                [disabled]="!canEdit">
            <div (click)="d1.toggle()" class="form-input-icon append pointer w-auto">
                <pwc-icon name="calendar_small"></pwc-icon>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="form-group form-input-group">
            <label class="form-input-label">{{'share.detail.end-validation-date'|translate}}</label>
            <input type="text" class="form-control form-input mandatory no-shadow" placeholder="YYYY-MM-DD" [ngModel]="validityEndDate" (ngModelChange)="setNgbValidityEndDate($event)" ngbDatepicker placement="bottom" #d2="ngbDatepicker" [disabled]="!canEdit">
            <div (click)="d2.toggle()" class="form-input-icon append pointer w-auto">
                <pwc-icon name="calendar_small"></pwc-icon>
            </div>
        </div>
    </div>
</div>

<hr class="my-4">

<email-input [(ngModel)]="emailInputValue" (keyup.enter)="addUser()" [label]="'share.detail.share-user'|translate" [placeholder]="'share.detail.add-user'|translate" [disabled]="!canEdit" [loading]="searchingUser$ | async">
</email-input>

<div class="alert alert-danger my-4" role="alert" *ngIf="error">
    {{error}}
</div>

<hr class="mb-4">

<badge-list [label]="'share.selected-user'|translate" [items]="userBadgeList" (remove)="removeUser($event)">
</badge-list>


<div class="custom-control custom-checkbox my-3" *ngIf="isNew">
    <input type="checkbox" class="custom-control-input" id="userIsolation" [(ngModel)]="sharing.userIsolation">
    <label class="custom-control-label" for="userIsolation">
        {{'share.detail.distinct-share'|translate}}
    </label>
</div>