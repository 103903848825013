import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {DmsElement} from '../../../models/dms-element';
import {ModalHandler} from '../../../contracts/common/modal-handler';
import {Router} from '@angular/router';
import {SphereService} from '../../../services/sphere.service';
import {DmsFolderRepositoryService} from '../../../services/dms-folder-repository.service';
import {DmsFileRepositoryService} from '../../../services/dms-file-repository.service';
import {LawConservationService} from '../../../services/law-conservation/law-conservation.service';
import {LawConservationConfigurationResource} from '../../../models/api/law-conservation/law-conservation-configuration-resource';
import {LawConservationConfigurationResponce} from '../../../models/api/law-conservation/law-conservation-configuration-responce';
import {SendingFrequencyTypeEnum} from '../../../enums/law-conservation/sending-frequency-type.enum';
import {LocalDate} from '../../../models/local-date.model';
import {LawConservationConfiguration} from '../../../models/law-conservation/law-conservation-configuration';
import {isNullOrUndefined} from 'util';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-law-conservation-modal',
  templateUrl: './law-conservation-modal.component.html',
  styleUrls: ['./law-conservation-modal.component.scss']
})
export class LawConservationModalComponent implements ModalHandler {
  @Input() selectedElements: DmsElement[] = [];
  @Input() fiscalYear: number;
  @Input() sphereId: number;
  @Output() saved: EventEmitter<DmsElement> = new EventEmitter<DmsElement>();
  name: string;

  keyword = 'name';
  data: LawConservationConfiguration[];
  initialValue = '';
  configurationId: number;
  configurationIdParent: number;
  configurationIdMultiple = false;
  saveLoading: Boolean = false;
  elements: DmsElement[] = [];
  autocompleteLoading = false;
  isSendNowEnabled = false;

  placeholder = this.translateSrv.instant('documents.law.modal.select-title');

  @ViewChild('lawConservationAuto') lawConservationAuto;

  constructor(private ngxSmartModalService: NgxSmartModalService,
              private router: Router,
              private sphereService: SphereService,
              private dmsFolderRepositoryService: DmsFolderRepositoryService,
              private dmsFileRepositoryService: DmsFileRepositoryService,
              private lawConservationService: LawConservationService,
              private translateSrv: TranslateService) {
  }

  open() {
    this.name = null;
    this.ngxSmartModalService.getModal('lawConservationModal').open();

    this.getConfigurationList(null);
  }

  openId(element: DmsElement) {
    this.name = null;
    this.ngxSmartModalService.getModal('lawConservationModal').open();

    this.getConfigurationList(element);
  }

  loadElements(element) {
    if (element == null) {
      this.elements = [];
      for (const dmsElement of this.selectedElements) {
        this.elements.push(dmsElement);
      }
    } else {
      this.elements = [];
      this.elements.push(element);
    }
  }

  sendingFrequencyTypeEnumToString(sendingFrequencyTypeEnum: string) {
    if (SendingFrequencyTypeEnum.MONTHLY === sendingFrequencyTypeEnum) {
      return this.translateSrv.instant('documents.law.modal.frequency.every-month');
    } else if (SendingFrequencyTypeEnum.TWO_MONTH === sendingFrequencyTypeEnum) {
      return this.translateSrv.instant('documents.law.modal.frequency.every-two-month');
    } else if (SendingFrequencyTypeEnum.QUARTER === sendingFrequencyTypeEnum) {
      return this.translateSrv.instant('documents.law.modal.frequency.every-three-month');
    } else if (SendingFrequencyTypeEnum.FOURTH === sendingFrequencyTypeEnum) {
      return this.translateSrv.instant('documents.law.modal.frequency.every-four-month');
    } else if (SendingFrequencyTypeEnum.HALF_YEAR === sendingFrequencyTypeEnum) {
      return this.translateSrv.instant('documents.law.modal.frequency.every-six-month');
    } else if (SendingFrequencyTypeEnum.ANNUAL === sendingFrequencyTypeEnum) {
      return this.translateSrv.instant('documents.law.modal.frequency.annual');
    }

    return '';
  }

  private padNumber(value: number) {
   return `0${value}`.slice(-2);
  }

  private loadConfigurationId() {
    this.configurationId = null;
    this.configurationIdParent = null;
    this.configurationIdMultiple = false;

    const configurationIdSet = new Set();
    const configurationIdParentSet = new Set();

    for (const dmsElement of this.elements) {
      configurationIdSet.add(dmsElement.configurationId);
      configurationIdParentSet.add(dmsElement.configurationIdParent);
    }

    this.configurationIdMultiple = configurationIdSet.size > 1 || configurationIdParentSet.size > 1;

    this.configurationId = configurationIdSet.size === 1 ? configurationIdSet.values().next().value : null;
    this.configurationIdParent = configurationIdParentSet.size === 1 ? configurationIdParentSet.values().next().value : null;
    //
    //   if (dmsElement.configurationId == null) {
    //     if (this.configurationId != null) {
    //       this.configurationIdMultiple = true;
    //     }
    //     this.configurationId = null;
    //   } else if (this.configurationId == null) {
    //     this.configurationId = dmsElement.configurationId;
    //   } else if (this.configurationId !== dmsElement.configurationId) {
    //     this.configurationId = null;
    //     this.configurationIdMultiple = true;
    //     break;
    //   }
    //
    //   if (dmsElement.configurationIdParent == null) {
    //     if (this.configurationIdParent != null) {
    //       this.configurationIdMultiple = true;
    //     }
    //     this.configurationIdParent = null;
    //   } else if (this.configurationIdParent == null) {
    //     this.configurationIdParent = dmsElement.configurationIdParent;
    //   } else if (this.configurationIdParent !== dmsElement.configurationIdParent) {
    //     this.configurationIdParent = null;
    //     this.configurationIdMultiple = true;
    //     break;
    //   }
    // }

  }

  getConfigurationList(element: DmsElement) {
    this.name = null;
    this.data = [];
    this.initialValue = '';
    this.lawConservationAuto.close();
    this.lawConservationAuto.clear();
    this.isSendNowEnabled = false;

    this.loadElements(element);
    this.loadConfigurationId();


    this.autocompleteLoading = true;
    const lawConservationConfigurationResource = new LawConservationConfigurationResource();
    lawConservationConfigurationResource.sphereId = this.sphereId;
    this.lawConservationService.configurationList(lawConservationConfigurationResource, this.fiscalYear).pipe()
      .subscribe((res: LawConservationConfigurationResponce) => {

        let valueSelected = '';
        for (const lawConservationConfiguration of res.list) {
          let elementName = lawConservationConfiguration.name + '  >  ' + lawConservationConfiguration.bucket.description;

          elementName += ' / ' + this.sendingFrequencyTypeEnumToString(lawConservationConfiguration.sendingFrequency);
          const years = [];
          for (const fiscalYear of lawConservationConfiguration.fiscalYear) {
            years.push(fiscalYear.fiscalYear.year);
          }
          if (years.length > 0) {
            elementName += ' / ' + this.translateSrv.instant('generic-field.year') + ' ' + years.sort().join(' + ');
          }
          if (lawConservationConfiguration.startSending != null) {
            const date = new Date(lawConservationConfiguration.startSending);
            const localDate = new LocalDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
            elementName += ' / ' + this.translateSrv.instant('generic-field.start') + ' ' + this.padNumber(localDate.day) + '/' + this.padNumber(localDate.month) + '/' + localDate.year;
          }

          lawConservationConfiguration.name = elementName;

          if (lawConservationConfiguration.id === this.configurationId) {
            valueSelected = lawConservationConfiguration.name;
          }

          this.data.push(lawConservationConfiguration);
        }
        this.name = valueSelected;
        this.initialValue = valueSelected;
        this.autocompleteLoading = false;

        this.isSendNowEnabled = this.configurationIdMultiple || this.configurationId != null || this.configurationIdParent != null;
      });
  }

  close() {
    this.ngxSmartModalService.getModal('lawConservationModal').close();
  }

  cancel() {
    this.close();
  }

  save() {
    this.saveLoading = true;
    this.lawConservationService.saveElementConfiguration(this.configurationId, this.elements, this.fiscalYear, this.sphereId).subscribe(
      value => {
        this.saveLoading = false;
        this.saved.emit();
        this.close();
      });
  }

  sendNow() {

    this.lawConservationService.sendNowToConservation(this.elements, this.fiscalYear, this.sphereId).subscribe(
      value => {
        this.saved.emit();
        this.close();
      });
  }

  selectEvent(item) {
    this.configurationId = (item as LawConservationConfiguration).id;
  }

  onChangeSearch(val: any) {
    this.configurationId = null;
  }

  onClearedSearch(val: any) {
    this.configurationId = null;
  }

  isSaveDisabled() {
    return false;
  }

  isAutocompleterLawConservation() {
    return this.autocompleteLoading;
  }

  isParentConfigurationSet() {
    return !this.configurationIdMultiple && this.configurationId == null && this.configurationIdParent != null;
  }

  getParentConfiguration() {
    if (this.configurationIdParent != null) {
      for (const lawConservationConfiguration of this.data) {
        if (lawConservationConfiguration.id === this.configurationIdParent) {
          return lawConservationConfiguration.name;
        }
      }
    }
    return '';
  }

}
