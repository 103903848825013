<app-header #header id="header" (updateLanguage)="useLanguage($event)"></app-header>
<div id="vdr-content">
  <div class="container-fluid">
    <main>
      <div class="row">
        <div class="col-xl-4 col-lg-5 col-md-6 col-sm-6 ml-auto mr-auto disclaimer-content">
          <section>
            <div class="row">
              <div class="col align-self-center">
                <div class="card">
                  <div class="card-body">
                      <div class="col">
                        <h2>{{'vdr.disclaimer.title'|translate}}</h2>
                        <div class="text-container">
                          <span class="disclaimer-text">
                            <span>{{'vdr.disclaimer.text-1'|translate}}</span>
                            <span class="bold">{{'vdr.disclaimer.text-1-bold'|translate}}</span>
                            <span>{{'vdr.disclaimer.text-2'|translate}}</span>
                            <span class="italic">{{'vdr.disclaimer.text-2-italic'|translate}}</span>
                            <span>{{'vdr.disclaimer.text-3'|translate}}</span>
                            <span class="bold">{{'vdr.disclaimer.text-3-bold'|translate}}</span>
                            <span>{{'vdr.disclaimer.text-4'|translate}}</span>
                            <span class="bold">{{'vdr.disclaimer.text-4-bold'|translate}}</span>
                            <span>{{'vdr.disclaimer.text-5'|translate}}</span>
                            <span class="italic">{{'vdr.disclaimer.text-5-italic'|translate}}</span>
                            <span>{{'vdr.disclaimer.text-6'|translate}}</span>
                            <span class="bold">{{'vdr.disclaimer.text-6-bold'|translate}}</span>
                            <span>{{'vdr.disclaimer.text-7'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-8'|translate}}</span>
                            <span class="bold">{{'vdr.disclaimer.text-8-bold'|translate}}</span>
                            <span>{{'vdr.disclaimer.text-9'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-10'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-11'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-12'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-13'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-14'|translate}}</span>
                            <span class="italic">{{'vdr.disclaimer.text-14-italic'|translate}}</span>
                            <span>{{'vdr.disclaimer.text-15'|translate}}</span>
                            <span class="bold">{{'vdr.disclaimer.text-15-bold'|translate}}</span>
                            <span>{{'vdr.disclaimer.text-16'|translate}}</span>
                            <br><br>
                            <span class="bold">{{'vdr.disclaimer.text-17-bold'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-18'|translate}}</span>
                            <span class="italic">{{'vdr.disclaimer.text-18-italic'|translate}}</span>
                            <span>{{'vdr.disclaimer.text-19'|translate}}</span>
                            <span class="bold">{{'vdr.disclaimer.text-19-bold'|translate}}</span>
                            <span>{{'vdr.disclaimer.text-20'|translate}}</span>
                            <span class="bold">{{'vdr.disclaimer.text-20-bold'|translate}}</span>
                            <span>{{'vdr.disclaimer.text-21'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-22'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-23'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-24'|translate}}</span>
                            <br><br>
                            <span class="bold">{{'vdr.disclaimer.text-25-bold'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-26'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-27'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-28'|translate}}</span>
                            <br>
                            <span>{{'vdr.disclaimer.text-29'|translate}}</span>
                            <br>
                            <span>{{'vdr.disclaimer.text-30'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-31'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-32'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-33'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-34'|translate}}</span>
                            <br><br>
                            <span>{{'vdr.disclaimer.text-35'|translate}}</span>
                            <span class="italic">{{'vdr.disclaimer.text-35-italic'|translate}}</span>
                            <span>{{'vdr.disclaimer.text-36'|translate}}</span>
                          </span>
                        </div>
                      </div>
                      <div class="disclaimer-buttons">
                        <button class="btn btn-success accept-button" (click)="acceptDisclaimer()">{{'vdr.disclaimer.accept'|translate}}</button>
                        <button class="btn btn-danger reject-button" (click)="rejectDisclaimer()">{{'vdr.disclaimer.reject'|translate}}</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  </div>
</div>