import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {SortDirectionEnum} from '../../enums/common/sort-direction.enum';

const rotate: { [key: string]: SortDirectionEnum } = {
  [SortDirectionEnum.ASC]: SortDirectionEnum.DESC,
  [SortDirectionEnum.DESC]: SortDirectionEnum.NONE,
  [SortDirectionEnum.NONE]: SortDirectionEnum.ASC
};

export interface SortEvent {
  column: string;
  direction: SortDirectionEnum;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.sortable]': 'true',
    '[class.asc]': `direction === ${SortDirectionEnum.ASC}`,
    '[class.desc]': `direction === ${SortDirectionEnum.DESC}`,
    '(click)': 'rotate()'
  }
})
export class NgbdSortableHeaderDirective {

  @Input() sortable: string;
  @Input() direction: SortDirectionEnum = SortDirectionEnum.NONE;
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({column: this.sortable, direction: this.direction});
  }
}
