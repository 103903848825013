import {Component, Input} from '@angular/core';

@Component({
  selector: 'pwc-sorting-arrows',
  templateUrl: './sorting-arrows.component.html',
  styleUrls: ['./sorting-arrows.component.scss']
})
export class SortingArrowsComponent {

  @Input() selected: boolean = false;
  @Input() direction: number = 0;

}
