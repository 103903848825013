<!--<div class="wrapper">
  <app-sidebar #sideBar></app-sidebar>
  <div id="content">
    <div class="container-fluid">-->
      <!-- header -->
      <!-- <app-header #header id="sidebar" [languages]="languages" [currentLanguage]="currentLanguage"
                  (updateLanguage)="useLanguage($event)"></app-header> -->

      <!-- routes will be rendered here -->

      <!-- <main>
        <router-outlet></router-outlet>
      </main>

    </div> -->
    <!-- footer -->
    <!-- <app-footer></app-footer> -->

    <!--<app-uploader-queue></app-uploader-queue>
    <app-downloader-queue></app-downloader-queue>
  </div>
</div> -->

<pwc-layout>
  <ng-container app-nav-menu>
    <app-nav-menu></app-nav-menu>
  </ng-container>

  <ng-container app-header>
    <app-header #header id="sidebar" (updateLanguage)="useLanguage($event)"></app-header>
  </ng-container>

  <!-- <app-uploader-queue></app-uploader-queue> -->
  <!-- <app-downloader-queue></app-downloader-queue> -->
</pwc-layout>
