<div class="page-container">

  <app-documents-breadcrumbs [showEdit]="false" componentName="exports-history" basePath="Exports">
  </app-documents-breadcrumbs>

  <div class="row">
    <div class="d-flex flex-row mb-4 align-items end box-filter col-6">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            {{'generic.filters.label'|translate}}
            <button class="btn btn-primary btn-sm btn-action-icon ml-0 btn-clear-filter"
              [ngbTooltip]="'generic.filters.clear'|translate" type="button" (click)="emptyFilters()">
              <pwc-icon name="clear"></pwc-icon>
            </button>
          </h5>

          <div class="d-md-flex flex-md-row flex-sm-column flex-wrap">

            <div class="item form-group-sm form-inline">
              <label for="filter-term">{{'exports-history.search'|translate}}</label>
              <search-input id="filter-term" [placeholder]="'generic.field.search'|translate" [(ngModel)]="searchTerm"
                (ngModelChange)="onSearch()"></search-input>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <button class="btn btn-ghost ml-auto align-self-end text-nowrap" id="delete-all-btn" *ngIf="data.length"
        (click)="openDeleteAllOrSelectedModal()">
        <pwc-icon name="delete" placement="left" id="delete-all-icon"></pwc-icon>
        <span class="d-inline-block">
          <ng-container *ngIf="!selectedResults.length else deleteOnlySelected">
            {{'exports-history.delete-all'|translate}}</ng-container>
          <ng-template #deleteOnlySelected>{{'exports-history.delete-selected'|translate}}</ng-template>
        </span>
      </button>
    </div>
  </div>

  <div class="card" *ngIf="!loaded || data.length">
    <div class="card-body card-table" *ngIf="data.length && viewChecked">
      <data-table #dataTable [dataSource]="data" [columns]="columns" [customColumnDefs]="customColumns"
        [customColumns]="customColumnsActive" [enableSelect]="data.length"
        [allSelected]="totalCount && selectedCount == totalCount" (sort)="onSort($event)" (select)="selectRows($event)"
        (selectAll)="selectAllRows($event)">

        <ng-container cdkColumnDef="type">
          <th cdk-header-cell *cdkHeaderCellDef scope="col" style="width: 30px;"></th>
          <td cdk-cell *cdkCellDef="let row" (click)="this.openElement(row)" class="pointer">
            <div class="outcome-true mr-0">
              <img src="{{getElementTypeImageCell(row)}}" alt="{{getElementTypeAltCell(row)}}">
            </div>
          </td>
        </ng-container>

        <ng-container cdkColumnDef="export_name">
          <th cdk-header-cell *cdkHeaderCellDef scope="col" id="filename-header">
            <!-- sortable="exportName" (sort)="onSort($event)" -->
            {{'exports-history.headers.file-name'|translate}}
            <!-- <pwc-sorting-arrows [selected]="sortType == 'exportName'" [direction]="sortReverse"></pwc-sorting-arrows> -->
          </th>
          <td cdk-cell *cdkCellDef="let row">
            {{(row.fileName ? row.fileName : '')}}
          </td>
        </ng-container>

        <ng-container cdkColumnDef="path">
          <th cdk-header-cell *cdkHeaderCellDef scope="col">
            {{'exports-history.headers.location'|translate}}
          </th>
          <td cdk-cell *cdkCellDef="let row">
            {{(row.path ? (row.path === 'law_conservation' ? ('exports-history.law-summary'|translate) : (row.path ===
            'advanced_search' ? ('exports-history.advanced-search'|translate) : row.path)) : '')}}
          </td>
        </ng-container>

        <ng-container cdkColumnDef="fy">
          <th cdk-header-cell *cdkHeaderCellDef scope="col">
            {{'exports-history.headers.fy'|translate}}
          </th>
          <td cdk-cell *cdkCellDef="let row">
            {{row.fiscalYear}}
          </td>
        </ng-container>

        <ng-container cdkColumnDef="status">
          <th cdk-header-cell *cdkHeaderCellDef scope="col">
            {{'exports-history.headers.status'|translate}}
          </th>
          <td cdk-cell *cdkCellDef="let row">
            {{mapStatus(row.status)|translate}}
          </td>
        </ng-container>

        <ng-container cdkColumnDef="date">
          <th cdk-header-cell *cdkHeaderCellDef scope="col" sortable="startDate" (sort)="onSort($event)">
            {{'exports-history.headers.date'|translate}}
            <pwc-sorting-arrows [selected]="sortType == 'startDate'" [direction]="sortReverse"></pwc-sorting-arrows>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            {{row.startDate|date:'dd/MM/yyyy'}}
          </td>
        </ng-container>

        <ng-container cdkColumnDef="actions">
          <th cdk-header-cell *cdkHeaderCellDef scope="col">
            {{'exports-history.headers.actions'|translate}}
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <button class="exports-buttons download" (click)="downloadExport(row)"
              [style.visibility]="row.status=='READY' ? 'visible' : 'hidden'">
              <pwc-icon name="download_simple" [ngbTooltip]="'exports-history.buttons.download'|translate"
                placement="left">
              </pwc-icon>
            </button>
            <button class="exports-buttons" (click)="openDeleteOneModal(row)">
              <pwc-icon name="delete" [ngbTooltip]="'exports-history.buttons.delete'|translate" placement="left"
                [style.visibility]="row.status=='READY' ? 'visible' : 'hidden'">
              </pwc-icon>
            </button>
          </td>
        </ng-container>
      </data-table>

    </div>
    <app-table-content-loader *ngIf="loadingDocuments$ | async"></app-table-content-loader>
  </div>

  <div *ngIf="!data.length && loaded" class="empty-exports-list">
    {{"exports-history.no-exports"|translate}}</div>

  <div class="my-4">
    <pwc-pagination *ngIf="pagination" [page]="pagination.number + 1" [pageSize]="pagination.size"
      [collectionSize]="pagination.totalElements" (pageChange)="onPageChange($event)"></pwc-pagination>
  </div>

  <app-document-confirm-modal #confirmModal (positiveButtonClick)="confirmDeleteModal()" [disabled]="deleting"
    documentConfirmModalId="document-modal-id" [message]="'generic.message.delete-question'|translate"
    [selectedResults]="selectedResults" [positiveButtonText]="'generic.action.confirm-caps'|translate"
    [negativeButtonText]="'generic.action.cancel-caps'|translate" [title]="'generic.action.delete.label'|translate"
    [sphereId]="currentSphereId"></app-document-confirm-modal>

</div>