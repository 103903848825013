export class AlertModal {
  id: any;
  type: string;
  message: any;

  constructor(id: any, type: string, message: string) {
    this.id = id;
    this.type = type;
    this.message = message;
  }
}
