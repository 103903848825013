<pwc-modal #startConnectionModal id="start-connection-modal">
  <div modal-title>
    <img src="/assets/images/icons/documents/toolbar/link.svg" [alt]="'connection.modal.link'|translate"
         style="width: 40px;height: 40px;">
    {{'connection.modal.link'|translate}}
  </div>

  <div modal-body>
    <div class="row">
      <div class="col-md-12">
        <span *ngIf="elementsSize === 1">
          {{'generic.action.you-selected'|translate}} {{elementsSize}} {{'connection.start.selected-element-single'|translate}}
        </span>
        <span *ngIf="elementsSize > 1">
          {{'generic.action.you-selected'|translate}} {{elementsSize}} {{'connection.start.selected-element'|translate}}
        </span>
      </div>
    </div>
  </div>

  <div modal-footer class="text-right">
    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onCancel()">
      {{'generic.action.cancel'|translate}}
    </button>
    <button type="button" class="btn btn-success ml-2" (click)="onContinue()">
      {{'connection.start.add-elements'|translate}} <span *ngIf="isAlias">{{'connection.start.select-destination'|translate}}</span>
    </button>
    <button type="button" class="btn btn-success ml-2" (click)="onConfirm()" *ngIf="canConfirm">
      {{'connection.modal.link'|translate}}
    </button>
  </div>
</pwc-modal>
