import { BaseModel } from './common/base/base-model';
import { SphereUserRelationEnum } from '../enums/sphere-user-relation.enum';
import { Sphere2FiscalYearResource } from './api/sphere2-fiscal-year-resource';
import { User } from '@pwc/common';

export class Sphere extends BaseModel {
  name: string;
  userId?: number;
  user?: User;
  ownerUserId: number;
  owner?: string;
  sphereUserRelation: SphereUserRelationEnum;
  societyId?: number;
  fiscalYears?: Sphere2FiscalYearResource[] = [];
  sphereColor: string;

  societyName?: string;
  societyVatNumber?: string;

  page?: number;
  sort?: string;

  parentSphereId?: number;

}
