import {Injectable} from '@angular/core';
import {PageableService} from '../common/pageable.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PageableResponse} from '../../models/api/pageable-response';
import {DmsElement} from '../../models/dms-element';
import {DmsElementResource} from '../../models/api/documents/dms-element-resource';
import {DmsRepositoryResponse} from '../../models/api/documents/dms-repository-response';
import {DmsDownloadElement} from '../../models/dms-download-element';
import {ErrorCode} from '../../enums/law-conservation/error-code.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LawConservationListSummaryService extends PageableService<DmsElementResource, DmsElement> {
  protected endpoint = 'law-conservation';

  protected errorMap: Map<ErrorCode, string> = new Map([
    [ErrorCode.CONFIG_ERROR, this.translateSrv.instant('generic.message.configuration-error')],
    [ErrorCode.EXTENTION_ERROR, this.translateSrv.instant('generic.message.unsupported-extension')],
    [ErrorCode.FILENAME_ERROR, this.translateSrv.instant('generic.message.filename-not-valid')],
    [ErrorCode.PHYSICALFILE_ERROR, this.translateSrv.instant('generic.message.file-not-reachable')],
    [ErrorCode.LD_WC007, this.translateSrv.instant('generic.message.param-file-not-valid')],
    [ErrorCode.LD_IV000, this.translateSrv.instant('generic.message.not-valid-metadata')]
  ]);

  constructor(http: HttpClient, private translateSrv: TranslateService) {
    super(http);
  }

  convertModelToResource(model: DmsElement): DmsElementResource {
    return Object.assign(new DmsElementResource(), model);
  }

  convertResourceToModel(resource: DmsElementResource): DmsElement {
    const model = new DmsElement();
    Object.assign(model, resource);
    return model;
  }

  search(search?: Partial<DmsElementResource>): Observable<DmsElement[]> {
    return this.post<DmsRepositoryResponse>(`summary-search`, search).pipe(
      map((response: DmsRepositoryResponse) => {
        return response.list;
      }),
      map((response: PageableResponse<DmsElementResource[]>): DmsElementResource[] =>
        this.extractPaginationInfo(response)),
      map((list: DmsElementResource[]): DmsElement[] => this.convertListToModel(list))
    );
  }

  downloadCSV(search?: Partial<DmsElementResource>): Observable<DmsDownloadElement> {
    console.log("search:  ",search)
    return this.post<DmsDownloadElement>('summary-download-export-csv', search).pipe(
      map((response: DmsDownloadElement) => {
        return response;
      }));
  }

  getErrorDescription(code: ErrorCode): string {
    if (this.errorMap.has(code)) {
      return this.errorMap.get(code);
    }
  }
}
