import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DmsElement} from '../../../models/dms-element';
import {ModalComponent} from '../../common/modals/modal/modal.component';
import {DmsSharingEntity} from '../../../models/share/dms-sharing-entity';

@Component({
  selector: 'app-start-sharing-modal',
  templateUrl: './start-sharing-modal.component.html',
  styleUrls: ['./start-sharing-modal.component.scss']
})
export class StartSharingModalComponent {
  @Input() elements: DmsSharingEntity[] = [];

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() continue: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('startSharingModal') modal: ModalComponent;

  open(): void {
    this.modal.open();
  }

  close(): void {
    this.modal.close();
  }

  onCancel() {
    this.cancel.emit();
  }

  onContinue() {
    this.close();
    this.continue.emit();
  }

  onConfirm() {
    this.confirm.emit();
  }

  get elementsSize(): number {
    return this.elements.length;
  }
}
