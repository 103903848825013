import {formatDate} from '@angular/common';
import * as moment from 'moment';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';

export function formatDateValueFromStringNumber(stringValue: string, locale: string): string {
  const date = new Date(parseInt(stringValue, null));
  return formatDate(date, 'dd/MM/yyyy', locale);
}

export function formatDateValueFromNumber(numberValue: number, locale: string): string {
  const date = new Date(numberValue);
  return formatDate(date, 'dd/MM/yyyy', locale);
}

export function validateDate(date: NgbDate): boolean {
  const ngbObj = JSON.parse(JSON.stringify(date));
  const newMoment = moment();

  if (ngbObj) {
    ngbObj.month--;
    newMoment.month(ngbObj.month);
    newMoment.date(ngbObj.day);
    newMoment.year(ngbObj.year);
  }

  return newMoment.isValid();
}
