export abstract class ApiResource {
  id?: number;
  version?: number;
  flagValid?: boolean;
  flagActive?: boolean;
  flagDeleted?: boolean;
  createdBy?: string;
  createdDate?: number;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
}
