import {Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {DmsElement} from '../../../models/dms-element';
import {ModalComponent} from '../../common/modals/modal/modal.component';
import {ConnectionEntry} from '../../../models/connection/connection-entry';
import {isNullOrUndefined} from 'util';
import {ConnectionService} from '../../../services/connection.service';
import {ConnectionElement} from '../../../models/connection/connection-element';
import {ConnectionElementEntryResource} from '../../../models/api/connection/connection-element-entry-resource';
import {SphereService} from '../../../services/sphere.service';
import {first, switchMap} from 'rxjs/operators';
import {Sphere} from '../../../models/sphere';
import {DmsFolderRepositoryService} from '../../../services/dms-folder-repository.service';
import {ConnectionCartService} from '../../../services/connection-cart.service';
import {ConnectionElementType} from '../../../enums/connection-element-type.enum';
import {FiscalYear} from '../../../models/fiscalYear';
import {FiscalYearResource} from '../../../models/api/fiscal-year-resource';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-connection-modal',
  templateUrl: './connection-modal.component.html',
  styleUrls: ['./connection-modal.component.scss']
})
export class ConnectionModalComponent implements OnDestroy{
  @Input() elements: ConnectionEntry[] = [];
  @Input() fiscalYear: FiscalYear | FiscalYearResource;
  @Input() directoryId: number;
  @Input() connectionType: ConnectionElementType;
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() deselect: EventEmitter<DmsElement> = new EventEmitter<DmsElement>();
  @Output() confirmed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('connectionModal') modal: ModalComponent;

  connection: ConnectionElement;

  private sphereSubscription: Subscription;

  constructor(private cartService: ConnectionCartService,
              private connectionService: ConnectionService,
              private sphereService: SphereService,
              private dmsFolderRepositoryService: DmsFolderRepositoryService) {
  }

  ngOnDestroy(): void {
    this.sphereSubscription?.unsubscribe();
  }

  open(): void {
    this.modal.open();
  }

  close(): void {
    this.modal.close();
  }

  cancel(): void {
    this.modal.close();
    // this.connectionType = undefined;
    this.canceled.emit();
  }

  onRemove(entry: ConnectionEntry): void {
    this.deselect.emit(entry.element);
  }

  submit() {
    if (!this.isValid) {
      console.warn('Invalid data!');
      return;
    }

    const resource = this.connectionService.convertModelToResource(this.connection);
    resource.type = this.connectionType;
    resource.fiscalYear = this.fiscalYear.year;
    resource.fiscalYearId = this.fiscalYear.id;
    resource.idDestinationFolder = this.directoryId;
    resource.elements = [];
    for (const element of this.elements) {
      const entry = new ConnectionElementEntryResource();
      entry.idElement = element.elementId;
      entry.fiscalYearId = element.fiscalYearId;
      entry.fiscalYear = element.fiscalYear;
      resource.elements.push(entry);
    }

    this.sphereSubscription = this.sphereService.currentSphere.pipe(
      first(),
      switchMap((sphere: Sphere) => {
        resource.sphereId = sphere.id;
        return this.dmsFolderRepositoryService.connect(resource);
      })
    ).subscribe((res: boolean) => {
      if (res) {
        this.confirmed.emit();
        this.cancel();
        this.cartService.clear();
      } else {
        console.error('Could not confirm connection', res);
      }
    });
  }

  get isValid(): boolean {
    return this.isConnectionValid && this.isElementsValid;
  }

  get isElementsValid(): boolean {
    if (isNullOrUndefined(this.elements)) {
      return false;
    }
    return (this.elements.length > 0 && this.connectionType === ConnectionElementType.ALIAS) || this.elements.length > 1;
  }

  get isConnectionValid(): boolean {
    const valid = !isNullOrUndefined(this.connectionType) && !isNullOrUndefined(this.fiscalYear);

    if (!valid) {
      return false;
    }

    if (this.connectionType !== ConnectionElementType.ALIAS) {
      return true;
    }

    if (isNullOrUndefined(this.directoryId)) {
      return false;
    }

    //  Cannot alias an element in the same parent directory.
    for (const element of this.elements) {
      if (element.element.parentId === this.directoryId && element.fiscalYearId === this.fiscalYear.id) {
        return false;
      }
    }

    return true;
  }
}
