import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard, AutologinGuard, PasswordManagerGuard } from '@pwc/security';
import { ConfiguratorImportComponent } from './components/configurator-import/configurator-import.component';
import { DirectDownloadComponent } from './components/direct-download/direct-download.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { LawConservationBucketComponent } from './components/documents/law-conservation/law-conservation-bucket/law-conservation-law-bucket.component';
import { LawConservationConfigurationComponent } from './components/documents/law-conservation/law-conservation-configuration/law-conservation-configuration.component';
import { LawConservationSummaryComponent } from './components/documents/law-conservation/law-conservation-summary/law-conservation-summary.component';
import { EventsComponent } from './components/events/events.component';
import { ExportsHistoryComponent } from './components/exports-history/exports-history.component';
import { LayoutComponent } from './components/layout/layout/layout.component';
import { LegalEntityComponent } from './components/legal-entity/legal-entity.component';
import { LoginComponent } from './components/login/login.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SharingManagerComponent } from './components/sharing/sharing-manager.component';
import { TagsManagerComponent } from './components/tags/tag-manager/tags-manager.component';
import { VdrDisclaimerComponent } from './components/vdr-disclaimer/vdr-disclaimer.component';
import { VdrComponent } from './components/vdr/vdr.component';
import { WeightReportComponent } from './components/weight-report/weight-report.component';
import { SuperUserGuard } from './guards/super-user.guard';
import { VdrGuard } from './guards/vdr.guard';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'documents',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      authorities: []
    }
  },
  {
    path: 'vdr-disclaimer',
    canActivate: [AuthenticationGuard, PasswordManagerGuard],
    component: VdrDisclaimerComponent,
    pathMatch: 'full',
    data: {
      authorities: []
    }
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AutologinGuard],
    canActivateChild: [AutologinGuard, AuthenticationGuard, PasswordManagerGuard, VdrGuard],
    children: [
      {
        path: 'documents',
        canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: DocumentsComponent,
        pathMatch: 'full',
        data: {
          authorities: []
        }
      },
      {
        path: 'import',
        canActivate: [AuthenticationGuard, PasswordManagerGuard, SuperUserGuard],
        component: ConfiguratorImportComponent,
        pathMatch: 'full',
        data: {
          authorities: []
        }
      },
      {

        path: 'admin-export',
        canActivate: [AuthenticationGuard, PasswordManagerGuard, SuperUserGuard],
        component: WeightReportComponent,
        pathMatch: 'full',
        data: {
          authorities: []
        }
      },
      {
        path: 'sharing',
        canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: SharingManagerComponent,
        pathMatch: 'full',
        data: {
          authorities: []
        }
      },
      {
        path: 'legal-entity',
        canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: LegalEntityComponent,
        pathMatch: 'full',
        data: {
          authorities: []
        }
      },
      {
        path: 'events',
        canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: EventsComponent,
        pathMatch: 'full',
        data: {
          authorities: []
        }
      },
      {
        path: 'tags',
        canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: TagsManagerComponent,
        pathMatch: 'full',
        data: {
          authorities: []
        }
      },
      {
        path: 'archive-summary',
        canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: LawConservationSummaryComponent,
        pathMatch: 'full',
        data: {
          authorities: []
        }
      },
      {
        path: 'archive-configuration',
        canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: LawConservationConfigurationComponent,
        pathMatch: 'full',
        data: {
          authorities: []
        }
      },
      {
        path: 'archive-bucket',
        canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: LawConservationBucketComponent,
        pathMatch: 'full',
        data: {
          authorities: []
        }
      },
      {
        path: 'exports',
        canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: ExportsHistoryComponent,
        pathMatch: 'full',
        data: {
          authorities: []
        }
      },
      {
        path: 'vdr',
        canActivate: [AuthenticationGuard, PasswordManagerGuard, SuperUserGuard],
        component: VdrComponent,
        pathMatch: 'full',
        data: {
          authorities: []
        }
      },
      {
        path: 'download/:token',
        canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: DirectDownloadComponent,
        pathMatch: 'full',
        data: {
          authorities: [],
          extension: '.zip'
        }
      },
      {
        path: 'export/:token',
        canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: DirectDownloadComponent,
        pathMatch: 'full',
        data: {
          authorities: [],
          extension: '.csv'
        }
      },
      {
        path: 'notifications',
        canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: NotificationsComponent,
        data: {
          authorities: []
        }
      },
    ]
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

