import {Injectable} from '@angular/core';
import {FiscalYear} from '../models/fiscalYear';
import {DmsElement} from '../models/dms-element';
import {FiscalYearResource} from '../models/api/fiscal-year-resource';
import {ConnectionEntry} from '../models/connection/connection-entry';
import {ApiResourceConverter} from '../contracts/common/api-resource-converter';
import {ConnectionElementResource} from '../models/api/connection/connection-element-resource';
import {ConnectionElement} from '../models/connection/connection-element';
import {ConnectionGroupResource} from '../models/api/connection/connection-group-resource';
import {ConnectionGroup} from '../models/connection/connection-group';
import {ConnectionAliasResource} from '../models/api/connection/connection-alias-resource';
import {ConnectionAlias} from '../models/connection/connection-alias';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService implements ApiResourceConverter<ConnectionElementResource, ConnectionElement> {
  public convertElementToConnectionEntry(element: DmsElement, year: FiscalYear | FiscalYearResource = new FiscalYear()): ConnectionEntry {
    const entry = new ConnectionEntry();
    entry.elementId = element.id;
    entry.element = element;
    entry.fiscalYear = year.year;
    entry.fiscalYearId = year.id;
    return entry;
  }

  public convertElementsListToConnectionEntries(elements: DmsElement[], year: FiscalYear | FiscalYearResource = new FiscalYear()): ConnectionEntry[] {
    return elements.map((element: DmsElement) => this.convertElementToConnectionEntry(element, year));
  }

  convertListToModel(list: ConnectionElementResource[]): ConnectionElement[] {
    return list.map((resource: ConnectionElementResource): ConnectionElement => this.convertResourceToModel(resource));
  }

  convertListToResource(list: ConnectionElement[]): ConnectionElementResource[] {
    return list.map((model: ConnectionElement): ConnectionElementResource => this.convertModelToResource(model));
  }

  convertModelToResource(model: ConnectionElement): ConnectionElementResource {
    return Object.assign(new ConnectionElementResource(), model);
  }

  convertResourceToModel(resource: ConnectionElementResource): ConnectionElement {
    return Object.assign(new ConnectionElement(), resource);
  }

  convertGroupToModel(resource: ConnectionGroupResource): ConnectionGroup {
    return Object.assign(new ConnectionGroup(), resource);
  }

  convertGroupListToModel(list: ConnectionGroupResource[]): ConnectionGroup[] {
    return list.map((resource: ConnectionGroupResource): ConnectionGroup => this.convertGroupToModel(resource));
  }

  convertAliasToModel(resource: ConnectionAliasResource): ConnectionAlias {
    return Object.assign(new ConnectionAlias(), resource);
  }

  convertAliasListToModel(list: ConnectionAliasResource[]): ConnectionAlias[] {
    return list.map((resource: ConnectionAliasResource): ConnectionAlias => this.convertAliasToModel(resource));
  }
}
