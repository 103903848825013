import {ApiResource} from '../../common/base/api-resource';

export class LawConservationListBucketResource extends ApiResource {

  sphereId: number;
  userId: number;
  id: number;
  description: string;
  name: string;
  date: Date;

  page?: number;
  sort?: string;
  sortType?: string;
}
