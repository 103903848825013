import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  LOCALE_ID,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {ConfiguratorImportService} from '../../services/configurator-import.service';
import {DmsElement} from '../../models/dms-element';
import {Observable, of, Subject} from 'rxjs';
import {isNullOrUndefined} from 'util';
import {PageableResponse} from '../../models/api/pageable-response';
import {ColumnDef} from '../../models/common/data-table/column-def';
import {SortDirectionEnum} from '../../enums/common/sort-direction.enum';
import {Router} from '@angular/router';
import {CdkColumnDef} from '@angular/cdk/table';
import {NgbdSortableHeaderDirective, SortEvent} from '../../directives/common/sortable.directive';
import {DataTableComponent} from '../common/data-table/data-table.component';
import {ApplicationEvent} from '../../models/event/application-event';
import {ApplicationEventResource} from '../../models/api/event/application-event-resource';
import {EventElementsService} from '../../services/events/event-elements.service';
import {debounceTime, switchMap} from 'rxjs/operators';
import {Sphere} from '../../models/sphere';
import {SphereService} from '../../services/sphere.service';
import {EventType} from '../../models/event/event-type';
import {piClear, piDelete, piDownload, piEdit, piView, PwcIconsLibrary} from "@pwc/icons";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  providers: [ConfiguratorImportService]
})
export class EventsComponent implements OnInit, AfterViewChecked {

  data: ApplicationEvent[] = [];
  pagination: PageableResponse<ApplicationEventResource[]>;
  columns: ColumnDef<ApplicationEvent>[] = [];
  customColumnsActive: string[] = [];
  customMetaColumnsActive: string[] = [];

  viewChecked = false;

  searchTerm = '';
  searchUser = null;
  searchSubject: Subject<string> = new Subject<string>();
  search$: Observable<string> = this.searchSubject.asObservable();

  currentSphereId: number;
  eventsType: EventType[] = [];
  eventCodes: string[] = [];
  usernames: string[] = [];

  sort: Map<string, SortDirectionEnum> = new Map();
  sortType = '';
  sortReverse = 0;

  @ViewChild('dataTable') dataTable: DataTableComponent<DmsElement>;
  @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;
  @ViewChildren(CdkColumnDef) customColumns: QueryList<CdkColumnDef>;

  constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef, private sphereService: SphereService,
              private eventService: EventElementsService, private iconsLibrary: PwcIconsLibrary) {
    iconsLibrary.registerIcons([
      piClear,
      piView,
      piEdit,
      piDelete
    ]);
    this.eventsType = this.eventService.getEventTypeValues();
  }

  ngOnInit(): void {
    this.eventService.pagination$.subscribe((pagination: PageableResponse<ApplicationEventResource[]>) => {
      this.pagination = pagination;
    });

    this.sphereService.currentSphere.pipe(
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of([]);
        }

        const search: Partial<ApplicationEventResource> = {
          sphereId: sphere.id
        };

        return this.eventService.getUsernames(search);
      })
    ).subscribe((res: string[]) => {
      this.usernames = [...res];
    });

    this.searchTerm = '';
    this.searchUser = null;
    this.refreshList();

    this.search$.pipe(
      debounceTime(200),
      switchMap(() => this.sphereService.currentSphere),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        this.currentSphereId = sphere.id;
        return this.eventService.search(this.getSearchBody(sphere.id));
      }),
    ).subscribe((res: ApplicationEvent[]) => this.updateElements(res));
  }

  emptyFilters() {
    this.searchTerm = '';
    this.searchUser = null;
    this.onSearch();
  }

  private refreshList() {
    this.sphereService.currentSphere.pipe(
      switchMap((sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        this.currentSphereId = sphere.id;
        return this.eventService.search(this.getSearchBody(sphere.id));
      })
    ).subscribe((res: ApplicationEvent[]) => this.updateElements(res));
  }

  private updateElements(elements: ApplicationEvent[]) {
    this.data = [...elements] || [];
    this.refreshColumns();
  }

  private refreshColumns() {
    let columns = [];
    columns = columns.concat(this.customMetaColumnsActive);
    columns.push('event_type');
    columns.push('description');
    columns.push('user');
    columns.push('event_date');
    this.customColumnsActive = columns;
  }

  ngAfterViewChecked(): void {
    this.viewChecked = true;
    this.changeDetectorRef.detectChanges();
  }

  onSearch() {
    if (!isNullOrUndefined(this.searchTerm) && this.searchTerm.length >= 3) {
      this.searchSubject.next(this.searchTerm);
    } else {
      this.searchSubject.next(null);
    }
  }

  onPageChange(page: number): void {
    const data = this.getSearchBody(this.currentSphereId);
    data.page = page - 1;

    this.eventService.search(data).pipe()
      .subscribe((res: ApplicationEvent[]) => this.updateElements(res));
  }

  onSort($event: SortEvent) {
    if ($event.direction === SortDirectionEnum.NONE) {
      this.sortReverse = 0;
    } else if ($event.direction === SortDirectionEnum.ASC) {
      this.sortReverse = 1;
    } else if ($event.direction === SortDirectionEnum.DESC) {
      this.sortReverse = 2;
    }

    this.sortType = $event.column;
    if ($event.direction === SortDirectionEnum.NONE) {
      this.sort.delete($event.column);
    } else {
      this.sort.set($event.column, $event.direction);
    }
    this.refreshList();
  }

  getEventTypeLabel(eventType: string) {
    return this.eventService.getEventTypeLabel(eventType);
  }

  private getSearchBody(idSphere: number): Partial<ApplicationEventResource> {
    const data: Partial<ApplicationEventResource> = {sphereId: idSphere};

    if ((!isNullOrUndefined(this.eventCodes) && this.eventCodes.length > 0)) {
      data.eventTypeCodes = [];
      data.eventTypeCodes.push(...this.eventCodes);
    }

    if (!isNullOrUndefined(this.searchUser)) {
      data.username = this.searchUser;
    }

    // if (!isNullOrUndefined(this.searchTerm) && this.searchTerm !== '' && this.searchTerm.length >= 3) {
    //   data.description = this.searchTerm;
    // }

    if (!isNullOrUndefined(this.sort)) {
      if (isNullOrUndefined(data.sort)) {
        data.sort = '';
      }
      this.sort.forEach((value, key) => {
        data.sort = key + ',' + value;
      });
    } else {
      data.sort = null;
    }

    return data;
  }
}
