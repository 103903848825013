import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { PageableResponse } from '../../../../models/api/pageable-response';
import { ColumnDef } from '../../../../models/common/data-table/column-def';
import { SortDirectionEnum } from '../../../../enums/common/sort-direction.enum';
import { Router } from '@angular/router';
import { CdkColumnDef } from '@angular/cdk/table';
import { NgbdSortableHeaderDirective, SortEvent } from '../../../../directives/common/sortable.directive';
import { DataTableComponent } from '../../../common/data-table/data-table.component';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Sphere } from '../../../../models/sphere';
import { SphereService } from '../../../../services/sphere.service';
import { LawConservationListBucketService } from '../../../../services/law-conservation/law-conservation-list-bucket.service';
import { LawConservationBucket } from '../../../../models/law-conservation/law-conservation-bucket';
import { LawConservationListBucketResource } from '../../../../models/api/law-conservation/law-conservation-list-bucket-resource';
import { ModalComponent } from '../../../common/modals/modal/modal.component';
import { ServiceTypeEnum } from '../../../../enums/law-conservation/service-type.enum';
import { LawConservationConfiguration } from '../../../../models/law-conservation/law-conservation-configuration';
import { piClear, piDelete, piEdit, piView, PwcIconsLibrary, piAdd } from "@pwc/icons";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-law-conservation-bucket',
  templateUrl: './law-conservation-bucket.component.html',
  styleUrls: ['./law-conservation-bucket.component.scss'],
  providers: [LawConservationListBucketService]
})
export class LawConservationBucketComponent implements OnInit, AfterViewChecked {

  data: LawConservationBucket[] = [];
  pagination: PageableResponse<LawConservationListBucketResource[]>;
  columns: ColumnDef<LawConservationBucket>[] = [
    {
      field: 'description',
      header: this.translateSrv.instant('generic.field.name'),
      sortable: true,
      cell: (row: LawConservationBucket) => `${(row.description ? row.description : '')}`,
    }
  ];
  customColumnsActive: string[] = [];
  customMetaColumnsActive: string[] = [];

  sort: Map<string, SortDirectionEnum> = new Map();
  viewChecked = false;

  searchTerm = '';
  searchSubject: Subject<string> = new Subject<string>();
  search$: Observable<string> = this.searchSubject.asObservable();

  selectedBucket: LawConservationBucket = new LawConservationBucket();
  newBucket: LawConservationBucket = new LawConservationBucket();
  currentSphereId: number;
  currentUserId: number;

  preview: boolean;

  errorDescription: string;
  errorPreservationSystem: string;
  errorUsername: string;
  errorBucket: string;
  errorPassword: string;
  errorPolicy: string;

  @ViewChild('dataTable') dataTable: DataTableComponent<LawConservationBucket>;
  @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;
  @ViewChildren(CdkColumnDef) customColumns: QueryList<CdkColumnDef>;
  @ViewChild('deleteBucketModal') deleteBucketModal: ModalComponent;
  @ViewChild('editBucketModal') editBucketModal: ModalComponent;
  @ViewChild('createBucket') createBucket: ModalComponent;

  constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef, private sphereService: SphereService,
    private eventService: LawConservationListBucketService, private iconsLibrary: PwcIconsLibrary, private translateSrv: TranslateService) {
    iconsLibrary.registerIcons([
      piClear,
      piView,
      piEdit,
      piDelete,
      piAdd
    ]);
  }

  ngOnInit(): void {
    this.eventService.pagination$.subscribe((pagination: PageableResponse<LawConservationListBucketResource[]>) => {
      this.pagination = pagination;
    });

    this.refreshList();

    this.search$.pipe(
      debounceTime(200),
      switchMap(() => this.sphereService.currentSphere),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        this.currentSphereId = sphere.id;
        this.currentUserId = sphere.userId;
        const body: Partial<LawConservationListBucketResource> = {
          sphereId: sphere.id,
          userId: sphere.userId
        };
        if (!isNullOrUndefined(this.searchTerm) && this.searchTerm !== '' && this.searchTerm.length >= 3) {
          body.description = this.searchTerm;
        }

        if (!isNullOrUndefined(this.sort)) {
          if (isNullOrUndefined(body.sort)) {
            body.sort = '';
          }
          this.sort.forEach((value, key) => {
            body.sort += key + ',' + value;
          });
        } else {
          body.sort = null;
        }

        return this.eventService.search(body);
      }),
    ).subscribe((res: LawConservationBucket[]) => this.updateElements(res));
  }

  private refreshList() {
    this.sphereService.currentSphere.pipe(
      switchMap((sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        this.currentSphereId = sphere.id;
        this.currentUserId = sphere.userId;

        const data: Partial<LawConservationListBucketResource> = { sphereId: sphere.id };

        if (!isNullOrUndefined(this.sort)) {
          if (isNullOrUndefined(data.sort)) {
            data.sort = '';
          }
          this.sort.forEach((value, key) => {
            data.sort += key + ',' + value;
          });
        } else {
          data.sort = null;
        }
        return this.eventService.search(data);
      })
    ).subscribe((res: LawConservationBucket[]) => this.resetPathAndUpdateElements(res));
  }

  private resetPathAndUpdateElements(elements: LawConservationBucket[]) {
    this.searchTerm = '';
    this.updateElements(elements);
  }

  private updateElements(elements: LawConservationBucket[]) {
    this.data = [...elements] || [];
    this.refreshColumns();
  }

  private refreshColumns() {
    let columns = [];
    columns = columns.concat(this.customMetaColumnsActive);
    columns.push('name');
    columns.push('creation_date');
    columns.push('details');
    this.customColumnsActive = columns;
  }

  ngAfterViewChecked(): void {
    this.viewChecked = true;
    this.changeDetectorRef.detectChanges();
  }

  emptyFilters() {
    this.searchTerm = '';
    this.onSearch();
  }

  onSearch() {
    if (!isNullOrUndefined(this.searchTerm) && this.searchTerm.length >= 3) {
      this.searchSubject.next(this.searchTerm);
    } else {
      this.searchSubject.next(null);
    }
  }

  onPageChange(page: number): void {

    let sortValues = '';
    if (!isNullOrUndefined(this.sort)) {
      this.sort.forEach((value, key) => {
        sortValues += key + ',' + value;
      });
    } else {
      sortValues = null;
    }

    const data: Partial<LawConservationListBucketResource> = {
      sort: sortValues,
      page: page - 1,
      sphereId: this.currentSphereId,
      userId: this.currentUserId,
    };

    this.eventService.search(data).pipe()
      .subscribe((res: LawConservationBucket[]) => this.updateElements(res));
  }

  onSort($event: SortEvent) {
    if ($event.direction === SortDirectionEnum.NONE) {
      this.sort.delete($event.column);
    } else {
      this.sort.set($event.column, $event.direction);
    }
    this.refreshList();
  }

  enableChanges(element: LawConservationBucket): boolean {
    return true;
  }

  enableDelete(element: LawConservationBucket): boolean {
    return element.allowDelete;
  }

  isDisabled(): boolean {
    return !this.selectedBucket.allowModify;
  }

  openDeleteElement(element: LawConservationBucket) {
    this.copyElement(element);
    this.deleteBucketModal.open();
  }

  beforeOpen() {
    this.clearErrors();
  }

  isPreview() {
    return this.preview;
  }

  openEditElement(element: LawConservationBucket, preview: boolean) {
    this.preview = preview;
    this.beforeOpen();
    this.copyElement(element);
    this.editBucketModal.open();
  }

  openCrateElement() {
    this.beforeOpen();
    this.newBucket = new LawConservationBucket();
    this.newBucket.preservationSystem = ServiceTypeEnum.INFOCERT;
    this.createBucket.open();
  }

  removeElement() {
    this.deleteBucketModal.close();
    this.eventService.delete(this.selectedBucket.id).pipe(switchMap(value => {
      const body: Partial<LawConservationListBucketResource> = {
        sphereId: this.currentSphereId,
        userId: this.currentUserId,
      };
      return this.eventService.search(body);
    })).subscribe((res: LawConservationBucket[]) => {
      this.selectedBucket = new LawConservationBucket();
      this.updateElements(res);
    });
  }

  clearErrors() {
    this.errorDescription = '';
    this.errorPreservationSystem = '';
    this.errorUsername = '';
    this.errorBucket = '';
    this.errorPassword = '';
    this.errorPolicy = '';
  }

  beforeSave(element: LawConservationBucket): boolean {
    this.clearErrors();

    if (element.description == null || element.description.trim() === '') {
      this.errorDescription = this.translateSrv.instant('documents.law.bucket.name-required');
    }
    if (element.preservationSystem == null) {
      this.errorPreservationSystem = this.translateSrv.instant('documents.law.bucket.required-system');
    }
    if (element.username == null || element.username.trim() === '') {
      this.errorUsername = this.translateSrv.instant('documents.law.bucket.user-name-required');
    }
    if (element.bucket == null || element.bucket.trim() === '') {
      this.errorBucket = this.translateSrv.instant('documents.law.bucket.bucket-required');
    }
    if (element.password == null || element.password.trim() === '') {
      this.errorPassword = this.translateSrv.instant('documents.law.bucket.password-required');
    }
    if (element.policy == null || element.policy.trim() === '') {
      this.errorPolicy = this.translateSrv.instant('documents.law.bucket.policy-mandatory');
    }

    if ((this.errorDescription + this.errorPreservationSystem + this.errorUsername + this.errorBucket +
      this.errorPassword + this.errorPolicy) === '') {
      return true;
    }
    return false;
  }

  editElement() {
    if (this.beforeSave(this.selectedBucket)) {
      this.editBucketModal.close();
      const elementResource = this.eventService.convertModelToResource(this.selectedBucket);
      elementResource.sphereId = this.currentSphereId;
      elementResource.userId = this.currentUserId;
      elementResource.flagValid = true;

      this.eventService.update(elementResource).pipe(switchMap(value => {
        const body: Partial<LawConservationListBucketResource> = {
          sphereId: this.currentSphereId,
          userId: this.currentUserId
        };
        return this.eventService.search(body);
      })).subscribe((res: LawConservationBucket[]) => {
        this.selectedBucket = new LawConservationBucket();
        this.updateElements(res);
      });
    }
  }

  addElement() {
    if (this.beforeSave(this.newBucket)) {
      this.createBucket.close();
      const elementResource = this.eventService.convertModelToResource(this.newBucket);
      elementResource.sphereId = this.currentSphereId;
      elementResource.userId = this.currentUserId;

      this.eventService.insert(elementResource).pipe(switchMap(value => {
        const body: Partial<LawConservationListBucketResource> = {
          sphereId: this.currentSphereId,
          userId: this.currentUserId
        };
        return this.eventService.search(body);
      })).subscribe((res: LawConservationBucket[]) => {
        this.newBucket = new LawConservationBucket();
        this.updateElements(res);
      });
    }
  }

  private copyElement(element: LawConservationBucket) {
    this.selectedBucket.id = element.id;
    this.selectedBucket.description = element.description;
    this.selectedBucket.sphereId = element.sphereId;
    this.selectedBucket.date = element.date;
    this.selectedBucket.preservationSystem = element.preservationSystem;
    this.selectedBucket.username = element.username;
    this.selectedBucket.bucket = element.bucket;
    this.selectedBucket.password = element.password;
    this.selectedBucket.policy = element.policy;
    this.selectedBucket.flagValid = element.flagValid;
    this.selectedBucket.allowDelete = element.allowDelete;
    this.selectedBucket.allowModify = element.allowModify;
  }

  optionINFOCERT(): string {
    return ServiceTypeEnum.INFOCERT;
  }

}
