import {ConnectionElementType} from '../../enums/connection-element-type.enum';
import {ConnectionEntry} from './connection-entry';
import {BaseModel} from '../common/base/base-model';

export class ConnectionElement extends BaseModel {
  sphereId: number;
  elements: ConnectionEntry[];
  idDestinationFolder: number;
  fiscalYear: number;
  fiscalYearId: number;
  type: ConnectionElementType;
}
