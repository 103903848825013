import {Component, OnInit, ViewChild, Output, EventEmitter, Input} from '@angular/core';
import {ModalComponent, SelectOption} from "@pwc/ui";

@Component({
  selector: 'app-sign-document',
  templateUrl: './sign-document.component.html',
  styleUrls: ['./sign-document.component.scss']
})
export class SignDocumentComponent implements OnInit {
  hasDropZoneOver = false;
  signTypes: SelectOption[];
  signType: string;

  @ViewChild('signModal') signModal: ModalComponent;

  constructor() {
  }

  ngOnInit(): void {
    // MOCK PER DEMO
    this.signType = 'INFOCERT';
    this.signTypes = [
      {
        id: 'INFOCERT',
        label: 'INFOCERT'
      }
    ];
  }

  fileOver($event: any) {
    this.hasDropZoneOver = $event;
  }
}



