import { Component, OnInit } from '@angular/core';
import { faAngleDown, faAngleUp, faCheckCircle, faCircleNotch, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { DownloaderService } from '../../services/downloader.service';
import { isNullOrUndefined } from 'util';
import { DownloadedItem } from '../../models/common/downloaded-item';

@Component({
  selector: 'app-downloader-queue',
  templateUrl: './downloader-queue.component.html',
  styleUrls: ['./downloader-queue.component.scss']
})
export class DownloaderQueueComponent implements OnInit {
  downloaderService: DownloaderService;

  listVisible = false;

  faCircleNotch = faCircleNotch;
  faTimesCircle = faTimesCircle;
  faCheckCircle = faCheckCircle;

  faTimes = faTimes;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;

  blob: Blob;

  constructor(downloaderService: DownloaderService) {
    this.downloaderService = downloaderService;
  }

  ngOnInit(): void {
    this.downloaderService.onDownloadCompleted.subscribe((res: DownloadedItem) => {
      // console.debug('onDownloadCompleted');
      this.openFile(res.blob, res.url, res.fileName);
    });
  }

  public openFile(blob: Blob, fileURL: string, fileName: string) {
    if (isNullOrUndefined(fileURL)) {
      return;
    }
    if (window.navigator.msSaveBlob) { // // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const tempLink = window.document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = fileURL;
      tempLink.setAttribute('download', fileName);
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(fileURL);
      }, 100);
    }
  }

  get isError(): boolean {
    return this.downloaderService.isError;
  }

  get isDownloading(): boolean {
    return this.downloaderService.isDownloading;
  }

  get isSuccess(): boolean {
    return this.downloaderService.isSuccess;
  }

  hideList(): void {
    this.listVisible = false;
  }

  showList(): void {
    this.listVisible = true;
  }

  emptyQueue() {
    this.downloaderService.removeAll();
  }

  closeDialog() {
    if (this.isSuccess || this.isError) {
      this.emptyQueue();
    }
    this.hideList();
  }
}
