import {Injectable} from '@angular/core';
import {DmsElement} from '../models/dms-element';
import {AuthenticationService} from '@pwc/security';
import {FiscalYear} from '../models/fiscalYear';
import {DmsSharingEntity} from '../models/share/dms-sharing-entity';
import {FiscalYearResource} from '../models/api/fiscal-year-resource';
import {SharingElementsService} from './share/sharing-elements.service';
import {QueueService} from './common/queue.service';

@Injectable({
  providedIn: 'root'
})
export class DmsCartService extends QueueService<DmsSharingEntity> {
  constructor(private authenticationService: AuthenticationService,
              private sharingElementsService: SharingElementsService) {
    super();
    this.authenticationService.logout$.subscribe(() => {
      this.clear();
    });
  }

  public push(element: DmsElement, year: FiscalYear | FiscalYearResource): void {
    this.pushAll([element], year);
  }

  public pushAll(elements: DmsElement[], year: FiscalYear | FiscalYearResource): void {
    const entities = this.sharingElementsService.convertElementsListToSharingEntities(elements, year);
    const list = [...this.listSubject.getValue(), ...entities];
    this.listSubject.next(list);
  }

  public remove(element: DmsElement): void {
    this.removeById(element.id);
  }

  public removeById(id: number): void {
    const list = this.listSubject.getValue();
    const newList: DmsSharingEntity[] = list.filter((item: DmsSharingEntity) => item.elementId !== id);

    this.listSubject.next(newList);
  }

  public removeByIdAndYear(id: number, year: FiscalYearResource): void {
    const list = this.listSubject.getValue();
    const newList: DmsSharingEntity[] = list.filter((item: DmsSharingEntity) => item.elementId !== id || item.fiscalYearId !== year.id);

    this.listSubject.next(newList);
  }

  getSelected(fiscalYear: FiscalYearResource): DmsElement[] {
    return this.listSubject.getValue()
      .filter((entity: DmsSharingEntity) => entity.fiscalYearId === fiscalYear.id)
      .map((item: DmsSharingEntity) => item.element);
  }
}
