import {ApiResource} from '../common/base/api-resource';


export class CheckCrossYearResource extends ApiResource {
  parentId: number;
  sphereId?: number;
  fiscalYear?: number; // Year of fiscal year.
  fiscalStartDate?: number;
  fiscalEndDate?: number;
  ok?: boolean;
  years: number[] = [];
}
