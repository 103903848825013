import {BaseModel} from './common/base/base-model';
import {DmsElementMetadataReg} from './dms-element-metadata-reg';
import {ExportCsvType} from '../enums/export-csv-type';

export class DmsExportCsv extends BaseModel {
  userId: number;
  sphereId: number;
  ids: number[];
  conservation: boolean;
  fiscalYear: number;
  error: boolean = false;
  type: ExportCsvType;
}
