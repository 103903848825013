<div class="page-container">

  <app-documents-breadcrumbs [showEdit]="false" componentName="events" basePath="Eventi"></app-documents-breadcrumbs>

  <div class="d-flex flex-row mb-4 align-items end box-filter">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">
          {{'generic.filters.label'|translate}}
          <button class="btn btn-primary btn-sm btn-action-icon ml-0 btn-clear-filter" [ngbTooltip]="'generic.filters.clear'|translate"
                  type="button" (click)="emptyFilters()">
            <pwc-icon name="clear"></pwc-icon>
          </button>
        </h5>

        <div class="d-md-flex flex-md-row flex-sm-column flex-wrap">

          <div class="item form-group-sm form-inline">
            <label for="filter-event">{{'event.select-event'|translate}}</label>
            <ng-select
              id="filter-event"
              bindLabel="code"
              appendTo="body"
              [(ngModel)]="eventCodes"
              (change)="onSearch()"
              (remove)="onSearch()"
              [multiple]="true">
              <ng-option [value]="event.code" *ngFor="let event of eventsType">
                {{event.value}}
              </ng-option>
            </ng-select>
          </div>

          <div class="item form-group-sm form-inline">
            <label for="filter-user">{{'event.created-by'|translate}}</label>
            <ng-select appendTo="body"
                       id="filter-user"
                       style="min-width: 200px; max-width: 250px;"
                       [clearable]="true"
                       [(ngModel)]="searchUser"
                       (ngModelChange)="onSearch()">
              <ng-option [value]="username" *ngFor="let username of usernames">
                {{username}}
              </ng-option>
            </ng-select>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="card" *ngIf="data && viewChecked">
    <div class="card-body card-table">
      <data-table #dataTable [dataSource]="data" [columns]="columns" [customColumnDefs]="customColumns"
                  [customColumns]="customColumnsActive" [enableSelect]="false" (sort)="onSort($event)">

        <ng-container cdkColumnDef="description">
          <th cdk-header-cell *cdkHeaderCellDef scope="col" sortable="description" (sort)="onSort($event)">
            {{'event.description'|translate}}
            <pwc-sorting-arrows [selected]="sortType == 'description'" [direction]="sortReverse"></pwc-sorting-arrows>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            {{(row.description ? row.description : '')}}
          </td>
        </ng-container>

        <ng-container cdkColumnDef="event_type">
          <th cdk-header-cell *cdkHeaderCellDef scope="col" sortable="eventType" (sort)="onSort($event)">
            {{'event.type'|translate}}
            <pwc-sorting-arrows [selected]="sortType == 'eventType'" [direction]="sortReverse"></pwc-sorting-arrows>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            {{getEventTypeLabel(row.eventType)}}
          </td>
        </ng-container>

        <ng-container cdkColumnDef="user">
          <th cdk-header-cell *cdkHeaderCellDef scope="col" sortable="username" (sort)="onSort($event)">
            {{'event.created-by'|translate}}
            <pwc-sorting-arrows [selected]="sortType == 'username'" [direction]="sortReverse"></pwc-sorting-arrows>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            {{row.username}}
          </td>
        </ng-container>

        <ng-container cdkColumnDef="event_date">
          <th cdk-header-cell *cdkHeaderCellDef scope="col" sortable="createdDate" (sort)="onSort($event)">
            {{'documents.metadata.event-date'|translate}}
            <pwc-sorting-arrows [selected]="sortType == 'createdDate'" [direction]="sortReverse"></pwc-sorting-arrows>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            {{row.createdDate|date:'dd/MM/yyyy'}}
          </td>
        </ng-container>

      </data-table>
    </div>
  </div>

  <div class="my-4">
    <pwc-pagination *ngIf="pagination" [page]="pagination.number + 1" [pageSize]="pagination.size"
                    [collectionSize]="pagination.totalElements" (pageChange)="onPageChange($event)"></pwc-pagination>
  </div>

</div>
