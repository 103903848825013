import {Injectable} from '@angular/core';
import {PageableService} from '../common/pageable.service';
import {isNullOrUndefined} from 'util';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PageableResponse} from '../../models/api/pageable-response';
import {ApplicationEvent} from '../../models/event/application-event';
import {ApplicationEventResource} from '../../models/api/event/application-event-resource';
import {ActivityEnum} from '../../enums/common/activity.enum';
import {EventType} from '../../models/event/event-type';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class EventElementsService extends PageableService<ApplicationEventResource, ApplicationEvent> {
  protected endpoint = 'user-event';

  constructor(http: HttpClient, private translateSrv: TranslateService) {
    super(http);
  }

  convertModelToResource(model: ApplicationEvent): ApplicationEventResource {
    return Object.assign(new ApplicationEventResource(), model);
  }

  convertResourceToModel(resource: ApplicationEventResource): ApplicationEvent {
    const model = new ApplicationEvent();
    Object.assign(model, resource);
    return model;
  }

  search(search?: Partial<ApplicationEventResource>): Observable<ApplicationEvent[]> {
    let params = new HttpParams();
    if (!isNullOrUndefined(search)) {
      for (const key of Object.getOwnPropertyNames(search)) {
        params = params.set(key, `${search[key]}`);
      }
    }
    return this.get<PageableResponse<ApplicationEventResource[]>>(`search`, {params}).pipe(
      map((response: PageableResponse<ApplicationEventResource[]>): ApplicationEventResource[] =>
        this.extractPaginationInfo(response)),
      map((list: ApplicationEventResource[]): ApplicationEvent[] => this.convertListToModel(list))
    );
  }

  getUsernames(search: Partial<ApplicationEventResource>): Observable<string[]> {
    let params = new HttpParams();
    if (!isNullOrUndefined(search)) {
      for (const key of Object.getOwnPropertyNames(search)) {
        params = params.set(key, `${search[key]}`);
      }
    }
    return this.get<string[]>('usernames', {params});
  }

  getEventTypeLabel(eventType: string) {

    if (eventType.toUpperCase() === 'MOVEEVENT') {
      return this.translateSrv.instant('menu.events.elements.moving');
    }
    if (eventType.toUpperCase() === 'DOWNLOADEVENT') {
      return this.translateSrv.instant('generic.action.download');
    }
    if (eventType.toUpperCase() === 'TAGADDEVENT') {
      return this.translateSrv.instant('menu.events.elements.add-tag');
    }
    if (eventType.toUpperCase() === 'TAGREMOVEEVENT') {
      return this.translateSrv.instant('menu.events.elements.disconnect-tag');
    }
    if (eventType.toUpperCase() === 'SHARINGADDEVENT') {
      return this.translateSrv.instant('share.label');
    }
    if (eventType.toUpperCase() === 'SHARINGADDMASTEREVENT') {
      return this.translateSrv.instant('menu.events.elements.add-master-user');
    }
    if (eventType.toUpperCase() === 'SHARINGDELETEEVENT') {
      return this.translateSrv.instant('menu.events.elements.close-sharing');
    }
    if (eventType.toUpperCase() === 'ADDDOCUMENTVERSIONEVENT') {
      return this.translateSrv.instant('menu.events.elements.new-version');
    }
    if (eventType.toUpperCase() === 'SENDNOWEVENT') {
      return this.translateSrv.instant('menu.events.elements.instant-conservation');
    }
    if (eventType.toUpperCase() === 'BUCKETINSERTEVENT') {
      return this.translateSrv.instant('menu.events.elements.bucket-configuration');
    }
    if (eventType.toUpperCase() === 'CONFIGURATIONINSERTEVENT') {
      return this.translateSrv.instant('documents.law.configuration.label');
    }
    if (eventType.toUpperCase() === 'SCHEDULEDSENDEVENT') {
      return this.translateSrv.instant('menu.events.elements.programmed-conversation');
    }
    if (eventType.toUpperCase() === 'SHARINGELEMENTADDEVENT') {
      return this.translateSrv.instant('menu.events.elements.add-sharing-element');
    }
    if (eventType.toUpperCase() === 'SHARINGELEMENTREMOVEEVENT') {
      return this.translateSrv.instant('menu.events.elements.delete-sharing-element');
    }
    if (eventType.toUpperCase() === 'SHARINGUSERADDEVENT') {
      return this.translateSrv.instant('menu.events.elements.add-subject-element');
    }
    if (eventType.toUpperCase() === 'SHARINGUSERREMOVEEVENT') {
      return this.translateSrv.instant('menu.events.elements.delete-subject-element');
    }
    if (eventType.toUpperCase() === 'SHARINGAUTHORIZATIONEDITEVENT') {
      return this.translateSrv.instant('menu.events.elements.edit-permission');
    }
    if (eventType.toUpperCase() === 'SHARINGVALIDITYEDITEVENT') {
      return this.translateSrv.instant('menu.events.elements.edit-validity');
    }


    if (eventType.indexOf(ActivityEnum.VIEW) > -1) {
      return this.translateSrv.instant('menu.events.elements.view');
    }
    if (eventType.indexOf(ActivityEnum.INSERT) > -1) {
      return  this.translateSrv.instant('menu.events.elements.enter');
    }
    if (eventType.indexOf(ActivityEnum.DELETE) > -1) {
      return this.translateSrv.instant('menu.events.elements.delete');
    }
    if (eventType.indexOf(ActivityEnum.UPDATE) > -1) {
      return this.translateSrv.instant('menu.events.elements.update');
    }


    return '';
  }

  getEventTypeValues(): EventType[] {
    const events: EventType[] = [];
    events.push(new EventType('DMSELEMENTEVENT' + ActivityEnum.VIEW, this.translateSrv.instant('menu.events.elements.view')));
    events.push(new EventType('DMSELEMENTEVENT' + ActivityEnum.INSERT, this.translateSrv.instant('menu.events.elements.enter')));
    events.push(new EventType('DMSELEMENTEVENT' + ActivityEnum.DELETE, this.translateSrv.instant('menu.events.elements.delete')));
    // events.push(new EventType('DMSELEMENTEVENT' + ActivityEnum.UPDATE, this.translateSrv.instant('menu.events.elements.update')));

    events.push(new EventType('MOVEEVENT', this.translateSrv.instant('menu.events.elements.moving')));
    events.push(new EventType('DOWNLOADEVENT', this.translateSrv.instant('generic.action.download')));
    events.push(new EventType('TAGADDEVENT', this.translateSrv.instant('menu.events.elements.add-tag')));
    events.push(new EventType('TAGREMOVEEVENT', this.translateSrv.instant('menu.events.elements.disconnect-tag')));
    events.push(new EventType('SHARINGADDEVENT', this.translateSrv.instant('share.label')));
    events.push(new EventType('SHARINGADDMASTEREVENT', this.translateSrv.instant('menu.events.elements.add-master-user')));
    events.push(new EventType('SHARINGDELETEEVENT', this.translateSrv.instant('menu.events.elements.close-sharing')));
    events.push(new EventType('ADDDOCUMENTVERSIONEVENT', this.translateSrv.instant('menu.events.elements.new-version')));
    events.push(new EventType('SENDNOWEVENT', this.translateSrv.instant('menu.events.elements.instant-conservation')));
    events.push(new EventType('SHARINGELEMENTADDEVENT', this.translateSrv.instant('menu.events.elements.add-sharing-element')));
    events.push(new EventType('SHARINGELEMENTREMOVEEVENT', this.translateSrv.instant('menu.events.elements.delete-sharing-element')));
    events.push(new EventType('SHARINGUSERADDEVENT', this.translateSrv.instant('menu.events.elements.add-subject-element')));
    events.push(new EventType('SHARINGUSERREMOVEEVENT', this.translateSrv.instant('menu.events.elements.delete-subject-element')));
    events.push(new EventType('SHARINGAUTHORIZATIONEDITEVENT', this.translateSrv.instant('menu.events.elements.edit-permission')));
    events.push(new EventType('SHARINGVALIDITYEDITEVENT', this.translateSrv.instant('menu.events.elements.edit-validity')));
    events.push(new EventType('BUCKETINSERTEVENT', this.translateSrv.instant('menu.events.elements.bucket-configuration')));
    events.push(new EventType('CONFIGURATIONINSERTEVENT', this.translateSrv.instant('documents.law.configuration.label')));
    events.push(new EventType('SCHEDULEDSENDEVENT', this.translateSrv.instant('menu.events.elements.programmed-conversation')));

    events.sort((a, b) => a.value.localeCompare(b.value));

    return events;
  }
}
