import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {ApiResource} from '../../models/common/base/api-resource';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {BaseModel} from '../../models/common/base/base-model';
import {map} from 'rxjs/operators';
import {isNullOrUndefined} from 'util';
import {ApiResourceConverter} from '../../contracts/common/api-resource-converter';

@Injectable({
  providedIn: 'root'
})
export abstract class HttpService<R extends ApiResource, M extends BaseModel> implements ApiResourceConverter<R, M> {
  protected abstract endpoint: string;

  constructor(protected http: HttpClient) {
  }

  protected get<T>(url: string = '', options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    return this.http.get<T>(`${environment.apiUrl}/${this.endpoint}/${url}`, options);
  }

  protected getBlob<Blob>(url: string = '', param: HttpParams, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Blob>> {
    let params = '';
    if (!isNullOrUndefined(param)) {
      params = '?';
      param.keys().forEach(value => params += `${value}=${param.get(value)}&`);
    }
    return this.http.get<Blob>(`${environment.apiUrl}/${this.endpoint}/${url}${params}`, {
      responseType: 'blob' as 'json',
      observe: 'response'
    });
  }

  protected post<T>(url: string = '', data: any): Observable<T> {
    return this.http.post<T>(`${environment.apiUrl}/${this.endpoint}/${url}`, data);
  }

  protected put<T>(url: string = '', data: object): Observable<T> {
    return this.http.put<T>(`${environment.apiUrl}/${this.endpoint}/${url}`, data);
  }

  public search(search?: Partial<R>): Observable<M[]> {
    let params = new HttpParams();
    if (!isNullOrUndefined(search)) {
      for (const key of Object.getOwnPropertyNames(search)) {
        params = params.set(key, `${search[key]}`);
      }
    }

    return this.get<R[]>('', {params}).pipe(
      map((list: R[]): M[] => this.convertListToModel(list))
    );
  }

  public insert(resource: R): Observable<M> {
    return this.post<R>('', resource).pipe(
      map((res: R): M => this.convertResourceToModel(res))
    );
  }

  public update(resource: R): Observable<M> {
    return this.put<R>('', resource).pipe(
      map((res: R): M => this.convertResourceToModel(res))
    );
  }

  public deleteByIds(ids: number[]): Observable<number> {
    const params = ids.map(id => `ids=${id}`).join('&');
    return this.http.delete<number>(`${environment.apiUrl}/${this.endpoint}/?${params}`);
  }

  public detail(id: number): Observable<M> {
    return this.get<R>(`${id}`).pipe(
      map((res: R): M => this.convertResourceToModel(res))
    );
  }

  public delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}/${this.endpoint}/${id}`);
  }

  public abstract convertResourceToModel(resource: R): M;

  public abstract convertModelToResource(model: M): R;

  public convertListToModel(list: R[]): M[] {
    return list.map((resource: R): M => this.convertResourceToModel(resource));
  }

  public convertListToResource(list: M[]): R[] {
    return list.map((model: M): R => this.convertModelToResource(model));
  }
}
