import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ExportHistory } from 'src/app/models/api/export-history/export-history';
import { environment } from 'src/environments/environment';
import { DmsFolderRepositoryService } from '../dms-folder-repository.service'

@Injectable({
  providedIn: 'root'
})
export class ExportsHistoryService {

  protected endpoint = 'exports';
  private http: HttpClient;

  constructor(http: HttpClient, private translateSrv: TranslateService, private folderSrv: DmsFolderRepositoryService) {
    this.http = http;
  }

  /**
   * Calls backend api to retrieve all valid exports related to current user and current sphere.
   * It accepts optional parameters to further filter the results
   * @param sphereId 
   * @param searchTerm 
   * @param sortColumn 
   * @param sortDirection 
   * @param page 
   * @param pageSize 
   * @returns 
   */
  findAll(
    sphereId: number,
    searchTerm: string = '',
    sortColumn: string = 'date',
    sortDirection: string = 'asc',
    page: number = 0,
    pageSize: number = 50): Observable<ExportHistory[]> {

    let params: HttpParams = new HttpParams().set('isPaged', true.toString());
    // optional paging details
    if (page) {
      params = params.append('page', page.toString());
    }
    if (pageSize) {
      params = params.append('size', pageSize.toString());
    }
    // optional sorting columns
    if (sortColumn && sortColumn !== '') { params = params.append('sort', sortColumn + ',' + sortDirection); }

    // optional search term (filtering by name)
    if (searchTerm && searchTerm !== '') {
      params = params.append('searchTerm', searchTerm);
    }

    return this.http.get<ExportHistory[]>(`${environment.apiUrl}/${this.endpoint}/${sphereId}`, { params: params });
  }

  /**
   * Calls backend api to invalidate all exports related to current user and current sphere
   * @param sphereId 
   * @returns 
   */
  softDeleteAll(sphereId: number) {
    return this.http.put<boolean>(`${environment.apiUrl}/${this.endpoint}/delete-all/${sphereId}`, null);
  }

  /**
   * Calls backend api to invalidate selected exports related to current user and current sphere
   * @param sphereId 
   * @param ids 
   * @returns 
   */
  public softDeleteByIds(sphereId: number, ids: number[]) {
    const params = ids.map(id => `ids=${id}`).join('&'); // map sphereId
    return this.http.put<boolean>(`${environment.apiUrl}/${this.endpoint}/delete-by-ids/${sphereId}/?${params}`, null);
  }

  public search() {
    // TODO
  }

  /**
   * Triggers export download by token
   * @param token 
   * @returns 
   */
  public downloadOne(token: string): Observable<{ blob: Blob, url: string, fileName: string }> {
    return this.folderSrv.getDownloadByToken(token);
  }

}