import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'ngx-toastr';
import { SessionStorageService } from 'ngx-webstorage';
import { ToastUtils } from 'src/app/helpers/toast-utils';
import { AuthenticationService } from 'src/app/services/common/authentication.service';
import { SphereService } from 'src/app/services/sphere.service';

@Component({
  selector: 'app-vdr-disclaimer',
  templateUrl: './vdr-disclaimer.component.html',
  styleUrls: ['./vdr-disclaimer.component.scss']
})
export class VdrDisclaimerComponent implements OnInit {

  constructor(private router: Router,
    private sphereSrv: SphereService,
    private toast: ToastrService,
    private translateSrv: TranslateService,
    private authSrv: AuthenticationService,
    private sessionStorageSrv: SessionStorageService) { }

  ngOnInit(): void {
  }

  acceptDisclaimer() {
    this.sphereSrv.acceptVdrDisclaimer().subscribe(success => {
      if (success) {
        this.router.navigate(['/documents']);
        this.toast.success(this.translateSrv.instant('generic.message.success-saving'), this.translateSrv.instant(ToastUtils.TITLE_SUCCESS));
        this.sessionStorageSrv.store('vdrPass', true);
      } else {
        this.toast.error(this.translateSrv.instant('generic.message.unexpected-error'), this.translateSrv.instant(ToastUtils.TITLE_ERROR));
        this.rejectDisclaimer();
      }
    });
  }

  rejectDisclaimer() {
    this.authSrv.logout();
  }

  useLanguage(language: string) {
    this.translateSrv.use(language.toLowerCase());
  }

}
