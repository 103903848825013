<div id="content-login">
  <div class="container-fluid">
    <main>
      <div class="row login-page">
        <div class="col-xl-4 col-lg-5 col-md-6 col-sm-6 ml-auto mr-auto login-col">
          <section class="login-box">
            <div class="row">
              <div class="col align-self-center">
                <div class="card login-card">
                  <div class="card-title">
                    <div class="col lang-box">
                      <select class="custom-select" id="gender2" style="float: right">
                        <option selected value="it">IT</option>
                        <option value="en">EN</option>
                      </select>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="col image-box">
                      <img width="500" class="image-login" src="/assets/images/digital-suite-tls.png"/>
                    </div>
                    <div class="col welcome-box">
                      <h3>{{'login.welcome-title'|translate}}</h3>
                      <span>{{'login.sub-title'|translate}}</span>
                      <hr>
                    </div>
                    <div class="col form-box">
                      <form *ngIf="!otpRequired" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                          <label for="username">{{'login.field.username'|translate}}</label>
                          <input type="text" formControlName="username" class="form-control"
                                 [ngClass]="{ 'is-invalid': submitted && f.username.errors }"/>
                          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required">{{'login.message.username-required'|translate}}</div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="password">{{'login.field.password'|translate}}</label>
                          <input type="password" formControlName="password" class="form-control"
                                 [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
                          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">{{'login.message.password-required'|translate}}</div>
                          </div>
                        </div>
                        <div class="form-check">
                          <input type="checkbox" class="form-check-input" id="exampleCheck1">
                          <label class="form-check-label" for="exampleCheck1">{{'login.field.stay-connect'|translate}}</label>
                          <div class="box-link-lost-password">
                            <a href="javascript:;" (click)="goToForgotPasswordPage()">{{'login.action.lost-password'|translate}}</a>
                          </div>
                        </div>
                        <div class="form-group">
                          <br>
                          <button [disabled]="loading" class="btn btn-primary text-uppercase login-btn">{{'login.action.login'|translate}}</button>
                          <img *ngIf="loading" class="pl-2"
                               src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                        </div>
                        <div *ngIf="error" class="alert alert-danger">{{error}}</div>
                      </form>

                      <form *ngIf="otpRequired" [formGroup]="otpForm" (ngSubmit)="onSubmitOtp()">
                        <div class="form-group">
                          <label for="code">{{'login.email-code'|translate}}</label>
                          <input type="text" maxlength="255" formControlName="code" id="code" name="code" class="form-control" [ngClass]="{ 'is-invalid': submitted && o.code.errors }" />
                          <div *ngIf="submitted && o.code.errors" class="invalid-feedback">
                            <div *ngIf="o.code.errors.required">{{'login.message.code-required'|translate}}</div>
                          </div>
                        </div>
                        <div class="form-group">
                          <br>
                          <button [disabled]="loading" class="btn btn-primary">{{'login.action.login'|translate}}</button>
                          <img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        </div>
                        <div *ngIf="error" class="alert alert-danger">{{error}}</div>
                      </form>
                    </div>
                    <div class="col welcome-box" *ngIf="false">
                      <hr>
                      <span>{{'login.registration-info'|translate}} <a href="#">{{'login.register-now'|translate}}</a></span>

                    </div>
                    <div class="col welcome-box">
                      <br>
                      <img alt="'login.ownership'|translate" class="logo-footer ml-auto" src="/assets/images/logo_footer_v2.PNG">
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section>
        </div>
      </div>
    </main>
  </div>
</div>
