import {ApiResource} from '../../common/base/api-resource';
import {SphereResource} from '../sphere-resource';
import {DocumentTypeEnum} from '../../../enums/document-type.enum';
import {DmsElementMetadataResource} from './dms-element-metadata-resource';
import {DmsFileHistoryResource} from '../dms-file-history-resource';
import {DmsElementInfoResource} from './dms-element-info-resource';
import {DmsSharingResource} from '../share/dms-sharing-resource';
import {DmsElementMetadataRegResource} from './dms-element-metadata-reg-resource';

export class DmsDocClassTypeResource extends ApiResource {
  code: string;
  description: string;
  metadata: DmsElementMetadataRegResource[] = [];
}
