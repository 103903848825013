import {Component, Input} from '@angular/core';
import {ButtonVariant} from '../enums/button-variant.enum';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'pwc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() variant: ButtonVariant = ButtonVariant.DEFAULT;
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;

  faCircleNotch = faCircleNotch;

  constructor() {
  }

  get classes(): object {
    return {
      'btn-success': this.variant === ButtonVariant.SUCCESS,
      'btn-secondary': this.variant === ButtonVariant.SECONDARY,
      'btn-danger': this.variant === ButtonVariant.DANGER,
      'btn-default': this.variant === ButtonVariant.DEFAULT
    };
  }
}
