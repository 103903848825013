import { CdkColumnDef } from '@angular/cdk/table';
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, LOCALE_ID, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { piClear, piDelete, piEdit, piView, PwcIconsLibrary } from "@pwc/icons";
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { NgbdSortableHeaderDirective, SortEvent } from '../../directives/common/sortable.directive';
import { SortDirectionEnum } from '../../enums/common/sort-direction.enum';
import { SphereUserRelationEnum } from '../../enums/sphere-user-relation.enum';
import { SharingUtils } from '../../helpers/common/sharing-utils';
import { PageableResponse } from '../../models/api/pageable-response';
import { DmsSharingResource } from '../../models/api/share/dms-sharing-resource';
import { Share2UserResource } from '../../models/api/share/share2-user-resource';
import { ColumnDef } from '../../models/common/data-table/column-def';
import { DmsElement } from '../../models/dms-element';
import { DmsSharing } from '../../models/share/dms-sharing';
import { Sphere } from '../../models/sphere';
import { SharingElementsService } from '../../services/share/sharing-elements.service';
import { SphereService } from '../../services/sphere.service';
import { DataTableComponent } from '../common/data-table/data-table.component';
import { ShareModalComponent } from '../share-modal/share-modal.component';

@Component({
  selector: 'app-sharing-manager',
  templateUrl: './sharing-manager.component.html',
  styleUrls: ['./sharing-manager.component.scss'],
  providers: [SharingManagerComponent]
})
export class SharingManagerComponent implements OnInit, AfterViewChecked {

  data: DmsSharing[] = [];
  pagination: PageableResponse<DmsSharingResource[]>;
  columns: ColumnDef<DmsSharing>[] = [
    {
      field: 'name',
      header: this.translateSrv.instant('share.detail.share-name'),
      sortable: true,
      cell: (row: DmsSharing) => `${(row.name ? row.name : '')}`,
    }
  ];
  customColumnsActive: string[] = [];
  customMetaColumnsActive: string[] = [];

  viewChecked = false;

  userList: Share2UserResource[] = [];

  searchTerm = '';
  searchUser: number;

  searchSubject: Subject<string> = new Subject<string>();
  search$: Observable<string> = this.searchSubject.asObservable();

  sort: Map<string, SortDirectionEnum> = new Map();
  sortType = '';
  sortReverse = 0;

  canEditSubject: Subject<boolean> = new BehaviorSubject(false);
  canEdit$: Observable<boolean> = this.canEditSubject.asObservable();

  @ViewChild('dataTable') dataTable: DataTableComponent<DmsElement>;
  @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;
  @ViewChildren(CdkColumnDef) customColumns: QueryList<CdkColumnDef>;
  @ViewChild('shareModal') shareModal: ShareModalComponent;

  constructor(private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private sharingService: SharingElementsService,
    private sphereService: SphereService,
    @Inject(LOCALE_ID) public locale: string,
    private iconsLibrary: PwcIconsLibrary,
    private translateSrv: TranslateService,
    private sharingUtils: SharingUtils
  ) {
    iconsLibrary.registerIcons([
      piClear,
      piView,
      piEdit,
      piDelete
    ]);
  }

  ngOnInit(): void {
    this.sharingService.pagination$.subscribe((pagination: PageableResponse<DmsSharingResource[]>) => {
      this.pagination = pagination;
    });

    this.sphereService.currentSphere.pipe(
      tap((sphere: Sphere) => {
        if (!isNullOrUndefined(sphere)) {
          this.canEditSubject.next(sphere.sphereUserRelation !== SphereUserRelationEnum.SHARED);
        }
      }),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of([]);
        }

        const search: Partial<DmsSharingResource> = { sphereId: sphere.id };

        return this.sharingService.searchUserSharing(search);
      })
    ).subscribe((res: DmsSharing[]) => this.resetPathAndUpdateElements(res));

    this.sphereService.currentSphere.pipe(
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of([]);
        }

        const search: Partial<DmsSharingResource> = { sphereId: sphere.id };

        return this.sharingService.getUsers(search);
      })
    ).subscribe((res: Share2UserResource[]) => {
      this.userList = [...res];
    });

    this.search$.pipe(
      debounceTime(200),
      switchMap(() => this.sphereService.currentSphere),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }

        const search: Partial<DmsSharingResource> = {
          sphereId: sphere.id
        };

        if (!isNullOrUndefined(this.searchTerm)) {
          search.name = this.searchTerm;
        }
        // console.debug('searchUser', this.searchUser);
        if (!isNullOrUndefined(this.searchUser)) {
          search.userId = this.searchUser;
        }

        if (!isNullOrUndefined(this.sort)) {
          this.sort.forEach((value, key) => {
            search.sort = key + ',' + value;
          });
        } else {
          search.sort = null;
        }

        return this.sharingService.searchUserSharing(search);
      })
    ).subscribe((res: DmsSharing[]) => this.updateElements(res));
  }

  emptyFilters() {
    this.searchTerm = '';
    this.searchUser = null;
    this.onSearch();
  }

  private resetPathAndUpdateElements(elements: DmsSharing[]) {
    this.searchTerm = '';
    this.searchUser = null;
    this.updateElements(elements);
  }

  private updateElements(elements: DmsSharing[]) {
    this.data = [...elements] || [];
    this.refreshColumns();
  }

  private refreshColumns() {
    let columns = [];
    columns = columns.concat(this.customMetaColumnsActive);
    columns.push('status');
    columns.push('share_date');
    columns.push('update_date');
    columns.push('validity_date');
    columns.push('permission');
    columns.push('shared_with');
    columns.push('details');
    this.customColumnsActive = columns;
  }

  ngAfterViewChecked(): void {
    this.viewChecked = true;
    this.changeDetectorRef.detectChanges();
  }

  onSearch() {
    if (!isNullOrUndefined(this.searchTerm) && this.searchTerm.length >= 3) {
      this.searchSubject.next(this.searchTerm);
    } else {
      this.searchSubject.next(null);
    }
  }

  onPageChange(page: number): void {
    this.sphereService.currentSphere.pipe(
      switchMap((sphere: Sphere) => {
        let sortValues = '';
        if (!isNullOrUndefined(this.sort)) {
          this.sort.forEach((value, key) => {
            sortValues = key + ',' + value;
          });
        } else {
          sortValues = null;
        }

        const data: Partial<DmsSharingResource> = {
          sphereId: sphere.id,
          sort: sortValues,
          page: page - 1
        };

        return this.sharingService.searchUserSharing(data);
      })
    ).subscribe((res: DmsSharing[]) => this.updateElements(res));
  }

  onSort($event: SortEvent) {
    if ($event.direction === SortDirectionEnum.NONE) {
      this.sortReverse = 0;
    } else if ($event.direction === SortDirectionEnum.ASC) {
      this.sortReverse = 1;
    } else if ($event.direction === SortDirectionEnum.DESC) {
      this.sortReverse = 2;
    }
    this.sortType = $event.column;
    if ($event.direction === SortDirectionEnum.NONE) {
      this.sort.delete($event.column);
    } else {
      this.sort.set($event.column, $event.direction);
    }
    this.refreshList();
  }

  private refreshList() {
    this.sphereService.currentSphere.pipe(
      switchMap((sphere: Sphere) => {
        const data: Partial<DmsSharingResource> = {
          sphereId: sphere.id
        };

        if (!isNullOrUndefined(this.sort)) {
          if (isNullOrUndefined(data.sort)) {
            data.sort = '';
          }
          this.sort.forEach((value, key) => {
            data.sort = key + ',' + value;
          });
        } else {
          data.sort = null;
        }
        return this.sharingService.searchUserSharing(data);
      })
    ).subscribe((res: DmsSharing[]) => {
      this.updateElements(res);
    });
  }

  openSharingModal(elem: DmsSharing) {
    this.shareModal.openModal(elem.id);
  }

  getSharingPermission(row: DmsSharing): string {
    return this.sharingUtils.sharingPermission(row);
  }

  getSharingUsers(row: DmsSharing): string {
    return this.sharingUtils.sharingUsers(row);
  }
}
