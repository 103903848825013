import { Component, Input } from '@angular/core';
import { DmsSharing } from '../../../models/share/dms-sharing';
import { User } from '@pwc/common';
import { BadgeListItem } from '../../../models/common/badge-list-item';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-share-selected-users',
  templateUrl: './share-selected-users.component.html',
  styleUrls: ['./share-selected-users.component.scss']
})
export class ShareSelectedUsersComponent {
  @Input() sharing: DmsSharing = new DmsSharing();
  @Input() canRemove: boolean = true;



  removeUser(email: any) {
    this.sharing.users = this.sharing.users.filter((item: User) => item.email !== email);
    this.getBadgeItems();
  }


  badgeItems: Array<any> = new Array<any>();

  getBadgeItems() {
    if (isNullOrUndefined(this.sharing.users)) {
      return [];
    }

    this.badgeItems = this.sharing.users.map((user: User) => ({ id: user.email, label: user.email, canRemove: this.canRemove && this.sharing.flagValid }));
  }
}
