import {ApiResource} from '../../common/base/api-resource';
import {MetadataFieldType} from '../../../enums/metadata-field-type.enum';

export class DmsElementMetadataRegResource extends ApiResource {
  code: string;
  name: string;
  required: boolean;
  searchable: boolean;
  visibleIndex: boolean;
  aggregatedName?: string;
  type: MetadataFieldType;
  elementMetadataRegGroup: any[];
  explicitNotVisible?: boolean;
}
