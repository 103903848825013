import {Directive, HostBinding, HostListener, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[pwcDetailRow]'
})
export class PwcDetailRowDirective {
  private row: any;
  private tRef: TemplateRef<any>;
  private opened: boolean;

  @HostBinding('class.expanded')
  get expanded(): boolean {
    return this.opened;
  }

  @Input()
  set pwcDetailRow(value: any) {
    if (value !== this.row) {
      this.row = value;
    }
  }

  @Input('pwcDetailRowTpl')
  set template(value: TemplateRef<any>) {
    if (value !== this.tRef) {
      this.tRef = value;
    }
  }

  @Input('expand')
  set expand(value: boolean) {
    if (value !== this.opened) {
      this.toggle();
    }
  }

  constructor(public vcRef: ViewContainerRef) {
  }

  toggle(): void {
    if (this.opened) {
      this.vcRef.clear();
    } else {
      this.render();
    }
    this.opened = this.vcRef.length > 0;
  }

  private render(): void {
    this.vcRef.clear();
    if (this.tRef && this.row) {
      this.vcRef.createEmbeddedView(this.tRef, {$implicit: this.row});
    }
  }
}
