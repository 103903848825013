import {Sphere} from '../../models/sphere';
import {SphereUserRelationEnum} from '../../enums/sphere-user-relation.enum';

export function sphereType(sphere: Sphere): string {
  let type = '';
  if (sphere.sphereUserRelation === SphereUserRelationEnum.OWNER) {
    type = 'Owner';
  } else if (sphere.sphereUserRelation === SphereUserRelationEnum.MASTER) {
    type = 'Master';
  } else if (sphere.sphereUserRelation === SphereUserRelationEnum.SHARED) {
    type = 'Condivisa';
  }
  return type;
}
