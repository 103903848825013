import {SelectableModel} from './common/base/selectable-model';
import {FileItem} from '../modules/file-upload/file-item.class';
import {DmsElement} from './dms-element';
import {DmsElementMetadata} from './dms-element-metadata';

export class UploadFile extends SelectableModel {
  item: FileItem;
  index: number;
  fiscalYear?: number;
  parentId?: number;
  fileName?: string;
  documentDate?: Date;
  fiscalStartDate?: Date;
  fiscalEndDate?: Date;
  parentList: DmsElement[] = [];
  searchList: DmsElement[] = [];
  meta: DmsElementMetadata[] = [];
  metadata: object = {};
  expand?: boolean = false;
}
