import {isNullOrUndefined} from 'util';
import {NotificationResource} from '../../models/api/notifications/notification-resource';
import {NotificationModel} from '../../models/common/notifications/notification-model';
import {NotificationRegistryResource} from '../../models/api/notifications/notification-registry-resource';
import {NotificationRegistry} from '../../models/common/notifications/notification-registry';
import {getCurrentLang} from '../common/i18n.helper';

export function transformEntityToNotification(entity: NotificationResource): NotificationModel {
  let model: NotificationModel = new NotificationModel();
  model = Object.assign(model, entity);
  model.isRead = entity.isRead === 'YES';
  model.date = new Date(entity.date);
  if (!isNullOrUndefined(entity.translation)) {
    if (!isNullOrUndefined(entity.translation[getCurrentLang()])) {
      model.name = entity.translation[getCurrentLang()].name;
      model.description = entity.translation[getCurrentLang()].description;
    }
  }

  model.notificationRegistry = transformEntityToNotificationRegistry(entity.notificationRegistry);

  // Backwards compatibility.
  if (isNullOrUndefined(model.name)) {
    model.name = model.notificationRegistry.name;
  }
  if (isNullOrUndefined(model.description)) {
    model.description = model.notificationRegistry.description;
  }
  return model;
}

export function transformNotificationToEntity(model: NotificationModel): NotificationResource {
  let entity: NotificationResource = new NotificationResource();
  entity = Object.assign(entity, model);
  entity.isRead = model.isRead === true ? 'YES' : 'NO';
  return entity;
}

export function transformEntityToNotificationRegistry(entity: NotificationRegistryResource): NotificationRegistry {
  let model = new NotificationRegistry();
  model = Object.assign(model, entity);
  if (!isNullOrUndefined(entity)) {
    if (isNullOrUndefined(model.name)) {
      model.name = entity.translation[getCurrentLang()].name;
    }
    if (isNullOrUndefined(model.description)) {
      model.description = entity.translation[getCurrentLang()].description;
    }
  }

  return model;
}
