import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { piCalendarSmall, piClear, piDelete, PwcIconsLibrary } from "@pwc/icons";
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { NgbdSortableHeaderDirective, SortEvent } from '../../../directives/common/sortable.directive';
import { SortDirectionEnum } from '../../../enums/common/sort-direction.enum';
import { mapTranslateMessages, tranlastionKeys } from '../../../helpers/common/i18n.helper';
import { NotificationModel } from '../../../models/common/notifications/notification-model';
import { Society } from '../../../models/common/society';
import { SocietiesService } from '../../../services/common/societies.service';
import { NotificationsListService } from '../../../services/notifications-list.service';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  providers: [DecimalPipe]
})
export class NotificationsListComponent implements OnInit {

  notificationsList$: Observable<NotificationModel[]>;
  total$: Observable<number>;
  total: number;
  selectAll: boolean;
  companyList: Society[] = [];
  selectedList: NotificationModel[] = [];
  selectedCount = 0;

  messages: any;

  @ViewChild('confirmationModal') confirmationModal: any;
  @Output() showLoading = new EventEmitter<boolean>();
  @Output() openDetail = new EventEmitter<object>();

  @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;

  constructor(public notificationsListService: NotificationsListService,
    private translateService: TranslateService,
    private societiesService: SocietiesService, private iconsLibrary: PwcIconsLibrary) {
    iconsLibrary.registerIcons([
      piCalendarSmall,
      piClear,
      piDelete
    ]);
    this.notificationsList$ = notificationsListService.list$;
    this.total$ = notificationsListService.total$;
    this.initTranslations();
  }

  initTranslations() {
    if (isNullOrUndefined(this.translateService)) {
      return;
    }

    this.translateService.get(tranlastionKeys).subscribe(translated => {
      this.messages = mapTranslateMessages(translated);
    });
  }

  ngOnInit() {
    this.notificationsListService.loadService();

    this.societiesService.getAll().subscribe(list => {
      this.companyList = list;
    });
    this.selectAll = false;

    this.notificationsListService.loading$.subscribe(show => {
      this.onShowLoading(show);
    });
    this.total$.subscribe(total => {
      this.total = total;
    });
  }

  onOpenDetail(id: number, permission: string) {
    this.openDetail.emit({ id, permission });
  }

  onShowLoading(show: boolean) {
    this.showLoading.emit(show);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = SortDirectionEnum.NONE;
      }
    });

    this.notificationsListService.sortColumn = column;
    this.notificationsListService.sortDirection = direction;
  }

  selectVisibleRows(selected: boolean) {
    this.notificationsList$.forEach(list => {
      list.forEach(i => {
        i.selected = selected;
      });
      if (selected) {
        this.selectedList = list.filter(e => e.selected);
      } else {
        this.selectedList = [];
      }
      this.selectedCount = this.selectedList.length;
    });
    this.selectAll = selected;
  }

  selectAllRows() {
    this.notificationsList$.forEach(list => {
      list.forEach(i => {
        i.selected = true;
      });
      // this.selectedList = list.filter(e => e.selected);
      this.selectedList = this.notificationsListService.list;
      this.selectedCount = this.selectedList.length;
    });
    this.selectAll = true;

  }

  selectRows(id: number, selected: boolean) {
    this.notificationsList$.forEach(list => {
      list.map(i => {
        i.selected = (i.id === id) ? selected : i.selected;
      });
      this.selectedList = list.filter(e => e.selected);
      this.selectedCount = this.selectedList.length;
    });
    this.selectAll = false;
  }

  pageChange() {
    this.selectAll = false;
    this.selectVisibleRows(false);
  }

  resetFilters() {
    this.notificationsListService.searchID = '';
    this.notificationsListService.searchName = '';
    this.notificationsListService.searchType = null;
    this.notificationsListService.searchDefault = null;
  }

  delete(id: any) {
    if (confirm(this.messages.deleteConfirm)) {
      this.notificationsListService.delete(id).subscribe(res => {
        this.openAlert(0, 'success', this.messages.deleteSuccess);
      }, (err) => {
        this.openAlert(0, 'error', this.messages.errorLoading);
      });
    }
  }

  deleteMassive() {
    if (confirm(this.messages.deleteSelectedConfirm)) {
      this.notificationsListService.deleteMassive(this.selectedList.map(u => u.id)).subscribe(res => {
        this.openAlert(0, 'success', this.messages.deleteSuccess);
      }, (err) => {
        this.openAlert(0, 'error', this.messages.errorLoading);
      });
    }
  }

  readNotification(obj: NotificationModel) {
    this.notificationsListService.markAsRead(obj.id).subscribe(res => {
      // this.notificationsListService.loadService();
      this.notificationsList$.forEach(list => {
        obj.isRead = true;
        /*list.map(i => {
            i.isRead = (i.id === objid) ? true : i.isRead;
        });*/
      });
    }, (err) => {
      this.openAlert(0, 'error', 'Si è verificato un errore');
    });
  }

  deleteMassiveEnable() {
    return this.selectedList.filter(i => i.isRead).length === 0;
  }

  openAlert(id: any, type: string, message: string) {
    this.confirmationModal.openModalAlert(id, type, message);
  }

  callbackApprovation() {
    this.notificationsListService.loadService();
  }

  truncateText(text: string, isRead: boolean) {
    if (!isRead && text.length > 50) {
      return text.substring(0, 50) + '...';
    } else {
      return text;
    }
  }
}
