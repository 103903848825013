import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {SphereService} from '../../../services/sphere.service';
import {DmsFolderRepositoryService} from '../../../services/dms-folder-repository.service';
import {switchMap} from 'rxjs/operators';
import {Sphere} from '../../../models/sphere';
import {DocumentTypeEnum} from '../../../enums/document-type.enum';
import {isNullOrUndefined} from 'util';
import {DmsElement} from '../../../models/dms-element';
import {ModalHandler} from '../../../contracts/common/modal-handler';
import {LocalDate} from '../../../models/local-date.model';

@Component({
  selector: 'app-new-folder-modal',
  templateUrl: './new-folder-modal.component.html',
  styleUrls: ['./new-folder-modal.component.scss']
})
export class NewFolderModalComponent implements ModalHandler {
  @Input() destinationDirectory: number;
  @Input() fiscalYear: number;
  @Input() sphereId: number;
  @Output() created: EventEmitter<DmsElement> = new EventEmitter<DmsElement>();
  name: string;

  submitted: boolean = false;

  constructor(private ngxSmartModalService: NgxSmartModalService,
              private dmsRepositoryService: DmsFolderRepositoryService) {
  }

  open() {
    this.name = null;
    this.ngxSmartModalService.getModal('createModal').open();
  }

  close() {
    this.ngxSmartModalService.getModal('createModal').close();
  }

  cancel() {
    this.close();
  }

  save() {
    this.submitted = true;
    if (isNullOrUndefined(this.name) || this.name === '') {
      // TODO: Show error to the user.
      console.error('Name is required!');
      this.submitted = false;
      return;
    }
    const date = new Date();
    this.dmsRepositoryService.insert({
      customFolder: true,
      lawConservation: false,
      lastLawConservationExecution: null,
      excludeFromLawConservation: false,
      name: this.name,
      sphereId: this.sphereId,
      parentId: this.destinationDirectory,
      fiscalYear: this.fiscalYear,
      documentType: DocumentTypeEnum.FOLDER
    }).subscribe((folder: DmsElement) => {
      this.created.emit(folder);
      this.submitted = false;
      this.close();
    });
  }
}
