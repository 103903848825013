import { LawConservationBucket } from './law-conservation-bucket';
import { SendingFrequencyTypeEnum } from '../../enums/law-conservation/sending-frequency-type.enum';
import { LawConservationConfigurationFiscalYear } from './law-conservation-configuration-fiscal-year';
import { SelectableModel } from '../common/base/selectable-model';
import { ServiceTypeEnum } from '../../enums/law-conservation/service-type.enum';

export class LawConservationConfiguration extends SelectableModel {

  id: number;
  sphereId: number;
  userId: number;
  name: string;
  bucket: LawConservationBucket;
  bucketId: number;
  sendingFrequency: SendingFrequencyTypeEnum;
  fiscalYear: LawConservationConfigurationFiscalYear[];
  startSending: number;
  date: Date;
  preservationSystem: ServiceTypeEnum;
  alias: string;
  pin: string;
  lastExecution?: Date;

  allowDelete: boolean;
  allowModify: boolean;

}
