import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {PaginationComponent} from './components/pagination/pagination.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {PwcCommonModule} from '../../components/common/pwc-common.module';
import {FormsModule} from '@angular/forms';

export {PaginationConfig} from './models/pagination-config';

@NgModule({
  declarations: [PaginationComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgbPaginationModule,
    FontAwesomeModule,
    PwcCommonModule
  ],
  exports: [
    PaginationComponent
  ]
})
export class PaginationModule {
}
