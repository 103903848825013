import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {isNullOrUndefined} from 'util';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor(private spinner: NgxSpinnerService) {
  }

  public show(name?: string) {
    if (!isNullOrUndefined(name)) {
      this.spinner.show(name);
    } else {
      this.spinner.show();
    }
  }

  public hide(name?: string) {
    if (!isNullOrUndefined(name)) {
      this.spinner.hide(name);
    } else {
      this.spinner.hide();
    }
  }
}
