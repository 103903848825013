import {BaseModel} from './common/base/base-model';
import {MetadataFieldType} from '../enums/metadata-field-type.enum';

export class DmsElementMetadataReg extends BaseModel {
  code: string;
  name: string;
  required: boolean;
  searchable: boolean;
  visibleIndex: boolean;
  type: MetadataFieldType;
  elementMetadataRegGroup: any[];
  aggregatedName?: string;
  explicitNotVisible?: boolean;

  // USED FOR ADVANCED SEARCH
  error?: boolean;
  value?: string;
}
