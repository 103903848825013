import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AlertModal } from '../../../../models/common/modals/alert-modal';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  constructor(private ngxSmartModalService: NgxSmartModalService) {
  }

  type: string;

  @Input() model: any;
  @Output() onAnyCloseEmitter = new EventEmitter<string>();

  ngOnInit() {
    this.type = '';
  }

  openModalAlert(id: any, type: string, message: string) {
    const obj: AlertModal = new AlertModal(id, type, message);
    this.type = obj.type;
    this.ngxSmartModalService.setModalData(obj, 'confirmationModal', true);
    this.ngxSmartModalService.getModal('confirmationModal').open();
  }

  onAnyClose(e) {
    this.onAnyCloseEmitter.emit(this.type);
  }

}
