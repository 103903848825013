import {BaseModel} from '../common/base/base-model';

export class ApplicationEvent extends BaseModel {
  applicationEventEntityId: number;
  eventType: string;
  flagValid: boolean;
  id: number;
  sphereId: number;
  userId: number;
  createdDate: number;

  username: string;
  description: string;

}
