export function getValueInRange(value: number, max: number, min: number = 0) {
  return Math.max(Math.min(value, max), min);
}

export function isNumber(value) {
  return !isNaN(toInteger(value));
}

export function toInteger(value) {
  return parseInt(`${value}`, 10);
}
