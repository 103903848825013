import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {DmsElement} from '../../../models/dms-element';
import {ConnectionElementType} from '../../../enums/connection-element-type.enum';
import {ModalComponent} from '../../common/modals/modal/modal.component';
import {ExportCsvType} from '../../../enums/export-csv-type';
import {DocumentTypeEnum} from '../../../enums/document-type.enum';
import {SphereService} from "../../../services/sphere.service";
import {
  piDelete,
  piDocumentale,
  piDocumentPage,
  piDownload, piExport,
  piInfo,
  piShare,
  piView,
  PwcIconsLibrary
} from "@pwc/icons";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-export-csv-modal',
  templateUrl: './export-csv-modal.component.html',
  styleUrls: ['./export-csv-modal.component.scss']
})
export class ExportCsvModalComponent {
  // @Input() elements: ConnectionEntry[] = [];
  @Input() elements: DmsElement[] = [];
  @Input() connectionType: ConnectionElementType;
  @Input() isGlobal: boolean;
  @Output() typeExport: EventEmitter<ExportCsvType> = new EventEmitter<ExportCsvType>();
  @Output() globalExportCsv: EventEmitter<void> = new EventEmitter<void>();
  @Output() globalExportRichCsv: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('addExportCSVModal') typeModal: ModalComponent;

  constructor(private iconsLibrary: PwcIconsLibrary) {
    iconsLibrary.registerIcons([
      piExport
    ]);
  }

  open(): void {
    this.typeModal.open();
  }

  close(): void {
    this.typeModal.close();
  }

  typeList(): void {
    if (this.isGlobal) {
      this.globalExportCsv.emit();
    } else {
      this.typeExport.emit(ExportCsvType.LIST_BASIC);
    }    
    this.close();
  }

  typeMetadata(): void {
    this.typeExport.emit(ExportCsvType.LIST_AND_METADATA);
    this.close();
  }

  typeTag(): void {
    this.typeExport.emit(ExportCsvType.LIST_AND_TAG);
    this.close();
  }

  get canDownloadExportCsvRich(): boolean {
    return `${environment.exportCsvRichEnabled}` === 'true';
  }

  onExportCSVRich() {
    if (this.isGlobal) {
      this.globalExportRichCsv.emit();
    } else {
      this.typeExport.emit(ExportCsvType.LIST_RICH);
    }
    this.close();
  }

  get hasFolder(): boolean {
    return this.elements.reduce((val: boolean, element: DmsElement) => val || element.documentType === DocumentTypeEnum.FOLDER, false);
  }
}
