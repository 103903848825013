<list-toolbar *ngIf="connectionCartService.isNotEmpty()">
  <ng-container toolbar-actions>
    <img src="/assets/images/icons/documents/toolbar/link.svg" [alt]="'connection.modal.link'|translate" placement="top"
         [ngbTooltip]="'connection.modal.link'|translate">
  </ng-container>

  <ng-container toolbar-text>
    <span *ngIf="connectionCartService.count() == 1">
      {{'generic.action.you-selected'|translate}} {{connectionCartService.count()}} {{'connection.toolbar.selected-element-single'|translate}}
    </span>
    <span *ngIf="connectionCartService.count() > 1">
      {{'generic.action.you-selected'|translate}} {{connectionCartService.count()}} {{'connection.toolbar.selected-element'|translate}}
    </span>

    <ng-container *ngIf="!isAlias">
      <a href="javascript:;" (click)="onConfirm()">{{'connection.modal.link'|translate}}</a>
      <span class="sep"> | </span>
    </ng-container>
    <ng-container *ngIf="isAlias && canCreateFolder">
      <a href="javascript:;" (click)="onConfirm()">{{'connection.toolbar.show'|translate}}</a>
      <span class="sep"> | </span>
    </ng-container>

    <a href="javascript:;" (click)="onCancel()">{{'connection.toolbar.cancel-selection'|translate}}</a>
  </ng-container>
</list-toolbar>
