import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthorizationService } from '@pwc/security';
import { concat, forkJoin, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthorityCodeEnum } from '../enums/common/authority-code.enum';
import { SphereService } from '../services/sphere.service';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class VdrGuard implements CanActivateChild {
  constructor(private authorizationService: AuthorizationService,
              private sphereService: SphereService,
              private router: Router,
              private sessionStorageSrv: SessionStorageService) {

  }

  canAccess = false;

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree | Promise<boolean> {

    const vdrPass = this.sessionStorageSrv.retrieve('vdrPass');

    if(vdrPass) {
      if(vdrPass === true) {
        return true;
      } else {
        this.router.navigate(['/vdr-disclaimer']);
        return false;
      }
    }

    return new Promise(resolve => {this.authorizationService.checkAuthorities(AuthorityCodeEnum.ROLE_DSA_IMPORT).pipe(
      switchMap(superUserPermission => this.sphereService.vdrCheck(superUserPermission))
    ).subscribe(insideVDRandNotAccepted => {
      if(insideVDRandNotAccepted) {
        this.sessionStorageSrv.store('vdrPass', false);
        this.router.navigate(['/vdr-disclaimer']);
      } else {
        this.sessionStorageSrv.store('vdrPass', true);
      }
      resolve(!insideVDRandNotAccepted);
    })});


  }

}
 