import {Component, Input} from '@angular/core';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'baloon',
  templateUrl: './baloon.component.html',
  styleUrls: ['./baloon.component.scss']
})
export class BaloonComponent {
  @Input() content: string;
  @Input() color: string;

  constructor() {
  }

  get styles() {
    let styles = {};

    if (!isNullOrUndefined(this.color)) {
      styles = {
        backgroundColor: this.color
      };
    }

    return styles;
  }
}
