import {isNullOrUndefined} from 'util';

export class ValidationUtils {
  static readonly EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

  public static email(value: string): boolean {
    if (ValidationUtils.isEmptyValue(value)) {
      return false;
    }
    return this.EMAIL_REGEXP.test(value);
  }

  public static isEmptyValue(value: string): boolean {
    return isNullOrUndefined(value) || !value.trim().length;
  }
}
