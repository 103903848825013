<pwc-modal #infoModal id="element-info" (closed)="onClose($event)" [bodyPadding]="false" [showFooter]="false">
    <div modal-title>
        <img src="{{iconPath}}" [alt]="dmsElement?.name" *ngIf="isFile"
            style="width: 40px;height: 40px; padding-right: 20px;">
        <img src="/assets/images/icons/directory.svg" [alt]="dmsElement?.name"
            style="width: 40px;height: 40px; padding-right: 20px;" *ngIf="isFolder">
        <div style="word-break: break-all;display:inline-block;">{{dmsElement?.name}}</div>
    </div>
    <div modal-body>
        <ngb-accordion [closeOthers]="true" activeIds="metadata" class="document-metadata" *ngIf="dmsElement"
            [activeIds]="activeIds">
            <ngb-panel id="path">
                <ng-template ngbPanelTitle>
                    <fa-icon [icon]="faAngleDown" class="document-metadata-show-closed"></fa-icon>
                    <fa-icon [icon]="faAngleUp" class="document-metadata-show-open"></fa-icon>
                    <img src="/assets/images/icons/documents/info/events.svg" [alt]="'generic.field.path'|translate" class="ml-2 mr-1"> {{'generic.field.path'|translate}}
                </ng-template>
                <ng-template ngbPanelContent>
                    <p>{{formatPath(dmsElement.path)}}</p>
                    <p class="mb-0"><strong>ID: </strong> {{dmsElement.id}}</p>
                </ng-template>
            </ngb-panel>
            <ngb-panel id="metadata">
                <ng-template ngbPanelTitle>
                    <fa-icon [icon]="faAngleDown" class="document-metadata-show-closed"></fa-icon>
                    <fa-icon [icon]="faAngleUp" class="document-metadata-show-open"></fa-icon>
                    <img src="/assets/images/icons/documents/info/metadata.svg" alt="'documents.metadata.metadata'|translate" class="ml-2 mr-1"> {{'documents.metadata.metadata'|translate}}
                </ng-template>
                <ng-template ngbPanelContent>
                    <dl class="row" style="overflow-y: scroll; max-height: 250px;">
                        <ng-container>
                            <dt class="col-sm-4" ngbTooltip="createdDate" placement="top-left" triggers="click:blur" style="cursor:pointer">{{'documents.metadata.upload-date'|translate}}
              </dt>
                            <dd class="col-sm-8">{{dmsElement.createdDate|date:'dd/MM/yyyy'}}</dd>
                        </ng-container>
                        <ng-container *ngFor="let meta of dmsElement.metadata">
                            <dt class="col-sm-4" style="margin-bottom: 10px;cursor:pointer" [ngbTooltip]="meta.elementMetadataReg?.code" placement="top-left" triggers="click:blur">{{meta.elementMetadataReg?.name}}</dt>
                            <dd class="col-sm-8" style="margin-bottom: 10px;">
                                {{getMetadataValue(meta)}}</dd>
                            <!-- <dt class="col-sm-4" style="margin-bottom: 10px;cursor:pointer"
                [ngbTooltip]="meta.elementMetadataReg?.code" placement="top-left"
                triggers="click:blur">{{meta.elementMetadataReg?.name}}</dt>
              <dd class="col-sm-8" style="margin-bottom: 10px;">{{getMetadataValue(meta)}}</dd> -->
                        </ng-container>
                    </dl>
                </ng-template>
            </ngb-panel>
            <ngb-panel id="document-versions" *ngIf="isFile">
                <ng-template ngbPanelTitle>
                    <fa-icon [icon]="faAngleDown" class="document-metadata-show-closed"></fa-icon>
                    <fa-icon [icon]="faAngleUp" class="document-metadata-show-open"></fa-icon>
                    <img src="/assets/images/icons/documents/info/events.svg" [alt]="'documents.metadata.version'|translate" class="ml-2 mr-1"> {{'documents.metadata.version'|translate}}
                </ng-template>
                <ng-template ngbPanelContent>
                    <dl class="row"
                        style="overflow-y: auto;overflow-x:hidden;max-height: 250px;padding-top:25px;padding-left:10px;">
                        <ng-container *ngFor="let history of fileHistory">
                            <dt class="col-sm-12">
                <button placement="top" [ngbTooltip]="'generic.action.download'|translate" type="button"
                  class="btn btn-primary btn-sm btn-action-icon" (click)="downloadDocument(history.id)">
                  <pwc-icon name="download" style="width:20px;height:20px;vertical-align:top;margin-right:.5rem;">
                  </pwc-icon>
                </button>
                <button placement="top" [ngbTooltip]="'generic.action.show-item'|translate" type="button"
                  class="btn btn-primary btn-sm btn-action-icon" (click)="openFileDetail(history.id)">
                  <pwc-icon name="view" style="width:25px;height:25px;vertical-align:top;margin-right:.5rem;">
                  </pwc-icon>
                </button>
                <button class="btn btn-sm" (click)="openFileDetail(history.id)">{{history.name}}</button>
              </dt>
                        </ng-container>
                    </dl>
                </ng-template>
            </ngb-panel>
            <ngb-panel id="tag">
                <ng-template ngbPanelTitle>
                    <fa-icon [icon]="faAngleDown" class="document-metadata-show-closed"></fa-icon>
                    <fa-icon [icon]="faAngleUp" class="document-metadata-show-open"></fa-icon>
                    <img src="/assets/images/icons/documents/info/tag.svg" [alt]="'documents.metadata.tag'|translate" class="ml-2 mr-1"> Tag
                </ng-template>
                <ng-template ngbPanelContent>
                    <dl class="row" style="overflow-y: scroll; max-height: 250px;">
                        <badge-list [items]="tagsList" (remove)="removeTag($event)"></badge-list>
                    </dl>
                </ng-template>
            </ngb-panel>
            <ngb-panel id="connections" *ngIf="allConnectionsLength">
                <ng-template ngbPanelTitle>
                    <fa-icon [icon]="faAngleDown" class="document-metadata-show-closed"></fa-icon>
                    <fa-icon [icon]="faAngleUp" class="document-metadata-show-open"></fa-icon>
                    <img src="/assets/images/icons/documents/info/link.svg" [alt]="'documents.metadata.connection'|translate" class="ml-2 mr-1" style="width:12px;height:12px;"> {{'documents.metadata.connection'|translate}} ({{allConnectionsLength}})
                </ng-template>
                <ng-template ngbPanelContent>
                    <div style="max-height: 250px;overflow-x:hidden;overflow-y:auto;padding-top:20px;">
                        <ng-container *ngIf="aliases.length">
                            <h5>{{'documents.metadata.see-other-directory'|translate}}</h5>
                            <div class="row" *ngFor="let alias of aliases">
                                <div class="col-md-8">
                                    <p>{{alias.parent?.path}}</p>
                                </div>
                                <div class="col-md-4">
                                    <button placement="top" [ngbTooltip]="'generic.action.disconnect'|translate" type="button" class="btn btn-primary btn-sm btn-action-icon" (click)="deleteAlias(alias)">
                    <pwc-icon name="delete" style="width:20px;height:20px;vertical-align:top;margin-right:.5rem;">
                    </pwc-icon>
                  </button>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="connections.length">
                            <h5>{{'documents.metadata.connected-document'|translate}}</h5>
                            <div class="row" *ngFor="let connection of connections">
                                <div class="col-md-8">
                                    <p class="mb-0">{{connection.element.name}}</p>
                                    <p class="text-muted" style="font-size:0.8em">{{connection.element.path}}</p>
                                </div>
                                <div class="col-md-4">
                                    <button placement="top" [ngbTooltip]="'generic.action.show-item'|translate" type="button" class="btn btn-primary btn-sm btn-action-icon" (click)="previewElement(connection.elementId)">
                    <pwc-icon name="view"></pwc-icon>

                  </button>
                                    <button placement="top" *ngIf="!isConserved(connection)" [ngbTooltip]="'generic.action.disconnect'|translate" type="button" class="btn btn-primary btn-sm btn-action-icon" (click)="deleteConnection(connection)">
                    <img src="/assets/images/icons/documents/toolbar/unlink.svg" [alt]="'generic.action.disconnect-document'|translate"
                      style="width:20px;height:20px;vertical-align:top;margin-right:.5rem;">
                  </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-template>
            </ngb-panel>
            <ngb-panel id="condivisioni" *ngIf="info.length">
                <ng-template ngbPanelTitle>
                    <fa-icon [icon]="faAngleDown" class="document-metadata-show-closed"></fa-icon>
                    <fa-icon [icon]="faAngleUp" class="document-metadata-show-open"></fa-icon>
                    <img src="/assets/images/icons/documents/info/shares.svg" [alt]="'documents.metadata.sharing'|translate" class="ml-2 mr-1"> {{'documents.metadata.sharing'|translate}}
                </ng-template>
                <ng-template ngbPanelContent>
                    <dl class="row" style="overflow-y: scroll; max-height: 250px;">
                        <ng-container *ngFor="let data of info">
                            <dd class="col-sm-12">{{data}}</dd>
                        </ng-container>
                    </dl>
                </ng-template>
            </ngb-panel>

            <ngb-panel id="eventi">
                <ng-template ngbPanelTitle>
                    <fa-icon [icon]="faAngleDown" class="document-metadata-show-closed"></fa-icon>
                    <fa-icon [icon]="faAngleUp" class="document-metadata-show-open"></fa-icon>
                    <img src="/assets/images/icons/documents/info/events.svg" [alt]="'documents.metadata.connection'|translate" class="ml-2 mr-1"> {{'documents.metadata.check-event'|translate}}
                </ng-template>
                <ng-template ngbPanelContent>
                    <div style="overflow-y: scroll; max-height: 250px;">
                        <data-table #dataTable [dataSource]="events" [columns]="[]" [customColumnDefs]="eventColumns"
                            [customColumns]="eventColumnsActive" [enableSelect]="false">

                            <ng-container cdkColumnDef="event_type">
                                <th cdk-header-cell *cdkHeaderCellDef scope="col">{{'documents.metadata.event-type'|translate}}</th>
                                <td cdk-cell *cdkCellDef="let row">
                                    {{getEventTypeLabel(row.eventType)}}
                                </td>
                            </ng-container>

                            <ng-container cdkColumnDef="user">
                                <th cdk-header-cell *cdkHeaderCellDef scope="col">{{'generic.field.user'|translate}}</th>
                                <td cdk-cell *cdkCellDef="let row">
                                    {{row.username}}
                                </td>
                            </ng-container>

                            <ng-container cdkColumnDef="event_date">
                                <th cdk-header-cell *cdkHeaderCellDef scope="col">{{'documents.metadata.event-date'|translate}}</th>
                                <td cdk-cell *cdkCellDef="let row">
                                    {{row.createdDate|date:'dd/MM/yyyy'}}
                                </td>
                            </ng-container>

                        </data-table>
                    </div>

                </ng-template>
            </ngb-panel>

            <!-- TEMPORARY HIDDEN -->
            <!--
      <ngb-panel id="pratica">
        <ng-template ngbPanelTitle>
          <fa-icon [icon]="faAngleDown" class="document-metadata-show-closed"></fa-icon>
          <fa-icon [icon]="faAngleUp" class="document-metadata-show-open"></fa-icon>
          <img src="/assets/images/icons/documents/info/events.svg" alt="Pratica" class="ml-2 mr-1">
          Pratica
        </ng-template>
        <ng-template ngbPanelContent>
          <dl class="row">
          </dl>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="coll_documentale">
        <ng-template ngbPanelTitle>
          <fa-icon [icon]="faAngleDown" class="document-metadata-show-closed"></fa-icon>
          <fa-icon [icon]="faAngleUp" class="document-metadata-show-open"></fa-icon>
          <img src="/assets/images/icons/documents/info/shares.svg" alt="Collegamento Documentale" class="ml-2 mr-1">
          Collegamento Documentale
        </ng-template>
        <ng-template ngbPanelContent>
          <dl class="row">
          </dl>
        </ng-template>
      </ngb-panel>
     -->
            <ngb-panel id="conservazione" *ngIf="isLawConservation">
                <ng-template ngbPanelTitle>
                    <fa-icon [icon]="faAngleDown" class="document-metadata-show-closed"></fa-icon>
                    <fa-icon [icon]="faAngleUp" class="document-metadata-show-open"></fa-icon>
                    <img src="/assets/images/icons/documents/info/conservazione_a_norma.svg" [alt]="'documents.metadata.norm-conservation'|translate" class="ml-2 mr-1"> {{'documents.metadata.norm-conservation'|translate}}
                </ng-template>
                <ng-template ngbPanelContent>
                    <dl class="row">
                        <dt class="col-sm-12" *ngIf="newVersionNotConserved(dmsElement)">
              <dl class="row">
                <dt class="col-sm-12 text-center"><b>{{'documents.metadata.last-version-message'|translate}}</b></dt>
                        <dt class="col-sm-4">{{'documents.metadata.file-name'|translate}}</dt>
                        <dd class="col-sm-8">{{dmsElement.name}}</dd>
                        <dt class="col-sm-4">{{'documents.metadata.note'|translate}}</dt>
                        <dd class="col-sm-8">{{dmsElement.lawConservatioNote}}</dd>
                    </dl>
                    </dt>
                    <dt class="col-sm-4">{{'documents.metadata.memorized'|translate}}</dt>
                    <dd class="col-sm-8">
                        <fa-icon [icon]="faTimesCircle" *ngIf="!dmsElement.lawConservation" class="text-danger"></fa-icon>
                        <fa-icon [icon]="faCheckCircle" *ngIf="dmsElement.lawConservation" class="text-success"></fa-icon>
                    </dd>

                    <dt class="col-sm-4">{{'documents.metadata.file-name'|translate}}</dt>
                    <dd class="col-sm-8">{{dmsElement.lawConservatioFilename}}</dd>

                    <dt class="col-sm-4">{{'generic.field.date'|translate}}</dt>
                    <dd class="col-sm-8">{{dmsElement.lawConservatioDate|date:'dd/MM/yyyy'}}</dd>

                    <dt class="col-sm-4">{{'documents.metadata.memorize-token'|translate}}</dt>
                    <dd class="col-sm-8">{{dmsElement.lawConservatioToken}}</dd>

                    <dt class="col-sm-4">{{'documents.metadata.rectification'|translate}}</dt>
                    <dd class="col-sm-8">{{dmsElement.lawConservatioRectification ? ('generic.simple-word.yes'|translate) : ('generic.simple-word.no'|translate) }}</dd>

                    <dt class="col-sm-12" *ngIf="!hasAtLeastOneVersionLawConserved(dmsElement)">
              <dl class="row">
                <dt class="col-sm-4">{{'documents.metadata.note'|translate}}</dt>
                    <dd class="col-sm-8">{{dmsElement.lawConservatioNote}}</dd>
                    </dl>
                    </dt>

                    <dt class="col-sm-12" style="margin-top: 5px;" *ngIf="lawConservationVersionList.length == 0 && dmsElement.lawConservatioRectification">
              <button class="btn btn-link" (click)="getAllLawConservationVersion(dmsElement)">{{'documents.metadata.see-all'|translate}}</button>
            </dt>
                    <dt class="col-sm-12" *ngIf="lawConservationVersionList.length > 0">
              <dl class="row previous-lc-versions">
                <dt class="col-sm-12 text-center">{{'documents.metadata.previous-version'|translate}}</dt>
                    </dl>
                    <dl class="row previous-tokens-info">
                        <ng-container *ngFor="let lawConservationVersion of lawConservationVersionList; let i=index">
                            <div *ngIf="i!=0" class="col-12 lc-divider"></div>
                            <div class="col-10">
                                <div class="row law-conservation-infos">
                                    <dt class="col-sm-5" style="margin-top: 10px;">{{'documents.metadata.file-name'|translate}}</dt>
                                    <dd class="col-sm-7" style="margin-top: 10px;">{{lawConservationVersion.filename}}</dd>
                                    <dt class="col-sm-5">{{'generic.field.date'|translate}}</dt>
                                    <dd class="col-sm-7">{{lawConservationVersion.date|date:'dd/MM/yyyy'}}</dd>
                                    <dt class="col-sm-5">{{'documents.metadata.memorize-token'|translate}}</dt>
                                    <dd class="col-sm-7">{{lawConservationVersion.token}}</dd>
                                </div>
                            </div>
                            <!-- <div class="col-2">
                                <button ngbTooltip="Download" type="button"
                                    class="btn btn-primary btn-sm btn-action-icon trigger-hover lc-download">
                                    <img src="/assets/images/icons/documents/toolbar/download.svg" alt="Download"
                                        class="hidden-hover">
                                    <img src="/assets/images/icons/documents/toolbar/download-hover.svg"
                                        alt="Download" class="visible-hover">
                                </button>
                            </div> -->
                        </ng-container>
                    </dl>
                    </dt>
                    </dl>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
</pwc-modal>

<pwc-modal #deleteTagModal id="deleteTagModal">
    <div modal-title>{{'generic.action.delete.label'|translate}}</div>
    <div modal-body>
        {{'documents.metadata.delete-tag'|translate}} {{getTagDescription()}}
    </div>
    <div modal-footer class="text-right">
        <button type="button" class="btn btn-undo" (click)="deleteTagModal.close()">{{'generic.action.cancel'|translate}}</button>
        <button type="button" class="btn btn-success ml-2" (click)="deleteTag()">{{'generic.action.confirm'|translate}}</button>
    </div>
</pwc-modal>

<pwc-modal #deleteConnectionModal id="delete-connection-modal">
    <div modal-title>
        {{'documents.metadata.unlink'|translate}}
    </div>
    <div modal-body>
        {{'documents.metadata.unlink-question'|translate}}
    </div>
    <div modal-footer class="text-right">
        <button type="button" class="btn btn-action btn-danger" (click)="deleteConnectionModal.close()">{{'generic.simple-word.no'|translate}}</button>
        <button type="button" class="btn btn-action btn-success ml-2" (click)="doDeleteConnection()">{{'generic.simple-word.yes'|translate}}</button>
    </div>
</pwc-modal>

<pwc-modal #deleteAliasModal id="delete-alias-modal">
    <div modal-title>
        {{'documents.metadata.delete'|translate}}
    </div>
    <div modal-body>
        {{'documents.metadata.delete-question'|translate}}
    </div>
    <div modal-footer class="text-right">
        <button type="button" class="btn btn-action btn-danger" (click)="deleteAliasModal.close()">{{'generic.simple-word.no'|translate}}</button>
        <button type="button" class="btn btn-action btn-success ml-2" (click)="doDeleteAlias()">{{'generic.simple-word.yes'|translate}}</button>
    </div>
</pwc-modal>
