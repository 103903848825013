import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {UserResource} from '../../models/api/user-resource';
import {User} from '@pwc/common';

@Injectable({
  providedIn: 'root'
})
export class UserService extends HttpService<UserResource, User> {
  protected endpoint: 'users';

  convertModelToResource(model: User): UserResource {
    return Object.assign<UserResource, User>(new UserResource(), model);
  }

  convertResourceToModel(resource: UserResource): User {
    return Object.assign<User, UserResource>(new User(), resource);
  }
}
