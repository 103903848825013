import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@pwc/common';
import { LanguageCode } from '@pwc/i18n';
import { piBell, piBell2, PwcIconsLibrary } from '@pwc/icons';
import { AuthenticationService, IdleTimeoutService } from '@pwc/security';
import { first, tap } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { isNullOrUndefined } from 'util';
import { environment } from '../../../../environments/environment';
import { Language } from '../../../models/common/language';
import { NotificationsListService } from '../../../services/notifications-list.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  user: User = null;
  users: User[] = [];
  userInitials = '';
  notificationCount = 0;

  // @Input() languages: Language[];
  // @Input() currentLanguage: string;
  @Output() updateLanguage = new EventEmitter<string>();

  it: Language = { id: '1', code: LanguageCode.IT, description: 'IT' };
  en: Language = { id: '2', code: LanguageCode.EN, description: 'EN' };
  languages: Language[] = [];
  currentLanguage: string;

  constructor(private appComponent: AppComponent,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationsListService,
    private pwcIconsLibrary: PwcIconsLibrary,
    private translateSrv: TranslateService,
    private idleService: IdleTimeoutService
  ) {
    this.idleService.start(5,900); // 5 secondi dopo nessuna attività e 15 minuti prima di fare logout

    this.pwcIconsLibrary.registerIcons([
      piBell,
      piBell2,
    ]);
    this.languages.push(this.it);
    this.languages.push(this.en);
  }

  ngOnInit(): void {
    this.initLoggedUser();
    this.initNotificationsCount();
  }

  logout(): void {
    this.appComponent.logout();
  }

  navigateToChangePassword(): void {
    window.location.href = `${environment.communicoUrl}/account/change-password`;
  }

  initLoggedUser(): void {
    this.authenticationService.dataUser()
      .pipe(
        first(),
        tap((user: User) => {
          if (user !== null && !isNullOrUndefined(user.firstName)) {
            this.userInitials += user.firstName.substr(0, 1);
          }
          if (user !== null && !isNullOrUndefined(user.lastName)) {
            this.userInitials += user.lastName.substr(0, 1);
          }
        })
      )
      .subscribe(
        data => {
          this.user = data;
          this.currentLanguage = data.language;
          this.changeLanguage();
          this.users.push(this.user);
          if (this.user === null) {
            this.user = new User();
            this.user.enabled = false;
          }
        },
        error => {
          console.error(error);
        });
  }

  private initNotificationsCount(): void {
    if (environment.notificationInterval > 0) {
      setInterval(() => {
        this.notificationService.countUnread()
          .pipe(first())
          .subscribe((count: number) => {
            this.notificationCount = count;
          }, (error) => {
            console.error(error);
          });
      }, environment.notificationInterval * 1000);
    }
  }

  getNotificationStatus(): string {
    let label = '';
    // const status = this.notificationCount > 0 ? 'header.notification.new' : 'header.notification.empty';
    // this.translate.get(status).subscribe((res: string) => {
    //   label = res;
    // });
    // return label;
    return label = (this.notificationCount > 0 ? this.translateSrv.instant('header.notification') : this.translateSrv.instant('header.no-notification'));
  }

  changeLanguage() {
    this.updateLanguage.emit(this.currentLanguage);
  }
}
