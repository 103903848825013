<pwc-modal #startSharingModal id="start-sharing-modal">
  <div modal-title>
    {{'generic.action.share'|translate}}
  </div>

  <div modal-body>
    <div class="row">
      <div class="col-md-12">
        {{'generic.action.you-selected'|translate}} {{elementsSize}} {{'documents.law.start.share-message'|translate}}
      </div>
    </div>
  </div>

  <div modal-footer class="text-right">
    <button type="button" class="btn btn-danger" data-dismiss="modal"
            (click)="onCancel()">{{'generic.action.cancel'|translate}}
    </button>
    <button type="button" class="btn btn-success ml-2"
            (click)="onContinue()">{{'generic.action.add-elements'|translate}}
    </button>
    <button type="button" class="btn btn-success ml-2"
            (click)="onConfirm()">{{'generic.action.share'|translate}}
    </button>
  </div>
</pwc-modal>
