<pwc-modal #addTagModal id="addTagModal">
  <div modal-title>{{'tag.add'|translate}}</div>
  <div modal-body>
    <ng-select bindLabel="code"
              bindValue="id"
              [placeholder]="'documents.component.select-tag'|translate"
              appendTo="body"
              [multiple]="true"
              [addTag]="addTagCallback"
              [searchable]="true"
              [clearable]="true"
              [(ngModel)]="selected"
              [hideSelected]="true"
              [disabled]="isLoading"
              (remove)="removeTag($event)">
      <ng-option [value]="tag.id" *ngFor="let tag of tags">
        {{tag.code}}
      </ng-option>
    </ng-select>
    <div class="row" *ngIf="newTag">
      <div class="custom-control custom-switch ml-3 mr-3 mt-3">
        <input type="checkbox" class="custom-control-input" id="createTag" [(ngModel)]="createTag">
        <label for="createTag" class="custom-control-label">{{'tag.create'|translate}}</label>
      </div>

      <div class="custom-control custom-switch mr-3 mt-3">
        <input type="checkbox" class="custom-control-input" id="privateTag" [(ngModel)]="privateTag">
        <label for="privateTag" class="custom-control-label">{{'tag.private'|translate}}</label>
      </div>
    </div>
  </div>
  <div modal-footer class="text-right">
    <button type="button" class="btn btn-undo" (click)="close()">
      {{'generic.action.cancel'|translate}}
    </button>
    <button type="button" class="btn btn-success ml-2" (click)="confirm()"
            [disabled]="!selected.length || disableForNewTag() || isSubmitted">
            {{'generic.action.confirm'|translate}}
    </button>
  </div>
</pwc-modal>
