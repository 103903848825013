import {ApiResource} from '../../common/base/api-resource';

export class ApplicationEventResource extends ApiResource {
  applicationEventEntityId: number;
  eventType: string;
  eventTypeCodes: string[];
  flagValid: boolean;
  id: number;
  sphereId: number;
  userId: number;
  username: string;
  description: string;
  createdDate: number;
  elementId?: number;

  page?: number;
  sort?: string;
}
