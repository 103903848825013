import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleChartsModule } from 'angular-google-charts';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { LoginComponent } from './components/login/login.component';
import { fakeBackendProvider } from './helpers/backend.interceptor';
import { BooleanPipe } from './pipes/common/boolean.pipe';
import { DndModule } from 'ngx-drag-drop';
import { DecimalPipe } from '@angular/common';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { HttpInterceptorsModule } from './modules/http-interceptors/http-interceptors.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DocumentsComponent } from './components/documents/documents.component';
import { PwcCommonModule } from './components/common/pwc-common.module';
import { DocumentsBreadcrumbsComponent } from './components/documents/documents-breadcrumbs/documents-breadcrumbs.component';
import { SphereSelectorComponent } from './components/sphere-selector/sphere-selector.component';
import { InitialsPipe } from './pipes/common/initials.pipe';
import { DocumentsUploaderComponent } from './components/documents/documents-uploader/documents-uploader.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { UploaderQueueComponent } from './components/uploader-queue/uploader-queue.component';
import { DownloaderQueueComponent } from './components/downloader-queue/downloader-queue.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NewFolderModalComponent } from './components/documents/new-folder-modal/new-folder-modal.component';
import { LawConservationSummaryComponent } from './components/documents/law-conservation/law-conservation-summary/law-conservation-summary.component';
import { LawConservationConfigurationComponent } from './components/documents/law-conservation/law-conservation-configuration/law-conservation-configuration.component';
import { LawConservationBucketComponent } from './components/documents/law-conservation/law-conservation-bucket/law-conservation-law-bucket.component';
import { LawConservationModalComponent } from './components/documents/law-conservation-modal/law-conservation-modal.component';
import { LawConservationSendNowModalComponent } from './components/documents/law-conservation-send-now-modal/law-conservation-send-now-modal.component';
import { LawConservationRectificationModalComponent } from './components/documents/law-conservation-rectification-modal/law-conservation-rectification-modal.component';
import { NoLawConservationModalComponent } from './components/documents/no-law-conservation-modal/no-law-conservation-modal.component';
import { DocumentMetadataComponent } from './components/documents/document-metadata/document-metadata.component';
import { ConfiguratorImportComponent } from './components/configurator-import/configurator-import.component';
import { DocumentsToolbarComponent } from './components/documents/documents-toolbar/documents-toolbar.component';
import { DownloadModalComponent } from './components/documents/download-modal/download-modal.component';
import { DocumentDetailComponent } from './components/documents/document-detail/document-detail.component';
import { FileSaverModule } from 'ngx-filesaver';
import { DocumentConfirmModalComponent } from './components/documents/document-confirm-modal/document-confirm-modal.component';
import { PwcDirectoryDropDirective } from './directives/common/pwc-directory-drop.directive';
import { PwcDirectorySelectDirective } from './directives/common/pwc-directory-select.directive';
import { FileUploadModule } from './modules/file-upload/file-upload.module';
import { DateParserFormatter } from './helpers/common/date-parser-formatter';
import { FileSizePipe } from './helpers/common/file-size.pipe';
import { ShareModalComponent } from './components/share-modal/share-modal.component';
import { ShareElementsListComponent } from './components/share-modal/share-elements-list/share-elements-list.component';
import { FileIconPipe } from './pipes/documents/file-icon.pipe';
import { ShareDetailsComponent } from './components/share-modal/share-details/share-details.component';
import { ShareTypeComponent } from './components/share-modal/share-type/share-type.component';
import { ShareSelectedUsersComponent } from './components/share-modal/share-selected-users/share-selected-users.component';
import { PaginationModule } from './modules/pagination/pagination.module';
import { IdToStringPipe } from './pipes/common/id-to-string.pipe';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { StartSharingModalComponent } from './components/documents/start-sharing-modal/start-sharing-modal.component';
import { SharingManagerComponent } from './components/sharing/sharing-manager.component';
import { LegalEntityComponent } from './components/legal-entity/legal-entity.component';
import { AddTagModalComponent } from './components/tags/add-tag-modal/add-tag-modal.component';
import { EventsComponent } from './components/events/events.component';
import { TagsManagerComponent } from './components/tags/tag-manager/tags-manager.component';
import { DocumentFilterModalComponent } from './components/documents/document-filter/document-filter-modal.component';
import { DirectDownloadComponent } from './components/direct-download/direct-download.component';
import { StartConnectionModalComponent } from './components/connection/start-connection-modal/start-connection-modal.component';
import { ConnectionModalComponent } from './components/connection/connection-modal/connection-modal.component';
import { ConnectionToolbarComponent } from './components/connection/connection-toolbar/connection-toolbar.component';
import { ConnectionElementsListComponent } from './components/connection/connection-elements-list/connection-elements-list.component';
import { ConnectionTypeModalComponent } from './components/connection/connection-type-modal/connection-type-modal.component';
import { ExportCsvModalComponent } from './components/export/export-csv-modal/export-csv-modal.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationsListComponent } from './components/notifications/notifications-list/notifications-list.component';
import { AttachmentsUploaderComponent } from './components/attachment/attachments-uploader/attachments-uploader.component';
import { AttachmentModalComponent } from './components/attachment/attachment-modal/attachment-modal.component';
import { AttachmentToolbarComponent } from './components/attachment/attachment-toolbar/attachment-toolbar.component';
import { StartAttachmentModalComponent } from './components/attachment/start-attachment-modal/start-attachment-modal.component';
import { AttachmentMetadataComponent } from './components/attachment/attachment-metadata/attachment-metadata.component';
import { SpinnerModule } from './modules/spinner/spinner.module';
import { AuthenticationServiceConfig } from './security/AuthenticationServiceConfig';
import { LayoutComponent } from './components/layout/layout/layout.component';
import { CommonModule } from '@pwc/common';
import { ErrorInterceptor, JwtInterceptor, SecurityModule } from '@pwc/security';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { LineContentLoaderComponent } from './components/content-loader/line-content-loader/line-content-loader.component';
import { CircleContentLoaderComponent } from './components/content-loader/circle-content-loader/circle-content-loader.component';
import { TableContentLoaderComponent } from './components/content-loader/table-content-loader/table-content-loader.component';
import { TableRowContentLoaderComponent } from './components/content-loader/table-row-content-loader/table-row-content-loader.component';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { CommentsComponent } from './components/comments/comments.component';
import { CommentListComponent } from './components/comments/comment-list/comment-list.component';
import { SignDocumentComponent } from './components/documents/sign-document/sign-document.component';
import { PwcIconsModule } from '@pwc/icons';
import { LayoutModule } from '@pwc/ui';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { WeightReportComponent } from './components/weight-report/weight-report.component';
import { ExportsHistoryComponent } from './components/exports-history/exports-history.component';
import { AlertModule } from 'appkit-angular/alert';
import { VdrComponent } from './components/vdr/vdr.component';
import { VdrDisclaimerComponent } from './components/vdr-disclaimer/vdr-disclaimer.component';
import { NgxWebstorageModule } from 'ngx-webstorage';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LoginComponent,
    BooleanPipe,
    DocumentsComponent,
    DocumentsBreadcrumbsComponent,
    SphereSelectorComponent,
    InitialsPipe,
    DocumentsUploaderComponent,
    UploaderQueueComponent,
    DownloaderQueueComponent,
    NewFolderModalComponent,
    LawConservationSummaryComponent,
    LawConservationConfigurationComponent,
    LawConservationBucketComponent,
    LawConservationModalComponent,
    LawConservationSendNowModalComponent,
    LawConservationRectificationModalComponent,
    NoLawConservationModalComponent,
    DocumentMetadataComponent,
    ConfiguratorImportComponent,
    DocumentsToolbarComponent,
    DownloadModalComponent,
    DocumentDetailComponent,
    DocumentConfirmModalComponent,
    PwcDirectoryDropDirective,
    PwcDirectorySelectDirective,
    FileSizePipe,
    ShareModalComponent,
    ShareElementsListComponent,
    FileIconPipe,
    ShareDetailsComponent,
    ShareTypeComponent,
    ShareSelectedUsersComponent,
    IdToStringPipe,
    StartSharingModalComponent,
    SharingManagerComponent,
    LegalEntityComponent,
    AddTagModalComponent,
    EventsComponent,
    TagsManagerComponent,
    DocumentFilterModalComponent,
    DirectDownloadComponent,
    StartConnectionModalComponent,
    ConnectionModalComponent,
    ConnectionToolbarComponent,
    ConnectionElementsListComponent,
    ConnectionTypeModalComponent,
    ExportCsvModalComponent,
    NotificationsComponent,
    NotificationsListComponent,
    AttachmentsUploaderComponent,
    AttachmentModalComponent,
    AttachmentToolbarComponent,
    StartAttachmentModalComponent,
    AttachmentMetadataComponent,
    LayoutComponent,
    LineContentLoaderComponent,
    CircleContentLoaderComponent,
    TableContentLoaderComponent,
    TableRowContentLoaderComponent,
    CommentsComponent,
    CommentListComponent,
    SignDocumentComponent,
    WeightReportComponent,
    ExportsHistoryComponent,
    VdrComponent,
    VdrDisclaimerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SecurityModule,
    PwcCommonModule,
    PwcIconsModule,
    LayoutModule,
    GoogleChartsModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    NgbModule,
    NgSelectModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    FileUploadModule,
    DndModule,
    PdfViewerModule,
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
    HttpInterceptorsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgIdleKeepaliveModule.forRoot(),
    FontAwesomeModule,
    FileSaverModule,
    PaginationModule,
    SpinnerModule,
    AutocompleteLibModule,
    ContentLoaderModule,
    ToastrModule.forRoot(),
    PwcIconsModule,
    AlertModule,
    NgxWebstorageModule.forRoot()
  ],
  providers: [
    { provide: 'FRAMEWORK_BASEURL', useValue: environment.authUrl },
    { provide: 'AUTH_OPTIONS', useClass: AuthenticationServiceConfig },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter },
    CookieService,
    // provider used to create fake backend
    fakeBackendProvider,
    DecimalPipe,
    IdToStringPipe
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
