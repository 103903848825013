import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalComponent} from '../../common/modals/modal/modal.component';
import {DmsFolderRepositoryService} from '../../../services/dms-folder-repository.service';
import {DmsElementResource} from '../../../models/api/documents/dms-element-resource';
import {isNullOrUndefined} from 'util';
import {DmsElement} from '../../../models/dms-element';
import {DocumentTypeEnum} from '../../../enums/document-type.enum';
import {FiscalYearResource} from '../../../models/api/fiscal-year-resource';
import {FolderDeleteTypeEnum} from '../../../enums/folder-delete-type.enum';
import {DmsElementDeleteResource} from '../../../models/api/documents/dms-element-delete-resource';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-document-confirm-modal',
  templateUrl: './document-confirm-modal.component.html',
  styleUrls: ['./document-confirm-modal.component.scss']
})
export class DocumentConfirmModalComponent implements OnInit {

  @Input() documentConfirmModalId = 'document_confirm_modal_id';
  @Input() title: string;
  @Input() message: string;
  @Input() positiveButtonText = this.translateSrv.instant('generic.action.confirm');
  @Input() negativeButtonText = this.translateSrv.instant('generic.action.cancel');
  @Input() disabled: boolean = false;

  @Input() sphereId: number;
  @Input() selectedYear: FiscalYearResource;
  @Input() selectedResults: DmsElement[] = [];

  @ViewChild('documentConfirmModal') documentConfirmModal: ModalComponent;

  @Output() negativeButtonClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() positiveButtonClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() closed: EventEmitter<string> = new EventEmitter<string>();

  constructor(private dmsRepositoryService: DmsFolderRepositoryService, private translateSrv: TranslateService) {
  }

  ngOnInit(): void {
  }

  open() {
    const directoryToDelete: number[] = [];
    this.selectedResults.forEach(value => {
      if (value.documentType === DocumentTypeEnum.FOLDER) {
        directoryToDelete.push(value.id);
      }
    });

    if (!isNullOrUndefined(directoryToDelete)) {
      const body: Partial<DmsElementDeleteResource> = {
        sphereId: this.sphereId
      };
      if (!isNullOrUndefined(this.selectedYear)) {
        body.fiscalYear = this.selectedYear.year;
      }
      body.ids = directoryToDelete;
      // body.fiscalDate = this.;

      this.dmsRepositoryService.canDeleteFolder(body).subscribe(value => {
        if (value === FolderDeleteTypeEnum.ELEMENTS_INSIDE) {
          this.message = this.translateSrv.instant('generic.message.delete-confirm');
          this.positiveButtonText = this.translateSrv.instant('generic.action.confirm');
          this.negativeButtonText = this.translateSrv.instant('generic.action.cancel');
        } else if (value === FolderDeleteTypeEnum.CONSERVATION_IN_LAW_ELEMENT_INSIDE) {
          this.message = this.translateSrv.instant('generic.message.delete-not-allowed');
          this.positiveButtonText = '';
          this.negativeButtonText = this.translateSrv.instant('generic.action.close-caps');
        } else if (value === FolderDeleteTypeEnum.FOLDER_EMPTY) {
          this.message = this.translateSrv.instant('generic.message.delete-question');
          this.positiveButtonText = this.translateSrv.instant('generic.action.confirm');
          this.negativeButtonText = this.translateSrv.instant('generic.action.cancel');
        }

        this.documentConfirmModal.open();
      });
    } else {
      this.message = this.translateSrv.instant('generic.message.delete-question');
      this.positiveButtonText = this.translateSrv.instant('generic.action.confirm');
      this.negativeButtonText = this.translateSrv.instant('generic.action.cancel');
      this.documentConfirmModal.open();
    }
  }

  close() {
    this.documentConfirmModal.close();
  }

  onPositiveClick() {
    // this.documentConfirmModal.close();
    this.positiveButtonClick.emit();
  }

  onNegativeClick() {
    this.documentConfirmModal.close();
    this.negativeButtonClick.emit();
  }

  onClose($event: string) {
    this.closed.emit($event);
  }

}
