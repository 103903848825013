<div class="page-container">

    <app-documents-breadcrumbs componentName="lawConservationBucket" [showEdit]="false"
        basePath="Gestione Credenziali (Bucket) di accesso"></app-documents-breadcrumbs>

    <div class="d-flex flex-row mb-4 align-items-end box-filter">
        <div class="card" style="width: calc(100% - 155px);">
            <div class="card-body">
                <h5 class="card-title">{{'generic.filters.label'|translate}}
                    <button class="btn btn-primary btn-sm btn-action-icon ml-0 btn-clear-filter"
                        [ngbTooltip]="'generic.filters.clear'|translate" type="button" (click)="emptyFilters()">
                        <pwc-icon name="clear"></pwc-icon>
                    </button>
                </h5>

                <div class="d-lg-flex flex-lg-row flex-sm-column flex-wrap">
                    <div class="item form-group-sm form-inline">
                        <label for="bucket-filter-name">{{'generic.field.name'|translate}}</label>
                        <input class="form-control input-with-border input-auto-height" id="bucket-filter-name"
                            name="bucket-filter-name" type="text" [(ngModel)]="searchTerm" (ngModelChange)="onSearch()">
                    </div>
                </div>
            </div>
        </div>

        <div class="ml-auto">
            <button class="btn btn-ghost ml-auto align-self-end text-nowrap" style="min-width: 125px"
                (click)="openCrateElement()">
                <!-- <img src="/assets/images/icons/documents/create_tag.svg" [alt]="'documents.law.bucket.create'|translate" class="btn-ghost-icon"> -->
                <pwc-icon name="add" style="margin-right:5px;"></pwc-icon>
                <span class="d-inline-block">{{'documents.law.bucket.create'|translate}}</span>
            </button>
        </div>
    </div>

    <div class="card" *ngIf="data && viewChecked">
        <div class="card-body card-table">
            <data-table #dataTable [dataSource]="data" [columns]="columns" [customColumnDefs]="customColumns"
                [customColumns]="customColumnsActive" [enableSelect]="false" (sort)="onSort($event)">

                <ng-container cdkColumnDef="name">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col">{{'documents.law.bucket.label'|translate}}</th>
                    <td cdk-cell *cdkCellDef="let row">
                        {{row.bucket}}
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="creation_date">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col">{{'generic.field.creation-date'|translate}}</th>
                    <td cdk-cell *cdkCellDef="let row">
                        {{row.createdDate|date:'dd/MM/yyyy'}}
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="details">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col" style="width: 120px;">
                        {{'generic.action.label'|translate}}
                    </th>
                    <td cdk-cell *cdkCellDef="let row" class="text-right">

                        <pwc-icon name="view" [ngbTooltip]="'generic.action.show-item'|translate" placement="left"
                            *ngIf="enableChanges(row)" class="document-info-icon" (click)="openEditElement(row, true)">
                        </pwc-icon>
                        <pwc-icon name="pen" [ngbTooltip]="'generic.action.edit-item'|translate" placement="left"
                            *ngIf="enableChanges(row)" class="document-info-icon" (click)="openEditElement(row, false)">
                        </pwc-icon>
                        <pwc-icon name="delete" [ngbTooltip]="'generic.action.delete.label'|translate" placement="left"
                            *ngIf="enableChanges(row)  && enableDelete(row)" class="document-info-icon"
                            (click)="openDeleteElement(row)"></pwc-icon>
                    </td>
                </ng-container>

            </data-table>
        </div>
    </div>

    <div class="my-4">
        <pwc-pagination *ngIf="pagination" [page]="pagination.number + 1" [pageSize]="pagination.size"
            [collectionSize]="pagination.totalElements" (pageChange)="onPageChange($event)"></pwc-pagination>
    </div>

</div>

<pwc-modal #deleteBucketModal id="deleteBucketModal">
    <div modal-title>{{'documents.law.bucket.delete'|translate}}</div>
    <div modal-body>
        {{'documents.law.bucket.delete-question'|translate}} {{selectedBucket.description}}
    </div>
    <div modal-footer class="text-right">
        <button type="button" class="btn btn-undo"
            (click)="deleteBucketModal.close()">{{'generic.action.cancel'|translate}}</button>
        <button type="button" class="btn btn-success ml-2"
            (click)="removeElement()">{{'generic.action.confirm'|translate}}</button>
    </div>
</pwc-modal>

<pwc-modal #editBucketModal id="editBucketModal" class="law-conservation-edit-modal">
    <div modal-title>{{(isPreview() ? ('documents.law.bucket.data'|translate) :
        ('generic.action.edit-item'|translate))}} {{'documents.law.bucket.label'|translate}}
    </div>
    <div modal-body>
        <div class="col-md-12 p-3" *ngIf="isDisabled() && !isPreview()">
            <label class="text-danger">{{'documents.law.bucket.edit-message'|translate}}</label>
        </div>
        <div class="form-inline">
            <div class="col-md-6 p-3">
                <div class="row">
                    <div class="col-md-12">{{'generic.field.name'|translate}} <i class="fa fa-question-circle"
                            container="body" [ngbTooltip]="'documents.law.bucket.name-tooltip'|translate"></i></div>
                    <div class="form-group form-input-group col-md-12">
                        <input type="text" style="width: 100%"
                            class="form-control form-input no-shadow input-with-border" [disabled]="isPreview()"
                            [(ngModel)]="selectedBucket.description">
                        <div *ngIf="!isPreview()" class="invalid-feedback">
                            {{'documents.law.bucket.name-required'|translate}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 p-3">
                <div class="row">
                    <div class="col-md-12">{{'documents.law.bucket.memorize-system'|translate}} <i
                            class="fa fa-question-circle" container="body"
                            [ngbTooltip]="'documents.law.bucket.system-tooltip'|translate"></i></div>
                    <div class="orm-group form-input-group col-md-12">
                        <ng-select bindLabel="code" [placeholder]="'generic.placeholder.select-conservation'|translate"
                            appendTo="body" style="width: 100%;" class="" [clearable]="false"
                            [disabled]="isDisabled() || isPreview()" [(ngModel)]="selectedBucket.preservationSystem">
                            <ng-option [value]="optionINFOCERT()">{{optionINFOCERT()}}</ng-option>
                        </ng-select>
                        <div *ngIf="!isPreview() && !isDisabled()" class="invalid-feedback" style="padding-top: 5px;">
                            {{'documents.law.bucket.required-system'|translate}}</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 p-3">
                <div class="row">
                    <div class="col-md-12">{{'documents.law.bucket.user-name'|translate}} <i
                            class="fa fa-question-circle" container="body"
                            [ngbTooltip]="'documents.law.bucket.user-name-tooltip'|translate"></i></div>
                    <div class="form-group form-input-group col-md-12">
                        <input type="text" style="width: 100%"
                            class="form-control form-input no-shadow input-with-border"
                            [(ngModel)]="selectedBucket.username" [disabled]="isDisabled() || isPreview()">
                        <div *ngIf="!isPreview() && !isDisabled()" class="invalid-feedback">
                            {{'documents.law.bucket.user-name-required'|translate}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 p-3">
                <div class="row">
                    <div class="col-md-12">{{'documents.law.bucket.label'|translate}} <i class="fa fa-question-circle"
                            container="body" [ngbTooltip]="'documents.law.bucket.bucket-tooltip'|translate"></i></div>
                    <div class="form-group form-input-group col-md-12">
                        <input type="text" style="width: 100%"
                            class="form-control form-input no-shadow input-with-border"
                            [(ngModel)]="selectedBucket.bucket" [disabled]="isDisabled() || isPreview()">
                        <div *ngIf="!isPreview() && !isDisabled()" class="invalid-feedback">
                            {{'documents.law.bucket.bucket-required'|translate}}</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 p-3">
                <div class="row">
                    <div class="col-md-12">Password <i class="fa fa-question-circle" container="body"
                            [ngbTooltip]="'documents.law.bucket.password-tooltip'|translate"></i></div>
                    <div class="form-group form-input-group col-md-12">
                        <input type="password" style="width: 100%"
                            class="form-control form-input no-shadow input-with-border" [disabled]="isPreview()"
                            [(ngModel)]="selectedBucket.password" autocomplete="off">
                        <div *ngIf="!isPreview()" class="invalid-feedback">
                            {{'documents.law.bucket.password-required'|translate}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 p-3">
                <div class="row">
                    <div class="col-md-12">Policy <i class="fa fa-question-circle" container="body"
                            [ngbTooltip]="'documents.law.bucket.policy-tooltip'|translate"></i></div>
                    <div class="form-group form-input-group col-md-12">
                        <input type="text" style="width: 100%"
                            class="form-control form-input no-shadow input-with-border"
                            [(ngModel)]="selectedBucket.policy" [disabled]="isDisabled() || isPreview()">
                        <div *ngIf="!isPreview() && !isDisabled()" class="invalid-feedback">
                            {{'documents.law.bucket.policy-mandatory'|translate}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div modal-footer class="text-right" *ngIf="!isPreview()">
        <button type="button" class="btn btn-undo"
            (click)="editBucketModal.close()">{{'generic.action.cancel'|translate}}</button>
        <button type="button" class="btn btn-success ml-2"
            (click)="editElement()">{{'generic.action.confirm'|translate}}</button>
    </div>
</pwc-modal>

<pwc-modal #createBucket id="createBucket" class="law-conservation-edit-modal">
    <div modal-title>{{'documents.law.bucket.create'|translate}}</div>
    <div modal-footer class="text-right">
        <button type="button" class="btn btn-undo"
            (click)="createBucket.close()">{{'generic.action.cancel'|translate}}</button>
        <button type="button" class="btn btn-success ml-2"
            (click)="addElement()">{{'generic.action.confirm'|translate}}</button>
    </div>
    <div modal-body>
        <div class="form-inline">
            <div class="col-md-6 p-3">
                <div class="row">
                    <div class="col-md-12">{{'generic.field.name'|translate}} <i class="fa fa-question-circle"
                            container="body" [ngbTooltip]="'documents.law.bucket.name-tooltip'|translate"></i></div>
                    <div class="form-group form-input-group col-md-12">
                        <input type="text" style="width: 100%"
                            class="form-control form-input no-shadow input-with-border"
                            [(ngModel)]="newBucket.description">
                        <div class="invalid-feedback">
                            {{'documents.law.bucket.name-required'|translate}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 p-3">
                <div class="row">
                    <div class="col-md-12">{{'documents.law.bucket.memorize-system'|translate}} <i
                            class="fa fa-question-circle" container="body"
                            [ngbTooltip]="'documents.law.bucket.system-tooltip'|translate"></i></div>
                    <div class="orm-group form-input-group col-md-12">
                        <ng-select bindLabel="code" [placeholder]="'generic.placeholder.select-conservation'|translate"
                            appendTo="body" style="width: 100%;" class="" [clearable]="false"
                            [(ngModel)]="newBucket.preservationSystem">
                            <ng-option [value]="optionINFOCERT()">{{optionINFOCERT()}}</ng-option>
                        </ng-select>
                        <div class="invalid-feedback" style="padding-top: 5px;">
                            {{'documents.law.bucket.required-system'|translate}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 p-3">
                <div class="row">
                    <div class="col-md-12">{{'documents.law.bucket.user-name'|translate}} <i
                            class="fa fa-question-circle" container="body"
                            [ngbTooltip]="'documents.law.bucket.user-name-tooltip'|translate"></i></div>
                    <div class="form-group form-input-group col-md-12">
                        <input type="text" style="width: 100%"
                            class="form-control form-input no-shadow input-with-border"
                            [(ngModel)]="newBucket.username">
                        <div class="invalid-feedback">
                            {{'documents.law.bucket.user-name-required'|translate}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 p-3">
                <div class="row">
                    <div class="col-md-12">{{'documents.law.bucket.label'|translate}} <i class="fa fa-question-circle"
                            container="body" [ngbTooltip]="'documents.law.bucket.bucket-tooltip'|translate"></i></div>
                    <div class="form-group form-input-group col-md-12">
                        <input type="text" style="width: 100%"
                            class="form-control form-input no-shadow input-with-border" [(ngModel)]="newBucket.bucket">
                        <div class="invalid-feedback">
                            {{'documents.law.bucket.bucket-required'|translate}}</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 p-3">
                <div class="row">
                    <div class="col-md-12">Password <i class="fa fa-question-circle" container="body"
                            [ngbTooltip]="'documents.law.bucket.password-tooltip'|translate"></i></div>
                    <div class="form-group form-input-group col-md-12">
                        <input type="password" style="width: 100%"
                            class="form-control form-input no-shadow input-with-border" [(ngModel)]="newBucket.password"
                            autocomplete="off">
                        <div class="invalid-feedback">
                            {{'documents.law.bucket.password-required'|translate}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 p-3">
                <div class="row">
                    <div class="col-md-12">Policy <i class="fa fa-question-circle" container="body"
                            [ngbTooltip]="'documents.law.bucket.policy-tooltip'|translate"></i></div>
                    <div class="form-group form-input-group col-md-12">
                        <input type="text" style="width: 100%"
                            class="form-control form-input no-shadow input-with-border" [(ngModel)]="newBucket.policy">
                        <div class="invalid-feedback">
                            {{'documents.law.bucket.policy-mandatory'|translate}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</pwc-modal>