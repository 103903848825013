import { Injectable } from '@angular/core';
import { DmsElementResource } from '../models/api/documents/dms-element-resource';
import { DmsElement } from '../models/dms-element';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SphereService } from './sphere.service';
import { PageableService } from './common/pageable.service';
import { DmsMetadataService } from './dms-metadata.service';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { CheckCrossYearResource } from '../models/api/check-cross-year-resource';
import { DmsRepositoryResponse } from '../models/api/documents/dms-repository-response';
import { PageableResponse } from '../models/api/pageable-response';
import { DmsFolderRepositoryService } from './dms-folder-repository.service';
import { Comment } from '../models/comment/comment';
import { DmsElementMetadata } from '../models/dms-element-metadata';

@Injectable({
  providedIn: 'root'
})
export class DmsFileRepositoryService extends PageableService<DmsElementResource, DmsElement> {
  protected endpoint = 'file';

  constructor(http: HttpClient,
    protected sphereService: SphereService,
    protected dmsMetadataService: DmsMetadataService,
    protected dmsFolderRepositoryService: DmsFolderRepositoryService) {
    super(http);
  }

  public search(search?: Partial<DmsElementResource>): Observable<DmsElement[]> {
    let params = new HttpParams();
    if (!isNullOrUndefined(search)) {
      for (const key of Object.getOwnPropertyNames(search)) {
        if (search[key] == null || search[key].length === 0) {
          continue;
        }
        params = params.set(key, `${search[key]}`);
      }
    }

    return this.get<DmsRepositoryResponse>('search', { params }).pipe(
      map((response: DmsRepositoryResponse) => {
        this.dmsFolderRepositoryService.canCreateFolderSubject.next(response.canCreateFolder);
        this.dmsFolderRepositoryService.visibleMetadataSubject.next(response.metadata);
        return response.list;
      }),
      map((response: PageableResponse<DmsElementResource[]>): DmsElementResource[] =>
        this.dmsFolderRepositoryService.extractPaginationInfo(response)),
      map((list: DmsElementResource[]): DmsElement[] => this.convertListToModel(list))
    );
  }

  convertModelToResource(model: DmsElement): DmsElementResource {
    const resource = new DmsElementResource();
    Object.assign(resource, model);

    if (!isNullOrUndefined(model.parent)) {
      resource.parent = this.convertModelToResource(model.parent);
    }
    if (!isNullOrUndefined(model.sphere)) {
      resource.sphere = this.sphereService.convertModelToResource(model.sphere);
    }
    if (!isNullOrUndefined(model.fiscalStartDate)) {
      resource.fiscalStartDate = model.fiscalStartDate;
    }
    if (!isNullOrUndefined(model.fiscalEndDate)) {
      resource.fiscalEndDate = model.fiscalEndDate;
    }
    if (!isNullOrUndefined(model.documentDate)) {
      resource.documentDate = model.documentDate;
    }
    if (!isNullOrUndefined(this.dmsMetadataService)) {
      let metadata = [];
      if (!isNullOrUndefined(model.metadata)) {
        metadata = this.dmsMetadataService.convertListToResource(model.metadata);
      }
      resource.metadata = metadata;
    }
    return resource;
  }

  convertResourceToModel(resource: DmsElementResource): DmsElement {
    const model = new DmsElement();
    Object.assign(model, resource);

    if (!isNullOrUndefined(resource.parent)) {
      model.parent = this.convertResourceToModel(resource.parent);
    }
    if (!isNullOrUndefined(resource.sphere)) {
      model.sphere = this.sphereService.convertResourceToModel(resource.sphere);
    }
    if (!isNullOrUndefined(resource.fiscalStartDate)) {
      model.fiscalStartDate = resource.fiscalStartDate;
    }
    if (!isNullOrUndefined(resource.fiscalEndDate)) {
      model.fiscalEndDate = resource.fiscalEndDate;
    }
    if (!isNullOrUndefined(model.documentDate)) {
      resource.documentDate = model.documentDate;
    }
    if (!isNullOrUndefined(this.dmsMetadataService)) {
      let metadata = [];
      if (!isNullOrUndefined(resource.metadata)) {
        metadata = this.dmsMetadataService.convertListToModel(resource.metadata);
        metadata.filter((m: DmsElementMetadata) => m && m.elementMetadataReg && m.elementMetadataReg.code === 'riservato_b').forEach((m: DmsElementMetadata) => {
          m.value = m.value === 'true' || m.value === 'TRUE';
        });
      }
      model.metadata = metadata;
    }

    return model;
  }

  loadFileDetail(fileId: number, year: number): Observable<DmsElement> {
    return this.get<DmsElementResource>(`${fileId}?year=${year}`).pipe(
      map((response: DmsElementResource) => {
        return this.convertResourceToModel(response);
      })
    );
  }

  loadFileHistoryDetail(fileId: number, selectedHistoryFileId: number): Observable<DmsElement> {
    return this.get<DmsElementResource>(`${fileId}/history/${selectedHistoryFileId}`).pipe(
      map((response: DmsElementResource) => {
        return this.convertResourceToModel(response);
      })
    );
  }

  loadVersions(fileId: number): Observable<DmsElementResource> {
    return this.get<DmsElementResource>(`${fileId}/history`).pipe(map((response: DmsElementResource) => {
      return response;
    }));
  }

  save(resource: DmsElementResource): Observable<DmsElementResource> {
    return this.put<DmsElementResource>('save', resource).pipe(
      map((response: DmsElementResource) => {
        return response;
      }));
  }

  checkCrossYear(resource: CheckCrossYearResource): Observable<CheckCrossYearResource> {
    return this.post<CheckCrossYearResource>('checkCrossYear', resource).pipe(
      map((response: CheckCrossYearResource) => {
        return response;
      }));
  }

  searchComments(fileId: number): Observable<Comment[]> {
    return this.get<Comment[]>(`comments/${fileId}`)
      .pipe(map((response: Comment[]) => {
        return response;
      }));
  }

  postComment(comment: Comment): Observable<boolean> {
    return this.post<boolean>(`postComment`, comment)
      .pipe(map((response: boolean) => {
        return response;
      }));
  }

  watermark(dmsElementId: number, sphereId: number, fiscalYear: number): Observable<boolean> {
    return this.get<boolean>(`watermark/${dmsElementId}/sphere/${sphereId}/fiscalYear/${fiscalYear}`)
      .pipe(map((response: boolean) => {
        return response;
      }));
  }

}
