import {isNullOrUndefined} from 'util';
import {SphereUserRelationEnum} from '../../enums/sphere-user-relation.enum';

// export function downloadBlob(blob: any, filename: string) {
//   const blobURL = URL.createObjectURL(blob);
//   const tempLink = window.document.createElement('a');
//   tempLink.style.display = 'none';
//   tempLink.href = blobURL;
//   tempLink.setAttribute('download', filename);
//   if (typeof tempLink.download === 'undefined') {
//     tempLink.setAttribute('target', '_blank');
//   }
//   document.body.appendChild(tempLink);
//   tempLink.click();
//   document.body.removeChild(tempLink);
//   setTimeout(() => {
//     // For Firefox it is necessary to delay revoking the ObjectURL
//     URL.revokeObjectURL(blobURL);
//   }, 100);
// }


export function getBlobUrl(blob: any) {
  return URL.createObjectURL(blob);
}

export function groupBy(xs: any[], key: any) {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function getSphereColorBySphereUserRelationEnum(sphereUserRelation: SphereUserRelationEnum) {
  let color = '#D04A02';
  if (isNullOrUndefined(sphereUserRelation)) {
    color = '#D04A02';
  } else if (sphereUserRelation === SphereUserRelationEnum.OWNER) {
    color = '#D04A02';
  } else if (sphereUserRelation === SphereUserRelationEnum.SHARED) {
    color = '#FFB600';
  } else if (sphereUserRelation === SphereUserRelationEnum.MASTER) {
    color = '#EB8C00';
  }
  return color;
}

export function isBiggerThanOneGigabyte(totalDimension: number) {
  return totalDimension >= 1e+9;
}

export function getIconByFileExtension(extension: string, croosYear: boolean) {
  let iconPath = '';
  if (isNullOrUndefined(extension)) {
    if (croosYear) {
      iconPath = '/assets/images/icons/documents/doc_pluriennale.svg';
    } else {
      iconPath = '/assets/images/icons/documents/doc.svg';
    }
  } else if (extension.toLocaleLowerCase().indexOf('doc') > -1 || extension.toLocaleLowerCase().indexOf('docx') > -1 ||
    extension.toLocaleLowerCase().indexOf('.txt') > -1) {
    if (croosYear) {
      iconPath = '/assets/images/icons/documents/doc_pluriennale.svg';
    } else {
      iconPath = '/assets/images/icons/documents/doc.svg';
    }
  } else if (extension.toLocaleLowerCase().indexOf('pdf') > -1) {
    if (croosYear) {
      iconPath = '/assets/images/icons/documents/pdf_pluriennale.svg';
    } else {
      iconPath = '/assets/images/icons/documents/pdf.svg';
    }
  } else if (extension.toLocaleLowerCase().indexOf('xbrl') > -1) {
    if (croosYear) {
      iconPath = '/assets/images/icons/documents/xbrl_pluriennale.svg';
    } else {
      iconPath = '/assets/images/icons/documents/xbrl.svg';
    }
  } else if (extension.toLocaleLowerCase().indexOf('xls') > -1 || extension.indexOf('xlsx') > -1) {
    if (croosYear) {
      iconPath = '/assets/images/icons/documents/xls_pluriennale.svg';
    } else {
      iconPath = '/assets/images/icons/documents/xls.svg';
    }
  } else if (extension.toLocaleLowerCase().indexOf('xml') > -1) {
    if (croosYear) {
      iconPath = '/assets/images/icons/documents/xml_pluriennale.svg';
    } else {
      iconPath = '/assets/images/icons/documents/xml.svg';
    }
  } else if (extension.toLocaleLowerCase().indexOf('zip') > -1 || extension.toLocaleLowerCase().indexOf('rar') > -1) {
    if (croosYear) {
      iconPath = '/assets/images/icons/documents/zip_pluriennale.svg';
    } else {
      iconPath = '/assets/images/icons/documents/zip.svg';
    }
  } else if (extension.toLocaleLowerCase().indexOf('p7m') > -1) {
    if (croosYear) {
      iconPath = '/assets/images/icons/documents/p7m_pluriennale.svg';
    } else {
      iconPath = '/assets/images/icons/documents/p7m.svg';
    }
  } else {
    if (croosYear) {
      iconPath = '/assets/images/icons/documents/doc_pluriennale.svg';
    } else {
      iconPath = '/assets/images/icons/documents/doc.svg';
    }
  }
  return iconPath;
}
