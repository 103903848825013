import {Component, EventEmitter, Output} from '@angular/core';
import {AttachmentCartService} from '../../../services/attachment-cart.service';

@Component({
  selector: 'app-attachment-toolbar',
  templateUrl: './attachment-toolbar.component.html',
  styleUrls: ['./attachment-toolbar.component.scss']
})
export class AttachmentToolbarComponent  {

  @Output() confirm: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  constructor(public attachmentCartService: AttachmentCartService) {
  }

  onConfirm(): void {
    this.confirm.emit();
  }

  onCancel(): void {
    this.cancel.emit();
  }
}
