import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DmsFolderRepositoryService} from '../../services/dms-folder-repository.service';
import {ActivatedRoute, Data, ParamMap, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {isNullOrUndefined} from 'util';
import {ModalComponent} from '../common/modals/modal/modal.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-direct-download',
  templateUrl: './direct-download.component.html',
  styleUrls: ['./direct-download.component.scss']
})
export class DirectDownloadComponent implements OnInit, OnDestroy {

  private token: string;

  private routeDataSubscription: Subscription;
  private routeParamSubscription: Subscription;

  @ViewChild('errorModal') errorModal: ModalComponent;

  constructor(private dmsRepositoryService: DmsFolderRepositoryService,
              private route: ActivatedRoute,
              private router: Router) {
  }
  
  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
    this.routeParamSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.routeDataSubscription = this.route.data.subscribe((data: Data) => {
      this.routeParamSubscription = this.route.paramMap.pipe(
        switchMap((params: ParamMap) => {
          this.token = params.get('token');
          return this.dmsRepositoryService.getDownloadByToken(this.token, data.extension);
        })
      ).subscribe((res: { blob: Blob, url: string, fileName: string }) => {
        let fileName = this.token;
        if (!isNullOrUndefined(data.extension)) {
          fileName += data.extension;
        }
        this.openFile(res.blob, res.url, fileName);
      }, (error) => {
        this.handleFailure();
      });
    });
  }

  public openFile(blob: Blob, fileURL: string, fileName: string) {
    if (isNullOrUndefined(fileURL)) {
      this.handleFailure();
      return;
    }

    if (window.navigator.msSaveBlob) { // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const tempLink = window.document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = fileURL;
      tempLink.setAttribute('download', fileName);
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(fileURL);
      }, 100);

      this.redirectToDocuments();
    }
  }

  handleFailure() {
    this.errorModal.open();
  }

  redirectToDocuments() {
    this.token = null;
    this.router.navigate(['/documents']);
  }
}
