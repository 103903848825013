import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';

@Injectable()
export class BackendInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authHeader = request.headers.get('Authorization');
    // const isLoggedIn = authHeader && authHeader.startsWith('Bearer fake-jwt-token');
    const isLoggedIn = true; // authHeader && authHeader.startsWith('Bearer');

    // wrap in delayed observable to simulate server api call
    return of(null).pipe(mergeMap(() => {

      // authenticate - public
      /*if (request.url.endsWith('/users/authenticate') && request.method === 'POST') {
          const user = users.find(x => x.username === request.body.username && x.password === request.body.password);
          if (!user) { return error(this.translateSrv.instant('login.message.wrong-credentials');); }
          return ok({
              id: user.id,
              username: user.username,
              firstName: user.firstName,
              lastName: user.lastName,
              token: `fake-jwt-token`
          });
      }*/

      // get all users
      /*if (request.url.endsWith('/users') && request.method === 'GET') {
          if (!isLoggedIn) { return unauthorised(); }
          return ok(users);
      }*/

      // get all indices
      /*if (request.url.endsWith('/indices') && request.method === 'GET') {
          if (!isLoggedIn) { return unauthorised(); }

          const dupReq = request.clone({
              url: 'assets/mock/indices-list.json'
            });
          return next.handle(dupReq);
      }*/

      /*
      // get all indices type
      if (request.url.endsWith('/indextypes') && request.method === 'GET') {
          if (!isLoggedIn) { return unauthorised(); }

          const dupReq = request.clone({
              url: 'assets/mock/index-types.json'
            });
          return next.handle(dupReq);
          // return ok(users);
      }*/

      // get all information set
      if (request.url.includes('/informationset') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/information-set.json'
        });
        return next.handle(dupReq);
      }

      // get all companies
      if (request.url.includes('/companies') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/society-list.json'
        });
        return next.handle(dupReq);
      }

      // get file templates
      if (request.url.includes('/templates/file') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/template-list-file.json'
        });
        return next.handle(dupReq);
      }

      // get form templates
      if (request.url.includes('/templates/form') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/template-list-form.json'
        });
        return next.handle(dupReq);
      }

      // get all analysis ecofin
      if (request.url.endsWith('/analysis-ecofin') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/analysis-ecofin-list.json'
        });
        return next.handle(dupReq);
      }

      // get all analysis performance
      if (request.url.endsWith('/analysis-performance') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/analysis-performance-list.json'
        });
        return next.handle(dupReq);
      }

      // get all analysis qualitative
      if (request.url.endsWith('/analysis-qualitative') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/analysis-qualitative-list.json'
        });
        return next.handle(dupReq);
      }

      // get all analysis qualitative
      if (request.url.endsWith('/rules') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/rules-list.json'
        });
        return next.handle(dupReq);
      }

      if (request.url.endsWith('/upload') && request.method === 'POST') {
        if (!isLoggedIn) {
          return unauthorised();
        }
        const resp = {
          status: 'success',
          message: 'Upload ok'
        };
        return ok(resp);
      }

      // get all information set
      if (request.url.includes('/information-set-templates') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/information-set-templates-list.json'
        });
        return next.handle(dupReq);
      }

      // get all information set templates scenarioTypeId scenarioTypeId
      if (request.url.endsWith('/information-set-template-types') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/information-set-template-types.json'
        });
        return next.handle(dupReq);
      }

      // get all information set collections
      if (request.url.endsWith('/information-set-collections') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/information-set-collections-list.json'
        });
        return next.handle(dupReq);
      }

      // get all scenarios
      /*if (request.url.endsWith('/scenarios') && request.method === 'GET') {
          if (!isLoggedIn) { return unauthorised(); }

          const dupReq = request.clone({
              url: 'assets/mock/scenarios-list.json'
          });
          return next.handle(dupReq);
      } */

      // get all monitoring
      if (request.url.endsWith('/monitoring') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/monitoring-list.json'
        });
        return next.handle(dupReq);
      }

      // get all planning
      if (request.url.endsWith('/planning') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/planning-list.json'
        });
        return next.handle(dupReq);
      }

      // get planning detail
      if (request.url.includes('/planning/') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/planning-list.json'
        });
        return next.handle(dupReq);
      }

      // get planning detail
      if (request.url.includes('/scenarios/') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/scenarios-list.json'
        });
        return next.handle(dupReq);
      }


      // get all monitoring
      if (request.url.includes('/information-set-form') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/information-set-form-list.json'
        });
        return next.handle(dupReq);
      }

      // get all reports
      if (request.url.includes('/reports') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/reports-list.json'
        });
        return next.handle(dupReq);
      }

      // get a business budget
      if (request.url.includes('/business-budget-active') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/business-budget-active.json'
        });
        return next.handle(dupReq);
      }

      // get a business budget
      if (request.url.includes('/business-budget-passive') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/business-budget-passive.json'
        });
        return next.handle(dupReq);
      }

      // get a business budget
      if (request.url.includes('/business-budget-incstat') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/business-budget-active.json'
        });
        return next.handle(dupReq);
      }

      // get a business budget
      if (request.url.includes('/business-budget-finstatind') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/business-budget-finstatind.json'
        });
        return next.handle(dupReq);
      }

      // get a business budget
      if (request.url.includes('/business-budget-finstatdir') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/business-budget-finstatdir.json'
        });
        return next.handle(dupReq);
      }


      // information set by index
      /*
      if (request.url.includes('information-set') && request.method === 'GET') {
          if (!isLoggedIn) { return unauthorised(); }
          let mock = 'assets/mock/information-set.json';

          const code = request.url.substr(request.url.length-19 ,3 );
          // console.log(request.url);
          // console.log(code);
          mock = 'assets/mock/information-set-' + code + '.json';

          const dupReq = request.clone({
              url: mock,
          });
          return next.handle(dupReq);
      }
      */

      // get all documents
      if (request.url.endsWith('/archive-documents') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/archive-documents.json'
        });
        return next.handle(dupReq);
      }

      // get all data models
      if (request.url.endsWith('/rule-data-models') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/rule-data-models.json'
        });
        return next.handle(dupReq);
      }

      // get all shared
      if (request.url.endsWith('/shared') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/shared-list.json'
        });
        return next.handle(dupReq);
      }

      // pass through any requests not handled above
      return next.handle(request);
    }))
      // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
      .pipe(materialize())
      .pipe(delay(500))
      .pipe(dematerialize());

    // private helper functions

    function ok(body) {
      return of(new HttpResponse({ status: 200, body }));
    }

    function unauthorised() {
      return throwError({ status: 401, error: { message: 'Unauthorised' } });
    }

    function error(message) {
      return throwError({ status: 400, error: { message } });
    }
  }
}

export let fakeBackendProvider = {
  // use fake backend in place of Http notificationsListService for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: BackendInterceptor,
  multi: true
};
