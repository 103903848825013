import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ConnectionEntry} from '../../../models/connection/connection-entry';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {DataTableComponent} from '../../common/data-table/data-table.component';
import {DmsElement} from '../../../models/dms-element';
import {CdkColumnDef} from '@angular/cdk/table';

@Component({
  selector: 'app-connection-elements-list',
  templateUrl: './connection-elements-list.component.html',
  styleUrls: ['./connection-elements-list.component.scss']
})
export class ConnectionElementsListComponent implements AfterViewChecked {

  @Input() elements: ConnectionEntry[] = [];
  @Output() removed: EventEmitter<ConnectionEntry> = new EventEmitter<ConnectionEntry>();

  faTimes = faTimes;
  customColumnsActive: string[] = [
    'name',
    'fiscalYear',
    'path',
    'remove'
  ];
  viewChecked: boolean = false;

  @ViewChild('dataTable') dataTable: DataTableComponent<DmsElement>;
  @ViewChildren(CdkColumnDef) customColumns: QueryList<CdkColumnDef>;

  ngAfterViewChecked(): void {
    this.viewChecked = true;
  }

  onRemove(row: ConnectionEntry): void {
    this.removed.emit(row);
  }
}
