import {AppInjector} from '../../app.component';
import {I18nService} from '../../services/common/i18n.service';
import {LocalTranslateEnum} from '../i18n/local-translate.enum';

export function getCurrentLang(): string {
  const i18n: I18nService = AppInjector.get(I18nService);
  return i18n.langSubject.getValue();
}

export function mapTranslateMessages(translated: any[]) {
  return {
    errorSaving: translated[LocalTranslateEnum.errorSaving],
    errorLoading: translated[LocalTranslateEnum.errorLoading],
    deleteConfirm: translated[LocalTranslateEnum.deleteConfirm],
    deleteSelectedConfirm: translated[LocalTranslateEnum.deleteSelectedConfirm],
    deleteSuccess: translated[LocalTranslateEnum.deleteSuccess],
    savingSuccess: translated[LocalTranslateEnum.savingSuccess],
    errorGeneric: translated[LocalTranslateEnum.errorGeneric],
    usersOwnerActivity: translated[LocalTranslateEnum.usersOwnerActivity],
    yesAnswer: translated[LocalTranslateEnum.yesAnswer],
    noAnswer: translated[LocalTranslateEnum.noAnswer]
  };
}

export const tranlastionKeys = [
  LocalTranslateEnum.errorSaving,
  LocalTranslateEnum.errorLoading,
  LocalTranslateEnum.deleteConfirm,
  LocalTranslateEnum.deleteSelectedConfirm,
  LocalTranslateEnum.deleteSuccess,
  LocalTranslateEnum.savingSuccess,
  LocalTranslateEnum.errorGeneric,
  LocalTranslateEnum.usersOwnerActivity,
  LocalTranslateEnum.yesAnswer,
  LocalTranslateEnum.noAnswer
];
