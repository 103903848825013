import { Injectable } from '@angular/core';
import { DmsElementResource } from '../models/api/documents/dms-element-resource';
import { DmsElement } from '../models/dms-element';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SphereService } from './sphere.service';
import { PageableService } from './common/pageable.service';
import { DmsMetadataService } from './dms-metadata.service';
import { map, tap } from 'rxjs/operators';
import { DmsRepositoryResponse } from '../models/api/documents/dms-repository-response';
import { DmsDownloadElementResource } from '../models/api/documents/dms-download-element-resource';
import { DmsDownloadElement } from '../models/dms-download-element';
import { isNullOrUndefined } from 'util';
import { DmsElementMetadataRegResource } from '../models/api/documents/dms-element-metadata-reg-resource';
import { PageableResponse } from '../models/api/pageable-response';
import { DmsElementDeleteResource } from '../models/api/documents/dms-element-delete-resource';
import { AuthenticationService } from '@pwc/security';
import { DmsElementMoveResource } from '../models/api/documents/dms-element-move-resource';
import { ConnectionElementResource } from '../models/api/connection/connection-element-resource';
import { ConnectionGroupResource } from '../models/api/connection/connection-group-resource';
import { environment } from '../../environments/environment';
import { ConnectionService } from './connection.service';
import { DmsExportCsvResource } from '../models/api/documents/dms-export-csv-resource';
import { ConnectionAliasResource } from '../models/api/connection/connection-alias-resource';
import { ConnectionAlias } from '../models/connection/connection-alias';
import { ConnectionGroup } from '../models/connection/connection-group';
import { DmsExportWeightReportResource } from '../models/api/documents/dms-export-weight-report-resource';

@Injectable({
  providedIn: 'root'
})
export class DmsFolderRepositoryService extends PageableService<DmsElementResource, DmsElement> {
  protected endpoint = 'folder';
  totalElementsSubject: Subject<number> = new Subject<number>();
  totalElements$: Observable<number> = this.totalElementsSubject.asObservable();
  canCreateFolderSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  visibleMetadataSubject: BehaviorSubject<DmsElementMetadataRegResource[]> = new BehaviorSubject([]);
  searchInTreeSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canCreateFolder$: Observable<boolean> = this.canCreateFolderSubject.asObservable();
  visibleMetadata$: Observable<DmsElementMetadataRegResource[]> = this.visibleMetadataSubject.asObservable();
  searchInTree$: Observable<boolean> = this.searchInTreeSubject.asObservable();

  constructor(http: HttpClient,
    protected sphereService: SphereService,
    protected dmsMetadataService: DmsMetadataService,
    protected connectionService: ConnectionService,
    protected authenticationService: AuthenticationService) {
    super(http);
    this.authenticationService.logout$.subscribe(() => {
      this.canCreateFolderSubject.next(false);
      this.visibleMetadataSubject.next([]);
      this.searchInTreeSubject.next(false);
    });
  }

  public find(search?: Partial<DmsElementResource>): Observable<DmsElement[]> {
    return this.post<DmsRepositoryResponse>(`searchPost`, search).pipe(
      map((response: DmsRepositoryResponse) => response.list),
      map((response: PageableResponse<DmsElementResource[]>) => response.content),
      map((list: DmsElementResource[]): DmsElement[] => this.convertListToModel(list))
    );
  }

  public search(search?: Partial<DmsElementResource>): Observable<DmsElement[]> {
    return this.post<DmsRepositoryResponse>('searchPost', search).pipe(
      tap((response: DmsRepositoryResponse) => {
        let canCreate = true;
        // if (isNullOrUndefined(response.sharingInfo)) {
        //   canCreate = true;
        // } else {
        //   canCreate = response.sharingInfo.permissionUpload;
        // }
        if (response.list.content.length > 0) {
          // Check if any element has a parent zip law conserved.
          canCreate = response.list.content.reduce((val: boolean, el: DmsElementResource) => val && !el.isParentAZipFileLawConserved, canCreate);
        }
        if (response.searchInTheTree) {
          canCreate = false;
        }
        this.canCreateFolderSubject.next(canCreate && response.canCreateFolder);
        // Disable metadata update when checking if directory exists.
        if (isNullOrUndefined(search.id)) {
          this.visibleMetadataSubject.next(response.metadata);
        }
        this.searchInTreeSubject.next(response.searchInTheTree);
      }),
      map((response: DmsRepositoryResponse) => response.list),
      map((response: PageableResponse<DmsElementResource[]>): DmsElementResource[] => {
        if (response.content.length !== response.totalElements) {
          this.totalElementsSubject.next(response.totalElements);
        }
        return this.extractPaginationInfo(response)}),
      map((list: DmsElementResource[]): DmsElement[] => this.convertListToModel(list))
    );
  }
  
  public navigationSearch(fiscalYear: number, sphereId: number, parentId?:number): Observable<DmsElement[]> {
    //return this.get<DmsElement[]>(`navigationSearch?fiscalYear=${fiscalYear}&sphereId=${sphereId}&parentId=${parentId}`);
    return this.get<DmsRepositoryResponse>(`navigationSearch?fiscalYear=${fiscalYear}&sphereId=${sphereId}&parentId=${parentId}`).pipe(
      tap((response: DmsRepositoryResponse) => {
        let canCreate = true;
        // if (isNullOrUndefined(response.sharingInfo)) {
        //   canCreate = true;
        // } else {
        //   canCreate = response.sharingInfo.permissionUpload;
        // }
        if (response.list.content.length > 0) {
          // Check if any element has a parent zip law conserved.
          canCreate = response.list.content.reduce((val: boolean, el: DmsElementResource) => val && !el.isParentAZipFileLawConserved, canCreate);
        }
        if (response.searchInTheTree) {
          canCreate = false;
        }
        this.canCreateFolderSubject.next(canCreate && response.canCreateFolder);
        // Disable metadata update when checking if directory exists.
        if (response.metadata) {
          this.visibleMetadataSubject.next(response.metadata);
        } else {
          this.visibleMetadataSubject.next([]);
        }
        this.searchInTreeSubject.next(response.searchInTheTree);
      }),
      map((response: DmsRepositoryResponse) => response.list),
      map((response: PageableResponse<DmsElementResource[]>): DmsElementResource[] => {
        if (response.content.length !== response.totalElements) {
          this.totalElementsSubject.next(response.totalElements);
        }
        return this.extractPaginationInfo(response)}),
      map((list: DmsElementResource[]): DmsElement[] => this.convertListToModel(list))
    );
  }

  public getInfo(search?: Partial<DmsElementResource>): Observable<DmsElement[]> {
    return this.post<DmsRepositoryResponse>('searchPost', search).pipe(
      map((response: DmsRepositoryResponse) => response.list),
      map((response: PageableResponse<DmsElementResource[]>): DmsElementResource[] => response.content),
      map((list: DmsElementResource[]): DmsElement[] => this.convertListToModel(list))
    );
  }

  public getMetadata(search?: Partial<DmsElementResource>): Observable<DmsElementMetadataRegResource[]> {
    return this.post<DmsRepositoryResponse>('metadata', search).pipe(
      map((response: DmsRepositoryResponse) => response.metadata)
    );
  }

  navigate(search: Partial<DmsElementResource>): Observable<DmsElement[]> {
    return this.search(search);
  }

  convertModelToResource(model: DmsElement): DmsElementResource {
    const resource = new DmsElementResource();
    Object.assign(resource, model);

    if (!isNullOrUndefined(model.parent)) {
      resource.parent = this.convertModelToResource(model.parent);
    }
    if (!isNullOrUndefined(model.sphere)) {
      resource.sphere = this.sphereService.convertModelToResource(model.sphere);
    }
    if (!isNullOrUndefined(model.fiscalStartDate)) {
      resource.fiscalStartDate = model.fiscalStartDate;
    }
    if (!isNullOrUndefined(model.fiscalEndDate)) {
      resource.fiscalEndDate = model.fiscalEndDate;
    }
    if (!isNullOrUndefined(resource.documentDate)) {
      model.documentDate = resource.documentDate;
    }
    if (!isNullOrUndefined(this.dmsMetadataService)) {
      let metadata = [];
      if (!isNullOrUndefined(model.metadata)) {
        metadata = this.dmsMetadataService.convertListToResource(model.metadata);
      }
      resource.metadata = metadata;
    }
    return resource;
  }

  convertResourceToModel(resource: DmsElementResource): DmsElement {
    const model = new DmsElement();
    Object.assign(model, resource);

    if (!isNullOrUndefined(resource.parent)) {
      model.parent = this.convertResourceToModel(resource.parent);
    }
    if (!isNullOrUndefined(resource.sphere)) {
      model.sphere = this.sphereService.convertResourceToModel(resource.sphere);
    }
    if (!isNullOrUndefined(resource.fiscalStartDate)) {
      model.fiscalStartDate = resource.fiscalStartDate;
    }
    if (!isNullOrUndefined(resource.fiscalEndDate)) {
      model.fiscalEndDate = resource.fiscalEndDate;
    }
    if (!isNullOrUndefined(resource.documentDate)) {
      model.documentDate = resource.documentDate;
    }
    if (!isNullOrUndefined(this.dmsMetadataService)) {
      let metadata = [];
      if (!isNullOrUndefined(resource.metadata)) {
        metadata = this.dmsMetadataService.convertListToModel(resource.metadata);
      }
      model.metadata = metadata;
    }

    return model;
  }

  public download(dmsDownloadElementResource: DmsDownloadElementResource): Observable<DmsDownloadElement> {
    return this.post<DmsDownloadElement>('download', dmsDownloadElementResource).pipe(
      map((response: DmsDownloadElement) => {
        return response;
      }));
  }

  public exportCsv(dmsExportCsvResource: DmsExportCsvResource): Observable<DmsDownloadElement> {
    return this.post<DmsDownloadElement>('download-export-csv', dmsExportCsvResource).pipe(
      map((response: DmsDownloadElement) => {
        return response;
      }));
  }
  public exportCsvRich(dmsExportCsvResource: DmsExportCsvResource): Observable<DmsDownloadElement> {
    return this.post<DmsDownloadElement>('download-export-rich-csv', dmsExportCsvResource).pipe(
      map((response: DmsDownloadElement) => {
        return response;
      }));
  }

  public exportWeightReport(dmsExportWeightReportResource: DmsExportWeightReportResource): Observable<DmsDownloadElement> {
    return this.post<DmsDownloadElement>('download-weight-report', dmsExportWeightReportResource).pipe(
      map((response: DmsDownloadElement) => {
        return response;
      }));
  }

  public downloadHistoryFile(dmsDownloadElementResource: DmsDownloadElementResource): Observable<DmsDownloadElement> {
    return this.post<DmsDownloadElement>('download-history', dmsDownloadElementResource).pipe(
      map((response: DmsDownloadElement) => {
        return response;
      }));
  }

  public getDownloadByToken(token: string, extension?: string): Observable<{ blob: Blob, url: string, fileName: string }> {
    let params = new HttpParams();
    if (!isNullOrUndefined(token)) {
      params = params.set('token', `${token}`);
    }
    return this.getBlob<Blob>('download/byToken', params).pipe(
      map((response: HttpResponse<Blob>) => {
        // console.debug('response ->', response);

        let fileName = '';
        const contentDisposition = response.headers.get('Content-Disposition');
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }

        // console.debug('extension', extension);

        let blob;
        if (!isNullOrUndefined(extension) && extension === '.csv') {
          blob = new Blob(['\ufeff', response.body]);
        } else {
          blob = new Blob([response.body]);
        }

        return {
          blob,
          url: URL.createObjectURL(blob),
          fileName
        };
      }));
  }

  public getDownloadStatus(id: number): Observable<DmsDownloadElement> {
    return this.post<DmsDownloadElement>(`download/${id}/status`, null).pipe(
      map((response: DmsDownloadElement) => {
        return response;
      }));
  }

  public downloadFileForPreview(id: number): Observable<HttpResponse<Blob>> {
    return this.getBlob<Blob>(`preview/byFileId/${id}`, null);
  }

  public downloadHistoryFileForPreview(id: number): Observable<HttpResponse<Blob>> {
    return this.getBlob<Blob>(`preview/byHistoryFileId/${id}`, null);
  }

  public canDeleteFolder(body: Partial<DmsElementDeleteResource>): Observable<string> {
    let params = new HttpParams();
    if (!isNullOrUndefined(body)) {
      for (const key of Object.getOwnPropertyNames(body)) {
        params = params.set(key, `${body[key]}`);
      }
    }
    return this.get<string>('checkBeforeDeleteCustomFolder', { params });
  }

  public move(body: Partial<DmsElementMoveResource>): Observable<boolean> {
    return this.post<boolean>('move', body);
  }

  public connect(connectionElement: Partial<ConnectionElementResource>): Observable<boolean> {
    return this.post<boolean>('connection', connectionElement);
  }

  public getConnectionGroups(elementId: number, sphereId: number): Observable<ConnectionGroup[]> {
    let params = new HttpParams();
    params = params.set('idElement', `${elementId}`);
    params = params.set('sphereId', `${sphereId}`);

    return this.get<ConnectionGroupResource[]>('connection-group', { params })
      .pipe(
        map((resources: ConnectionGroupResource[]) => this.connectionService.convertGroupListToModel(resources))
      );
  }

  public getConnectionAliases(elementId: number, sphereId: number): Observable<ConnectionAlias[]> {
    let params = new HttpParams();
    params = params.set('idElement', `${elementId}`);
    params = params.set('sphereId', `${sphereId}`);

    return this.get<ConnectionAliasResource[]>('connection-alias', { params })
      .pipe(
        map((resources: ConnectionAliasResource[]) => this.connectionService.convertAliasListToModel(resources))
      );
  }

  public deleteConnectionGroup(connectionId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}/${this.endpoint}/connection-group/${connectionId}`);
  }

  public deleteConnectionAlias(aliasId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}/${this.endpoint}/connection-alias/${aliasId}`);
  }

  public getDefaultAttachmentFolders(sphereId: number): Observable<DmsElement[]> {
    let params = new HttpParams();
    params = params.set('sphereId', `${sphereId}`);

    return this.get<DmsElementResource[]>('attachment/default', { params })
      .pipe(
        map((resources: DmsElementResource[]) => this.convertListToModel(resources))
      );
  }
  public exportCsvGlobal(search?: Partial<DmsElementResource>): Observable<DmsDownloadElement> {
    return this.post<DmsDownloadElement>('download-export/global', search).pipe(
      map((response: DmsDownloadElement) => {
        return response;
      }));
  }

  public exportRichCsvGlobal(search?: Partial<DmsElementResource>): Observable<DmsDownloadElement> {
    return this.post<DmsDownloadElement>('download-export-rich/global', search).pipe(
      map((response: DmsDownloadElement) => {
        return response;
      }));
  }
}
