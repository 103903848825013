import {Injectable} from '@angular/core';
import {NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';

/**
 * A configuration service for the Pagination component.
 *
 * You can inject this service, typically in your root component, and customize the values of its properties in order to provide default
 * values for all the paginations used in the application.
 */
@Injectable({providedIn: 'root'})
export class PaginationConfig extends NgbPaginationConfig {
}
