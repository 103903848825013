export class TagElementResource {
  id: number;
  code: string;
  description?: string;
  requiredInsert = false;
  type?: any; // TODO: Enum
  userId?: number;
  sphereId?: number;
  onlyForMe: boolean;

  page?: number;
  sort?: string;
}
