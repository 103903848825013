import {Tag} from '../../models/tag/tag';
import {TagTypeEnum} from '../../enums/tag-type.enum';

export function tagType(tag: Tag): string {
  let type = '';
  if (tag.type === TagTypeEnum.SYSTEM) {
    type = 'Tag di sistema';
  } else if (tag.type === TagTypeEnum.CUSTOM) {
    type = 'Tag custom';
  } else if (tag.type === TagTypeEnum.CUSTOM_PRIVATE) {
    type = 'Tag custom privato';
  }
  return type;
}

export function isSystemTag(tag: Tag): boolean {
  return tag.type === TagTypeEnum.SYSTEM;
}
