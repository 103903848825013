import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../../environments/environment';

@Pipe({name: 'idToStringPipe'})
export class IdToStringPipe implements PipeTransform {
  transform(n: any): string {
    if (typeof n === 'undefined' || n === null) {
      n = 0;
    }
    const z = '0';
    const width = environment.idStringLength;
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
}
