import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {ModalComponent} from '../modal/modal.component';

@Component({
  selector: 'pwc-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {

  @Input() id = 'confirmationModal';
  @Input() title: string;
  @Input() message: string;
  @Input() positiveButtonText = this.translateSrv.instant('generic.action.confirm');
  @Input() negativeButtonText = this.translateSrv.instant('generic.action.cancel');

  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() positiveButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() negativeButtonClick: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('confirmationModal') confirmationModal: ModalComponent;

  constructor(private translateSrv: TranslateService){

  }

  open() {
    this.confirmationModal.open();
  }

  onClose() {
    this.closed.emit();
  }

  onPositiveClick() {
    this.confirmationModal.close();
    this.positiveButtonClick.emit();
  }

  onNegativeClick() {
    this.confirmationModal.close();
    this.negativeButtonClick.emit();
  }
}
