<link href="//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css" rel="stylesheet">
<table cdk-table [dataSource]="dataSource" class="table table-striped mb-0" [ngClass]="{'table-hover': hoverable}">
  <ng-container cdkColumnDef="selected" *ngIf="enableSelect" sticky>
    <th cdk-header-cell *cdkHeaderCellDef scope="col" style="width: 15px">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="selectAllRowsCheckBox"
               name="selectAllRowsCheckBox" (change)="selectVisibleRows()" [(ngModel)]="allSelected">
        <label class="custom-control-label" for="selectAllRowsCheckBox"></label>
      </div>
    </th>
    <td cdk-cell *cdkCellDef="let row">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" [attr.id]="row.id" [attr.name]="row.id"
               [checked]="row.selected" [ngModelOptions]="{standalone: true}" [ngModel]="row.selected"
               (change)="selectRows(row.id)">
        <label class="custom-control-label" [attr.for]="row.id"></label>
      </div>
    </td>
  </ng-container>

  <ng-container *ngFor="let column of columns" [cdkColumnDef]="column.field">
    <ng-container *ngIf="column.sortable">
      <th cdk-header-cell *cdkHeaderCellDef scope="col" [ngStyle]="getColumnStyle(column)" [sortable]="column.field"
          (sort)="onSort($event)">
        {{ column.header }}
        <span *ngIf="sortType == column.field && sortReverse == 1" class="fa fa-sort-asc"></span>
        <span *ngIf="sortType == column.field && sortReverse == 2" class="fa fa-sort-desc"></span>
        <span *ngIf="sortType == column.field && sortReverse == 0" class="fa fa-sort"></span>
      </th>
    </ng-container>
    <ng-container *ngIf="!column.sortable">
      <th cdk-header-cell *cdkHeaderCellDef scope="col" [ngStyle]="getColumnStyle(column)">
        {{ column.header }}
      </th>
    </ng-container>

    <ng-container *ngIf="!column.raw">
      <td cdk-cell *cdkCellDef="let row" [ngClass]="{pointer: hasDblClickHandler(column)}"
          (dblclick)="handleDoubleClick(column, row)">{{ column.cell(row) }}</td>
    </ng-container>
    <ng-container *ngIf="column.raw">
      <td cdk-cell *cdkCellDef="let row" [ngClass]="{pointer: hasDblClickHandler(column)}"
          (dblclick)="handleDoubleClick(column, row)" [innerHTML]="column.cell(row)"></td>
    </ng-container>
  </ng-container>

  <ng-container></ng-container>

  <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
  <ng-container *ngIf="!detailRowTpl">
    <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr>
  </ng-container>
  <ng-container *ngIf="detailRowTpl">
    <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;" [pwcDetailRow]="row" [pwcDetailRowTpl]="detailRowTpl"
        [expand]="row.expand"></tr>
  </ng-container>
</table>
