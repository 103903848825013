import {NgModule} from '@angular/core';
import {CommonModule as AngularCommonModule} from '@angular/common';
import {BaloonComponent} from './baloon/baloon.component';
import {CollapsableComponent} from './collapsable/collapsable.component';
import {AlertComponent} from './modals/alert/alert.component';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {FormsModule} from '@angular/forms';
import {NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {DataTableComponent} from './data-table/data-table.component';
import {CdkTableModule} from '@angular/cdk/table';
import {NgbdSortableHeaderDirective} from '../../directives/common/sortable.directive';
import { FormInputComponent } from './forms/form-input/form-input.component';
import { SearchInputComponent } from './forms/search-input/search-input.component';
import { ModalComponent } from './modals/modal/modal.component';
import { ListToolbarComponent } from './list-toolbar/list-toolbar.component';
import {FileUploadModule} from '../../modules/file-upload/file-upload.module';
import { EmailInputComponent } from './forms/email-input/email-input.component';
import { BadgeListComponent } from './badge-list/badge-list.component';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { SortingArrowsComponent } from './sorting-arrows/sorting-arrows.component';
import {PwcDetailRowDirective} from '../../directives/common/pwc-detail-row.directive';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { ButtonComponent } from './buttons/button/button.component';

@NgModule({
  declarations: [
    BaloonComponent,
    CollapsableComponent,
    AlertComponent,
    DataTableComponent,
    NgbdSortableHeaderDirective,
    FormInputComponent,
    SearchInputComponent,
    ModalComponent,
    ListToolbarComponent,
    EmailInputComponent,
    BadgeListComponent,
    ConfirmationModalComponent,
    SortingArrowsComponent,
    PwcDetailRowDirective,
    ButtonComponent
  ],
  imports: [
    AngularCommonModule,
    FormsModule,
    CdkTableModule,
    FileUploadModule,
    NgbAlertModule,
    FontAwesomeModule,
    NgxSmartModalModule.forRoot()
  ],
  exports: [
    BaloonComponent,
    CollapsableComponent,
    AlertComponent,
    DataTableComponent,
    FormInputComponent,
    SearchInputComponent,
    NgbdSortableHeaderDirective,
    ModalComponent,
    ListToolbarComponent,
    CdkTableModule,
    EmailInputComponent,
    BadgeListComponent,
    ConfirmationModalComponent,
    SortingArrowsComponent,
    ButtonComponent
  ]
})
export class PwcCommonModule {
}
