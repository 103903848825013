import {Component, EventEmitter, Output} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ModalHandler} from '../../../contracts/common/modal-handler';
import {piDownload, PwcIconsLibrary} from "@pwc/icons";

@Component({
  selector: 'app-download-modal',
  templateUrl: './download-modal.component.html',
  styleUrls: ['./download-modal.component.scss']
})
export class DownloadModalComponent implements ModalHandler {

  @Output() downloadStandard: EventEmitter<void> = new EventEmitter<void>();
  @Output() downloadConservation: EventEmitter<void> = new EventEmitter<void>();

  constructor(private ngxSmartModalService: NgxSmartModalService,
              private iconsLibrary: PwcIconsLibrary) {
    iconsLibrary.registerIcons([
      piDownload,
    ]);
  }

  open(): void {
    this.ngxSmartModalService.getModal('downloadModal').open();
  }

  close(): void {
    this.ngxSmartModalService.getModal('downloadModal').close();
  }

  onDownloadStandard() {
    this.downloadStandard.emit();
  }

  onDownloadConservation() {
    this.downloadConservation.emit();
  }

}
