import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ConnectionElementType} from '../../../enums/connection-element-type.enum';
import {ModalComponent} from '../../common/modals/modal/modal.component';

@Component({
  selector: 'app-connection-type-modal',
  templateUrl: './connection-type-modal.component.html',
  styleUrls: ['./connection-type-modal.component.scss']
})
export class ConnectionTypeModalComponent {
  @Input() connectionType: ConnectionElementType;
  @Output() typeSelected: EventEmitter<ConnectionElementType> = new EventEmitter<ConnectionElementType>();

  @ViewChild('connectionTypeModal') typeModal: ModalComponent;

  open(): void {
    this.typeModal.open();
  }

  close(): void {
    this.typeModal.close();
  }

  typeAlias(): void {
    this.typeSelected.emit(ConnectionElementType.ALIAS);
  }

  typeGroup(): void {
    this.typeSelected.emit(ConnectionElementType.GROUP);
  }
}
