<pwc-modal #documentFilterModal id="documentFilterModal">
  <div modal-title>
    {{'documents.filter.advanced-search'|translate}}
  </div>

  <div modal-body>
    <div class="row mt-2">
      <div class="col">
        <dt class="col-sm-12 metadata-label">{{'generic.field.fiscal-year'|translate}}</dt>
        <dd class="col-sm-12 metadata-value">
          <div class="form-group form-input-group">
            <input type="text" disabled readonly class="form-control form-input no-shadow" [ngModel]="fiscalYear">
          </div>
        </dd>
      </div>
      <div class="col">
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <dt class="col-sm-12 metadata-label">{{'documents.filter.file-name'|translate}}</dt>
        <dd class="col-sm-12 metadata-value">
          <div class="form-group form-input-group">
            <input type="text" class="form-control form-input no-shadow" [(ngModel)]="name">
          </div>
        </dd>
      </div>
      <div class="col">
        <dt class="col-sm-12 metadata-label">{{'documents.filter.select-tag'|translate}}</dt>
        <dd class="col-sm-12 metadata-value">
          <div class="form-group form-input-group">
            <ng-select
              id="tag"
              bindLabel="code"
              appendTo="body"
              [(ngModel)]="selectedTag"
              [multiple]="true"
              [clearable]="true"
              class="no-shadow">
              <ng-option [value]="tag.id" *ngFor="let tag of tags">
                {{tag.code}}
              </ng-option>
            </ng-select>
          </div>
        </dd>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col">
        <dt class="col-sm-12 metadata-label">{{'generic.field.start-date'|translate}}</dt>
        <dd class="col-sm-12 metadata-value">
          <input type="text" class="form-control form-input no-shadow" placeholder="DD/MM/YYYY" ngbDatepicker
            (ngModelChange)="setNgbFiscalStartDate($event)" [(ngModel)]="startDate" #d0="ngbDatepicker">
          <div (click)="d0.toggle()" class="form-input-icon append pointer mr-2">
            <pwc-icon name="calendar_small"></pwc-icon>
          </div>
          <div *ngIf="errorDate" class="invalid-feedback" style="padding-top: 5px">{{errorDate}}</div>
        </dd>
      </div>
      <div class="col">
        <dt class="col-sm-12 metadata-label">{{'generic.field.end-date'|translate}}</dt>
        <dd class="col-sm-12 metadata-value">
          <input type="text" class="form-control form-input no-shadow" placeholder="DD/MM/YYYY" ngbDatepicker
            (ngModelChange)="setNgbFiscalEndDate($event)" [(ngModel)]="endDate" #d1="ngbDatepicker">
          <div (click)="d1.toggle()" class="form-input-icon append pointer mr-2">
            <pwc-icon name="calendar_small"></pwc-icon>
          </div>
          <div *ngIf="errorEndDate" class="invalid-feedback" style="padding-top: 5px">{{errorEndDate}}</div>
        </dd>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col">
        <dt class="col-sm-12 metadata-label">{{'documents.filter.upload-date-from'|translate}}</dt>
        <dd class="col-sm-12 metadata-value">
          <input type="text" class="form-control form-input no-shadow" placeholder="DD/MM/YYYY" ngbDatepicker
            (ngModelChange)="setNgbCreatedDateFrom($event)" [(ngModel)]="createdFrom" #d2="ngbDatepicker">
          <div (click)="d2.toggle()" class="form-input-icon append pointer mr-2">
            <pwc-icon name="calendar_small"></pwc-icon>
          </div>
          <div *ngIf="errorCreatedDateFrom" class="invalid-feedback" style="padding-top: 5px">{{errorCreatedDateFrom}}
          </div>
        </dd>
      </div>
      <div class="col">
        <dt class="col-sm-12 metadata-label">{{'documents.filter.upload-date-to'|translate}}</dt>
        <dd class="col-sm-12 metadata-value">
          <input type="text" class="form-control form-input no-shadow" placeholder="DD/MM/YYYY" ngbDatepicker
            (ngModelChange)="setNgbCreatedDateTo($event)" [(ngModel)]="createdTo" #d3="ngbDatepicker">
          <div (click)="d3.toggle()" class="form-input-icon append pointer mr-2">
            <pwc-icon name="calendar_small"></pwc-icon>
          </div>
          <div *ngIf="errorCreatedDateTo" class="invalid-feedback" style="padding-top: 5px">{{errorCreatedDateTo}}</div>
        </dd>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col">
        <dt class="col-sm-12 metadata-label">{{'documents.filter.select-class'|translate}}</dt>
        <dd class="col-sm-12 metadata-value">
          <ng-select
            id="docClassType"
            bindLabel="code"
            appendTo="body"
            (ngModelChange)="onSelectedDocClassType($event)"
            [(ngModel)]="selectedDocClassTypes"
            [multiple]="false"
            class="no-shadow">
            <ng-option [value]="docClass.code" *ngFor="let docClass of docClassType">
              {{docClass.description}}
            </ng-option>
          </ng-select>
        </dd>
      </div>
      <div class="col">
        <dt class="col-sm-12 metadata-label">{{'documents.filter.recent'|translate}}</dt>
        <dd class="col-sm-12 metadata-value">
          <div class="col-sm-6 custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="recent" [(ngModel)]="recent">
            <label for="recent" class="custom-control-label"></label>
          </div>
        </dd>
      </div>
    </div>

    <ng-container *ngIf="selectedMetadata.length>0">
      <div class="row mt-2">
        <dt class="col-sm-12 metadata-label">{{'documents.filter.deliver-results'|translate}}</dt>
        <dd class="col-sm-12 metadata-value">
          <div class="custom-control custom-radio">
            <input type="radio" id="operatorAND" class="custom-control-input" value="AND" [(ngModel)]="operator">
            <label for="operatorAND" class="custom-control-label">{{'documents.filter.all-criterion'|translate}}</label>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" id="operatorOR" class="custom-control-input" value="OR" [(ngModel)]="operator">
            <label for="operatorOR" class="custom-control-label">{{'documents.filter.one-criterion'|translate}}</label>
          </div>
        </dd>
      </div>

      <div class="row mt-2">
        <dt class="col-sm-4 metadata-label">{{'documents.filter.metadata'|translate}}</dt>
        <dd class="col-sm-8 metadata-value multiple-values-disclaimer">
          <p class="suggestion">{{'documents.filter.tip'|translate}}</p>: {{'documents.filter.tip-text'|translate}} <p class="special-character">;</p> ({{'documents.filter.special-separator'|translate}}).
        </dd>
      </div>

      <div class="row mt-2">
        <ng-container *ngFor="let meta of selectedMetadata; let i=index">
          <dt class="col-sm-4 metadata-label">{{meta.name}}</dt>
          <dd class="col-sm-8 metadata-value">
            <div class="form-group form-input-group" *ngIf="isStringMetadata(meta.type)">
              <input type="text" class="form-control form-input no-shadow" [(ngModel)]="meta.value">
            </div>
            <div class="form-group form-input-group" *ngIf="isNumberMetadata(meta.type)">
              <input type="number" class="form-control form-input no-shadow" [(ngModel)]="meta.value">
            </div>
            <div class="form-group form-input-group" *ngIf="isBooleanMetadata(meta.type)"
              style="vertical-align: middle;">
              <input type="checkbox" class="form-control form-input no-shadow" [(ngModel)]="meta.value">
            </div>
            <div class="form-group form-input-group withError" *ngIf="isDateMetadata(meta.type)">
              <input type="text" class="form-control form-input no-shadow" placeholder="DD/MM/YYYY"
                     (ngModelChange)="setNgbMetadata($event,meta.id)" [ngModel]="getDatePickerValue(meta)"
                     ngbDatepicker #i="ngbDatepicker">
              <div (click)="i.toggle()" class="form-input-icon append pointer w-auto">
                <pwc-icon name="calendar_small"></pwc-icon>
              </div>
              <div *ngIf="meta.error" class="invalid-feedback" style="padding-top: 5px">{{'generic.message.date-format-error'|translate}}
              </div>
            </div>
          </dd>
        </ng-container>
      </div>
    </ng-container>
  </div>


  <div modal-footer class="row" style="width:100%;margin-right:0;margin-left:0">
    <div class="col text-left" style="padding-top:14px;padding-bottom:14px;padding-left:0;">
      <button class="btn btn-primary btn-sm btn-action-icon ml-0 btn-clear-filter" [ngbTooltip]="'generic.filters.clear'|translate"
              placement="right" type="button" (click)="clearFilters()">
        <pwc-icon name="clear"></pwc-icon>
      </button>
    </div>
    <div class="col text-right" style="padding-right:0">
      <div>
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="cancel()">
          {{'generic.action.cancel'|translate}}
        </button>
        <button type="button" class="btn btn-secondary" (click)="search()" [disabled]="disableSearch()">
          {{'generic.action.confirm'|translate}}
        </button>
      </div>
    </div>
  </div>
</pwc-modal>