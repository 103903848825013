import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {LanguageCode} from '../../enums/common/language-code.enum';
import {Language} from '../../models/common/language';

@Injectable({
    providedIn: 'root'
})
export class I18nService {
    public langSubject: BehaviorSubject<string> = new BehaviorSubject<string>(LanguageCode.IT);
    public lang = this.langSubject.asObservable();
    public languages = [
        new Language('1', LanguageCode.IT, 'IT'),
        new Language('2', LanguageCode.EN, 'EN')
    ];

    constructor(private translate: TranslateService) {
    }
}
