import { Injectable } from '@angular/core';
import { ListServiceHelper } from './common/list-service-helper.service';
import { HttpClient } from '@angular/common/http';
import { NotificationModel } from '../models/common/notifications/notification-model';
import { DecimalPipe } from '@angular/common';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { NotificationResource } from '../models/api/notifications/notification-resource';
import { transformEntityToNotification } from '../helpers/transformers/notification.transformer';

@Injectable({
  providedIn: 'root'
})
export class NotificationsListService extends ListServiceHelper<NotificationModel> {
  constructor(protected http: HttpClient,
    protected pipe: DecimalPipe) {
    super(http, pipe);
  }

  unsubscribeAll() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  loadService() {
    this.list = [];
    this.unsubscribeAll();
    this.getAll().pipe(first()).subscribe(list => this.updateList(list));
  }

  getAll(): Observable<NotificationModel[]> {
    return this.http.get<NotificationResource[]>(`${environment.authUrl}/notification`)
      .pipe(map((entities: any): NotificationModel[] => {
        return entities.content.map(transformEntityToNotification);
      }));
  }

  countUnread(): Observable<number> {
    return this.http.get<number>(`${environment.authUrl}/notification/count`);
  }

  markAsRead(id: number) {
    return this.http.post<any>(`${environment.authUrl}/notification/${id}/mark`, null);
  }

  delete(id: number) {
    return this.http.delete<NotificationModel>(`${environment.authUrl}/notification/${id}`);
  }

  deleteMassive(list: any[]) {
    const params = list.map(id => `ids=${id}`).join('&');
    return this.http.delete(`${environment.authUrl}/notification?${params}`);
  }

  getBEVersion(): Observable<string> {
    return this.http.get<string>(`${environment.apiUrl}/version/`);
  }

}
