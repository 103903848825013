import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BadgeListItem} from '../../../models/common/badge-list-item';

@Component({
  selector: 'badge-list',
  templateUrl: './badge-list.component.html',
  styleUrls: ['./badge-list.component.scss']
})
export class BadgeListComponent {
  @Input() label: string;
  @Input() items: BadgeListItem[] = [];
  @Output() remove: EventEmitter<any> = new EventEmitter<any>();

  removeItem(id: any) {
    this.remove.emit(id);
  }
}
