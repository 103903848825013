import {Injectable} from '@angular/core';
import {FiscalYearResource} from '../models/api/fiscal-year-resource';
import {DmsElement} from '../models/dms-element';
import {FiscalYear} from '../models/fiscalYear';
import {ConnectionService} from './connection.service';
import {QueueService} from './common/queue.service';
import {ConnectionEntry} from '../models/connection/connection-entry';
import {DmsSharingEntity} from '../models/share/dms-sharing-entity';

@Injectable({
  providedIn: 'root'
})
export class ConnectionCartService extends QueueService<ConnectionEntry> {
  constructor(private connectionService: ConnectionService) {
    super();
  }

  public push(element: DmsElement, year: FiscalYear | FiscalYearResource): void {
    this.pushAll([element], year);
  }

  public pushAll(elements: DmsElement[], year: FiscalYear | FiscalYearResource): void {
    const entities = this.connectionService.convertElementsListToConnectionEntries(elements, year);
    const list = [...this.listSubject.getValue(), ...entities];
    this.listSubject.next(list);
  }

  public remove(element: DmsElement): void {
    this.removeById(element.id);
  }

  public removeById(id: number): void {
    const list = this.listSubject.getValue();
    const newList: ConnectionEntry[] = list.filter((item: ConnectionEntry) => item.elementId !== id);

    this.listSubject.next(newList);
  }

  public removeByIdAndYear(id: number, year: FiscalYearResource): void {
    const list = this.listSubject.getValue();
    const newList: ConnectionEntry[] = list.filter((item: ConnectionEntry) => item.elementId !== id || item.fiscalYearId !== year.id);

    this.listSubject.next(newList);
  }

  getSelected(fiscalYear: FiscalYearResource): DmsElement[] {
    return this.listSubject.getValue()
      .filter((entry: ConnectionEntry) => entry.fiscalYearId === fiscalYear.id)
      .map((item: ConnectionEntry) => item.element);
  }
}
