import {Injectable} from '@angular/core';
import {SphereResource} from '../models/api/sphere-resource';
import {BehaviorSubject, Observable} from 'rxjs';
import {FiscalYearResource} from '../models/api/fiscal-year-resource';
import {Sphere} from '../models/sphere';
import {isNullOrUndefined} from 'util';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UserService} from './common/user.service';
import {PageableService} from './common/pageable.service';
import {AuthenticationService} from '@pwc/security';
import {map} from 'rxjs/operators';
import {PageableResponse} from '../models/api/pageable-response';
import {SphereUserRelationEnum} from '../enums/sphere-user-relation.enum';
import {DmsSharing} from '../models/share/dms-sharing';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SphereService extends PageableService<SphereResource, Sphere> {
  protected endpoint = 'sphere';

  private currentSphereSubject: BehaviorSubject<Sphere> = new BehaviorSubject<Sphere>(null);
  public currentSphere: Observable<Sphere> = this.currentSphereSubject.asObservable();

  constructor(http: HttpClient,
              private userService: UserService,
              private authenticationService: AuthenticationService) {
    super(http);
    this.authenticationService.logout$.subscribe(() => {
      this.currentSphereSubject.next(null);
    });
  }

  // TODO: Return FiscalYear model.
  public getFiscalYears(sphereId: number): Observable<FiscalYearResource[]> {
    return this.get<FiscalYearResource[]>(`${sphereId}/fiscal_years`);
  }

  public getSharingInfo(sphereId: number): Observable<DmsSharing> {
    return this.get<DmsSharing>(`${sphereId}/sharing`);
  }

  public setCurrentSphere(sphere: Sphere): void {
    this.currentSphereSubject.next(sphere);
  }

  public isOwned(): boolean {
    const sphere = this.currentSphereSubject.getValue();

    if (isNullOrUndefined(sphere)) {
      return false;
    }

    return sphere.sphereUserRelation === SphereUserRelationEnum.OWNER || sphere.sphereUserRelation === SphereUserRelationEnum.MASTER;
  }

  public isShared(): boolean {
    const sphere = this.currentSphereSubject.getValue();

    if (isNullOrUndefined(sphere)) {
      return false;
    }

    return sphere.sphereUserRelation === SphereUserRelationEnum.SHARED;
  }

  public convertModelToResource(model: Sphere): SphereResource {
    const resource = new SphereResource();
    Object.assign(resource, model);

    if (!isNullOrUndefined(model.user)) {
      resource.user = this.userService.convertModelToResource(model.user);
    }

    return resource;
  }

  public convertResourceToModel(resource: SphereResource): Sphere {
    const model = new Sphere();
    Object.assign(model, resource);

    if (!isNullOrUndefined(resource.user)) {
      model.user = this.userService.convertResourceToModel(resource.user);
    }

    return model;
  }

  public search(search?: Partial<Sphere>): Observable<Sphere[]> {
    let params = new HttpParams();
    if (!isNullOrUndefined(search)) {
      for (const key of Object.getOwnPropertyNames(search)) {
        params = params.set(key, `${search[key]}`);
      }
    }
    return this.get<PageableResponse<SphereResource[]>>('search', {params}).pipe(
      map((response: PageableResponse<SphereResource[]>): SphereResource[] => {
        return this.extractPaginationInfo(response);
      }),
      map((list: SphereResource[]): Sphere[] => {
        return this.convertListToModel(list);
      })
    );
  }

  public getOwnerSpheres(): Observable<SphereResource[]>{
    return this.get<SphereResource[]>('owner');
  }

  public vdrCheck(superUserPermission: boolean): Observable<boolean> {
    if(superUserPermission) {
      return new BehaviorSubject<boolean>(false);
    } else {
      return this.get<boolean>('vdr-check');
    }
  }

  public admitSocietyToVDR(resource: SphereResource): Observable<boolean>{
    return this.http.put<boolean>(`${environment.apiUrl}/${this.endpoint}/vdr-admit/${resource.id}`, null);
  }

  public removeSocietyFromVDR(resource: SphereResource): Observable<boolean> {
    return this.http.put<boolean>(`${environment.apiUrl}/${this.endpoint}/vdr-remove/${resource.id}`, null);
  }

  public acceptVdrDisclaimer(){
    return this.http.put<boolean>(`${environment.apiUrl}/${this.endpoint}/vdr-accept-disclaimer`, null);
  }

}
