import {Component, Input} from '@angular/core';
import {AbstractValueAccessor, MakeProvider} from '../abstract-value-accessor';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  providers: [MakeProvider(SearchInputComponent)]
})
export class SearchInputComponent extends AbstractValueAccessor<string>  {
  @Input() placeholder: string;
  @Input() label: string;
}
