import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { piCalendarSmall, PwcIconsLibrary } from "@pwc/icons";
import * as moment from 'moment';
import { isNullOrUndefined } from 'util';
import { MetadataFieldType } from '../../../enums/metadata-field-type.enum';
import { validateDate } from '../../../helpers/common/date-utils';
import {
  isBooleanMetadata,
  isDateMetadata,
  isNumberMetadata,
  isStringMetadata
} from '../../../helpers/common/metadata-utils';
import { DmsElementMetadata } from '../../../models/dms-element-metadata';
import { LocalDate } from '../../../models/local-date.model';

@Component({
  selector: 'app-attachment-metadata',
  templateUrl: './attachment-metadata.component.html',
  styleUrls: ['./attachment-metadata.component.scss']
})
export class AttachmentMetadataComponent {

  @Input() fileName: string;

  @Input()
  set fiscalYear(year: number) {
    if (isNullOrUndefined(year)) {
      return;
    }

    const startDate = moment.utc().year(year).month(0).date(1);
    const endDate = moment.utc().year(year).month(11).date(31);
    this.localFiscalStartDate = this.toLocalDate(startDate.toDate());
    this.localFiscalEndDate = this.toLocalDate(endDate.toDate());
  }

  @Input() metadata: DmsElementMetadata[] = [];

  localDocumentDate: LocalDate;
  localFiscalStartDate: LocalDate;
  localFiscalEndDate: LocalDate;

  @Output() dateChange: EventEmitter<{ field: string, value: any }> = new EventEmitter<{ field: string, value: any }>();
  @Output() fileNameChange: EventEmitter<string> = new EventEmitter<string>();

  errors: {
    fileName?: string,
    documentDate?: string,
    fiscalStartDate?: string,
    fiscalEndDate?: string
  } = {};


  mapMetaDate = new Map<number, LocalDate>();
  
  sogg1TypeMetaCode = 'soggetto_1_tipo_s';
  sogg2TypeMetaCode = 'soggetto_2_tipo_s';

  get isFileNameValid(): boolean {
    const regexp: RegExp = /^[a-zA-Z0-9_\-.'@ ]+$/;
    return regexp.test(this.fileName);
  }

  constructor(private iconsLibrary: PwcIconsLibrary, private translateSrv: TranslateService) {
    iconsLibrary.registerIcons([
      piCalendarSmall
    ]);
  }

  setNgbDate(field: string, date: NgbDate): void {
    if (isNullOrUndefined(date)) {
      this.errors[field] = '';
      return;
    }

    if (date.year >= 1970 && validateDate(date)) {
      this.errors[field] = '';
      const value = moment().year(date.year).month(date.month - 1).date(date.day);
      this.dateChange.emit({ field, value });
    } else {
      this.errors[field] = this.translateSrv.instant('generic.message.date-format-error');
    }
  }

  setNgbMetadata(code: string, date: NgbDate): void {
    if (isNullOrUndefined(date)) {
      this.errors[code] = '';
      return;
    }

    if (date.year > 1970 && validateDate(date)) {
      this.errors[code] = '';
      const value = moment().year(date.year).month(date.month - 1).date(date.day);
      const metas = this.metadata.filter((meta: DmsElementMetadata) => meta.elementMetadataReg.code === code);
      if (metas.length > 0) {
        this.mapMetaDate[metas[0].elementMetadataReg.id] = new LocalDate(date.year, date.month, date.day);
        metas[0].value = value.format('YYYY-MM-DD');
      }
    } else {
      this.errors[code] = this.translateSrv.instant('generic.message.date-format-error');
    }
  }

  getDatePickerValue(meta: DmsElementMetadata): LocalDate {
    if (isNullOrUndefined(meta.elementMetadataReg) || isNullOrUndefined(meta.elementMetadataReg.code)) {
      return null;
    }

    return this.mapMetaDate[meta.elementMetadataReg.id];
  }

  setMeta(id: number, value: string | number | boolean): void {
    const metas = this.metadata.filter((meta: DmsElementMetadata) => meta.id === id);
    if (metas.length > 0) {
      metas[0].value = value + '';
    }
  }

  onFileNameChange(event: string): void {
    this.fileNameChange.emit(event);
  }

  isString(type: MetadataFieldType): boolean {
    return isStringMetadata(type);
  }

  isBoolean(type: MetadataFieldType): boolean {
    return isBooleanMetadata(type);
  }

  isNumber(type: MetadataFieldType): boolean {
    return isNumberMetadata(type);
  }

  isDate(type: MetadataFieldType): boolean {
    return isDateMetadata(type);
  }

  toLocalDate(date: Date): LocalDate {
    if (isNullOrUndefined(date)) {
      return null;
    }

    return new LocalDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }
}
