import { CdkColumnDef } from '@angular/cdk/table';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { piAdd, piDelete, piDownloadSimple, piMinus, piPen, PwcIconsLibrary } from '@pwc/icons';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ToastUtils } from 'src/app/helpers/toast-utils';
import { ApplicationEvent } from 'src/app/models/event/application-event';
import { Sphere } from 'src/app/models/sphere';
import { DocumentLoaderService } from 'src/app/services/document-loader.service';
import { PageableResponse } from '../../models/api/pageable-response';
import { SphereResource } from '../../models/api/sphere-resource';
import { ColumnDef } from '../../models/common/data-table/column-def';
import { DmsElement } from '../../models/dms-element';
import { SphereService } from '../../services/sphere.service';
import { DataTableComponent } from '../common/data-table/data-table.component';


@Component({
  selector: 'app-vdr',
  templateUrl: './vdr.component.html',
  styleUrls: ['./vdr.component.scss']
})
export class VdrComponent implements OnInit, AfterViewChecked {

  data: SphereResource[] = [];
  columns: ColumnDef<ApplicationEvent>[] = [];
  customColumnsActive: string[] = [];
  deleting: boolean = false;
  selectedResults: SphereResource[] = [];
  loadingDocuments$: Observable<boolean> = this.documentLoaderService.loading$;
  viewChecked = false;

  loaded: Boolean = false;
  public vdrAlertSuccess: boolean = false;
  currentActionPerformedOnSociety = '';
  addOrRemoveAction = '';

  @ViewChild('dataTable') dataTable: DataTableComponent<DmsElement>;
  @ViewChildren(CdkColumnDef) customColumns: QueryList<CdkColumnDef>;

  constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef,
    private sphereService: SphereService, private iconsLibrary: PwcIconsLibrary, private translateSrv: TranslateService, private toast: ToastrService, private documentLoaderService: DocumentLoaderService<Sphere>) {
    iconsLibrary.registerIcons([
      piDelete,
      piDownloadSimple,
      piPen,
      piAdd,
      piMinus
    ]);
  }

  ngOnInit(): void {

    this.documentLoaderService.startLoader();

    this.refreshSpheres();
  }

  refreshSpheres() {
    this.loaded = false;
    this.data = [];
    this.documentLoaderService.startLoader();
    return this.getVDRspheres()
    .subscribe((res: any) => {
      if (res) {
        this.updateElements(res);
        this.documentLoaderService.commit(res || []);
        this.loaded = true;
      } else {
        this.updateElements([]);
      }
    }), (err: Error) => {
      this.toast.error(this.translateSrv.instant('generic.message.unexpected-error'), this.translateSrv.instant(ToastUtils.TITLE_ERROR));
      this.data = this.documentLoaderService.rollback();
    };
  }

  private updateElements(elements: SphereResource[]) {
    this.data = [...elements] || [];
    this.refreshColumns();
  }

  private refreshColumns() {
    let columns = [];
    columns.push('society');
    columns.push('society_vat');
    columns.push('vdr');
    columns.push('actions');
    this.customColumnsActive = columns;
  }

  private getVDRspheres(): Observable<SphereResource[]> {
    return this.sphereService.getOwnerSpheres();
  }

  ngAfterViewChecked(): void {
    this.viewChecked = true;
    this.changeDetectorRef.detectChanges();
  }

  admitSociety(item: SphereResource){
    this.sphereService.admitSocietyToVDR(item).subscribe(
      (actionConfirm: boolean) => {
        this.currentActionPerformedOnSociety = item.societyName;
        if(actionConfirm) {
          this.vdrAlertSuccess = true;
          this.addOrRemoveAction = 'add';
          this.refreshSpheres();
        } else {
          this.toast.error(this.translateSrv.instant('generic.message.unexpected-error'), this.translateSrv.instant(ToastUtils.TITLE_ERROR));
        }
      }
    );
  }

  removeSociety(item: SphereResource){
    this.sphereService.removeSocietyFromVDR(item).subscribe(
      (actionConfirm: boolean) => {
        this.currentActionPerformedOnSociety = item.societyName;
        if(actionConfirm) {
          this.vdrAlertSuccess = true;
          this.addOrRemoveAction = 'remove';
          this.refreshSpheres();
        } else {
          this.toast.error(this.translateSrv.instant('generic.message.unexpected-error'), this.translateSrv.instant(ToastUtils.TITLE_ERROR));
        }
      }
    );
  }

}
