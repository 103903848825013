import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { matches, SearchResult, sort, State } from '../../helpers/common/list-helper';
import { SortDirectionEnum } from '../../enums/common/sort-direction.enum';
import { LocalDate } from '../../models/local-date.model';
import { NotificationModel } from '../../models/common/notifications/notification-model';

@Injectable({ providedIn: 'root' })
export class ListServiceHelper<T> {

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _list$ = new BehaviorSubject<T[]>([]);
  private _filteredList$ = new BehaviorSubject<T[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private _state: State;

  list: T[];
  subscriptions: any[] = [];

  constructor(protected http: HttpClient,
    protected pipe: DecimalPipe) {
    this._state = {
      page: 1,
      pageSize: 10, // isNullOrUndefined(pageSizeParam) ? 10 : pageSizeParam,
      searchTerm: '',
      searchID: '',
      searchType: 0,
      searchSeason: null,
      searchCompany: 0,
      searchName: '',
      searchStatus: null,
      searchDefault: '',
      sortColumn: '',
      searchYearVal: null,
      searchInfoSet: 0,
      searchInfoSetTemplate: 0,
      searchProgress: null,
      searchDateFrom: null,
      searchDateTo: null,
      searchReminderDateFrom: null,
      searchReminderDateTo: null,
      searchPeriod: '',
      searchRole: '',
      searchTraceActivity: '',
      searchTraceProcess: '',
      sortDirection: SortDirectionEnum.NONE
    };
  }

  unsubscribeAll() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  // getter methods
  get list$() {
    return this._list$.asObservable();
  }

  get filteredList$() {
    return this._filteredList$.asObservable();
  }

  get total$() {
    return this._total$.asObservable();
  }

  get loading$() {
    return this._loading$.asObservable();
  }

  get search$() {
    return this._search$.asObservable();
  }

  get page() {
    return this._state.page;
  }

  get pageSize() {
    return this._state.pageSize;
  }

  get searchTerm() {
    return this._state.searchTerm;
  }

  get searchDefeault() {
    return this._state.searchDefault;
  }

  get searchID() {
    return this._state.searchID;
  }

  get searchType() {
    return this._state.searchType;
  }

  get searchName() {
    return this._state.searchName;
  }

  get searchStatus() {
    return this._state.searchStatus;
  }

  get searchCompany() {
    return this._state.searchCompany;
  }

  get searchSeason() {
    return this._state.searchSeason;
  }

  get searchYearVal() {
    return this._state.searchYearVal;
  }

  get searchInfoSetTemplate() {
    return this._state.searchInfoSetTemplate;
  }

  get searchInfoSet() {
    return this._state.searchInfoSet;
  }

  get searchProgress() {
    return this._state.searchProgress;
  }

  get searchDateFrom() {
    return this._state.searchDateFrom;
  }

  get searchDateTo() {
    return this._state.searchDateTo;
  }

  get searchReminderDateFrom() {
    return this._state.searchReminderDateFrom;
  }

  get searchReminderDateTo() {
    return this._state.searchReminderDateTo;
  }

  get searchPeriod() {
    return this._state.searchPeriod;
  }

  get searchRole() {
    return this._state.searchRole;
  }

  get searchTraceProcess() {
    return this._state.searchTraceProcess;
  }

  get searchTraceActivity() {
    return this._state.searchTraceActivity;
  }

  // setter methods
  set page(page: number) {
    this._set({ page });
  }

  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }

  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }

  set searchDefault(searchDefault: string) {
    this._set({ searchDefault });
  }

  set searchID(searchID: string) {
    this._set({ searchID });
  }

  set searchType(searchType: number) {
    this._set({ searchType });
  }

  set searchName(searchName: string) {
    this._set({ searchName });
  }

  set searchStatus(searchStatus: string) {
    this._set({ searchStatus });
  }

  set searchCompany(searchCompany: number) {
    this._set({ searchCompany });
  }

  set searchSeason(searchSeason: number) {
    this._set({ searchSeason });
  }

  set sortColumn(sortColumn: string) {
    this._set({ sortColumn });
  }

  set sortDirection(sortDirection: SortDirectionEnum) {
    this._set({ sortDirection });
  }

  set searchYearVal(searchYearVal: boolean) {
    this._set({ searchYearVal });
  }

  set searchInfoSetTemplate(searchInfoSetTemplate: number) {
    this._set({ searchInfoSetTemplate });
  }

  set searchInfoSet(searchInfoSet: number) {
    this._set({ searchInfoSet });
  }

  set searchProgress(searchProgress: boolean) {
    this._set({ searchProgress });
  }

  set searchDateFrom(searchDateFrom: LocalDate) {
    this._set({ searchDateFrom });
  }

  set searchDateTo(searchDateTo: LocalDate) {
    this._set({ searchDateTo });
  }

  set searchReminderDateFrom(searchReminderDateFrom: LocalDate) {
    this._set({ searchReminderDateFrom });
  }

  set searchReminderDateTo(searchReminderDateTo: LocalDate) {
    this._set({ searchReminderDateTo });
  }

  set searchPeriod(searchPeriod: string) {
    this._set({ searchPeriod });
  }

  set searchRole(searchRole: string) {
    this._set({ searchRole });
  }

  set searchTraceProcess(searchTraceProcess: string) {
    this._set({ searchTraceProcess });
  }

  set searchTraceActivity(searchTraceActivity: string) {
    this._set({ searchTraceActivity });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm, searchID, searchName, searchSeason, searchCompany, searchType, searchStatus, searchDefault, searchYearVal, searchInfoSetTemplate, searchInfoSet, searchProgress, searchDateFrom, searchDateTo, searchPeriod, searchReminderDateFrom, searchReminderDateTo, searchRole, searchTraceProcess, searchTraceActivity } = this._state;

    // 1. sort
    let list = sort(this.list, sortColumn, sortDirection);

    // 2. filter
    list = list.filter(field => matches(field, searchTerm, this.pipe));

    if (!isNullOrUndefined(searchCompany) && searchCompany !== 0) {
      list = list.filter(field => field.societyId === searchCompany);
    }

    if (!isNullOrUndefined(searchName) && searchName !== '') {
      if (list.length > 0 && list[0] instanceof NotificationModel) {
        list = list.filter(field => !isNullOrUndefined(field.description) && field.description.toLowerCase().includes(searchName.toLowerCase()));
      } else {
        list = list.filter(field => !isNullOrUndefined(field.name) && field.name.toLowerCase().includes(searchName.toLowerCase()));
      }
    }

    if (!isNullOrUndefined(searchStatus) && searchStatus !== '') {
      list = list.filter(field => field.status === searchStatus);
    }

    if (!isNullOrUndefined(searchSeason)) {
      list = list.filter(field => field.seasonId === searchSeason);
    }

    if (!isNullOrUndefined(searchID) && searchID !== '') {
      list = list.filter(field => (field.id + '').toLowerCase() === searchID.toLowerCase());
    }

    if (!isNullOrUndefined(searchDefault) && searchDefault !== null && searchDefault !== '') {
      if (list.length > 0 && list[0] instanceof NotificationModel) {
        list = list.filter(field => field.isRead === searchDefault);
      }
    }

    if (!isNullOrUndefined(searchProgress)) {
      if (searchProgress) {
        list = list.filter(field => field.completed === 1);
      } else {
        list = list.filter(field => field.completed === 0);
      }
    }

    if (!isNullOrUndefined(searchPeriod) && searchPeriod !== '') {
      list = list.filter(field => field.period.toLowerCase().includes(searchPeriod.toLowerCase()));
    }

    if (!isNullOrUndefined(searchDateFrom)) {
      const date = new Date(searchDateFrom.year, searchDateFrom.month - 1, searchDateFrom.day, 0, 0, 0, 0);
    }

    if (!isNullOrUndefined(searchDateTo)) {
      const date = new Date(searchDateTo.year, searchDateTo.month - 1, searchDateTo.day, 23, 59, 59, 0);
    }

    if (!isNullOrUndefined(searchReminderDateFrom)) {
      const date = new Date(searchReminderDateFrom.year, searchReminderDateFrom.month - 1, searchReminderDateFrom.day, 0, 0, 0, 0);
      list = list.filter(field => {
        return field.reminder >= date;
      });
    }

    if (!isNullOrUndefined(searchReminderDateTo)) {
      const date = new Date(searchReminderDateTo.year, searchReminderDateTo.month - 1, searchReminderDateTo.day, 23, 59, 59, 0);
      list = list.filter(field => field.reminder <= date);
    }

    const total = list.length;
    const filteredList = JSON.parse(JSON.stringify(list));

    // 3. paginate
    list = list.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ list, filteredList, total });
  }

  public updateList(list: T[], sortColumn?: string, sortDirection?: SortDirectionEnum) {
    this.list = list;
    this._state.sortColumn = isNullOrUndefined(sortColumn) ? 'id' : sortColumn;
    this._state.sortDirection = isNullOrUndefined(sortDirection) ? SortDirectionEnum.ASC : sortDirection;
    const sub = this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._list$.next(result.list);
      this._filteredList$.next(result.filteredList);
      this._total$.next(result.total);
    });
    this.subscriptions.push(sub);
    this._search$.next();
  }
}
