import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {forwardRef} from '@angular/core';

export abstract class AbstractValueAccessor<T> implements ControlValueAccessor {
  accessorValue: T;
  accessorDisabled: boolean = false;

  protected onChange = (_) => {
  };
  protected onTouched = () => {
  };

  get value(): T {
    return this.accessorValue;
  }

  set value(v: T) {
    if (v !== this.accessorValue) {
      this.accessorValue = v;
      this.onChange(v);
    }
  }

  registerOnChange(fn: (_: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.accessorDisabled = isDisabled;
  }

  writeValue(obj: T): void {
    // NOTE: Customize logic if you want to trigger onChange only on user intervention.
    this.value = obj;
  }
}

export function MakeProvider(type: any) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => type),
    multi: true
  };
}
