<ng-template #first><span aria-hidden="true" i18n="@@ngb.pagination.first">&laquo;&laquo;</span></ng-template>
<ng-template #previous><span aria-hidden="true" i18n="@@ngb.pagination.previous"><fa-icon
  [icon]="faAngleLeft"></fa-icon></span></ng-template>
<ng-template #next><span aria-hidden="true" i18n="@@ngb.pagination.next"><fa-icon
  [icon]="faAngleRight"></fa-icon></span></ng-template>
<ng-template #last><span aria-hidden="true" i18n="@@ngb.pagination.last">&raquo;&raquo;</span></ng-template>
<ng-template #ellipsis>...</ng-template>
<ng-template #defaultNumber let-page let-currentPage="currentPage">
  {{ page }}
  <span *ngIf="page === currentPage" class="sr-only">(current)</span>
</ng-template>
<ul [class]="'pagination' + (size ? ' pagination-' + size : '')">
  <li *ngIf="boundaryLinks" class="page-item"
      [class.disabled]="previousDisabled()">
    <a aria-label="First" i18n-aria-label="@@ngb.pagination.first-aria" class="page-link" href
       (click)="selectPage(1); $event.preventDefault()" [attr.tabindex]="(hasPrevious() ? null : '-1')">
      <ng-template [ngTemplateOutlet]="tplFirst?.templateRef || first"
                   [ngTemplateOutletContext]="{disabled: previousDisabled(), currentPage: page}"></ng-template>
    </a>
  </li>
  <li *ngIf="directionLinks" class="page-item"
      [class.disabled]="previousDisabled()">
    <a aria-label="Previous" i18n-aria-label="@@ngb.pagination.previous-aria" class="page-link" href
       (click)="selectPage(page-1); $event.preventDefault()" [attr.tabindex]="(hasPrevious() ? null : '-1')">
      <ng-template [ngTemplateOutlet]="tplPrevious?.templateRef || previous"
                   [ngTemplateOutletContext]="{disabled: previousDisabled()}"></ng-template>
    </a>
  </li>
  <li class="page-item page-number-selector">
    <form-input type="number" id="page-number" cssClass="page-number-input" [(ngModel)]="tmpPage"
                (keyup.enter)="applyPageChange()" (blur)="resetPageChange()"></form-input>
  </li>
  <li class="page-item disabled">
    <a class="page-link">
      di {{pageCount}}
    </a>
  </li>
  <!--  <li *ngFor="let pageNumber of pages" class="page-item" [class.active]="pageNumber === page"-->
  <!--      [class.disabled]="isEllipsis(pageNumber) || disabled">-->
  <!--    <a *ngIf="isEllipsis(pageNumber)" class="page-link">-->
  <!--      <ng-template [ngTemplateOutlet]="tplEllipsis?.templateRef || ellipsis"-->
  <!--                   [ngTemplateOutletContext]="{disabled: true, currentPage: page}"></ng-template>-->
  <!--    </a>-->
  <!--    <a *ngIf="!isEllipsis(pageNumber)" class="page-link" href (click)="selectPage(pageNumber); $event.preventDefault()">-->
  <!--      <ng-template [ngTemplateOutlet]="tplNumber?.templateRef || defaultNumber"-->
  <!--                   [ngTemplateOutletContext]="{disabled: disabled, $implicit: pageNumber, currentPage: page}"></ng-template>-->
  <!--    </a>-->
  <!--  </li>-->
  <li *ngIf="directionLinks" class="page-item" [class.disabled]="nextDisabled()">
    <a aria-label="Next" i18n-aria-label="@@ngb.pagination.next-aria" class="page-link" href
       (click)="selectPage(page+1); $event.preventDefault()" [attr.tabindex]="(hasNext() ? null : '-1')">
      <ng-template [ngTemplateOutlet]="tplNext?.templateRef || next"
                   [ngTemplateOutletContext]="{disabled: nextDisabled(), currentPage: page}"></ng-template>
    </a>
  </li>
  <li *ngIf="boundaryLinks" class="page-item" [class.disabled]="nextDisabled()">
    <a aria-label="Last" i18n-aria-label="@@ngb.pagination.last-aria" class="page-link" href
       (click)="selectPage(pageCount); $event.preventDefault()" [attr.tabindex]="(hasNext() ? null : '-1')">
      <ng-template [ngTemplateOutlet]="tplLast?.templateRef || last"
                   [ngTemplateOutletContext]="{disabled: nextDisabled(), currentPage: page}"></ng-template>
    </a>
  </li>
</ul>
