<div class="card-table light" *ngIf="viewChecked && elements">
  <data-table #dataTable [dataSource]="elements" [customColumnDefs]="customColumns"
              [customColumns]="customColumnsActive">
    <ng-container cdkColumnDef="name">
      <th cdk-header-cell *cdkHeaderCellDef scope="col">{{'generic.field.name'|translate}}</th>
      <td cdk-cell *cdkCellDef="let row">
        {{row.element.name}}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="fiscalYear">
      <th cdk-header-cell *cdkHeaderCellDef scope="col">{{'generic.field.fiscal-year'|translate}}</th>
      <td cdk-cell *cdkCellDef="let row">
        {{row.fiscalYear}}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="path">
      <th cdk-header-cell *cdkHeaderCellDef scope="col">{{'generic.field.path'|translate}}</th>
      <td cdk-cell *cdkCellDef="let row">
        {{row.element.path}}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="remove">
      <th cdk-header-cell *cdkHeaderCellDef scope="col" style="width: 30px"></th>
      <td cdk-cell *cdkCellDef="let row">
        <button placement="top" [ngbTooltip]="'generic.action.remove'|translate" type="button" class="btn btn-danger btn-sm btn-action-icon"
                (click)="onRemove(row)">
          <fa-icon [icon]="faTimes" [classes]="['text-danger']"></fa-icon>
        </button>
      </td>
    </ng-container>
  </data-table>
</div>
