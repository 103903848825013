<ngx-smart-modal identifier="downloadModal" customClass="modal-content p-0" [force]="false">
  <div class="modal-header">
    <h5 class="modal-title text-uppercase" style="height:20px;vertical-align:top;margin-right:.5rem;">
      <pwc-icon name="download"></pwc-icon>
      {{'documents.download.document'|translate}}
    </h5>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <div class="download-choice download-standard trigger-hover" (click)="onDownloadStandard()">
          <img src="/assets/images/icons/documents/download/metadata.svg"
            [alt]="'documents.download.standard-document'|translate" class="download-choice-icon hidden-hover">
          <img src="/assets/images/icons/documents/download/metadata-hover.svg"
            [alt]="'documents.download.standard-document'|translate" class="download-choice-icon visible-hover">
          {{'documents.download.standard-document'|translate}}
        </div>
      </div>
      <div class="col">
        <div class="download-choice download-law-conservation trigger-hover" (click)="onDownloadConservation()">
          <img src="/assets/images/icons/documents/download/conservazione_a_norma.svg"
            [alt]="'documents.download.memorized-document'|translate" class="download-choice-icon hidden-hover">
          <img src="/assets/images/icons/documents/download/conservazione_a_norma-hover.svg"
            [alt]="'documents.download.memorized-document'|translate" class="download-choice-icon visible-hover">
          {{'documents.download.memorized-document'|translate}}
        </div>
      </div>
    </div>
  </div>
</ngx-smart-modal>