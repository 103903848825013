import { ChangeDetectorRef, Directive, ElementRef } from '@angular/core';
import { FileDropDirective } from '../../modules/file-upload/file-drop.directive';

@Directive({
  selector: '[pwcDirectoryDrop]'
})
export class PwcDirectoryDropDirective extends FileDropDirective {
  constructor(element: ElementRef<any>,
    protected changeDetector: ChangeDetectorRef) {
    super(element);
  }

  onDrop(event: any): void {
    const transfer = this._getTransfer(event);
    if (!transfer) {
      return;
    }

    const options = this.getOptions();
    const filters = this.getFilters();
    this._preventAndStop(event);

    this.manageDropEvent(transfer, options, filters);
    this.fileOver.emit(false);
    this.onFileDrop.emit(transfer.files);
  }

  protected manageDropEvent(transfer, options, filters) {
    for (const item of transfer.items) {
      if (typeof item.webkitGetAsEntry !== 'function') {
        console.warn('webkitGetAsEntry feature not supported, cannot handle upload of directory.');

        this.uploader.addToQueue(transfer.files, options, filters);
        return;
      }

      const entry = item.webkitGetAsEntry();
      if (entry) {
        this.traverseFileTree(entry, options, filters);
      }
    }
  }

  protected traverseFileTree(item, options, filters) {
    if (item.isFile) {
      const fullPath = item.fullPath;
      const isDirUpload = true;
      item.file((file: File) => {
        const opts = {
          ...options,
          additionalParameter: {
            fullPath,
            isDirUpload
          }
        };
        // console.debug('options', opts);
        this.uploader.addToQueue([file], opts, filters);
        this.changeDetector.detectChanges();
      });
    } else if (item.isDirectory) {
      const dirReader = item.createReader();
      dirReader.readEntries((entries) => {
        for (const entry of entries) {
          this.traverseFileTree(entry, options, filters);
        }
      });
    }
  }
}
