import {Component, Input, OnInit} from '@angular/core';
import {Size} from 'ngx-spinner/lib/ngx-spinner.enum';

@Component({
  selector: 'pwc-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Input() name: string;
  @Input() fullscreen: boolean = false;
  @Input() size: Size = 'default';
  @Input() color: string = '#fff';

  constructor() {
  }

  ngOnInit() {
  }

}
