import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {DmsElement} from '../models/dms-element';

@Injectable({
  providedIn: 'root'
})
export class DocumentLoaderService<T> {
  private loadingSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this.loadingSubject.asObservable();

  private previousState: T[] = [];

  public startLoader(): void {
    this.loadingSubject.next(true);
  }

  public commit(state: T[]): void {
    this.previousState = state;
    this.loadingSubject.next(false);
  }

  public rollback(): T[] {
    this.loadingSubject.next(false);
    return this.previousState;
  }
}
