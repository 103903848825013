import {Injectable} from '@angular/core';
import {ApiResourceConverter} from '../contracts/common/api-resource-converter';
import {DmsElementResource} from '../models/api/documents/dms-element-resource';
import {DmsElement} from '../models/dms-element';
import {FiscalYear} from '../models/fiscalYear';
import {FiscalYearResource} from '../models/api/fiscal-year-resource';
import {ConnectionEntry} from '../models/connection/connection-entry';
import {AttachmentEntry} from '../models/attachment/attachment-entry';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService implements ApiResourceConverter<DmsElementResource, DmsElement> {
  public convertElementToAttachmentEntry(element: DmsElement, year: FiscalYear | FiscalYearResource = new FiscalYear()): AttachmentEntry {
    const entry = new AttachmentEntry();
    entry.elementId = element.id;
    entry.element = element;
    entry.fiscalYear = year.year;
    entry.fiscalYearId = year.id;
    return entry;
  }

  public convertElementsListToAttachmentEntries(elements: DmsElement[], year: FiscalYear | FiscalYearResource = new FiscalYear()): AttachmentEntry[] {
    return elements.map((element: DmsElement) => this.convertElementToAttachmentEntry(element, year));
  }

  convertListToModel(list: DmsElementResource[]): DmsElement[] {
    return [];
  }

  convertListToResource(list: DmsElement[]): DmsElementResource[] {
    return [];
  }

  convertModelToResource(model: DmsElement): DmsElementResource {
    return undefined;
  }

  convertResourceToModel(resource: DmsElementResource): DmsElement {
    return undefined;
  }
}
