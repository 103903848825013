<div ngbDropdown class="d-inline-block" [open]="open" (openChange)="onClose($event)">
  <a href="javascript:;" id="sphere-selector-toggle" ngbDropdownToggle *ngIf="currentSphere$ | async">
    {{(currentSphere$ | async).name ? (currentSphere$ | async).name : noName}}
  </a>
  <div ngbDropdownMenu aria-labelledby="sphere-selector-toggle" id="sphere-selector-dropdown">
    <div class="px-3 pt-2 pb-3">
      <search-input #searchInputComponent [placeholder]="'generic.placeholder.search-company'|translate" [(ngModel)]="searchTerm"
                    (ngModelChange)="onSearch()"></search-input>
    </div>
    <div ngbDropdownItem *ngFor="let sphere of ownedSpheres" class="sphere-selector-item px-3"
         (click)="changeSphere(sphere)" [ngClass]="{active: sphere.id === (currentSphere$ | async).id}">
      <div class="row">
        <div class="col-auto">
          <baloon [content]="sphere.name | initials | uppercase" [color]="sphere.sphereColor"></baloon>
        </div>
        <div class="col d-flex align-items-center" style="overflow-y: hidden;" [title]="sphere.name">
          <div class="sphere-name" style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">{{sphere.name}}</div>
        </div>
      </div>
    </div>
    <h6 class="dropdown-header text-uppercase" *ngIf="sharedSpheres.length > 0">{{'sphere.shared-with-me'|translate}}</h6>
    <div ngbDropdownItem *ngFor="let sphere of sharedSpheres" class="sphere-selector-item px-3"
         (click)="changeSphere(sphere)" [ngClass]="{active: sphere.id === (currentSphere$ | async).id}">
      <div class="row">
        <div class="col-auto d-flex align-items-center">
          <baloon [content]="sphere.name | initials | uppercase" [color]="sphere.sphereColor"></baloon>
        </div>
        <div class="col d-flex flex-column justify-content-center" style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">
          <div class="sphere-name">{{sphere.name}}</div>
          <div class="sphere-owner text-muted">{{'sphere.owner'|translate}} {{sphere.owner}}</div>
        </div>
      </div>
    </div>
    <a routerLink="/legal-entity" (click)="onClose(false)"><h6 class="dropdown-header text-uppercase">{{'sphere.see-all'|translate}}</h6>
    </a>
  </div>
</div>
