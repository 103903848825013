<pwc-modal #errorModal id="errorModal" (closed)="redirectToDocuments()">
  <div modal-title>{{'documents.component.error-title'|translate}}</div>
  <div modal-body>
    {{'download.not-available'|translate}}
  </div>
  <div modal-footer class="text-right">
    <button type="button" class="btn btn-secondary" (click)="errorModal.close()">
      {{'generic.action.close'|translate}}
    </button>
  </div>
</pwc-modal>
