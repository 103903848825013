import {ApiResource} from '../common/base/api-resource';
import {SphereUserRelationEnum} from '../../enums/sphere-user-relation.enum';
import {Sphere2FiscalYearResource} from './sphere2-fiscal-year-resource';
import {UserResource} from './user-resource';

export class SphereResource extends ApiResource {
  name: string;
  userId?: number;
  user?: UserResource;
  ownerUserId: number;
  owner?: string;
  sphereUserRelation: SphereUserRelationEnum;
  societyId?: number;
  societyName?: string;
  societyVatNumber?: string;
  fiscalYears?: Sphere2FiscalYearResource[];
  page?: number;
  sort?: string;
  vdr?: boolean;
}
