import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ModalComponent} from '../../common/modals/modal/modal.component';
import {ConnectionEntry} from '../../../models/connection/connection-entry';
import {ConnectionElementType} from '../../../enums/connection-element-type.enum';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-start-connection-modal',
  templateUrl: './start-connection-modal.component.html',
  styleUrls: ['./start-connection-modal.component.scss']
})
export class StartConnectionModalComponent {
  @Input() elements: ConnectionEntry[] = [];
  @Input() connectionType: ConnectionElementType;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() continue: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('startConnectionModal') modal: ModalComponent;

  open(): void {
    this.modal.open();
  }

  close(): void {
    this.modal.close();
  }

  onCancel(): void {
    this.cancel.emit();
  }

  onContinue(): void {
    this.continue.emit();
    this.modal.close();
  }

  onConfirm(): void {
    this.confirm.emit();
  }

  get elementsSize(): number {
    return this.elements.length;
  }

  get canConfirm(): boolean {
    return !isNullOrUndefined(this.connectionType) &&
      this.connectionType === ConnectionElementType.GROUP && this.elementsSize > 1;
  }

  get isAlias(): boolean {
    return !isNullOrUndefined(this.connectionType) && this.connectionType === ConnectionElementType.ALIAS;
  }
}
