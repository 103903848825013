import {ApiResource} from '../../common/base/api-resource';
import {NotificationRegistryResource} from './notification-registry-resource';

export class NotificationResource extends ApiResource {
  cause: string;
  date: any;
  notificationRegistry: NotificationRegistryResource;
  isRead: string;
  name: string;
  description: string;
  translation: object;
}
