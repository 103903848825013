import {ApiResource} from '../../common/base/api-resource';
import {ConnectionElementEntryResource} from './connection-element-entry-resource';
import {ConnectionElementType} from '../../../enums/connection-element-type.enum';

export class ConnectionElementResource extends ApiResource {
  sphereId: number;
  elements: ConnectionElementEntryResource[];
  idDestinationFolder: number;
  fiscalYear: number;
  fiscalYearId: number;
  type: ConnectionElementType;
}
