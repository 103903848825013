import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Comment} from '../../models/comment/comment';
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import {DmsFileRepositoryService} from '../../services/dms-file-repository.service';
import {ToastUtils} from "../../helpers/toast-utils";
import {ToastrService} from "ngx-toastr";
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnDestroy {

  @Input() commentList: Comment[];
  @Input() fileId: number = null;

  collapsed: boolean = true;

  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;

  text: string = null;

  private postCommentSubscription : Subscription;
  private searchCommentSubscription : Subscription;

  constructor(
    private dmsFileRepositoryService: DmsFileRepositoryService,
    private toast: ToastrService,
    private translateSrv: TranslateService
  ) { }
  
  ngOnDestroy(): void {
    this.postCommentSubscription?.unsubscribe();
    this.searchCommentSubscription?.unsubscribe();
  }

  ngOnInit(): void {
  }

  addNote(): void {
    this.postCommentSubscription = this.dmsFileRepositoryService.postComment({
      text: this.text,
      fileId: this.fileId
    }).subscribe(() => {
        this.text = null;
        this.searchCommentSubscription = this.dmsFileRepositoryService.searchComments(this.fileId)
         .subscribe((res) => {
         this.commentList = [...res];
       });
      }
    , error => {
        this.toast.error(this.translateSrv.instant('generic.message.error-saving'), this.translateSrv.instant(ToastUtils.TITLE_ERROR));
      }
    );
  }

}
