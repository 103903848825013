import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpService} from '../common/http.service';
import {LawConservation} from '../../models/law-conservation/law-conservation';
import {LawConservationResource} from '../../models/api/law-conservation/law-conservation-resource';
import {LawConservationConfigurationResource} from '../../models/api/law-conservation/law-conservation-configuration-resource';
import {LawConservationConfigurationResponce} from '../../models/api/law-conservation/law-conservation-configuration-responce';
import {DmsElement} from '../../models/dms-element';
import {LawConservationElementsConfigurationResource} from '../../models/api/law-conservation/law-conservation-elements-configuration-resource';
import {isNullOrUndefined} from 'util';
import {HttpParams} from '@angular/common/http';
import {LawConservationVersion} from '../../models/law-conservation/law-conservation-version';
import {DmsRepositoryResponse} from '../../models/api/documents/dms-repository-response';
import {PageableResponse} from '../../models/api/pageable-response';
import {DmsElementResource} from '../../models/api/documents/dms-element-resource';

@Injectable({
  providedIn: 'root'
})
export class LawConservationService extends HttpService<LawConservationResource, LawConservation> {
  protected endpoint = 'law-conservation';

  public configurationList(lawConservationConfigurationResource: LawConservationConfigurationResource, fiscalYear: number):
                           Observable<LawConservationConfigurationResponce> {
    let params = new HttpParams();
    if (!isNullOrUndefined(lawConservationConfigurationResource)) {
      for (const key of Object.getOwnPropertyNames(lawConservationConfigurationResource)) {
        params = params.set(key, `${lawConservationConfigurationResource[key]}`);
      }
    }

    return this.get<LawConservationConfigurationResponce>('configuration/year/' + fiscalYear, {params}).pipe(
      map((response: LawConservationConfigurationResponce) => {
        return response;
      }));
  }

  public saveElementConfiguration(configurationId: number, selectedElements: DmsElement[], year: number, sphereId: number): Observable<boolean> {
    const lawConservationElementsConfigurationResource = new LawConservationElementsConfigurationResource();
    for (const dmsElement of selectedElements) {
      lawConservationElementsConfigurationResource.elements.push(dmsElement.id);
    }
    lawConservationElementsConfigurationResource.configurationId = configurationId;
    lawConservationElementsConfigurationResource.year = year;
    lawConservationElementsConfigurationResource.sphereId = sphereId;

    return this.put<boolean>('elements/configuration', lawConservationElementsConfigurationResource).pipe(
      map((response: boolean) => {
        return response;
      }));
  }

  public saveElementNoConservation(selectedElements: DmsElement[], year: number, sphereId: number): Observable<boolean> {
    const lawConservationElementsConfigurationResource = new LawConservationElementsConfigurationResource();
    for (const dmsElement of selectedElements) {
      lawConservationElementsConfigurationResource.elements.push(dmsElement.id);
    }
    lawConservationElementsConfigurationResource.year = year;
    lawConservationElementsConfigurationResource.sphereId = sphereId;

    return this.put<boolean>('elements/exclude', lawConservationElementsConfigurationResource).pipe(
      map((response: boolean) => {
        return response;
      }));
  }

  public sendElementToConservation(selectedElement: DmsElement, year: number): Observable<boolean> {
    return this.post<boolean>('upload-file/' + selectedElement.id + '/year/' + year, null).pipe(
      map((response: boolean) => {
        return response;
      }));
  }

  public sendNowToConservation(selectedElements: DmsElement[], year: number, sphereId: number): Observable<boolean> {
    const lawConservationElementsConfigurationResource = new LawConservationElementsConfigurationResource();
    for (const dmsElement of selectedElements) {
      lawConservationElementsConfigurationResource.elements.push(dmsElement.id);
    }
    lawConservationElementsConfigurationResource.year = year;
    lawConservationElementsConfigurationResource.sphereId = sphereId;

    return this.put<boolean>('elements/send-now', lawConservationElementsConfigurationResource).pipe(
      map((response: boolean) => {
        return response;
      }));
  }

  convertResourceToModel(resource: LawConservationResource): LawConservation {
    const model = new LawConservation();
    Object.assign(model, resource);

    return model;
  }

  convertModelToResource(model: LawConservation): LawConservationResource {
    const resource = new LawConservationResource();
    Object.assign(resource, model);

    return resource;
  }

  getAllVersions(selectedElement: DmsElement, year: number): Observable<LawConservationVersion[]> {
    return this.get<LawConservationVersion[]>('element/' + selectedElement.id + '/year/' + year + '/old-versions').pipe(
      map((response: LawConservationVersion[]) => {
        return response;
      })
    );
  }

}
