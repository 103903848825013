<div class="page-container">

    <app-documents-breadcrumbs [showEdit]="false" componentName="sharing" basePath="Gestione condivisioni">
    </app-documents-breadcrumbs>

    <div class="d-flex flex-row mb-4 align-items end box-filter">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">
                    {{'generic.filters.label'|translate}}
                    <button class="btn btn-primary btn-sm btn-action-icon ml-0 btn-clear-filter" [ngbTooltip]="'generic.filters.clear'|translate" type="button" (click)="emptyFilters()">
            <pwc-icon name="clear"></pwc-icon>
          </button>
                </h5>

                <div class="d-md-flex flex-md-row flex-sm-column flex-wrap">

                    <div class="item form-group-sm form-inline">
                        <label for="filter-term">{{'share.manager.search'|translate}}</label>
                        <search-input id="filter-term" [placeholder]="'generic.field.search'|translate" [(ngModel)]="searchTerm" (ngModelChange)="onSearch()"></search-input>
                    </div>

                    <div class="item form-group-sm form-inline">
                        <label for="filter-user">{{'generic.field.user'|translate}}</label>
                        <ng-select appendTo="body" id="filter-user" style="min-width: 200px; max-width: 250px;" [clearable]="true" [(ngModel)]="searchUser" (ngModelChange)="onSearch()">
                            <ng-option [value]="user.userId" *ngFor="let user of userList">
                                {{user.email}}
                            </ng-option>
                        </ng-select>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="card" *ngIf="data && viewChecked">
        <div class="card-body card-table">
            <data-table #dataTable [dataSource]="data" [columns]="columns" [customColumnDefs]="customColumns" [customColumns]="customColumnsActive" [enableSelect]="false" (sort)="onSort($event)">

                <ng-container cdkColumnDef="status">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col" sortable="flagValid" (sort)="onSort($event)">
                        {{'generic.field.state'|translate}}
                        <pwc-sorting-arrows [selected]="sortType == 'flagValid'" [direction]="sortReverse"></pwc-sorting-arrows>
                    </th>
                    <td cdk-cell *cdkCellDef="let row">
                        <div *ngIf="row.flagValid" style="white-space:nowrap;">
                            <img src="/assets/images/icons/positive.svg" class="outcome-true">
                            <span>{{'share.manager.active'|translate}}</span>
                        </div>
                        <div *ngIf="!row.flagValid" style="white-space:nowrap;">
                            <img src="/assets/images/icons/negative.svg" class="outcome-false">
                            <span>{{'share.manager.deactivate'|translate}}</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="share_date">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col" sortable="sharingDate" (sort)="onSort($event)">
                        {{'share.manager.sharing-date'|translate}}
                        <pwc-sorting-arrows [selected]="sortType == 'sharingDate'" [direction]="sortReverse"></pwc-sorting-arrows>
                    </th>
                    <td cdk-cell *cdkCellDef="let row">
                        {{row.sharingDate|date:'dd/MM/yyyy'}}
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="update_date">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col" sortable="lastModifiedDate" (sort)="onSort($event)">
                        {{'share.manager.update-date'|translate}}
                        <pwc-sorting-arrows [selected]="sortType == 'lastModifiedDate'" [direction]="sortReverse">
                        </pwc-sorting-arrows>
                    </th>
                    <td cdk-cell *cdkCellDef="let row">
                        {{row.lastModifiedDate|date:'dd/MM/yyyy'}}
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="validity_date">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col" sortable="validityStartDate" (sort)="onSort($event)">
                        {{'share.manager.validation-date'|translate}}
                        <pwc-sorting-arrows [selected]="sortType == 'validityStartDate'" [direction]="sortReverse">
                        </pwc-sorting-arrows>
                    </th>
                    <td cdk-cell *cdkCellDef="let row">
                        <span *ngIf="row.alwaysValid">{{'share.manager.always-valid'|translate}}</span>
                        <span *ngIf="!row.alwaysValid"> {{row.validityStartDate|date:'dd/MM/yyyy'}}
              - {{row.validityEndDate|date:'dd/MM/yyyy'}}</span>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="permission">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col">{{'share.manager.permission'|translate}}</th>
                    <td cdk-cell *cdkCellDef="let row">
                        {{getSharingPermission(row)}}
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="shared_with">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col">{{'share.manager.invited-user'|translate}}</th>
                    <td cdk-cell *cdkCellDef="let row">
                        {{getSharingUsers(row)}}
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="details">
                    <th cdk-header-cell *cdkHeaderCellDef scope="col" style="width: 120px;"></th>
                    <td cdk-cell *cdkCellDef="let row" class="text-right">
                        <pwc-icon name="pen" [ngbTooltip]="'generic.action.edit-item'|translate" placement="left" (click)="openSharingModal(row)" class="document-info-icon"></pwc-icon>
                    </td>
                </ng-container>

            </data-table>
        </div>
    </div>

    <div class="my-4">
        <pwc-pagination *ngIf="pagination" [page]="pagination.number + 1" [pageSize]="pagination.size" [collectionSize]="pagination.totalElements" (pageChange)="onPageChange($event)"></pwc-pagination>
    </div>

    <app-share-modal #shareModal (shared)="onPageChange(pagination.number)" (deleted)="onPageChange(0)" [canEdit]="canEdit$ | async"></app-share-modal>
</div>