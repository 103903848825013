import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { Cookie } from '../../models/common/cookie';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private cookieSubject: BehaviorSubject<Cookie> = new BehaviorSubject<Cookie>(JSON.parse(null));
  public cookie: Observable<Cookie> = this.cookieSubject.asObservable();
  public readonly LOGOUT_ENDPOINT = '/users/me/logout';

  private logoutSubject: Subject<void> = new Subject<void>();
  public logout$: Observable<void> = this.logoutSubject.asObservable();

  constructor(private http: HttpClient,
    private cookieService: CookieService,
    private sessionStorageSrv: SessionStorageService,
    private localStorageSrv: LocalStorageService) {
    if (this.cookieService.get('cookie') !== '') {
      this.cookieSubject.next(JSON.parse(this.cookieService.get('cookie')));
    }
  }

  public get cookieValue(): Cookie {
    return this.cookieSubject.value;
  }

  login(username: string, password: string) {
    const payload = new FormData();
    payload.append('username', username);
    payload.append('password', password);
    payload.append('grant_type', 'password');

    const headers = new HttpHeaders({
      Authorization: `Basic dGxzLWNsaWVudC1pZDp0bHMtY2xpZW50LXNlY3JldA==`
    });

    return this.http.post<any>(`${environment.authUrl}/oauth/token`, payload, { headers })
      .pipe(map(res => {
        // login successful if there's a jwt token in the response
        if (res && res.access_token) {
          this.setCookie(res);
        }
        return res;
      }));
  }

  dataUser() {
    return this.http.get<any>(`${environment.authUrl}/users/me`)
      .pipe(map(user => {
        if (user) {
        }
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    this.revokeAuthTokens().subscribe(
      res => {
        this.cookieService.delete('cookie', environment.cookiePath, environment.cookieDomain);
        this.sessionStorageSrv.clear();
        this.localStorageSrv.clear();
        window.location.href = '/login';
      },
      error => {
        this.cookieService.delete('cookie', environment.cookiePath, environment.cookieDomain);
        this.sessionStorageSrv.clear();
        this.localStorageSrv.clear();
        window.location.href = '/login';
        console.log(error, "revokeAuthTokens");
        console.debug('revokeTokens', error);
      }
    );
    
  }

  setToken(token: string): void {
    const cookie = new Cookie();
    cookie.access_token = token;
    this.setCookie(cookie);
  }

  private setCookie(cookie: Cookie): void {
    this.cookieService.set('cookie', JSON.stringify(cookie), 1, environment.cookiePath, environment.cookieDomain);
    this.cookieSubject.next(cookie);
  }

  private revokeAuthTokens(): Observable<boolean> {
    return this.http.post<boolean>(`${environment.authUrl}${this.LOGOUT_ENDPOINT}`, {});
  }
}
