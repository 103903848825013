import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  piAvatar, piDocumentale, piDocumentPage, piHome, piInfo, piOrganizationChart, piShare, piView,
  PwcIconsLibrary, piShieldCheckWhite
} from "@pwc/icons";
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { environment } from '../../../../environments/environment';
import { SphereUserRelationEnum } from '../../../enums/sphere-user-relation.enum';
import { DmsSharing } from '../../../models/share/dms-sharing';
import { Sphere } from '../../../models/sphere';
import { SphereService } from '../../../services/sphere.service';
import { AuthenticationService } from 'src/app/services/common/authentication.service';
import { AuthorizationService } from '@pwc/security';
import { AuthorityCodeEnum } from 'src/app/enums/common/authority-code.enum';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  // @HostListener('mousover', ['$event.target'])
  // mousover(element) {
  //   console.log(element)
  //   if (element.classList.contains('participant-button')) {
  //     return;
  //   }
  //   if (!element.classList.contains('participant-section')) {
  //   }
  // }
  @HostListener('mouseenter') onMouseEnter() {
    this.highlight('yellow');
  }

  active: boolean;
  showDesc: boolean;
  menuCommunicoCollaped: boolean;
  menuArchiveCollaped: boolean;
  permissionLawConservation: boolean = false;
  showPAurl: Boolean = false;
  adminPermission: Boolean = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private sphereService: SphereService,
    private authSrv: AuthorizationService,
    private pwcIconsLibrary: PwcIconsLibrary,
  ) {
    this.pwcIconsLibrary.registerIcons([
      piInfo,
      piView,
      piAvatar,
      piOrganizationChart,
      piDocumentale,
      piHome,
      piDocumentPage,
      piShare,
      piShieldCheckWhite
    ]);
  }

  ngOnInit() {
    this.active = false;
    this.showDesc = true;
    this.menuCommunicoCollaped = true;
    this.menuArchiveCollaped = true;
    this.showPAurl = environment.showPAurl;

    this.subscribeRouteEvents();
    this.subscribeSphereChange();
    this.identifyAdmin();
  }
  highlight(color: string) {
    // console.log(color);
  }

  subscribeRouteEvents(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.startsWith('/archive')) {
          this.menuArchiveCollaped = false;
        }
      }
    });
  }

  subscribeSphereChange(): void {
    this.sphereService.currentSphere.pipe(
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(new DmsSharing());
        }

        if (sphere.sphereUserRelation !== SphereUserRelationEnum.SHARED) {
          const sharing = new DmsSharing();
          sharing.permissionRead = true;
          sharing.permissionDownload = true;
          sharing.permissionUpload = true;
          sharing.permissionReSharing = true;
          sharing.permissionDelete = true;
          sharing.permissionLawConservationAllowed = true;
          sharing.permissionLawConservationDownloadAllowed = true;

          return of(sharing);
        }

        return this.sphereService.getSharingInfo(sphere.id);
      })
    ).subscribe((sharing: DmsSharing) => {
      this.permissionLawConservation = sharing.permissionLawConservationAllowed && environment.showConservation;
    });
  }

  goToCommunico() {
    self.location.href = environment.communicoUrl;
  }

  redirectToPA() {
    let accessToken = JSON.parse(sessionStorage.getItem(SessionName.ACCESS)).access_token;
    let refreshToken = JSON.parse(sessionStorage.getItem(SessionName.REFRESH)).refresh_token;
    window.open(`${environment.paUrl}?token=${accessToken}&refresh_token=${refreshToken}`, '_self');
  }

  identifyAdmin(): void{
    this.authSrv.checkAuthorities(AuthorityCodeEnum.ROLE_DSA_IMPORT).subscribe(permissionFlag => {
      this.adminPermission = permissionFlag;
    });
  }
}

export enum SessionName {
  ACCESS = 'cookie',
  REFRESH = 'refresh'
}