import {Pipe, PipeTransform} from '@angular/core';
import {DmsElement} from '../../models/dms-element';
import {isNullOrUndefined} from 'util';
import {DocumentTypeEnum} from '../../enums/document-type.enum';

@Pipe({
  name: 'fileIcon'
})
export class FileIconPipe implements PipeTransform {

  transform(element: DmsElement): string {
    let iconPath = '';
    if (element.documentType === DocumentTypeEnum.FOLDER) {
      iconPath = '/assets/images/icons/directory.svg';
    } else {
      if (isNullOrUndefined(element.extension)) {
        if (element.crossYear) {
          iconPath = '/assets/images/icons/documents/doc_pluriennale.svg';
        } else {
          iconPath = '/assets/images/icons/documents/doc.svg';
        }
      } else if (element.extension.toLocaleLowerCase().indexOf('doc') > -1 || element.extension.toLocaleLowerCase().indexOf('docx') > -1 ||
        element.extension.toLocaleLowerCase().indexOf('.txt') > -1) {
        if (element.crossYear) {
          iconPath = '/assets/images/icons/documents/doc_pluriennale.svg';
        } else {
          iconPath = '/assets/images/icons/documents/doc.svg';
        }
      } else if (element.extension.toLocaleLowerCase().indexOf('pdf') > -1) {
        if (element.crossYear) {
          iconPath = '/assets/images/icons/documents/pdf_pluriennale.svg';
        } else {
          iconPath = '/assets/images/icons/documents/pdf.svg';
        }
      } else if (element.extension.toLocaleLowerCase().indexOf('xbrl') > -1) {
        if (element.crossYear) {
          iconPath = '/assets/images/icons/documents/xbrl_pluriennale.svg';
        } else {
          iconPath = '/assets/images/icons/documents/xbrl.svg';
        }
      } else if (element.extension.toLocaleLowerCase().indexOf('xls') > -1 || element.extension.indexOf('xlsx') > -1) {
        if (element.crossYear) {
          iconPath = '/assets/images/icons/documents/xls_pluriennale.svg';
        } else {
          iconPath = '/assets/images/icons/documents/xls.svg';
        }
      } else if (element.extension.toLocaleLowerCase().indexOf('xml') > -1) {
        if (element.crossYear) {
          iconPath = '/assets/images/icons/documents/xml_pluriennale.svg';
        } else {
          iconPath = '/assets/images/icons/documents/xml.svg';
        }
      } else if (element.extension.toLocaleLowerCase().indexOf('zip') > -1 || element.extension.toLocaleLowerCase().indexOf('rar') > -1) {
        if (element.crossYear) {
          iconPath = '/assets/images/icons/documents/zip_pluriennale.svg';
        } else {
          iconPath = '/assets/images/icons/documents/zip.svg';
        }
      } else if (element.extension.toLocaleLowerCase().indexOf('p7m') > -1) {
        if (element.crossYear) {
          iconPath = '/assets/images/icons/documents/p7m_pluriennale.svg';
        } else {
          iconPath = '/assets/images/icons/documents/p7m.svg';
        }
      } else {
        if (element.crossYear) {
          iconPath = '/assets/images/icons/documents/doc_pluriennale.svg';
        } else {
          iconPath = '/assets/images/icons/documents/doc.svg';
        }
      }
    }

    return iconPath;
  }

}
