<!-- <header>
  <div class="row header">
    <div class="col">
      <div class="row justify-content-between">
        <div class="col">
          <div class="row" style="flex-wrap:nowrap">
            <div class="col-auto">
              <div class="welcomeText">
                {{'header.welcome-message'|translate}} <span *ngFor="let user of users">{{user.firstName}} {{user.lastName}}</span>
                (<a (click)="logout()" href="javascript:;">{{'header.logout'|translate}}</a>)
              </div>
            </div>
            <div class="col-auto" style="display:flex;align-items:center;">
              <div class="sep vertical"></div>
            </div>
            <div class="col d-flex align-items-center">
              <app-sphere-selector></app-sphere-selector>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="rightHeader d-flex flex-row-reverse">
            <div class="p-3">
              <div class="d-inline-block" *ngIf="languages.length>0 && false">
                <ng-select [items]="languages"
                           name="languageSelector"
                           autofocus="false"
                           [searchable]="false"
                           noAutoComplete="true"
                           (change)="changeLanguage()"
                           bindValue="code"
                           bindLabel="description"
                           class="custom"
                           [clearable]="false"
                           [(ngModel)]="currentLanguage">
                </ng-select>
              </div>
              <div class="d-inline-block pointer" ngbDropdown #myDrop="ngbDropdown" display="dynamic"
                   placement="bottom-right">
                <div class="baloon" ngbDropdownToggle id="dropdownManual">
                  {{userInitials|uppercase}}
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownManual">
                  <button ngbDropdownItem (click)="navigateToChangePassword()">{{'header.change-password'|translate}}</button>
                  <button ngbDropdownItem (click)="logout()">{{'header.logout'|translate}}</button>
                </div>
              </div>
            </div>
            <div class="notification-bell m-3" routerLink="/notifications"
                 [ngbTooltip]="notificationCount>0 ? ('header.notification'|translate) : ('header.no-notification'|translate)">
              <span class="badge badge-danger pointer" *ngIf="notificationCount>0">{{ notificationCount }}</span>
              <pwc-icon name="bell" class="notification-image"></pwc-icon>
            </div>
            <div class="p-3" *ngIf="false">
              <input type="text" class="form-control search" value="" placeholder="Ricerca">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header> -->

<div class="row justify-content-between header-container">
    <div class="col">
        <div class="row header-content-left">
            <!-- <div class="col-auto">
        <div class="welcomeText">
          <span translate>header.welcome-message</span>
          <span *ngIf="user">{{user.firstName}} {{user.lastName}}</span>(<a (click)="logout()" class="user-logout"
            translate>header.logout</a>)
        </div>
      </div> -->
            <div class="col-auto">
                <div class="welcomeText">
                    {{'header.welcome-message'|translate}} <span *ngFor="let user of users">{{user.firstName}}
            {{user.lastName}}</span> (
                    <a (click)="logout()" href="javascript:;">{{'header.logout'|translate}}</a>)
                </div>
            </div>
            <div class="col-auto">
                <div class="sep vertical"></div>
            </div>
            <div class="col d-flex align-items-center color-white">
                <app-sphere-selector></app-sphere-selector>
            </div>
            <!-- <div class="col header-ngselect">
        <ng-select bindLabel="name" class="company-select" [items]="societies" [(ngModel)]="selectedSociety"
          (change)="setCurrentSociety()" [clearable]="false">
        </ng-select>
      </div> -->
        </div>
    </div>

    <div class="col">
        <div class="row">
            <div>
                <app-uploader-queue></app-uploader-queue>
                <app-downloader-queue></app-downloader-queue>
            </div>
        </div>
    </div>

    <div class="col">
        <div class="header-actions">
            <div class="notification-bell" routerLink="/notifications" [ngbTooltip]="getNotificationStatus()">
                <span class="badge badge-danger pointer" *ngIf="notificationCount>0">{{ notificationCount }}
        </span>
                <pwc-icon class="icon-white" *ngIf="notificationCount==0; else bell2;" name="bell"></pwc-icon>
                <ng-template #bell2>
                    <pwc-icon name="bell_2"></pwc-icon>
                </ng-template>
                <!-- <img src="/assets/images/icons/bell.svg" class="notification-image" /> -->
            </div>

            <div class="language-selector">
                <ng-select name="languageSelector" autofocus="false" noAutoComplete="true" bindValue="code" bindLabel="description" class="lang-select" (change)="changeLanguage()" [items]="languages" [searchable]="false" [clearable]="false" [(ngModel)]="currentLanguage">
                </ng-select>
            </div>
            <div class="pointer" ngbDropdown #myDrop="ngbDropdown" display="dynamic" placement="bottom-right">
                <div class="baloon" ngbDropdownToggle id="dropdownManual">
                    {{userInitials|uppercase}}
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownManual">
                    <button ngbDropdownItem (click)="navigateToChangePassword()">{{'header.change-password'|translate}}</button>
                    <button ngbDropdownItem (click)="logout()">{{'header.logout'|translate}}</button>
                </div>
                <!-- <div ngbDropdownMenu aria-labelledby="dropdownManual">
          <button ngbDropdownItem (click)="navigateToChangePassword()" translate>header.change-password</button>
          <button ngbDropdownItem (click)="logout()" translate>header.logout</button>
        </div> -->
            </div>
            <!--<div class="p-3">
        <input type="text" class="form-control search" value="" placeholder="Ricerca">
      </div>-->
        </div>
    </div>
</div>