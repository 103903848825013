import { Component, OnInit } from '@angular/core';
import { NotificationsListService } from 'src/app/services/notifications-list.service';
import packageJson from '../../../../package.json'

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  BEversion: string = '';
  FEversion: string = packageJson.version;
  showingVersions = false;

  constructor(private notificationsSrv: NotificationsListService) { }


  ngOnInit() {

    // if BE version is not already set in session storage
    // if(!this.getBEVersionOnSessionStorage()){

    // calls version api and set it
    this.getBEversion();

    // } else {

    // otherwise retrieves it from session storage
    // this.BEversion = this.getBEVersionOnSessionStorage();

    // }
  }

  /**
   * Calls notification service which retrieves BE version from the api
   */
  getBEversion(): void {

    this.notificationsSrv.getBEVersion().subscribe(BEversion => {
      this.BEversion = BEversion;
      // this.setBEVersionOnSessionStorage(this.BEversion);
    });

  }

  /**
   * Sets BE version string in session storage
   * @param BE versions string
   * Deprecated: it has to always refresh this data from the api
   */
  // setBEVersionOnSessionStorage(BEversion: string) {

  //     sessionStorage.setItem('BEversion', BEversion);

  // }

  /**
   * Retrieves BE version within session storage
   * Deprecated: it has to always refresh this data from the api
   */
  // getBEVersionOnSessionStorage(): string {

  //   return sessionStorage.getItem('BEversion');

  // }

}
