import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Society} from '../../models/common/society';

@Injectable({providedIn: 'root'})
export class SocietiesService {
  constructor(private http: HttpClient) {
  }

  getAll() {
    return this.http.get<Society[]>(`${environment.authUrl}/societies`);
  }

  getUserCompany() {
    return this.http.get<Society[]>(`${environment.authUrl}/societies`);
  }
}
