import { AndOrEnum } from '../../../enums/common/and-or.enum';
import { DocumentTypeEnum } from '../../../enums/document-type.enum';
import { ErrorCode } from '../../../enums/law-conservation/error-code.enum';
import { ApiResource } from '../../common/base/api-resource';
import { DmsFileHistoryResource } from '../dms-file-history-resource';
import { DmsSharingResource } from '../share/dms-sharing-resource';
import { SphereResource } from '../sphere-resource';
import { DmsElementMetadataResource } from './dms-element-metadata-resource';
import { DmsElementSearchMetadataResource } from './dms-element-search-metadata-resource';

export class DmsElementResource extends ApiResource {
  name: string;
  searchInTheTree?: boolean;
  parent?: DmsElementResource;
  parentId: number;
  sphere?: SphereResource;
  sphereId?: number;
  userId?: number;
  fiscalYear?: number; // Year of fiscal year.
  documentType: DocumentTypeEnum;
  customFolder: boolean;

  lawConservation: boolean;
  lastLawConservationExecution?: Date;
  excludeFromLawConservation?: boolean;

  isParentAZipFile?: boolean;
  isParentAZipFileLawConserved?: boolean;

  fiscalStartDate?: number;
  fiscalEndDate?: number;
  metadata?: DmsElementMetadataResource[] = [];
  metadataComplete?: boolean;
  history?: DmsFileHistoryResource[] = [];
  fileHistorySelected?: DmsFileHistoryResource;
  documentDate?: number;
  isShared?: boolean;
  sharingIds?: number[] = [];
  dmsSharing?: DmsSharingResource;

  // ------- SEARCH -------
  tagCodes?: string[] = [];
  searchStartDate?: number;
  searchEndDate?: number;
  recent?: boolean;
  createdDateFrom?: number;
  createdDateTo?: number;
  docClassType?: string[] = [];
  searchMetadata?: DmsElementSearchMetadataResource[] = [];
  searchMetadataOperator?: AndOrEnum;
  lawConservationErrorCode?: ErrorCode;
  // ---------------------

  favorite?: boolean;

  path?: string;
  page?: number;
  sort?: string;
  sortType?: string;
}
