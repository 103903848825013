<pwc-modal #signModal
           id="signModal"
           class="modal-body"
           (closed)="signModal.onClose()">

  <span modal-title>{{'documents.signature.digital-signature'|translate}}</span>

  <div modal-body>
    <span>{{'documents.signature.to-sign'|translate}}</span>
    <div class="form-group form-input-group row mt-4 mb-2">
      <div class="col-md-12">
        <label for="signTypes" class="form-input-label col-md-12">
          {{'documents.law.configuration.system-and-signature'|translate}}
        </label>
        <div class="col-md-12">
          <ng-select id="signTypes" [items]="signTypes" bindLabel="signTypes"
                     appendTo="signModal" [readonly]="true"
                     [(ngModel)]="signType"></ng-select>
        </div>
      </div>
    </div>
    <div class="row form-group form-input-group">
      <div class="col-md-6">
        <label for="signAlias" class="form-input-label col-md-12">
          {{'documents.law.configuration.alias-signature'|translate}}
        </label>
        <div class="col-md-12">
          <input type="text"
                 id="signAlias"
                 name="signAlias"
                 class="form-control form-input input-with-border">
        </div>
      </div>
      <div class="col-md-6">
        <label for="signPin" class="form-input-label col-md-12">
          {{'documents.law.configuration.pin-signature'|translate}}
        </label>
        <div class="col-md-12">
          <input type="text"
                 id="signPin"
                 name="signPin"
                 class="form-control form-input input-with-border">
        </div>
      </div>
    </div>

    <hr>

    <span>{{'documents.signature.upload-scan'|translate}}</span>
    <!--(fileOver)="fileOver($event)"
         (onFileDrop)="onFileAdded($event)"
         [uploader]="uploader"-->
    <div directoryDrop
         [ngClass]="{'nv-file-over': hasDropZoneOver}"
         class="well drop-zone d-flex flex-column ml-0 justify-content-center align-items-center"
         style="min-height: 20vh; margin-top: 5px;">
      <div class="drop-text">
        {{'configurator.drag-file-or'|translate}} <a href="javascript:;" (click)="files.click()">{{'documents.uploader.select-file'|translate}}</a>
        <input #files type="file" style="display:none;" ng2FileSelect>
      </div>
    </div>
  </div>

  <div modal-footer>
    <button type="button" class="btn btn-default mx-2" (click)="signModal.close()" translate>
      {{'generic.action.cancel'|translate}}
    </button>
    <button type="button"
            class="btn btn-undo mx-2"
            (click)="signModal.close()"
            translate>
            {{'generic.action.save'|translate}}
    </button>
  </div>
</pwc-modal>
