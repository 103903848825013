<div class="d-flex flex-row mb-2" *ngFor="let comment of commentList">
  <div class="ml-3 w-100 flex-grow-1">
    <div class="item form-group-sm form pt-0">
      <div class="mb-2 mt-2 w-100">{{comment.text}}</div>
      <div style="color: #b4b4b4;"> {{comment.createdDate | date: 'dd/MM/yyyy HH:mm'}}
      </div>
    </div>
    <hr>
  </div>
</div>
