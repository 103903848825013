import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {AttachmentEntry} from '../../../models/attachment/attachment-entry';
import {ModalComponent} from '../../common/modals/modal/modal.component';

@Component({
  selector: 'app-start-attachment-modal',
  templateUrl: './start-attachment-modal.component.html',
  styleUrls: ['./start-attachment-modal.component.scss']
})
export class StartAttachmentModalComponent {

  @Input() elements: AttachmentEntry[] = [];
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() continue: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('startAttachmentModal') modal: ModalComponent;

  open(): void {
    this.modal.open();
  }

  close(): void {
    this.modal.close();
  }

  onCancel(): void {
    this.cancel.emit();
  }

  onContinue(): void {
    this.continue.emit();
    this.modal.close();
  }

  onConfirm(): void {
    this.confirm.emit();
  }

  get elementsSize(): number {
    return this.elements.length;
  }
}
