import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {LoggerInterceptor} from '../../helpers/common/logger-interceptor';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: LoggerInterceptor, multi: true},
  ]
})
export class HttpInterceptorsModule {
}
