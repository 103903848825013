<h2>{{'vdr.title'|translate}}</h2>
<div class="card" *ngIf="!loaded || data.length">
    <div class="card-body card-table" *ngIf="data.length && viewChecked">
        <data-table  #dataTable [dataSource]="data" [columns]="columns"
        [customColumnDefs]="customColumns" [customColumns]="customColumnsActive" [enableSelect]="false" >

            <ng-container cdkColumnDef="society">
                <th cdk-header-cell *cdkHeaderCellDef scope="col">
                    {{'legal-entity.society'|translate}}
                </th>
                <td cdk-cell *cdkCellDef="let row">
                    {{(row.societyName ? row.societyName : '')}}
                </td>
            </ng-container>

            <ng-container cdkColumnDef="society_vat">
                <th cdk-header-cell *cdkHeaderCellDef scope="col">
                    {{'legal-entity.society_vat'|translate}}
                </th>
                <td cdk-cell *cdkCellDef="let row">
                    {{(row.societyVatNumber ? row.societyVatNumber : '')}}
                </td>
            </ng-container>

            <ng-container cdkColumnDef="vdr">
                <th cdk-header-cell *cdkHeaderCellDef scope="col">
                    {{'vdr.belonging-to'|translate}}
                </th>
                <td cdk-cell *cdkCellDef="let row">
                    <span class="d-inline-block">
                        <ng-container *ngIf="row.vdr else noVdr">
                          {{row.vdr === true ? ('generic.simple-word.yes'|translate) : 'generic.simple-word.no'|translate}}</ng-container>
                        <ng-template #noVdr>{{'generic.simple-word.no'|translate}}</ng-template>
                    </span>
                </td>
            </ng-container>

            <ng-container cdkColumnDef="actions">
                <th cdk-header-cell *cdkHeaderCellDef scope="col">
                {{'exports-history.headers.actions'|translate}}
                </th>
                <td cdk-cell *cdkCellDef="let row">
                    <button (click)="admitSociety(row)" *ngIf="!row.vdr">
                        <pwc-icon name="add" [ngbTooltip]="'vdr.include'|translate" placement="left">
                        </pwc-icon>
                    </button>
                    <button (click)="removeSociety(row)" *ngIf="row.vdr">
                        <pwc-icon name="delete" [ngbTooltip]="'vdr.remove'|translate" placement="left">
                        </pwc-icon>
                    </button>
                </td>
            </ng-container>

        </data-table>

    </div>
    <app-table-content-loader *ngIf="loadingDocuments$ | async"></app-table-content-loader>
</div>

<div *ngIf="!data.length && loaded" class="empty-spheres-list">
    {{"vdr.no-societies"|translate}}
</div>

<ap-alert [(show)]="vdrAlertSuccess" [alertType]="'success'" [iconName]="'icon-confirmed-fill'">
    <span>{{'vdr.alert.left'|translate}}</span><span class="font-weight-bold">{{currentActionPerformedOnSociety}}</span><span>
        <ng-container *ngIf="addOrRemoveAction === 'add' else removed">{{'vdr.alert.right-add'|translate}}</ng-container>
        <ng-template #removed>{{'vdr.alert.right-remove'|translate}}</ng-template>
    </span>
</ap-alert>