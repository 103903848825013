import {Component} from '@angular/core';
import {ContentLoaderComponent} from '@ngneat/content-loader';

@Component({
  selector: 'pwc-line-content-loader',
  templateUrl: './line-content-loader.component.html',
  styleUrls: ['./line-content-loader.component.scss']
})
export class LineContentLoaderComponent extends ContentLoaderComponent {
}
