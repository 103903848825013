import {BaseModel} from '../common/base/base-model';
import {DmsElement} from '../dms-element';

export class ConnectionAlias extends BaseModel {
  fiscalYear: number;
  fiscalYearId: number;
  fiscalStartDate?: Date;
  fiscalEndDate?: Date;
  element?: DmsElement;
  elementId: number;
  parent?: DmsElement;
  parentId?: number;
}
