import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  LOCALE_ID,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { DmsElement } from '../../models/dms-element';
import { Observable, of, Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { PageableResponse } from '../../models/api/pageable-response';
import { ColumnDef } from '../../models/common/data-table/column-def';
import { SortDirectionEnum } from '../../enums/common/sort-direction.enum';
import { Router } from '@angular/router';
import { CdkColumnDef } from '@angular/cdk/table';
import { NgbdSortableHeaderDirective, SortEvent } from '../../directives/common/sortable.directive';
import { DataTableComponent } from '../common/data-table/data-table.component';
import { ApplicationEvent } from '../../models/event/application-event';
import { ApplicationEventResource } from '../../models/api/event/application-event-resource';
import { EventElementsService } from '../../services/events/event-elements.service';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Sphere } from '../../models/sphere';
import { SphereService } from '../../services/sphere.service';
import { EventType } from '../../models/event/event-type';
import { piClear, piDelete, piDownloadSimple, piEdit, piView, PwcIconsLibrary } from "@pwc/icons";
import { ExportsHistoryService } from 'src/app/services/exports/exports-history.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastUtils } from '../../helpers/toast-utils';
import { ToastrService } from 'ngx-toastr';
import { DocumentConfirmModalComponent } from '../documents/document-confirm-modal/document-confirm-modal.component';
import { ExportHistory } from 'src/app/models/api/export-history/export-history';
import { DownloaderService } from 'src/app/services/downloader.service';
import { DocumentLoaderService } from 'src/app/services/document-loader.service';

@Component({
  selector: 'app-exports-history',
  templateUrl: './exports-history.component.html',
  styleUrls: ['./exports-history.component.scss']
})
export class ExportsHistoryComponent implements OnInit, AfterViewChecked {

  data: ExportHistory[] = [];
  pagination: PageableResponse<ApplicationEventResource[]>;
  columns: ColumnDef<ApplicationEvent>[] = [];
  customColumnsActive: string[] = [];
  deleting: boolean = false;
  selectedResults: ExportHistory[] = [];
  statusMap = new Map<string, string>();
  loadingDocuments$: Observable<boolean> = this.documentLoaderService.loading$;
  viewChecked = false;

  loaded: Boolean = false;

  searchTerm = '';
  searchUser = null;
  searchSubject: Subject<string> = new Subject<string>();
  search$: Observable<string> = this.searchSubject.asObservable();
  page: number = 0;
  pageSize: number = 50;

  currentSphereId: number;
  eventsType: EventType[] = [];
  eventCodes: string[] = [];
  usernames: string[] = [];

  sort: Map<string, SortDirectionEnum> = new Map();
  sortType = '';
  sortReverse = 0;
  deleteAll: boolean = false;

  @ViewChild('dataTable') dataTable: DataTableComponent<DmsElement>;
  @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;
  @ViewChildren(CdkColumnDef) customColumns: QueryList<CdkColumnDef>;
  @ViewChild('confirmModal') confirmModal: DocumentConfirmModalComponent;

  constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef, private sphereService: SphereService,
    private exportsService: ExportsHistoryService, private iconsLibrary: PwcIconsLibrary, private translateSrv: TranslateService, private toast: ToastrService, private downloadSrv: DownloaderService, private documentLoaderService: DocumentLoaderService<ExportHistory>) {
    iconsLibrary.registerIcons([
      piClear,
      piView,
      piEdit,
      piDelete,
      piDownloadSimple
    ]);
    // this.eventsType = this.eventService.getEventTypeValues();
  }

  ngOnInit(): void {
    // this.exportsService.pagination$.subscribe((pagination: PageableResponse<ApplicationEventResource[]>) => {
    //   this.pagination = pagination;
    // });

    this.documentLoaderService.startLoader();

    // status labels
    this.statusMap.set("PROGRESS", "exports-history.status.ongoing");
    this.statusMap.set("READY", "exports-history.status.done");
    this.statusMap.set("INIT", "");
    this.statusMap.set("ERROR", "exports-history.status.error");

    this.sphereService.currentSphere.pipe(
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          // TODO: show error toast
          return of(this.data);
        }
        const search: Partial<ExportHistory> = {
          sphereId: sphere.id
        };
      })), (err: Error) => {
        this.toast.error(this.translateSrv.instant('generic.message.unexpected-error'), this.translateSrv.instant(ToastUtils.TITLE_ERROR));
      };
    this.searchTerm = '';
    // this.refreshList(this.currentSphereId);

    this.getAllExports();
  }

  emptyFilters() {
    this.searchTerm = '';
    this.onSearch();
  }

  /**
   * Retrieves all export histories from backend and maps them into this.data
   */
  getAllExports() {
    this.search$.pipe(
      debounceTime(200),
      switchMap(() => this.sphereService.currentSphere),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        this.currentSphereId = sphere.id;
        return this.refreshExports(this.currentSphereId, this.searchTerm, this.page, this.pageSize, 'date', 'asc');
      }),
    ).subscribe((res: any) => {
      if (res && res.content) {
        this.updateElements(res.content);
        this.documentLoaderService.commit(res.content || []);
        this.loaded = true;
      } else {
        this.updateElements([]);
      }
    }), (err: Error) => {
      this.toast.error(this.translateSrv.instant('generic.message.unexpected-error'), this.translateSrv.instant(ToastUtils.TITLE_ERROR));
      this.data = this.documentLoaderService.rollback();
    };
  }

  /**
   * Maps backend exports elements into this.data
   * @param elements 
   */
  private updateElements(elements: ExportHistory[]) {
    this.data = [...elements] || [];
    this.refreshColumns();
  }

  private refreshColumns() {
    let columns = [];
    columns.push('export_name');
    columns.push('path');
    columns.push('fy');
    columns.push('status');
    columns.push('date');
    columns.push('actions');
    this.customColumnsActive = columns;
  }

  private refreshExports(sphereId: number, searchTerm?: string, page?: number, pageSize?: number, sortColumn?: string, sortDirection?: string): Observable<ExportHistory[]> {
    return this.exportsService.findAll(sphereId, searchTerm, sortColumn, sortDirection, page, pageSize); // TODO: handle sort with map
  }

  /**
   * Maps backend status enum to correct labels
   * @param dbStatus 
   * @returns 
   */
  mapStatus(dbStatus: string): string {
    if (!dbStatus || dbStatus === '') {
      return '';
    } else {
      return this.statusMap.get(dbStatus);
    }
  }

  ngAfterViewChecked(): void {
    this.viewChecked = true;
    this.changeDetectorRef.detectChanges();
  }

  onSearch() {
    this.searchSubject.next(this.searchTerm);
    var sortColumn = this.sort.keys()[0];
    var sortDirection = this.sort.values()[0];
  }

  onPageChange(page: number): void {
    page += 1;
    this.exportsService.findAll(this.currentSphereId, '', 'date', 'asc', page, 50).pipe()
      .subscribe((res: ExportHistory[]) => this.updateElements(res));
  }

  onSort($event: SortEvent) {
    this.loaded = false;
    this.data = [];
    this.documentLoaderService.startLoader();
    if ($event.direction === SortDirectionEnum.NONE) {
      this.sortReverse = 0;
    } else if ($event.direction === SortDirectionEnum.ASC) {
      this.sortReverse = 1;
    } else if ($event.direction === SortDirectionEnum.DESC) {
      this.sortReverse = 2;
    }

    this.sortType = $event.column;
    if ($event.direction === SortDirectionEnum.NONE) {
      this.sort.delete($event.column);
    } else {
      this.sort.set($event.column, $event.direction);
    }
    this.refreshExports(this.currentSphereId, this.searchTerm, this.page, this.pageSize, $event.column, $event.direction)
      .subscribe((res: any) => {
        if (res && res.content) {
          this.updateElements(res.content);
          this.documentLoaderService.commit(res.content || []);
          this.loaded = true;
        } else {
          this.updateElements([]);
        }
      });
  }

  // getEventTypeLabel(eventType: string) {
  //   return this.eventService.getEventTypeLabel(eventType);
  // }

  private getSearchBody(idSphere: number): Partial<ApplicationEventResource> {
    const data: Partial<ApplicationEventResource> = { sphereId: idSphere };

    if ((!isNullOrUndefined(this.eventCodes) && this.eventCodes.length > 0)) {
      data.eventTypeCodes = [];
      data.eventTypeCodes.push(...this.eventCodes);
    }

    if (!isNullOrUndefined(this.searchUser)) {
      data.username = this.searchUser;
    }

    // if (!isNullOrUndefined(this.searchTerm) && this.searchTerm !== '' && this.searchTerm.length >= 3) {
    //   data.description = this.searchTerm;
    // }

    if (!isNullOrUndefined(this.sort)) {
      if (isNullOrUndefined(data.sort)) {
        data.sort = '';
      }
      this.sort.forEach((value, key) => {
        data.sort = key + ',' + value;
      });
    } else {
      data.sort = null;
    }

    return data;
  }

  /**
   * Function called by selecting all elements with the uppest square
   * @param select 
   */
  selectAllRows(select: boolean) {
    this.data.map((item: ExportHistory) => {
      item.selected = select;
    });
    this.selectedResults = select ? [...this.data] : [];
  }

  /**
   * Function called by selecting every element with its square
   * @param id 
   */
  selectRows(id: number) {
    for (const item of this.data) {
      if (item.id === id) {
        item.selected = !item.selected;
      }
    }
    this.selectedResults = this.data.filter(e => e.selected);
  }

  getSelectedFileIds(): number[] {
    const ids: number[] = [];
    this.selectedResults.filter(value => value.status === 'READY').forEach(value => ids.push(value.id));
    return ids;
  }

  get selectedCount(): number {
    return this.selectedResults.length;
  }

  /**
   * Downloads csv export on click
   * @param token 
   * @returns 
   */
  downloadExport(item: ExportHistory) {
    return this.downloadSrv.downloadExportHistory(item);
  }

  /**
   * Modal opened by clicking the delete button or the delete all button.
   * It triggers the deletion of all (or only selected) exports (depending if any have been selected or not)
   */
  confirmDeleteModal(): void {
    const selectIds: number[] = [];
    for (const item of this.selectedResults) {
      selectIds.push(item.id);
    }
    var sortColumn = this.sort.keys()[0];
    var sortDirection = this.sort.values()[0];
    this.deleting = true;
    this.loaded = false;
    this.data = [];
    this.documentLoaderService.startLoader();
    if (this.deleteAll) {
      this.exportsService.softDeleteAll(this.currentSphereId)
        .subscribe(() => {
          this.confirmModal.close();
          this.deleting = false;

          this.refreshExports(this.currentSphereId, '', this.page, this.pageSize, sortColumn ? sortColumn.value : null, sortDirection ? sortDirection.value : null)
            .subscribe((res: any) => {
              if (res && res.content) {
                this.updateElements(res.content);
                this.documentLoaderService.commit(res.content || []);
                this.loaded = true;
              } else {
                this.updateElements([]);
              }
              this.toast.success(this.translateSrv.instant('generic.message.successful-delete'), this.translateSrv.instant(ToastUtils.TITLE_SUCCESS));
            });
        });
    } else {
      this.exportsService.softDeleteByIds(this.currentSphereId, selectIds)
        .subscribe(() => {
          this.confirmModal.close();
          this.deleting = false;

          this.refreshExports(this.currentSphereId, '', this.page, this.pageSize, sortColumn ? sortColumn.value : null, sortDirection ? sortDirection.value : null)
            .subscribe((res: any) => {
              if (res && res.content) {
                this.updateElements(res.content);
                this.documentLoaderService.commit(res.content || []);
                this.loaded = true;
              } else {
                this.updateElements([]);
              }
              this.selectedResults = [];
              this.toast.success(this.translateSrv.instant('generic.message.successful-delete'), this.translateSrv.instant(ToastUtils.TITLE_SUCCESS));
            });
        });
    }
  }

  /**
   * Opens deletion modal on delete-all/delete-selected button click
   * @param ids 
   */
  openDeleteAllOrSelectedModal() {
    if (!this.selectedResults.length) {
      this.deleteAll = true;
    }
    this.confirmModal.open();
  }

  /**
   * Opens deletion modal on delete-one button click
   * @param exportHistory 
   */
  openDeleteOneModal(exportHistory: ExportHistory): void {
    this.confirmModal.open();
    this.selectedResults = [];
    this.selectedResults.push(exportHistory);
  }

}
