<nav id="sidebar" [ngClass]="{active: active}">
    <!-- <div class="sidebar-header">
    <img src="/assets/images/logo.png" class="logo">
    <button type="button" id="sidebarCollapse" class="btn btn-info" style="display: none">
      <i class="fas fa-align-left"></i>
    </button>
  </div> -->

    <!-- <ul class="list-unstyled components"> -->
    <li class="mainActive pointer">
        <a (click)="menuCommunicoCollaped = !menuCommunicoCollaped" [attr.aria-expanded]="!menuCommunicoCollaped" aria-controls="collapseCommunico">
            <pwc-icon name="documentale" class="svg-main"></pwc-icon>
            <span *ngIf="showDesc" translate>menu.label</span>
            <div class="card list-2nd-level" *ngIf="false">
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li>
                            <a [routerLink]="" (click)="goToCommunico()">
                                <pwc-icon name="home"></pwc-icon>
                                <span translate>menu.submenu</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </a>
        <ul class="list-unstyled list-2liv" [ngbCollapse]="menuCommunicoCollaped" id="collapseCommunico">
            <li>
                <a [routerLink]="" (click)="goToCommunico()">
                    <pwc-icon class="icon-white" name="home" class="svg-main"></pwc-icon>
                    <span translate *ngIf="showDesc">menu.submenu</span>
                </a>
            </li>
            <hr>
        </ul>
    </li>
    <!--    <li routerLinkActive="active">-->
    <!--      <a routerLink="/dashboard">-->
    <!--        <img src="/assets/images/icons/menu-laterale-dashboard.svg">-->
    <!--        <span *ngIf="showDesc" translate>menu.dashboard.label</span>-->
    <!--      </a>-->
    <!--    </li>-->
    <li routerLinkActive="active">
        <a routerLink="/documents">

            <pwc-icon name="document_page" class="svg-document-page"></pwc-icon>
            <span *ngIf="showDesc" translate>menu.documents.label</span>
        </a>
    </li>
    <li routerLinkActive="active">
        <a routerLink="/sharing">
            <pwc-icon name="share" class="svg-share"></pwc-icon>
            <span *ngIf="showDesc" translate>menu.shares.label</span>
        </a>
    </li>
    <li routerLinkActive="active">
        <a routerLink="/events" class="svg-events">
            <img src="/assets/images/icons/calendar-clock-sidebar.svg">
            <span *ngIf="showDesc" translate>menu.events.label</span>
        </a>
    </li>
    <li routerLinkActive="active">
        <a routerLink="/tags" class="svg-tags">
            <img src="/assets/images/icons/tag-sidebar.svg">
            <span *ngIf="showDesc" translate>menu.tags.label</span>
        </a>
    </li>
    <!--    <li routerLinkActive="active">-->
    <!--      <a>-->
    <!--        <img src="/assets/images/icons/files.svg">-->
    <!--        <span *ngIf="showDesc" translate>menu.files.label</span>-->
    <!--      </a>-->
    <!--    </li>-->

    <li class="pointer" routerLinkActive="active" *ngIf="permissionLawConservation">
        <a (click)="menuArchiveCollaped = !menuArchiveCollaped" [attr.aria-expanded]="!menuArchiveCollaped" aria-controls="collapseArchive" class="svg-archive">
            <img src="/assets/images/icons/lock-closed-sidebar.svg">
            <span *ngIf="showDesc" translate>menu.archive.label</span>
        </a>
        <ul class="list-unstyled list-2liv" [ngbCollapse]="menuArchiveCollaped" id="collapseArchive">
            <li routerLinkActive="active">
                <a routerLink="/archive-summary">
                    <img src="/assets/images/icons/menu-laterale-law-conservation-riepilogo_invio.svg">
                    <span translate *ngIf="showDesc">menu.archive.summary</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/archive-configuration">
                    <img src="/assets/images/icons/menu-laterale-law-conservation-configurazione.svg">
                    <span translate *ngIf="showDesc">menu.archive.configuration</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/archive-bucket">
                    <img src="/assets/images/icons/menu-laterale-law-conservation-gestione_credenziali.svg">
                    <span translate *ngIf="showDesc">menu.archive.bucket</span>
                </a>
            </li>
            <hr>
        </ul>
    </li>
    <li routerLinkActive="active">
        <a routerLink="/exports" class="svg-tags">
            <img src="/assets/images/icons/menu-laterale-export.svg">
            <span translate>menu.exports-history.label</span>
        </a>
    </li>
    <li *ngIf="adminPermission" routerLinkActive="active">
        <a routerLink="/import" class="svg-tags">
            <img src="/assets/images/icons/menu-laterale-monitoraggio.svg">
            <span translate>menu.import</span>
        </a>
    </li>
    <li *ngIf="adminPermission" routerLinkActive="active">
        <a routerLink="/admin-export" class="svg-tags">
            <img src="/assets/images/icons/menu-laterale-scenario.svg">
            <span translate>menu.admin-export</span>
        </a>
    </li>
    <li *ngIf="adminPermission" routerLinkActive="active">
        <a routerLink="/vdr" class="svg-tags">
            <img src="/assets/images/icons/menu-laterale-raccolta.svg">
            <span translate>menu.vdr</span>
        </a>
    </li>

    <!-- Redirect -->
    <li class="pointer" routerLinkActive="active" *ngIf="showPAurl">
        <a (click)="redirectToPA()">
            <pwc-icon name="shield_check_white" class="svg-main"></pwc-icon>
            <span translate>menu.redirect-to-pa.label</span>
        </a>
    </li>

</nav>