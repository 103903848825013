import { SelectableModel } from '@pwc/common';
import { DmsElement } from '../dms-element';

export class DmsSharingEntity extends SelectableModel {
  id: number; // Share2Elements id
  elementId: number;
  fiscalYearId: number;
  fiscalYear: number;
  element: DmsElement;
  years: string;
}
