import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalComponent} from '../../common/modals/modal/modal.component';
import {DmsFolderRepositoryService} from '../../../services/dms-folder-repository.service';
import {DmsElement} from '../../../models/dms-element';
import {FiscalYearResource} from '../../../models/api/fiscal-year-resource';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-document-confirm-law-conservation-send-now-modal',
  templateUrl: './law-conservation-send-now-modal.component.html',
  styleUrls: ['./law-conservation-send-now-modal.component.scss']
})
export class LawConservationSendNowModalComponent implements OnInit {

  @Input() documentConfirmLawConservationSendNowModalId = 'document-law-conservation-send-now-modal-id';
  @Input() title: string;
  @Input() message: string;
  @Input() positiveButtonText = this.translateSrv.instant('generic.action.confirm');
  @Input() negativeButtonText = this.translateSrv.instant('generic.action.cancel');

  @Input() sphereId: number;
  @Input() selectedYear: FiscalYearResource;
  @Input() selectedResults: DmsElement[] = [];

  @ViewChild('lawConservationSendNowModal') documentConfirmModal: ModalComponent;

  @Output() negativeButtonClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() positiveButtonClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() closed: EventEmitter<string> = new EventEmitter<string>();

  constructor(private dmsRepositoryService: DmsFolderRepositoryService, private translateSrv: TranslateService) {
  }

  ngOnInit(): void {
  }

  open() {
    this.message = this.translateSrv.instant('generic.message.conservation-sent-now');
    this.positiveButtonText = this.translateSrv.instant('generic.action.confirm');
    this.negativeButtonText = this.translateSrv.instant('generic.action.cancel');
    this.documentConfirmModal.open();
  }

  onPositiveClick() {
    this.documentConfirmModal.close();
    this.positiveButtonClick.emit();
  }

  onNegativeClick() {
    this.documentConfirmModal.close();
    this.negativeButtonClick.emit();
  }

  onClose($event: string) {
    this.closed.emit($event);
  }

}
