import { EventEmitter, Injectable } from '@angular/core';
import { AuthenticationService } from '@pwc/security';
import { environment } from '../../environments/environment';
import { FileSaverService } from 'ngx-filesaver';
import { FileItem } from '../modules/file-upload/file-item.class';
import { FileUploader, FileUploaderOptions } from '../modules/file-upload/file-uploader.class';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ConfiguratorImportService {
  uploader: FileUploader;
  onUploadCompleted: EventEmitter<void> = new EventEmitter<void>();
  onUploadError: EventEmitter<void> = new EventEmitter<void>();

  private readonly uploaderOptions: FileUploaderOptions;

  constructor(private authenticationService: AuthenticationService,
    private fileSaverService: FileSaverService,
    private http: HttpClient) {
    const cookie = this.authenticationService.cookieValue;

    this.uploaderOptions = {
      url: `${environment.apiUrl}/config-import`,
      authTokenHeader: 'Authorization',
      authToken: `Bearer ${cookie.access_token}`
    };

    this.uploader = new FileUploader(this.uploaderOptions);
  }

  public upload() {
    if (!this.uploader.queue.length) {
      console.warn('ConfiguratorImportService: uploader queue is empty.');
      return;
    }

    this.refreshOptions();

    this.uploader.onBeforeUploadItem = (item: FileItem) => {
      item.withCredentials = false;
      item._xhr.responseType = 'blob';
    };

    this.uploader.onSuccessItem = (item: FileItem, response: any, status: number) => {
      // console.debug('onSuccessItem', {item, response, status});
      this.onUploadCompleted.emit();
      this.fileSaverService.save(response, 'result.xlsx');
    };

    this.uploader.onErrorItem = (item: FileItem, response: any, status: number) => {
      this.onUploadError.emit();
      // console.debug('onErrorItem', {item, response, status});
    };

    // this.uploader.onCompleteAll = () => {
    //   this.onUploadCompleted.emit();
    // };

    // console.debug('Upload items');

    this.uploader.uploadAll();
  }

  public sync(): Observable<string> {
    return this.http.post<string>(`${environment.apiUrl}/config-import/sync`, {});
  }

  public syncSphere(sphereId: number): Observable<string> {
    return this.http.post<string>(`${environment.apiUrl}/config-import/sync/${sphereId}`, {});
  }

  private refreshOptions(): void {
    const cookie = this.authenticationService.cookieValue;

    // Ensure the correct auth token is set in the request.
    this.uploaderOptions.authToken = `Bearer ${cookie.access_token}`;

    this.uploader.setOptions(this.uploaderOptions);
  }

  removePending() {
    if (!this.uploader.queue.length) {
      return;
    }

    const toRemove = this.uploader.queue.filter((item: FileItem) => !item.isSuccess && !item.isError);

    for (const item of toRemove) {
      this.uploader.removeFromQueue(item);
    }
  }

  public removeAll(): void {
    this.uploader.queue = [];
  }
}
