<pwc-modal #startAttachmentModal id="start-attachment-modal">
  <div modal-title>
    <img src="/assets/images/icons/documents/toolbar/paper-clip.svg" [alt]="'generic.action.attach'|translate"
         style="width: 40px;height: 40px;">
         {{'generic.action.attach'|translate}}
  </div>

  <div modal-body>
    <div class="row">
      <div class="col-md-12">
            <span *ngIf="elementsSize === 1">
              {{'generic.action.you-selected'|translate}} {{elementsSize}} {{'attachment.start.selected-element-single'|translate}}
        </span>
        <span *ngIf="elementsSize > 1">
          {{'generic.action.you-selected'|translate}} {{elementsSize}} {{'attachment.start.selected-element'|translate}}
        </span>
      </div>
    </div>
  </div>

  <div modal-footer class="text-right">
    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onCancel()">
      {{'generic.action.cancel'|translate}}
    </button>
    <button type="button" class="btn btn-success ml-2" (click)="onContinue()">
      {{'generic.action.add-elements'|translate}}
    </button>
    <button type="button" class="btn btn-success ml-2" (click)="onConfirm()">
      {{'generic.action.attach'|translate}}
    </button>
  </div>
</pwc-modal>
